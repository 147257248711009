import React, { PureComponent } from 'react'
import { loginWithFb, login, requestPassword } from '../../data/auth.data'
import { authenticateUser } from '../../actions/auth.actions'
import { setAuthToken } from '../../utils/session-manager.util'
import { savePreOnboardingRoute } from '../../actions/onboarding.actions'
import { connect } from 'react-redux'
import { RoomiDialog, RoomiButton } from 'roomi/material'
import { Facebook, Mail } from 'roomi/icons'
import jwtDecode from 'jwt-decode'
import { ErrorBanner } from 'roomi/common'
import { FacebookAuthorize } from 'socials'
import PropTypes from 'prop-types'
import LoginForm from './login-form/login-form.component'
import loginModalStyles from './login-modal.styles'
import { withStyles, Grid, Divider } from '@material-ui/core'
import messages from './login-modal.text'
import { FormattedMessage, injectIntl } from 'react-intl'
import track from 'react-tracking'
import { withRouter } from 'react-router-dom';
import ForgotPasswordForm from './forgot-form/forgot-form.component'
import successImage from './assets/sms_verification.png'
import RoomiNavLink from '../../../shared/components/roomi-nav-link/roomi-nav-link.component'

const modalClasses = {}

@withRouter
@injectIntl
@connect(null, { authenticateUser })
@withStyles(loginModalStyles)
@track({})
class LoginModal extends PureComponent {
  static propTypes = {
    onAuthentication: PropTypes.func,
    onCancel: PropTypes.func,
    preSignUp: PropTypes.func,
    isOpen: PropTypes.bool.isRequired
  }

  state = {
    showForm: 'mainLogin',
    error: '',
    processing: false
  }

  onSubmit = body => {
    this.setState({ processing: true, error: '' })
    if (this.state.showForm === 'roomiLogin') {
      return login(body).then(this.handleSuccess, this.onError)
    }
    if (this.state.showForm === 'forgotPassword') {
      return requestPassword(body).then(
        this.handlePasswordRequestSuccess,
        this.onError
      )
    }
    return null
  }

  setRoomiLogin = () => this.setState({ showForm: 'roomiLogin' })
  setForgotPassword = () =>
    this.setState({ showForm: 'forgotPassword', error: '' })

  handlePasswordRequestSuccess = () => {
    this.setState({ showForm: 'successPasswordRequest', processing: false })
  }

  handleFacebookLogin = accessToken => {
    this.setState({ processing: true })
    loginWithFb({ accessToken }).then(this.handleSuccess, this.onError)
  }

  handleSuccess = ({ token }) => {
    setAuthToken(token)
    this.setState({
      showForm: 'mainLogin',
      error: '',
      processing: false
    })

    const { _id } = jwtDecode(token)
    this.props.authenticateUser(_id, true)
    this.props.onAuthentication && this.props.onAuthentication()
  }

  @track({ action: 'Click_GetStarted' })
  beforeSignUp = () => {
    savePreOnboardingRoute()
    this.props.preSignUp && this.props.preSignUp()
    this.handleClose()
  }

  onError = error => this.setState({ error, processing: false })

  handleClose = () => {
    this.props.onCancel && this.props.onCancel()
  }

  resetError = () => this.setState({ error: null })

  trigger = () => (
    <RoomiButton
      primary
      small
      fullWidth
      className={this.props.classes.facebookButton}
      startAdornment={<Facebook />}
      label={this.props.intl.formatMessage(messages.loginFacebook)}
    />
  )

  stopPropagate = e => e.stopPropagation()

  render() {
    const {
      isOpen,
      classes,
      intl: { formatMessage }
    } = this.props
    const { showForm, error, processing } = this.state
    if (modalClasses.paper !== classes.paper) modalClasses.paper = classes.paper
    return (
      <RoomiDialog
        open={isOpen}
        onBackdropClick={this.handleClose}
        dialogClasses={modalClasses}
        onClose={this.handleClose}
        onDoubleClick={this.stopPropagate} // for experiments switcher
      >
        <ErrorBanner
          error={error}
          onHide={this.resetError}
          className="aut-text-error"
        />
        <div className={classes.loginModal}>
          <Grid container direction="column" spacing={16}>
            {showForm === 'mainLogin' && (
              <Grid item className={classes.title}>
                <FormattedMessage {...messages.hi}>
                  {value => <span className="aut-text-copy">{value}</span>}
                </FormattedMessage>
              </Grid>
            )}
            {showForm !== 'successPasswordRequest' && (
              <Grid
                container
                direction="column"
                spacing={16}
                className={classes.formWrapper}
              >
                {showForm === 'mainLogin' && (
                  <Grid item>
                    <FacebookAuthorize
                      onAuthorization={this.handleFacebookLogin}
                      redirectType="login"
                      isDisabled={processing}
                      trigger={this.trigger}
                      fullWidth
                      className="aut-button-facebook"
                    />
                    <Divider />
                  </Grid>
                )}
                <Grid item>
                  {showForm === 'mainLogin' && (
                    <RoomiButton
                      primary
                      small
                      fullWidth
                      startAdornment={<Mail height={18} width={18} />}
                      onClick={this.setRoomiLogin}
                      label={formatMessage(messages.loginEmail)}
                      className="aut-button-email"
                    />
                  )}
                  {showForm === 'roomiLogin' && (
                    <LoginForm onSubmit={this.onSubmit} />
                  )}
                  {showForm === 'forgotPassword' && (
                    <ForgotPasswordForm onSubmit={this.onSubmit} />
                  )}
                </Grid>
              </Grid>
            )}
            {showForm !== 'forgotPassword' &&
              showForm !== 'successPasswordRequest' && (
                <Grid item>
                  <div
                    onClick={this.setForgotPassword}
                    className={classes.forgotDiv}
                  >
                    <FormattedMessage {...messages.forgot}>
                      {value => <span className={classes.forgot}>{value}</span>}
                    </FormattedMessage>
                  </div>
                </Grid>
              )}
            {showForm === 'successPasswordRequest' && (
              <Grid item className={classes.title}>
                <Grid item className={classes.headerImage}>
                  <img src={successImage} className={classes.image} alt="" />
                </Grid>
                <FormattedMessage {...messages.forgotSuccess} />
              </Grid>
            )}
            <Grid item>
              <Divider className={classes.divider} />
              <Grid
                container
                justify="center"
                className={classes.signUpContainer}
              >
                <FormattedMessage {...messages.dontHave} tagName="div" />
                <RoomiNavLink
                  to={`/onboarding?redirect=${this.props.location.pathname}${this.props.location.search}`}
                  onClick={this.beforeSignUp}
                  className={classes.signUp}
                >
                  <FormattedMessage {...messages.signUp} tagName="div">
                    {value => <div className="aut-button-sign-up">{value}</div>}
                  </FormattedMessage>
                </RoomiNavLink>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </RoomiDialog>
    )
  }
}

export default LoginModal
