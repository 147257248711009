const spinnerStyles = theme => ({
  root: {
    display: 'inline-block',
    position: 'relative'
  },
  middleRoot: {
    minWidth: '100%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    position: 'absolute',
    '&$inherit g': {
      fill: theme.palette.common.white
    }
  },
  inherit: {}
});

export default spinnerStyles;
