import { isEqual, transform, isObject } from 'lodash'

export const promisableAction = actionFunction => {
  return new Promise((resolve, reject) => {
    actionFunction(resolve, reject)
  })
}

export const objDiff = (object, base, skipKey) => {
  function changes(object, base) {
    // eslint-disable-line
    return transform(object, function(result, value, key) {
      if (skipKey !== key) {
        if (!isEqual(value, base[key])) {
          result[key] =
            isObject(value) && isObject(base[key])
              ? changes(value, base[key])
              : value
        }
      }
    })
  }
  return changes(object, base)
}
