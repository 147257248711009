import React, { PureComponent } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { withStyles, Icon } from '@material-ui/core';
import paginationStyles from './pagination.styles';
import classNames from 'classnames';
import { parse, stringify } from 'querystring';

@withStyles(paginationStyles)
class Pagination extends PureComponent {
  static propTypes = {
    initial: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  hrefBuilder = page => {
    const search = parse(window.location.search.replace('?', ''));
    search.page = page;
    return (
      window.location.origin +
      window.location.pathname +
      `?${stringify(search)}`
    );
  };

  render() {
    const { initial, pages, onPageChange, classes, className } = this.props;
    return (
      pages > 1 && (
        <ReactPaginate
          hrefBuilder={this.hrefBuilder}
          previousLabel={<Icon>chevron_left</Icon>}
          nextLabel={<Icon>chevron_right</Icon>}
          breakLabel={<Icon>more_horiz</Icon>}
          breakClassName={classes.break}
          pageCount={pages}
          marginPagesDisplayed={2}
          initialPage={initial}
          forcePage={initial}
          pageRangeDisplayed={2}
          containerClassName={classNames(classes.root, className)}
          onPageChange={onPageChange}
          disableInitialCallback
          pageClassName={classes.page}
          pageLinkClassName={classes.link}
          activeClassName={classes.active}
          nextClassName={classes.navigation}
          previousClassName={classes.navigation}
        />
      )
    );
  }
}

export default Pagination;
