const selectableListingCardStyles = {
  boostListingCard: {
    maxWidth: 320,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 15,
    position: 'relative'
  },
  selectedBadge: {
    position: 'absolute',
    width: 28,
    height: 28,
    zIndex: 1,
    right: 10,
    top: 10
  }
};

export default selectableListingCardStyles;
