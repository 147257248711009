import update from 'immutability-helper';
import { set, error as errorType } from '../actions/users.types';

const initialState = { data: {} };

export default (state = initialState, { type, id, error, user } = {}) => {
  switch (type) {
    case set:
      return update(state, {
        [id]: {
          $set: { fetching: false, fetched: true }
        },
        data: { $merge: { [id]: user } }
      });
    case errorType:
      return update(state, {
        [id]: { $set: { fetching: false, fetched: false, error } }
      });
    default:
      return state;
  }
};
