import {
  notificationsFetch,
  notificationsFetchSuccess,
  notificationMarkRead,
  notificationMarkReadSuccess,
  notificationMarkAllRead,
  notificationMarkAllReadSuccess,
  REGISTER_DEVICE,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_ERROR,
} from './announcement.types'

export const fetchNotifications = () => ({
  type: notificationsFetch,
});

export const fetchNotificationsSuccess = (data) => ({
  type: notificationsFetchSuccess,
  payload: data,
});

export const markNotificationsRead = (notificationId) => ({
  type: notificationMarkRead,
  notificationId,
});

export const markNotificationsReadSuccess = () => ({
  type: notificationMarkReadSuccess,
});

export const markAllNotificationsRead = (notificationId) => ({
  type: notificationMarkAllRead,
  notificationId,
});

export const markAllNotificationsReadSuccess = () => ({
  type: notificationMarkAllReadSuccess,
});

export const registerDevice = (firebaseToken) => ({
  type: REGISTER_DEVICE,
  firebaseToken,
});

export const registerDeviceSuccess = () => ({
  type: REGISTER_DEVICE_SUCCESS,
});

export const registerDeviceError = (error) => ({
  type: REGISTER_DEVICE_ERROR,
  error,
});
