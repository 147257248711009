import React, { Component } from 'react';
import { map, flattenDeep } from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';
import PageNotFound from 'roomi/components/page-not-found/page-not-found.component';
import Base from './base.component';
import GlobalDialogs from './global-dialogs.component';
import GlobalError from './global-error.component';
import routesSettings from './routes.config';
// import Intercom from '../analytics/intercom/intercom-button/intercom-button.component';
// import Stick from '../shared/components/stick/stick.component';
import PropTypes from 'prop-types';
import cn from 'classnames';

// If we need to turn intercom on remove this variable and make necessary changes. It will fallback to the logic on which pages it should show and which pages it should not.
// const HIDE_INTERCOM_CONFIG = false;

class Main extends Component {
  static propTypes = {
    fullScreen: PropTypes.bool
  };

  static renderRoutes() {
    return flattenDeep(
      map(routesSettings, ({ component, paths, remount = true, deep = false }) =>
        map(paths, path => (
          <Route
            path={path}
            exact={!deep}
            component={component}
            key={remount ? path : 'static'}
          />
        ))
      )
    );
  }
  render() {
    return (
      <StickyContainer
        className={cn('main-body-container', {
          'full-screen': this.props.fullScreen
        })}
      >
        <Route path="" component={Base} />
        <Switch>
          {Main.renderRoutes()}
          <Route component={PageNotFound} />
        </Switch>
        <GlobalDialogs />
        <GlobalError />
        {/*HIDE_INTERCOM_CONFIG && (
          <Stick
            position="bottom"
            disableCompensation
            style={{ right: 20, bottom: 20, left: 'initial' }}
          >
            <Intercom routeShowsIntercom={!this.props.hideIntercom} />
          </Stick>
        )*/}
      </StickyContainer>
    );
  }
}

export default Main;
