import React, { PureComponent } from 'react'
import Footer from './footer/footer.component'
import Header from './header/header.component'
import Main from './main/main.component'
import Seo from './shared/components/seo/seo.component'
import CookiesConsent from './shared/components/cookies-consent/cookies-consent.component'
import routeSettings from './main/routes.config'
import { find, some } from 'lodash'
import { defaultSeo } from './app.seo'
import { withRouter, matchPath } from 'react-router-dom'

class Layout extends PureComponent {
  state = {
    hideFooter: false,
    hideSitemap: false,
    hideIntercom: true,
    fullScreen: false
  }

  setLayout = pathname => {
    const routeSetting =
      find(routeSettings, setting =>
        some(setting.paths, path => {
          const match = matchPath(pathname, { path })
          return match && match.isExact
        })
      ) || {}
    this.setState({
      hideFooter: routeSetting.hideFooter,
      hideSitemap: routeSetting.hideSitemap,
      hideIntercom: routeSetting.hideIntercom,
      fullScreen: routeSetting.fullScreen
    })
  }

  componentDidMount() {
    this.setLayout(this.props.location.pathname)
  }

  componentWillReceiveProps({ location: { pathname } }) {
    this.setLayout(pathname)
  }

  render() {
    const { hideFooter, hideSitemap, hideIntercom, fullScreen } = this.state
    const onboarding = this.props.location.pathname === '/onboarding'

    return (
      <div className="absolute-fill-parent layout-column">
        <Seo {...defaultSeo} />
        <Header pathname={this.props.location.pathname} />
        <CookiesConsent />
        <Main fullScreen={fullScreen} hideIntercom={hideIntercom} />
        {!hideFooter && (
          <Footer hideSitemap={hideSitemap} onboarding={onboarding} />
        )}
      </div>
    )
  }
}

export default withRouter(Layout)
