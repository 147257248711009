import { get } from 'lodash'
import { createSelector } from 'reselect'

export const getUser = state => get(state, 'user.data')
export const getUserId = state => get(state, 'user.data._id')
export const getIsUserBgChecked = state =>
  !!get(state, 'user.data.backgroundCheck.verified')
export const getPaymentAccounts = state =>
  get(state, 'user.data.accounts.payment')
export const getPayoutAccounts = state =>
  get(state, 'user.data.accounts.payout')

export const hasPayoutAccountSelector = createSelector(
  getPayoutAccounts,
  payoutAccounts => !!payoutAccounts.find(account => account.isDefault)
)
