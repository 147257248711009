import { call, put, takeLatest, select } from 'redux-saga/effects'
import { fetch } from '../actions/users.types'
import { getUserFromState } from '../reducers/users.selector'
import { setUser, errorUser } from '../actions/users.actions'
import { getUser } from '../data/users.data'

function* fetchUser({ id }) {
  try {
    let user = yield select(state => getUserFromState(state, id))
    if (user) return
    user = yield call(getUser, id)
    yield put(setUser(id, user))
  } catch (e) {
    yield put(errorUser(id, e))
  }
}

export default function*() {
  yield takeLatest(fetch, fetchUser)
}
