import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'forgot.modal.form.roomi.title',
    defaultMessage: "Having trouble signing in? Let's fix that."
  },
  email: {
    id: 'forgot.modal.form.email',
    defaultMessage: 'Your Email'
  },
  resetButton: {
    id: 'forgot.modal.form.reset.button',
    defaultMessage: 'Reset Password'
  },
  forgot: {
    id: 'forgot.modal.form.forgot',
    defaultMessage: 'Oops! I forgot my password.'
  },
  missingEmail: {
    id: 'forgot.modal.form.missingEmail',
    defaultMessage: 'Please enter your email'
  },
  invalidEmail: {
    id: 'forgot.modal.form.invalidEmail',
    defaultMessage: 'Please enter a valid email'
  }
});
