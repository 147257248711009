import React, { PureComponent } from 'react';
import ExperimentsManager from 'roomi/components/configuration/experiments/experiments-manager/experiments-manager';
import RoomiDialog from 'roomi/material/dialog/dialog.component';

const experimentsManagerHoc = (ComposedComponent) => {
  if (process.env.NODE_ENV === 'production') return ComposedComponent;

  class WithExperimentsManager extends PureComponent {
    state = {};

    toggleManager = () => this.setState(({ open }) => ({ open: !open }));
    stopPropagate = e => e.stopPropagation();

    render() {
      const { open } = this.state;
      return (
        <div onDoubleClick={this.toggleManager} onCopy={this.toggleManager}>
          <ComposedComponent {...this.props}/>
          {open && (
            <RoomiDialog
              open
              title="Experiments manager"
              onBackdropClick={this.toggleManager}
              onClose={this.toggleManager}
              onDoubleClick={this.stopPropagate}
            >
              <ExperimentsManager/>
            </RoomiDialog>
          )}
        </div>
      );
    }
  }

  return WithExperimentsManager;
};

export default experimentsManagerHoc;
