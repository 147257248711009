import { getMembers, fetchedMembers, fetchingError } from './actions.types';

export const fetchMembers = () => {
  return {
    type: getMembers
  };
};

export const membersFetched = payload => {
  return {
    type: fetchedMembers,
    payload
  };
};

export const errorFetching = payload => {
  return {
    type: fetchingError,
    payload
  };
};
