import {
  addPayoutAccount,
  deletePayoutAccount,
  setDefaultPayoutAccount,
  setPayoutFlowStep,
  resetPayoutFlow,
  clearPayoutFlow,
  toggleAddPayoutDialog,
  paymentConfigsFetched,
  fetchPayemntConfigs
} from './payout.types';

export const resetPayoutFlowAction = () => ({ type: resetPayoutFlow });
export const setPayoutFlowStepAction = (step) => ({ type: setPayoutFlowStep, payload: step });
export const clearPayoutFlowAction = () => ({ type: clearPayoutFlow });
export const toggleAddPayoutDialogAction = () => ({ type: toggleAddPayoutDialog });
export const addPayoutAccountAction = (account) => ({ type: addPayoutAccount, payload: account });
export const deletePayoutAccountAction = (accountId) => ({ type: deletePayoutAccount, payload: accountId });
export const setDefaultPayoutAccountAction = (accountId) => ({ type: setDefaultPayoutAccount, payload: accountId });

export const paymentConfigsFetchedAction = (configs) => ({
  type: paymentConfigsFetched,
  payload: { data: configs }
})

export const fetchPaymentConfigsAction = () => ({
  type: fetchPayemntConfigs
})