import React, { PureComponent } from 'react';
import minimumLengthStyles from './minimum-length.styles';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

@withStyles(minimumLengthStyles)
class MinimumLength extends PureComponent {
  static propTypes = {
    min: PropTypes.number,
    value: PropTypes.string
  };

  render() {
    let { min = 50, value = '', classes } = this.props;
    value = value.replace(/  +/g, ' ').replace(/(\r?\n)+|(\r)+/g, ' ');
    const display = value.length < min;
    return display ? (
      <div className={classes.root}>
        <FormattedMessage
          id="req.character.component"
          defaultMessage="{total}/{minimum} minimum"
          values={{
            total: value.length,
            minimum: min
          }}
        />
      </div>
    ) : null;
  }
}

export default MinimumLength;
