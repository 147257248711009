import update from 'immutability-helper';

import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FOLLOW_USER,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_ERROR,
  UNFOLLOW_USER,
  UNFOLLOW_USER_SUCCESS,
  UNFOLLOW_USER_ERROR,
  CHECK_FOLLOW_STATUS,
  CHECK_FOLLOW_STATUS_SUCCESS,
  CHECK_FOLLOW_STATUS_ERROR,
  FETCH_NEIGHBORHOOD_TOKEN,
  FETCH_NEIGHBORHOOD_TOKEN_SUCCESS,
  FETCH_NEIGHBORHOOD_TOKEN_ERROR,
  REGISTER_ACTIVITY_REACTION,
  REGISTER_ACTIVITY_REACTION_SUCCESS,
  REGISTER_ACTIVITY_REACTION_ERROR,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
} from './timeline.types';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

const TimelineReducer = (state = initialState, action) => {
  switch(action.type) {
    case REGISTER_USER:
      return update(state, {
        $merge: {
          loading: true,
          loaded: false,
          userId: action.userId,
        },
      });
    case REGISTER_USER_SUCCESS:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
          userToken: action.userToken,
        },
      });
    case REGISTER_USER_ERROR:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
          error: action.error
        },
      });
    case FOLLOW_USER:
      return update(state, {
        $merge: {
          loading: true,
          loaded: false,
        },
      });
    case FOLLOW_USER_SUCCESS:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
          followInfo: {
            userId: action.userId,
            followStatus: true,
          },
        },
      });
    case FOLLOW_USER_ERROR:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
        },
      });
    case UNFOLLOW_USER:
      return update(state, {
        $merge: {
          loading: true,
          loaded: false,
        },
      });
    case UNFOLLOW_USER_SUCCESS:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
          followInfo: {
            userId: action.userId,
            followStatus: false,
          },
        },
      });
    case UNFOLLOW_USER_ERROR:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
        },
      });
    case CHECK_FOLLOW_STATUS:
      return update(state, {
        $merge: {
          loading: true,
          loaded: false,
          followInfo: null,
        },
      });
    case CHECK_FOLLOW_STATUS_SUCCESS:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
          followInfo: {
            userId: action.userId,
            followStatus: action.followStatus,
          },
        },
      });
    case CHECK_FOLLOW_STATUS_ERROR:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
        },
      });
    case FETCH_NEIGHBORHOOD_TOKEN:
      return update(state, {
        $merge: {
          loading: true,
          loaded: false,
          neighborhood: action.neighborhood,
          neighborhoodToken: null,
          neighborhoodId: null,
        },
      });
    case FETCH_NEIGHBORHOOD_TOKEN_SUCCESS:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
          neighborhood: action.neighborhood,
          neighborhoodToken: action.neighborhoodToken,
          neighborhoodId: action.neighborhoodId,
        },
      });
    case FETCH_NEIGHBORHOOD_TOKEN_ERROR:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
        },
      });

    case REGISTER_ACTIVITY_REACTION:
      return update(state, {
        $merge: {
          loading: true,
          loaded: false,
        }
      });
    case REGISTER_ACTIVITY_REACTION_SUCCESS:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
        }
      });
    case REGISTER_ACTIVITY_REACTION_ERROR:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
          error: action.error,
        }
      });
    case DELETE_ACTIVITY:
      return update(state, {
        $merge: {
          loading: true,
          loaded: false,
        },
      });
    case DELETE_ACTIVITY_SUCCESS:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
        },
      });
    case DELETE_ACTIVITY_ERROR:
      return update(state, {
        $merge: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      });
    default: 
      return state;
  }
};

export default TimelineReducer;
