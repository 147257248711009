const smHeaderStyles = theme => ({
  left: {
    display: 'flex',
    alignItems: 'center'
  },
  right: {
    display: 'flex',
    alignItems: 'center'
  },
  boost: {
    marginRight: 10,
    marginBottom: 10
  },
  banner: {
    width: 100,
    marginLeft: theme.spacing.unit * 2
  },
  link: {
    marginLeft: theme.spacing.unit * 3,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
});

export default smHeaderStyles;
