import { size, find, get, map, orderBy, filter, values } from 'lodash';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

const isActiveBookingRequest = (request) => request.state === 'accepted' || request.state === 'active';

export const getBookingRequests = state => state.bookingRequests.data;
const getSentRequestsIds = state => state.bookingRequests.sentIds;
const getReceivedRequestsIds = state => state.bookingRequests.receivedIds;
export const bookingRequestsResolved = state => state.bookingRequests.fetched;

const bookingRequestsArraySelector = createSelector(
  getBookingRequests,
  bookingRequestsMap => values(bookingRequestsMap)
);

export const getSentRequests = createSelector(
  [getBookingRequests, getSentRequestsIds],
  (requests, sentIds) => orderBy(map(sentIds, id => requests[id]), bookingSortFn, 'desc')
);

export const hasPendingBookingRequestFromMoverSelector = createCachedSelector(
  bookingRequestsArraySelector,
  (state, moverId) => moverId,
  (bookingRequests, moverId) => !!bookingRequests.find(request => request.state === 'active' && request.mover._id === moverId)
)(
  (state, moverId) => moverId
);

export const getReceivedRequests = createSelector(
  [getBookingRequests, getReceivedRequestsIds],
  (requests, receivedIds) => filter(orderBy(map(receivedIds, id => requests[id]), bookingSortFn, 'desc'), request => !!request.mover)
);

export const canSendBookingRequest = createSelector(
  [getSentRequests, (state, id) => id],
  (sentRequests, id) => !find(sentRequests, request => get(request, 'listing._id') === id && request.state === 'active')
);

export const getBookingRequestsCount = createSelector(
  [getBookingRequests], requests => size(requests)
);

export const hasPendingBookingRequests = createSelector(
  [getReceivedRequests], requests => !!find(requests, { state: 'active' })
);

export const activeBookingRequestsSelector = createSelector(
  bookingRequestsArraySelector,
  bookingRequests => bookingRequests.filter(request => isActiveBookingRequest(request))
);

export const inactiveBookingRequestsSelector = createSelector(
  bookingRequestsArraySelector,
  bookingRequests => bookingRequests.filter(request => !isActiveBookingRequest(request))
);

function bookingSortFn(request) {
  switch (request.state) {
    case 'active':
      return 1000 + Date.parse(request.createdAt) / 100000000000;
    case 'accepted':
      return 500 + Date.parse(request.response.datetime) / 100000000000;
    case 'expired':
      return 200 + Date.parse(request.expirationDate) / 100000000000;
    case 'rejected':
      return 100 + Date.parse(request.response.datetime) / 100000000000;
    case 'canceled':
      return 100 + Date.parse(request.cancellationDate) / 100000000000;
    default:
      return 0;
  }
}
