const confirmationDialogStyles = theme => ({
  title: {
    color: theme.palette.common.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '27px',
    textAlign: 'center'
  },
  cancelBtn: {
    marginRight: 20
  },
  dialogContent: {
    textAlign: 'center'
  },
  dialogActions: {
    justifyContent: 'center',
    marginBottom: 0,
    paddingBottom: 24
  }
});

export default confirmationDialogStyles;
