import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { values, orderBy } from 'lodash'
import {
  isListingActiveSelector,
  getListingData
} from '../../listings/reducers/listings.selector'
import { bookingRequestsResolved } from '../../requests/reducers/booking-requests.selector'
import { getCurrentUserFromState } from '../../users/reducers/user.selector'
import { instantBookingEnabledSelector } from './bookings.selector'

export const isActiveBookingInvite = invite =>
  invite.state === 'accepted' || invite.state === 'active'

const bookingInvitesMapSelector = state => state.bookingInvites.data

export const bookingInvitesArraySelector = createSelector(
  bookingInvitesMapSelector,
  bookingInvitesMap => values(bookingInvitesMap)
)

export const bookingInvitesFetchedSelector = state =>
  state.bookingInvites.fetched

export const bookingInviteSelector = createCachedSelector(
  bookingInvitesArraySelector,
  getListingData,
  (state, bookingInviteId) => bookingInviteId,
  (bookingInvites, listingsMap, bookingInviteId) => {
    const bookingInvite = bookingInvites.find(
      invite => invite.id === bookingInviteId
    )
    return !!bookingInvite
      ? { ...bookingInvite, listing: listingsMap[bookingInvite.listingId] }
      : bookingInvite
  }
)((state, bookingInviteId) => bookingInviteId)

export const bookingInvitesSelector = createSelector(
  [bookingInvitesArraySelector, getListingData],
  (bookingInvites, listingsMap) =>
    bookingInvites.map(invite => ({
      ...invite,
      listing: listingsMap[invite.listingId]
    }))
)

export const hasPendingBookingInviteForMoverSelector = createCachedSelector(
  bookingInvitesSelector,
  (state, moverId) => moverId,
  (bookingInvites, moverId) =>
    !!bookingInvites.find(
      invite => invite.mover._id === moverId && invite.state === 'active'
    )
)((state, moverId) => moverId)

export const getPendingBookingInviteFromListerSelector = createCachedSelector(
  bookingInvitesSelector,
  (state, listerId) => listerId,
  (bookingInvites, listerId) =>
    bookingInvites.find(
      invite => invite.lister._id === listerId && invite.state === 'active'
    )
)((state, listerId) => listerId)

export const isListerSelector = createCachedSelector(
  getCurrentUserFromState,
  (state, listerId) => listerId,
  (currentUser, listerId) => currentUser._id === listerId
)((state, listerId) => listerId)

export const bookingInvitesCountSelector = createSelector(
  bookingInvitesSelector,
  bookingInvites => bookingInvites.length
)

export const activeBookingInvitesSelector = createSelector(
  bookingInvitesSelector,
  bookingInvites => {
    const activeInvites = bookingInvites.filter(invite =>
      isActiveBookingInvite(invite)
    )
    return orderBy(activeInvites, ['state', 'createdAt'], ['desc', 'desc'])
  }
)

export const inactiveBookingInvitesSelector = createSelector(
  bookingInvitesSelector,
  bookingInvites => {
    const inactiveInvites = bookingInvites.filter(
      invite => !isActiveBookingInvite(invite)
    )
    return orderBy(inactiveInvites, ['createdAt'], ['desc'])
  }
)

export const canSendBookingInviteSelector = createCachedSelector(
  bookingRequestsResolved,
  bookingInvitesFetchedSelector,
  (state, props) => instantBookingEnabledSelector(state, props.listingRegionId),
  (state, props) => isListerSelector(state, props.listerId),
  (state, props) => isListingActiveSelector(state, props.listingId),
  (
    bookingRequestsFetched,
    bookingInvitesFetched,
    instantBookingEnabled,
    isLister,
    isListingActive
  ) => {
    return (
      bookingRequestsFetched &&
      bookingInvitesFetched &&
      instantBookingEnabled &&
      isLister &&
      isListingActive
    )
  }
)(
  (state, props) =>
    `${props.listingId}:${props.listerId}:${props.listingRegionId}:${props.moverId}`
)
