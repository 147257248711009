import { defineMessages } from 'react-intl'

export default defineMessages({
  listRoom: {
    id: 'header.item.listRoom',
    defaultMessage: 'LIST YOUR ROOM FOR FREE'
  },
  howItWorks: {
    id: 'header.item.howItWorks',
    defaultMessage: 'How It Works'
  },
  browseListings: {
    id: 'header.item.browseListings',
    defaultMessage: 'Browse Listings'
  },
  browsePeople: {
    id: 'header.item.browsePeople',
    defaultMessage: 'Browse People'
  },
  community: {
    id: 'header.item.communityHeader',
    defaultMessage: 'Community'
  },
  notifications: {
    id: 'header.item.notifications',
    defaultMessage: 'Notifications'
  },
  chatRequests: {
    id: 'header.item.chatRequests',
    defaultMessage: 'Chat Requests'
  },
  messages: {
    id: 'header.item.messages',
    defaultMessage: 'Messages'
  },
  timeline: {
    id: 'header.item.timeline',
    defaultMessage: 'Community'
  },
  bookings: {
    id: 'header.item.bookings',
    defaultMessage: 'Bookings'
  },
  myListings: {
    id: 'header.item.myListings',
    defaultMessage: 'My Listings'
  },
  createListing: {
    id: 'header.item.createListing',
    defaultMessage: 'Create Free Listing'
  },
  rentpayment: {
    id: 'header.item.rentpayment',
    defaultMessage: 'Rent Payment'
  },
  settings: {
    id: 'header.item.settings',
    defaultMessage: 'Settings'
  },
  myFavorites: {
    id: 'header.item.myFavorites',
    defaultMessage: 'My Favorites'
  },
  favorites: {
    id: 'header.item.favorites',
    defaultMessage: 'Favorites'
  },
  logout: {
    id: 'header.item.logoutSm',
    defaultMessage: 'Logout'
  },
  logOut: {
    id: 'header.item.logOut',
    defaultMessage: 'Log Out'
  },
  logIn: {
    id: 'header.item.logIn',
    defaultMessage: 'Log In'
  },
  community: {
    id: 'header.item.community',
    defaultMessage: 'Community'
  },
  signUp: {
    id: 'header.item.signUp',
    defaultMessage: 'Sign Up'
  },
  viewAll: {
    id: 'header.item.dropdown.viewAll',
    defaultMessage: 'View All'
  },
  profile: {
    id: 'header.item.dropdown.profile',
    defaultMessage: 'Profile'
  },
  haveAnAccount: {
    id: 'header.haveAnAccount',
    defaultMessage: 'Have an account?'
  }
})
