import {
  errored,
  fetched,
  update,
  fetch,
  editRequest,
  reset,
  openChatRequest,
  closeChatRequest,
  sent,
  acceptRequest,
  ignoreRequest
} from './chat-requests.types'
import { map, filter, get, keyBy } from 'lodash'

export const fetchChatRequestsAction = userId => ({ type: fetch, userId })
export const resetChatRequests = () => ({ type: reset })

export const fetchedChatRequests = (chatRequests, id, isSenderEnabled) => {
  const payload = {
    data: keyBy(chatRequests, '_id'),
    sent: map(
      filter(
        chatRequests,
        chatRq =>
          get(chatRq, isSenderEnabled ? 'sender._id' : 'mover._id') === id
      ),
      '_id'
    ),
    received: map(
      filter(
        chatRequests,
        chatRq =>
          get(chatRq, isSenderEnabled ? 'sender._id' : 'mover._id') !== id
      ),
      '_id'
    )
  }
  return { type: fetched, payload }
}
export const errorChatRequests = error => ({ type: errored, payload: error })
export const sentChatRequest = chatRq => ({ type: sent, payload: chatRq })
export const startChatRequest = (
  listingId,
  listerId,
  listerName,
  backgroundRequired
) => ({
  type: openChatRequest,
  payload: { listingId, listerId, listerName, backgroundRequired }
})
export const endChatRequest = () => ({ type: closeChatRequest })
export const updateChatRequest = chatRequest => ({
  type: update,
  payload: chatRequest
})
export const acceptChatRequest = chatRequest => ({
  type: acceptRequest,
  payload: chatRequest._id
})
export const ignoreChatRequest = chatRequest => ({
  type: ignoreRequest,
  payload: chatRequest._id
})
export const editChatRequest = editedChatRequest => ({
  type: editRequest,
  payload: editedChatRequest
})
