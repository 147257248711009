import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getAuthToken } from '../../utils/session-manager.util';
import LoginModal from '../login-modal/login-modal.component';
import { RoomiSpinner } from 'roomi/common';

export default function(ComposedComponent) {
  class EnsureLoginRoute extends PureComponent {
    handleDenyAuthentication = () => {
      this.props.history.replace('/');
    };

    componentWillReceiveProps(nextProps) {
      if (this.props.user && !nextProps.user) {
        this.handleDenyAuthentication();
      }
    }

    render() {
      const { user, ...props } = this.props;
      const token = getAuthToken();
      if (token && !user) {
        return <RoomiSpinner middle />;
      } else if (!token) {
        return <LoginModal isOpen onCancel={this.handleDenyAuthentication} />;
      }
      return <ComposedComponent me={user} {...props} />;
    }
  }

  const mapStateToProps = state => ({ user: state.user.data });

  return connect(mapStateToProps)(EnsureLoginRoute);
}
