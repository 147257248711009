import React, { PureComponent } from 'react'
import { roomiBlackLogo, roomiGuestUser } from 'roomi/icons'
import EnsureLogin from '../../auth/components/ensure-login-button/ensure-login-button.component'
import { RoomiButton } from 'roomi/material'
import onboardingHeaderStyles from './onboarding.styles'
import { withStyles, Hidden } from '@material-ui/core'
import messages from '../header.text'
import { FormattedMessage, injectIntl } from 'react-intl'
import { LazyImage } from '../../shared/components/lazy-image/lazy-image.component'
import RoomiNavLink from '../../shared/components/roomi-nav-link/roomi-nav-link.component'

@injectIntl
@withStyles(onboardingHeaderStyles)
class OnBoardingHeader extends PureComponent {
  render() {
    const {
      user,
      classes,
      intl: { formatMessage }
    } = this.props
    return (
      <div className={classes.onboardingHeader}>
        <RoomiNavLink to="/">
          <img
            className={classes.headerBanner}
            src={roomiBlackLogo}
            alt="roomi-banner"
          />
        </RoomiNavLink>
        {user ? (
          <div>
            {user.defaultImage ? (
              <LazyImage
                crop="faces"
                fit="crop"
                mask={'ellipse'}
                square={true}
                src={user.defaultImage}
                height={34}
                width={34}
              />
            ) : (
              <img src={roomiGuestUser} height={34} width={34} />
            )}
          </div>
        ) : (
          <EnsureLogin>
            <div className={classes.headerRight}>
              <Hidden only={['xs']}>
                <FormattedMessage {...messages.haveAnAccount} /> &nbsp;&nbsp;
              </Hidden>
              <RoomiButton
                secondary
                small
                className={classes.onboardingLogin}
                label={formatMessage(messages.logIn)}
              />
            </div>
          </EnsureLogin>
        )}
      </div>
    )
  }
}

export default OnBoardingHeader
