import { get as fetch, post as update } from '../../shared/utils/fetch.util'
import { logOldEvents } from '../../analytics/data/old.events.data'
import { omit, times } from 'lodash'
import 'formdata-polyfill'

const listingApi = '/v1/listings'

const incompleteListingImageTransform = data => {
  if (data.photos && data.photos.length) {
    const final = new FormData()
    times(data.photos.length, i => {
      final.append('file', data.photos[i], data.photos[i].name)
    })
    final.append(
      'listingData',
      JSON.stringify(omit(data, ['photos', 'incomplete']))
    )
    final.append('incomplete', data.incomplete)
    return final
  }
  return JSON.stringify(data)
}

const completeListingImageTransform = ({ photos }) => {
  const form = new FormData()
  times(photos.length, i => {
    form.append('file', photos[i], photos[i].name)
  })
  return form
}

export const getConfigsForListing = id => {
  return fetch(`/v2/config/listings/${id}`).then(response => {
    // Hacky way to rename UK with proper iso code GB. TODO: remove once fixed on backend
    return {
      ...response,
      data: {
        ...response.data,
        payoutForm: {
          ...omit(response.data.payoutForm, 'UK'),
          GB: response.data.payoutForm.UK
        }
      }
    }
  })
}
export const getListingBookedDates = listingId => {
  return fetch(`/v2/listings/${listingId}/bookedDates`).then(response => {
    // Hacky way to rename UK with proper iso code GB. TODO: remove once fixed on backend
    return response.data
  })
}
export const get = id => fetch(`/v1/listings/${id}`)
export const updateIncompleteListing = data => update(`/v1/listings`, data, {})
export const addIncompleteListingImage = data =>
  update(`/v1/listings`, data, {}, [incompleteListingImageTransform])
export const addListingImage = data =>
  update(`/v1/listings/${data._id}/addImage`, data, {}, [
    completeListingImageTransform
  ])
export const updateListing = data =>
  update(`/v1/listings/${data._id}/editCopy`, data, {})
export const getListings = ids => fetch(`/v1/listings`, { ids: ids.join(',') })
export const getSuggested = id => fetch(`/v1/listings/${id}/suggest`)
export const getWeeklyViews = id =>
  fetch(`/v1/listings/${id}/views?period=week`)
export const getChatRequest = id => fetch(`/v2/config/listings/${id}`)
export const createChatRequest = payload =>
  update(`/v2/applications`, payload).then(resp => resp.data)
export const reactivate = id => update(`/v1/listings/${id}/requestApproval`)
export const searchListings = query =>
  fetch(`/v2/listings/search`, query).then(resp => ({
    ...resp.data,
    ...resp.summary
  }))
export const deleteListing = id =>
  logOldEvents({
    type: 'listing-deleted',
    targetId: id
  })
export const deactivateListing = id => fetch(`/v1/listings/${id}/deactivate`)
