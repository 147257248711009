import { get, size, filter, map, identity, find, includes, pick, isString } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { getRoomID } from '../../../app/messaging/reducers/conversations.selector';
import { getSponsorshipsFromState } from '../../sponsorships/reducers/sponsorships.selector';

const getSuggestedListingIds = (state, id) => get(state, `listings.suggested.${id}.suggestedListings`);

export const getListingContainerFromState = (state, id) => state.listings[id];
export const getListingData = state => state.listings.data;
export const getListing = (state, listing) => isString(listing) ? get(state, `listings.data.${listing}`) : listing;
export const hasFetchedSuggested = (state, id) => get(state, `listings.suggested.${id}.fetched`);

export const getListingPayoutFormConfigSelector = (state, listingId) =>
  state.listings.configs.data[listingId] && state.listings.configs.data[listingId].payoutForm;

export const isListingActiveSelector = (state, id) => {
  const listing = getListing(state, id);
  return listing && listing.status === 'active';
};

export const getSuggestedListings = createSelector(
  [getListingData, getSuggestedListingIds],
  (data, ids) => {
    if (!ids) return null;
    const listings = filter(map(ids, id => data[id]), identity);
    return size(listings) === size(ids) ? listings : null;
  }
);

export const getListingFromUuid = createSelector(
  [getRoomID], (roomID) => getListing(roomID)
);

export const getAnalyticsListing = createSelector(
  [getListing, s => s.badges.data, s => s.rules.data, s => s.amenities.data, (s, i, route) => route],
  getAnalyticsListingObject
);

export const isListingBoosted = createCachedSelector(
  getSponsorshipsFromState,
  (s, i) => i,
  (sponsorships, entityId) => !!find(sponsorships, {
    status: 'active',
    platform: 'web',
    entity: 'listing',
    entityId
  })
)((state, entityId) => entityId);

function getAnalyticsListingObject(listing, badges, rules, amenities, route) {
  if (!listing) return null;
  const details = {
    neighborhoodId: listing.neighborhoodId,
    convertedFromCraigslist: listing.convertedFromCraigslist,
    currency: listing.currency,
    deactivationNotified: listing.deactivationNotified,
    type: listing.type,
    isFlagged: listing.isFlagged,
    listingAddress: listing.listingAddress,
    listingAddressCity: listing.listingAddressCity,
    listingAddressCountry: listing.listingAddressCountry,
    listingAddressCounty: listing.listingAddressCounty,
    listingAddressGeopoint: listing.listingAddressGeopoint,
    listingAddressNeighborhood: listing.listingAddressNeighborhood,
    listingAddressNumber: listing.listingAddressApartment,
    listingAddressRegion: listing.listingRegion,
    listingAddressState: listing.listingAddressState,
    listingAddressStreet: listing.listingAddressStreet,
    listingAddressZipCode: listing.listingAddressZipCode,
    listingDescription: listing.listingDescription,
    listingId: listing._id,
    listingSource: listing.listingSource,
    numImages: size(listing.urlOfListingImages),
    onlinePayments: listing.onlinePayments,
    pageSource: route,
    preferredAge: listing.preferredAge,
    preferredGender: listing.preferredGender,
    roomAvailableDate: listing.roomAvailableDate,
    roomDeposit: listing.roomDeposit,
    roomLeaseDuration: listing.roomLeaseDuration || 0,
    roomLeaseMaxDuration: listing.roomLeaseMaxDuration,
    roomRent: listing.roomRent,
    status: listing.status,
    urlOfExternalListing: listing.urlOfExternalListing,
    urlOfListingDeeplink: listing.urlOfListingDeeplink,
    urlOfListingImages: listing.urlOfListingImages
  };
  if (listing.isShortTerm) {
    details.roomAvailableDateFrom = listing.roomAvailableDateFrom;
    details.roomAvailableDateTo = listing.oomAvailableDateTo;
  }
  if (listing.externalRedirect) {
    details.externalRedirectText = listing.externalRedirect.text;
    details.externalRedirectUrl = listing.externalRedirect.url;
  }
  if (listing.user) {
    details.corporate = listing.user.corporate;
    details.listerId = listing.user._id;
    details.listerVerifications = map(filter(badges, badge => includes(listing.user.badges, badge._id)), 'name');
  }
  details.amenities = map(pick(amenities, listing.amenities), 'title');
  details.rules = map(pick(rules, listing.rules), 'title');
  details.listingVerifications = map(filter(badges, badge => includes(listing.badges, badge._id)), 'name');
  return details;
}

