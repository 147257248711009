import React, { PureComponent } from 'react';
import { Fields } from 'redux-form';
import { get, find } from 'lodash';
import PropTypes from 'prop-types';
import ErrorBanner from './error-banner.component';

class FieldsError extends PureComponent {
  static propTypes = {
    names: PropTypes.array.isRequired,
    error: PropTypes.string,
    onHide: PropTypes.func,
    style: PropTypes.object
  };

  renderFirstError = fields => {
    const errorField = find(fields,
      field => get(field, 'meta.invalid') && get(field, 'meta.submitFailed'));
    return errorField ? <ErrorBanner error={errorField.meta.error} /> : null;
  };

  render() {
    const { names, error, onHide } = this.props;
    return error ? <ErrorBanner onHide={onHide} error={error} />
      : <Fields names={names} component={this.renderFirstError} />;
  }
}

export default FieldsError;
