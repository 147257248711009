const addCreditCardButtonStyles = theme => ({
  button: {
    cursor: 'pointer',
    fontSize: 15,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '15px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  addIcon: {
    fontSize: 15,
    marginRight: 20,
    position: 'relative',
    top: 2
  }
});

export default  addCreditCardButtonStyles;
