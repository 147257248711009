import { call, put, takeLatest, select } from 'redux-saga/effects'
import { map } from 'lodash'
import {
  errorUserListings,
  fetchedUserListings
} from '../actions/user-listings.actions'
import { getUserListingsFromState } from '../reducers/user-listings.selector'
import {
  fetchedListings,
  removeListing
} from '../../listings/actions/listings.actions'
import { fetchedIncompleteListings } from '../../listings/actions/incomplete-listings.actions'
import { fetch, remove } from '../actions/user-listings.types'
import { getUserListings } from '../data/users.data'
import { getMyListings } from '../data/user.data'
import { deleteListing } from '../../listings/data/listings.data'

function* fetchUserListings({ id }) {
  try {
    let userListings = yield select(state =>
      getUserListingsFromState(state, id)
    )
    if (userListings) return
    if (id === 'me') {
      const resp = yield call(getMyListings, { includeIncomplete: true })
      userListings = resp[0]
      let incompleteListings = resp[1]
      yield put(fetchedIncompleteListings(incompleteListings))
    } else {
      userListings = yield call(getUserListings, id)
    }
    yield put(fetchedListings(userListings))
    yield put(fetchedUserListings(id, map(userListings, '_id')))
  } catch (e) {
    yield put(errorUserListings(id, e))
  }
}

function* deleteUserListing({ id }) {
  yield call(deleteListing, id)
  yield put(removeListing(id))
}

export default function*() {
  yield [
    takeLatest(fetch, fetchUserListings),
    takeLatest(remove, deleteUserListing)
  ]
}
