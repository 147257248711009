import { defineMessages } from 'react-intl'

export default defineMessages({
  description: {
    id: 'boost.listing-modal.description',
    defaultMessage:
      'Boost your room to the top of search results and find a roommate faster!'
  },
  success: {
    id: 'boost.listing-modal.success',
    defaultMessage: 'Listing Boosted!'
  },
  successDescription: {
    id: 'boost.listing-modal.success-description',
    defaultMessage:
      'Your Listing has been boosted in the search results. For even more hits, start messaging potential roommates directly!'
  }
})
