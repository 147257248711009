const ICON_DIMENSION = 28;

const selectStyles = theme => ({
  root: {
    display: 'flex'
  },
  icon: {
    fill: theme.palette.primary.main,
    height: ICON_DIMENSION,
    width: ICON_DIMENSION,
    marginRight: 5,
    top: `calc(50% - ${ICON_DIMENSION / 2}px)`
  },
  select: {
    '&:focus': {
      background: theme.palette.common.white
    }
  },
  arrow: {},
  selectMenu: {
    height: '100%',
    padding: `0 ${theme.spacing.unit}px 0 0`,
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    textIndent: 12,
    '& .placeholder': {
      color: theme.palette.grey[500]
    },
    '&$arrow': {
      padding: `0 ${ICON_DIMENSION + 5}px 0 0`
    }
  },
  localInputRootClass: {
    padding: 0
  }
});

export default selectStyles;
