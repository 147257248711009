import { Collapse, FormHelperText, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ButtonToggleItem from 'roomi/material/button-toggle/button-toggle-item/button-toggle-item';
import propValidator from 'roomi/utils/prop-validator';

class RoomiButtonToggle extends PureComponent {
  static propTypes = {
    input: propValidator({ required: ['value'] }),
    meta: propValidator({ required: ['value'] }),
    value: propValidator({ required: ['input.value'] }),
    onChange: propValidator({
      required: ['input.onChange'],
      type: 'function',
    }),
    options: PropTypes.array.isRequired,
    showError: PropTypes.bool,
    titleKey: PropTypes.string,
    valueKey: PropTypes.string,
    itemClass: PropTypes.string,
  };

  static defaultProps = {
    options: [],
  };

  updateValue = value => {
    const { input, onChange = input.onChange } = this.props;

    onChange(value);
    if (this.props.meta && !this.props.meta.touched) this.props.input.onBlur();
    if (this.props.meta && !this.props.meta.visited) this.props.input.onFocus();
  };

  render() {
    const {
      input,
      meta: { error, warning, touched } = {},
      showError = Boolean(touched && (error || warning)),
      options,
      valueKey,
      titleKey,
      itemClass,
      value = input.value,
    } = this.props;
    return (
      <div>
        <Grid container spacing={24}>
          {options.map((opt, i) => {
            const val = String(!valueKey ? opt : opt[valueKey]);
            const label = !titleKey ? opt : opt[titleKey];
            return (
              <Grid item key={i}>
                <ButtonToggleItem
                  selected={val === value}
                  itemClass={itemClass}
                  value={val}
                  label={label}
                  onClick={this.updateValue}
                />
              </Grid>
            );
          })}
        </Grid>
        <Collapse in={showError}>
          <FormHelperText error={showError}>{error || warning}</FormHelperText>
        </Collapse>
      </div>
    );
  }
}

export default RoomiButtonToggle;
