import {
  listingFetching,
  listingFetched,
  listingError,
  listingUpdated,
  listingsFetched,
  suggestedListingsFetching,
  suggestedListingsFetched,
  suggestedListingsError,
  remove,
  listingConfigsFetched,
  fetchListingConfigs
} from './listings.types'
import Listing from '../model/listing.model'

export const getListing = id => ({
  type: listingFetching,
  id
})

export const fetchedListing = (id, listing) => ({
  type: listingFetched,
  id,
  listing: new Listing(listing)
})

export const fetchedListings = listings => ({
  type: listingsFetched,
  listings: Listing.createList(listings)
})

export const fetchingError = (id, error) => ({
  type: listingError,
  id,
  error
})

export const getSuggested = id => ({
  type: suggestedListingsFetching,
  id
})

export const suggestedFetched = (id, listings) => ({
  type: suggestedListingsFetched,
  id,
  listings: Listing.createList(listings)
})

export const suggestedError = (id, error) => ({
  type: suggestedListingsError,
  id,
  error
})

export const removeListing = id => ({ type: remove, id })

export const updateListing = (id, update) => ({
  type: listingUpdated,
  id,
  update
})

export const fetchListingConfigsAction = listingId => ({
  type: fetchListingConfigs,
  payload: listingId
})

export const listingConfigsFetchedAction = (listingId, configs) => ({
  type: listingConfigsFetched,
  payload: { id: listingId, data: configs }
})
