import { put, takeLatest, call, throttle } from 'redux-saga/effects'

import history from '../history'
import { get, post, remove } from '../shared/utils/fetch.util'

import {
  REGISTER_USER,
  FOLLOW_USER,
  UNFOLLOW_USER,
  CHECK_FOLLOW_STATUS,
  REGISTER_ACTIVITY_REACTION,
  DELETE_ACTIVITY
  // FETCH_NEIGHBORHOOD_TOKEN,
} from './timeline.types'

import {
  registerUserSuccess,
  registerUserError,
  followUserSuccess,
  followUserError,
  unfollowUserSuccess,
  unfollowUserError,
  checkFollowStatusSuccess,
  checkFollowStatusError,
  fetchNeighborhoodTokenSuccess,
  fetchNeighborhoodTokenError,
  registerActivityReactionSuccess,
  registerActivityReactionError,
  deleteActivitySuccess,
  deleteActivityError
} from './timeline.actions'

const registerFeedUser = () => post('/v2/feeds/register')

export function* registerUserSaga({ userId }) {
  try {
    const { data } = yield call(registerFeedUser)
    const { streamUserToken } = data
    yield put(registerUserSuccess(userId, streamUserToken))
  } catch (err) {
    yield put(registerUserError(err))
  }
}

const getNeighborhoodToken = neighborhood =>
  post(`/v2/feeds/neighborhood/${neighborhood}/token`)

export function* fetchNeighborhoodTokenSaga({ neighborhood }) {
  try {
    const { data } = yield call(getNeighborhoodToken, neighborhood)
    const { neighborhoodToken, neighborhoodId } = data
    yield put(
      fetchNeighborhoodTokenSuccess(
        neighborhood,
        neighborhoodToken,
        neighborhoodId
      )
    )
  } catch (err) {
    yield put(fetchNeighborhoodTokenError(err))
  }
}

const followUserFeed = userId => post('/v2/feeds/follow', { user_id: userId })

export function* followUserSaga({ userId }) {
  try {
    const { data: wasOperationSuccessful } = yield call(followUserFeed, userId)
    if (!wasOperationSuccessful) {
      throw new Error('Unsuccessful follow operation')
    }
    yield put(followUserSuccess(userId))
  } catch (err) {
    yield put(followUserError(err))
  }
}

const unfollowUserFeed = userId =>
  post('/v2/feeds/unfollow', { user_id: userId })

export function* unfollowUserSaga({ userId }) {
  try {
    const { data: wasOperationSuccessful } = yield call(
      unfollowUserFeed,
      userId
    )
    if (!wasOperationSuccessful) {
      throw new Error('Unsuccessful unfollow operation')
    }
    yield put(unfollowUserSuccess(userId))
  } catch (err) {
    yield put(unfollowUserError(err))
  }
}

const checkUserFeedFollowStatus = userId => get(`/v2/feeds/follow/${userId}`)

export function* checkFollowStatusSaga({ userId }) {
  try {
    const { data: followStatus } = yield call(checkUserFeedFollowStatus, userId)
    yield put(checkFollowStatusSuccess(userId, followStatus))
  } catch (err) {
    yield put(checkFollowStatusError(err))
  }
}

const registerActivityReaction = (
  activityId,
  reactionType,
  reactionOptions
) => {
  const body = {
    type: reactionType,
    data: reactionOptions
  }
  post(`/v2/feeds/activity/${activityId}/reaction`, body)
}

export function* registerActivityReactionSaga({
  activityId,
  reactionType,
  reactionOptions
}) {
  try {
    yield call(
      registerActivityReaction,
      activityId,
      reactionType,
      reactionOptions
    )
    yield put(registerActivityReactionSuccess())
  } catch (err) {
    yield put(registerActivityReactionError(err))
  }
}

const deleteActivityService = activityId =>
  remove(`/v2/feeds/roomi/${activityId}/user`)

export function* deleteActivitySaga({ activityId }) {
  try {
    yield call(deleteActivityService, activityId)
    yield put(deleteActivitySuccess())
    window.location.reload()
  } catch (err) {
    yield put(deleteActivityError(err))
  }
}

export default function* TimelineSaga() {
  yield takeLatest([REGISTER_USER], registerUserSaga)
  yield takeLatest([FOLLOW_USER], followUserSaga)
  yield takeLatest([UNFOLLOW_USER], unfollowUserSaga)
  yield takeLatest([CHECK_FOLLOW_STATUS], checkFollowStatusSaga)
  // yield takeLatest([REGISTER_ACTIVITY_REACTION], registerActivityReactionSaga);
  yield throttle(1000, REGISTER_ACTIVITY_REACTION, registerActivityReactionSaga)
  yield takeLatest([DELETE_ACTIVITY], deleteActivitySaga)
  // yield takeLatest([FETCH_NEIGHBORHOOD_TOKEN], fetchNeighborhoodTokenSaga);
}
