import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'payment.selector.select.payment.option.title',
    defaultMessage: 'Payment Details'
  },
  subtitle: {
    id: 'payment.selector.select.payment.option.subtitle',
    defaultMessage: 'Select a payment option to boost your listing:'
  },
  backButton: {
    id: 'payment.selector.back.button.text',
    defaultMessage: 'Back'
  },
  submitButton: {
    id: 'payment.selector.submit.button.text',
    defaultMessage: 'Submit'
  }
});
