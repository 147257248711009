import {
  errored,
  fetched,
  fetching,
  reset,
  update,
  sent
} from './booking-requests.types'
import { get, keyBy, partition, map } from 'lodash'

export const resetInstantRenting = () => ({ type: reset })
export const fetchingInstantRenting = userId => ({ type: fetching, userId })
export const fetchedInstantRenting = (irRequests, id) => {
  const [sentBR, receivedBR] = partition(
    irRequests,
    app => get(app, 'mover._id') === id
  )
  const payload = {
    data: keyBy(irRequests, '_id'),
    receivedIds: map(receivedBR, '_id'),
    sentIds: map(sentBR, '_id')
  }
  return { type: fetched, payload }
}
export const errorInstantRenting = error => ({ type: errored, payload: error })

export const sentBookingRequest = ({ data }) => ({
  type: sent,
  payload: { data }
})
export const updateBookingRequest = ({ id, data }) => ({
  type: update,
  payload: { id, data }
})
