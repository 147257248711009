import React, { PureComponent } from 'react';
import imageElementStyles from './image-element.styles';
import { withStyles } from '@material-ui/core';
import { LazyImage } from '../../lazy-image/lazy-image.component';

@withStyles(imageElementStyles)
class ImageElement extends PureComponent {
  render() {
    const { src, classes } = this.props;
    return (
      <div className={classes.overlay}>
        <LazyImage
          fit="clip"
          draggable={false}
          className={classes.image}
          src={src}
        />
      </div>
    );
  }
}

export default ImageElement;
