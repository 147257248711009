import React, { PureComponent, cloneElement, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'material-ui-pickers/DatePicker';
import { ForwardIcon, BackIcon, Calendar } from 'roomi/icons';
import RoomiInput from '../input/input';
import { isUndefined } from 'lodash';

const DEFAULT_FORMAT = 'MMM D, YYYY';
const DEFAULT_PLACEHOLDER = 'Select date';
const EMPTY_PROPS = {};
const EMPTY_INPUT = {};

class RoomiDatePicker extends PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string,
    format: PropTypes.string,
    input: PropTypes.object,
    inputComponent: PropTypes.func,
    inputProps: PropTypes.object,
    meta: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    showError: PropTypes.bool,
    value: PropTypes.string,
    iconSize: PropTypes.number,
    iconPosition: PropTypes.oneOf(['start', 'end']),
    showGenericError: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  inputRef = createRef();
  setPicker = picker => {
    this.picker = picker;
  };
  openPicker = () => !this.props.disabled && this.picker && this.picker.open();
  onClose = () => this.props.input && this.props.input.onBlur();
  onOpen = () => {
    this.props.input && this.props.input.onFocus();
    if (
      (this.props.input && this.props.input.value) ||
      !this.props.scrollYearSelectionToBottom
    )
      return null;
    // TODO remove this once date-picker resolve issues. hacky fix for WEB-2048. Issue submitted on their repo JIIRA TICKET LINK: https://roomiapp.atlassian.net/secure/RapidBoard.jspa?rapidView=4&projectKey=WEB&view=planning&selectedIssue=WEB-2396
    return setTimeout(() => {
      let elements = document.querySelectorAll('div[role="button"]');
      const [element] = Array.prototype.filter.call(elements, e => {
        return new RegExp(
          `${String(this.props.maxDate.getFullYear())}$`,
          'i',
        ).test(e.textContent);
      });
      element.scrollIntoView({ behavior: 'instant' });
    }, 50);
  };

  render() {
    const {
      input = EMPTY_INPUT,
      value = input.value, // should be in utc
      onChange = input.onChange,
      format = DEFAULT_FORMAT,
      placeholder,
      meta,
      errorMessage,
      showError,
      iconSize = 18,
      iconPosition = 'start',
      inputProps = EMPTY_PROPS,
      inputComponent,
      showGenericError,
      ...rest
    } = this.props;
    if (!isUndefined(meta)) inputProps.meta = meta;
    if (!isUndefined(showError)) inputProps.showError = !!showError;
    inputProps.errorMessage = errorMessage;
    inputProps.showGenericError = showGenericError;

    return (
      <DatePicker
        ref={this.setPicker} // 16.3 createRef syntax logging warnings
        TextFieldComponent={() =>
          !inputComponent ? (
            <RoomiInput
              {...{
                [`${iconPosition}Adornment`]: (
                  <Calendar width={iconSize} height={iconSize} />
                ),
              }}
              value={value ? moment(value).format(format) : value}
              onClick={this.openPicker}
              inputRef={this.inputRef}
              disabled={this.props.disabled}
              placeholder={placeholder || DEFAULT_PLACEHOLDER}
              {...inputProps}
            />
          ) : (
            cloneElement(
              inputComponent(value ? moment(value).format(format) : value),
              {
                onClick: this.openPicker,
              },
            )
          )
        }
        value={value}
        DialogProps={{
          onExited: () =>
            this.inputRef.current && this.inputRef.current.focus(),
        }}
        autoOk
        onClose={this.onClose}
        onOpen={this.onOpen}
        leftArrowIcon={<BackIcon />}
        rightArrowIcon={<ForwardIcon />}
        onChange={onChange}
        {...rest}
      />
    );
  }
}

export default RoomiDatePicker;
