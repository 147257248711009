const userAvatarStyles = theme => ({
  userAvatar: {
    transition: 'color 0.25s ease-in-out',
    '&:hover': {
      color: theme.palette.primary.main,
      '& $userImageContainer': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  userImageContainer: {
    borderRadius: '50%',
    borderWidth: 3,
    borderStyle: 'solid',
    transition: 'border 0.25s ease-in-out',
    borderColor: theme.palette.common.white,
    margin: '0 auto',
    position: 'relative'
  },
  userImage: {
    borderRadius: '50%'
  },
  badge: {
    height: 30,
    width: 20
  },
  badgesContainer: {
    position: 'absolute',
    right: 0,
    top: 35
  }
});

export default userAvatarStyles;
