import { post, put, get, remove } from '../../shared/utils/fetch.util'
import { times } from 'lodash'

export const createRoomiPayAccountCall = params => post('/v3/roomipay', params)
export const updateRoomiPayAddressCall = params => put('/v3/roomipay/address', params)
export const updateRoomiPayTenantsCall = params => put('/v3/roomipay/tenants', params)
export const updateRoomiPayRenterCall = params => put('/v3/roomipay/renter', params)
export const updateRoomiPayPaymentCall = params => put('/v3/roomipay/payment', params)


export const fetchRoomiPayCall = () => get('/v3/roomipay')
export const fetchInvitationCall = (id, type) => get(`/v3/roomipay/${type}/invitation/${id}`)
export const acceptInvitationCall = (id, type) => post(`/v3/roomipay/${type}/invitation/${id}/accept`)

export const cancelRoomiPayCall = () => remove('/v3/roomipay')