const browserNotSupportedStyles = theme => ({
  browserNotSupported: {
    textAlign: 'center',
    fontWeight: 900,
    minHeight: '100vh',
    maxWidth: 400,
    margin: '0 auto',
    position: 'relative'
  },
  container: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  errorImage: {
    height: 150,
    marginBottom: theme.spacing.unit
  },
  title: {
    fontSize: 22,
    margin: `${theme.spacing.unit * 2}px 0`
  },
  note: {
    fontSize: 14
  }
});

export default browserNotSupportedStyles;
