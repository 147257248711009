import { defineMessages } from 'react-intl'

export default defineMessages({
  boostSuccessButton: {
    id: 'boost.success.button',
    defaultMessage: 'Close'
  },
  boostSuccessUnit: {
    id: 'boost.success.unit',
    defaultMessage: '{unit} {unit, plural, one {Day} other {Days}} left!'
  }
})
