const socialDisconnectStyles = theme => ({
  socialDisconnect: {
    cursor: 'pointer',
    fontSize: 15,
    textAlign: 'center',
    fontWeight: 500,
    textTransform: 'capitalize',
    '&$disabled': {
      opacity: 0.6,
      pointerEvents: 'none'
    }
  },
  disabled: {}
});

export default socialDisconnectStyles;
