import { Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Close as CloseIcon, Error as ErrorIcon } from 'roomi/icons';
import errorBannerStyles from './error-banner.styles';

@withStyles(errorBannerStyles)
class ErrorBanner extends PureComponent {
  static propTypes = {
    error: PropTypes.string,
    className: PropTypes.string,
    onHide: PropTypes.func
  };

  render() {
    const { error, onHide, className, classes } = this.props;
    return error ? (
      <Grid container justify="space-between" alignItems="center" className={classNames(classes.error, className)}>
        <Grid container alignItems="center" item xs>
          <ErrorIcon className={classes.errorIcon}/>
          <div className={classes.errorMessage}>{error}</div>
        </Grid>
        {onHide && (
          <Grid item xs="auto">
            <CloseIcon className={classes.closeIcon} onClick={onHide}/>
          </Grid>
        )}
      </Grid>
    ) : null;
  }
}

export default ErrorBanner;
