import { fetching, fetched, error } from '../actions/amenities.types';
import { keyBy } from 'lodash';

export default (state = {}, { type, payload } = {}) => {
  switch (type) {
  case fetching:
    return { ...state, ...{ fetching: true, fetched: false } };
  case fetched:
    return { ...state, ...{ fetching: false, fetched: true, data: keyBy(payload, '_id') } };
  case error:
    return { ...state, ...{ fetching: false, fetched: true, error: payload } };
  default:
    return state;
  }
};

