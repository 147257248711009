import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Hidden } from '@material-ui/core'
import boostProfileBannerStyles from './boost-profile-banner.component.styles'
import { ReactComponent as BoostIcon } from '../../assets/boost.svg'
import boostProfileBannerText from './boost-profile-banner.text'
import { FormattedMessage } from 'react-intl'
import { RoomiButton } from 'roomi/material'
import { injectIntl } from 'react-intl'
import BoostProfileModal from '../boost-profile-modal/boost-profile-modal.component'

@injectIntl
@withStyles(boostProfileBannerStyles)
class BoostProfileBanner extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    plans: PropTypes.array
  }

  state = { open: this.props.open, hidden: false }

  hideBanner = () => this.setState({ hidden: true })

  openBoostDialog = () => this.setState({ open: true })
  closeBoostDialog = () => this.setState({ open: false })

  render() {
    const {
      plans,
      user,
      classes,
      showIcon = true,
      showButtonOnly,
      intl: { formatMessage }
    } = this.props
    const { open, hidden } = this.state

    if (!plans.length || hidden) return null

    return (
      <Fragment>
        {!showButtonOnly}
        <div
          className={!showButtonOnly ? classes.boostBanner : classes.nullClass}
        >
          <div
            className={
              !showButtonOnly
                ? classes.boostBannerContentContainer
                : classes.nullClass
            }
          >
            <div
              className={
                !showButtonOnly || showIcon
                  ? classes.boostBannerContent
                  : classes.nullClass
              }
            >
              <Fragment>
                {showIcon && (
                  <Hidden smDown>
                    <BoostIcon />{' '}
                    {/* TODO: this icon is old one and is bit different than the design */}
                  </Hidden>
                )}
                {!showButtonOnly && (
                  <FormattedMessage {...boostProfileBannerText.boostBannerText}>
                    {text => <div className={classes.boostMessage}>{text}</div>}
                  </FormattedMessage>
                )}
              </Fragment>
              <Hidden smDown>
                <RoomiButton
                  className={classes.boostButton}
                  primary
                  small
                  onClick={this.openBoostDialog}
                  label={formatMessage(
                    boostProfileBannerText.boostBannerButton
                  )}
                />
              </Hidden>
            </div>
          </div>
          <div className={classes.actions}>
            <Hidden mdUp>
              <RoomiButton
                primary
                small
                onClick={this.openBoostDialog}
                label={formatMessage(
                  boostProfileBannerText.boostBannerButtonTabletOrMobile
                )}
              />
              `
            </Hidden>
            {!showButtonOnly && (
              <RoomiButton
                className={classes.closeButton}
                small
                onClick={this.hideBanner}
                label={formatMessage(
                  boostProfileBannerText.boostBannerButtonClose
                )}
              />
            )}
          </div>
        </div>
        {open && (
          <BoostProfileModal
            user={user}
            plans={plans}
            open
            onClose={this.closeBoostDialog}
          />
        )}
      </Fragment>
    )
  }
}

export default BoostProfileBanner
