const shortTermInfoCardStyles = theme => ({
    card: {
        maxWidth: 270,
        maxHeight: 40,
    },
    cardContent: {
      padding: '10px'
    },
    tooltip: {
      marginLeft: '10px'
    },
    text: {
      fontSize: '14px',
    }
  });
  
  export default shortTermInfoCardStyles;
  