import {
  errored,
  fetched,
  fetching,
  reset,
  newImport
} from './import-requests.types'
import { keyBy } from 'lodash'

export const resetInstantRenting = () => ({ type: reset })
export const addImportedListing = imported => ({ type: newImport, imported })
export const fetchingInstantRenting = () => ({ type: fetching })
export const fetchedInstantRenting = requests => ({
  type: fetched,
  requests: keyBy(requests, '_id')
})
export const errorInstantRenting = error => ({ type: errored, error })
