import { find, get, map } from 'lodash';
import { createSelector } from 'reselect';

const getConversations = state => state.conversations.data;
export const getRoomID = (state, uuid) => get(state, `conversations.data.${uuid}.metadata.roomID`);
export const isFetchingConversations = state => state.conversations.fetching;
export const hasFetchedConversations = state => state.conversations.fetched;
export const getChatUserInfo = state => state.conversations.chatUserInfo;
export const getChatUserInfoById = (state, userId) => state.conversations.chatUserInfo[userId];

export const getListingInfo = state => {
  if (!state.conversations.listingInfo) return;
  return state.conversations.listingInfo;
}

export const findExistingConversationForListing = createSelector(
  [getConversations, (state, search) => search],
  (conversations, { listingId, userId }) =>
    find(
      conversations,
      convo =>
        get(convo, 'metadata.roomID') === listingId &&
        convo.recipient.userId === userId
    )
);

export const findExistingConversationWithUser = createSelector(
  [getConversations, (state, userId) => userId],
  (conversations, userId) => find(conversations, convo => get(convo, 'recipient.userId') === userId)
);

export const getTotalUnread = state => state.conversations.totalUnread;
export const getConversationsOrder = state => state.conversations.order;
export const getConversation = (state, uuid) => get(state, `conversations.data.${uuid}`);
export const getOrderedConversations = createSelector(
  [getConversations, getConversationsOrder],
  (conversations, orderedIds) => map(orderedIds, id => conversations[id])
);

