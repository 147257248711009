const promoCodeStyles = theme => ({
  promoCodeContainer: {
    alignSelf: 'stretch'
  },
  promoWrapper: {
    [theme.breakpoints.down(400)]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  promoCode: {
    textAlign: 'left'
  },
  inputWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: 200
    }
  },
  input: {
    border: `1px dashed ${theme.palette.grey[500]}`
  },
  applyCode: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    position: 'relative',
    top: 10, // cannot use flex center, when error is present it shift Apply down due to height change
    '&:hover': {
      color: theme.palette.primary[800]
    }
  },
  appliedCode: {
    marginRight: theme.spacing.unit * 2,
    color: theme.palette.primary.main,
    fontWeight: 900
  },
  price: {},
  ['@keyframes strike']: {
    from: { transform: 'translateX(0)' },
    to: { transform: 'translateX(-100%)' }
  },
  reducedPrice: {
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      width: '100%',
      height: 1,
      background: '#979797',
      animationName: 'strike',
      animationDuration: '.5s',
      animationTimingFunction: 'cubic-bezier(0.99,-0.1, 0.63, 1.5)',
      animationIterationCount: 1,
      animationFillMode: 'forwards'
    }
  },
  checkMark: {
    fill: '#96BDA3'
  },
  errorIcon: {
    fill: 'red'
  },
  divider: {
    alignSelf: 'stretch',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: 'none'
  },
  buttonsContainer: {
    '& > button:not(:last-child)': {
      marginRight: 35
    }
  }
})

export default promoCodeStyles
