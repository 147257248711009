import moment from 'moment'
import { truncate, replace } from 'lodash'
import { compact, map, groupBy, size, first, find } from 'lodash'

export const userNameWithAge = (user, maxNameLength) => {
  let firstName = getUserName(user)
  firstName = truncate(firstName, { length: maxNameLength || 20 })
  const age = getUserAge(user)
  return age ? `${firstName}, ${age}` : firstName
}

export const getUserName = user => {
  const defaultName = 'Guest'
  if (!user) return defaultName
  let firstName = user.usrFirstName || user.firstName || defaultName
  firstName = firstName.toLocaleLowerCase()
  firstName = replace(firstName.replace(/ +/g, ' '), /(^|[\s-])\S/g, e =>
    e.toUpperCase()
  )
  return firstName
}

export const getUserAge = user =>
  user && user.dob && moment().diff(moment(new Date(user.dob)), 'years')

export const mapQuestionnaire = (
  { questionnaire: configQuestionnaire = [] } = {},
  questionnaire = {}
) =>
  compact(
    map(configQuestionnaire, question => {
      const answer = questionnaire[question.value]
      if (question.multi) {
        const group = groupBy(question.answers, 'value')
        return size(answer)
          ? {
              title: question.title,
              answer: map(
                questionnaire[question.value],
                i => first(group[i]).title
              ),
              multi: question.multi
            }
          : null
      }
      const mappedAnswer = find(question.answers, ans => ans.value === answer)
      return mappedAnswer
        ? {
            title: question.title,
            answer: mappedAnswer.title,
            multi: question.multi
          }
        : null
    })
  )

export const getPreferredLocation = ROI =>
  ROI.regionLongName || ROI.regionName || 'None'

export const getMoveInDate = ({ usrMovingTimeframe = {} }) => {
  const date = usrMovingTimeframe.iso
  return date ? moment(date).format('MMM D, YYYY') : 'None'
}
