import {
  error as errorType,
  fetched,
  remove,
  addUserListing
} from '../actions/user-listings.types';
import { unAuthenticate } from '../../auth/actions/auth.types';
import { without } from 'lodash';
import update from 'immutability-helper';

export default (state = {}, { type, id, error, listingIds } = {}) => {
  switch (type) {
    case fetched:
      return update(state, {
        [id]: { $set: { listingIds, fetched: true } }
      });
    case addUserListing:
      return update(state, {
        [id]: { listingIds: { $push: listingIds } }
      });
    case unAuthenticate:
      return update(state, { $unset: ['me'] });
    case errorType:
      return update(state, {
        [id]: { $set: { error, fetched: false } }
      });
    case remove:
      return update(state, {
        me: { listingIds: { $set: without(state.me.listingIds, id) } }
      });
    default:
      return state;
  }
};
