import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { updateExperimentsAction } from 'roomi/actions/config.actions';
import { getExperiments } from 'roomi/reducers/experiments.selector';
import ExperimentItem from './components/experiment-item/experiment-item';
import experimentsManagerStyles from './experiments-manager.styles';

const mapStateToProps = state => ({
  experiments: getExperiments(state)
});

@connect(
  mapStateToProps,
  { updateExperimentsAction }
)
@withStyles(experimentsManagerStyles)
class ExperimentsManager extends PureComponent {
  static propTypes = {
    experiments: PropTypes.array
  };

  state = {
    open: this.props.experiments[0].name
  };

  openExperiment = ({ name }) => this.setState({ open: name });

  updateExperiments = (experiment, variation) => {
    const _experiments = [...this.props.experiments];
    const index = _experiments.findIndex(e => e._id === experiment._id);
    _experiments[index] = { ..._experiments[index], variation };
    this.props.updateExperimentsAction(_experiments);
  };

  render() {
    const { open } = this.state;
    const { experiments, classes } = this.props;
    return (
      <div className={classes.experimentsManager}>
        {experiments.map(experiment => (
          <ExperimentItem
            experiment={experiment}
            open={open}
            key={experiment.name}
            onClick={this.openExperiment}
            onVariationChange={this.updateExperiments}
          />
        ))}
      </div>
    );
  }
}

export default ExperimentsManager;
