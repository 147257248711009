const selectBoostPlan = theme => ({
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 40,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      padding: '0 15px',
      fontSize: 24
    }
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.button.fontFamily,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: 30
  },
  description: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '19px',
    textAlign: 'center'
  },
  planSelectorContainer: {
    padding: '90px 0'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: 320
  },
  cancelBtn: {
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      marginRight: 35
    }
  }
});

export default selectBoostPlan;
