import { loadScript } from '../../shared/actions/scripts.action'
import { get } from 'lodash'
import history from '../../history'

const initBranch = () =>
  new Promise(
    (resolve, reject) =>
      window.branch &&
      window.branch.init(process.env.BRANCH_KEY, (err, data) => {
        if (err) return reject(err)
        return resolve(data)
      })
  )

const showDownloadAppBanner = () =>
  window.branch &&
  window.branch.banner(
    {
      icon: 'https://roomipngs.imgix.net/coreIcon.png',
      title: 'Roomi',
      description: 'Find Roommates & Apartment Rentals',
      openAppButtonText: 'Open app',
      forgetHide: 1,
      showDesktop: false
    },
    {}
  )

const handleError = error => {
  console.log('!! Branch error !!', error) // eslint-disable-line
}

const handleRedirect = data => {
  const type = get(data, 'data_parsed.deepLinkType')
  switch (type) {
    case 'viewBookingInvite':
      history.replace({
        pathname: `/bookings/invites/${data.data_parsed.bookingInviteId}`,
        state: { source: 'deepLink' }
      })
      break
    case 'invites':
      history.replace('/bookings?type=invites')
      break
    case 'myListings':
      history.replace('/myListings/manage')
      break
    case 'recentlyViewed':
      history.replace('/myListings/recently-viewed')
      break
    case 'searchListing':
      history.replace(`/s?region=${data.data_parsed.regionId}`)
      break
    case 'messages':
      history.replace('/messages')
      break
    case 'createListing':
      history.replace('/post-listing')
      break
    case 'listing':
      history.replace(`/rooms-for-rent/${data.data_parsed.objectId}`)
      break
    case 'booking':
      history.replace('/my-requests')
      break
    case 'conversation':
      history.replace(`/messages?id=${data.data_parsed.objectId}`)
      break
    case 'editIncompleteListing':
      history.replace(`/incomplete/${data.data_parsed.listingId}/edit`)
      break
    default:
  }
}

export const startBranch = () => dispatch => {
  loadScript('Branch', 'https://cdn.branch.io/branch-latest.min.js')(dispatch)
    .then(initBranch)
    .then(handleRedirect)
    .then(showDownloadAppBanner)
    .catch(handleError)
}
