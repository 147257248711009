const lgHeaderStyles = theme => ({
  left: {
    display: 'flex',
    alignItems: 'center',
    float: 'left'
  },
  leftLinkContainer: {
    '& > *': {
      marginLeft: theme.spacing.unit * 3
    }
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:first-child):not($dropDowns)': {
      marginLeft: theme.spacing.unit * 3
    }
  },
  dropDowns: {
    display: 'flex',
    marginTop: 5,
    '& > *': {
      marginBottom: 8,
      height: 60,
      width: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      marginLeft: 0
    }
  },
  banner: {
    width: 110
  },
  button: {
    height: 30,
    fontSize: 12
  },
  divider: {
    border: `1px solid ${theme.palette.grey[200]}`,
    height: '80%'
  },
  link: {
    cursor: 'pointer',
    '&.active span': {
      color: theme.palette.primary.main
    },
    '&:focus': {
      outline: 'none'
    }
  }
});

export default lgHeaderStyles;
