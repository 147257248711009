const checkboxStyles = theme => ({
  size: {
    width: 16,
    height: 16
  },
  sizeIcon: {
    fontSize: 16
  },
  checked: {},
  colorPrimary: {
    color: theme.palette.grey[400],
    '&$checked + $label': {
      color: theme.palette.primary.main
    }
  },
  colorSecondary: {
    color: theme.palette.grey[400],
    '&$checked + $label': {
      color: theme.palette.secondary.main
    }
  },
  label: {
    color: theme.palette.grey[500]
  }
});

export default checkboxStyles;
