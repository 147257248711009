import { takeLatest, call, put } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import {
  notificationsFetch,
  notificationMarkRead,
  notificationMarkAllRead,
  REGISTER_DEVICE
} from '../actions/announcement.types'
import { get, post, put as putRequest } from '../../shared/utils/fetch.util'
import { getAuthToken } from '../../auth/utils/session-manager.util'

import {
  fetchNotifications,
  fetchNotificationsSuccess,
  // markNotificationsReadSuccess,
  // markAllNotificationsReadSuccess,
  registerDeviceSuccess,
  registerDeviceError
} from '../actions/announcement.actions'

export const registerWebDevice = token => {
  const body = {
    firebaseToken: token
  }
  post('/v2/notifications/register-device/web', body)
}

export function* registerWebDeviceService(/* { firebaseToken } */) {
  const token = getAuthToken()
  const firebaseToken = window.localStorage.getItem('firebaseToken')
  if (!token || !firebaseToken) return
  try {
    yield call(registerWebDevice, firebaseToken)
    yield put(registerDeviceSuccess())
  } catch (err) {
    yield put(registerDeviceError(err))
  }
}

export const loadNotificationsForUser = () => get('/v2/notifications')

export function* notificationSagaService() {
  const token = getAuthToken()
  if (token) {
    yield takeLatest(notificationsFetch, loadNotificationsForUser)
    const { data } = yield call(loadNotificationsForUser)
    yield put(fetchNotificationsSuccess(data))
  }
  yield delay(10000)
  yield put(fetchNotifications())
}

export const markNotificationRead = notificationId => {
  const url = `/v2/notifications/${notificationId}/mark_read`
  return putRequest(url)
}

export function* markNotificationReadSaga({ notificationId }) {
  if (!notificationId) return
  yield call(markNotificationRead, notificationId)
  // yield put(markNotificationsReadSuccess());
  // yield put(fetchNotifications());
}

export const markRead = notificationId => {
  const requestBody = { notification_id: notificationId }
  const url = '/v2/notifications/mark_all_read'
  return putRequest(url, requestBody)
}

export function* markReadSaga({ notificationId }) {
  if (!notificationId) return
  yield call(markRead, notificationId)
  // yield put(markNotificationsReadSuccess());
  // yield put(fetchNotifications());
}

export function* notificationReadSagaService() {
  yield takeLatest([notificationMarkAllRead], markReadSaga)
  yield takeLatest([notificationMarkRead], markNotificationReadSaga)
}

export default function*() {
  yield takeLatest([notificationsFetch], notificationSagaService)
  yield takeLatest([REGISTER_DEVICE], registerWebDeviceService)
}
