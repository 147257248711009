const pageNotFoundStyles = theme => ({
  pageNotFound: {
    maxWidth: 500,
    textAlign: 'center',
    margin: `${theme.spacing.unit * 5}px auto`,
    [theme.breakpoints.only('xs')]: {
      maxWidth: '85%'
    }
  },
  header: {
    fontWeight: 900,
    fontSize: 35
  },
  topHeader: {
    fontWeight: 900,
    fontSize: 20
  },
  bottomHeader: {
    fontWeight: 900,
    fontSize: 16
  },
  notFoundImage: {
    height: 320,
    width: 340,
    margin: `${theme.spacing.unit * 4}px 0`,
    [theme.breakpoints.only('xs')]: {
      height: 250,
      width: 270
    }
  }
});

export default pageNotFoundStyles;
