import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import qs from 'querystring'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import WindowInfo from 'roomi/utils/window-info'
import styles from '../facebook-connect/facebook-connect.styles'
import messages from '../facebook-connect/facebook-connect.text'

// Permission needed: 'user_about_me', 'user_relationships', 'user_work_history', 'user_education_history', 'user_actions.music'
const mapStateToProps = ({ scripts: { FB } }) => ({ fbStatus: FB })
const permissions = [
  'email',
  'user_likes',
  'user_friends',
  'user_birthday',
  'user_location',
  'user_hometown',
  'public_profile',
  'user_photos'
]
const scope = permissions.join(',')

@withStyles(styles)
@connect(mapStateToProps)
class FacebookAuthorize extends PureComponent {
  static propTypes = {
    onAuthorization: PropTypes.func,
    onCancel: PropTypes.func,
    redirectType: PropTypes.oneOf(['connect', 'login', 'signUp']),
    isDisabled: PropTypes.bool,
    trigger: PropTypes.func,
    fullWidth: PropTypes.bool,
    className: PropTypes.string
  }

  static shouldUseSdk(sdkStatus) {
    const { os, browser } = WindowInfo
    return (
      sdkStatus === 'SUCCESS' &&
      !(os && os.name === 'iOS' && browser && browser.name === 'Chrome')
    )
  }

  static getAuthUrl(redirectType = 'login') {
    const location = window.location
    const query = qs.parse(window.location.search.substring(1))
    query.redirectType = redirectType
    const redirect = `${location.origin}${
      location.pathname
    }?${encodeURIComponent(qs.stringify(query))}`
    return `https://www.facebook.com/dialog/oauth?client_id=${
      process.env.FACEBOOK_APP_ID
    }&redirect_uri=${redirect}&response_type=token&scope=${scope}`
  }

  state = {
    processing: false
  }

  getAccessToken = () => {
    const {
      fbStatus,
      onAuthorization,
      onCancel,
      redirectType,
      isDisabled
    } = this.props
    if (isDisabled || this.state.processing) return
    this.setState({ processing: true })
    if (FacebookAuthorize.shouldUseSdk(fbStatus)) {
      window.FB.login(
        ({ authResponse }) => {
          this.setState({ processing: false })
          if (authResponse) onAuthorization(authResponse.accessToken)
          else onCancel && onCancel(authResponse)
        },
        { scope }
      )
    } else {
      const authUrl = FacebookAuthorize.getAuthUrl(redirectType)
      window.open(authUrl, '_self')
    }
  }

  trigger = () => <FormattedMessage {...messages.label} />

  render() {
    const {
      trigger = this.trigger,
      className,
      classes,
      isDisabled,
      fullWidth
    } = this.props

    return (
      <div
        onClick={this.getAccessToken}
        className={classNames(className, classes.facebookConnect, {
          [classes.disabled]: isDisabled,
          [classes.fullWidth]: fullWidth
        })}
      >
        {trigger()}
      </div>
    )
  }
}

export default FacebookAuthorize
