const selectStyles = theme => ({
  root: {
    display: 'flex',
    outlineStyle: 'none'
  },
  icon: {
    display: 'none'
  },
  select: {
    background: theme.palette.common.white,
    '&:focus': {
      outlineStyle: 'none',
      background: theme.palette.common.white
    }
  },
  arrow: {},
  selectMenu: {
    height: '100%',
    padding: `0 ${theme.spacing.unit}px 0 0`,
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    textIndent: 12
  },
  localInputRootClass: {
    padding: 0
  }
});

export default selectStyles;
