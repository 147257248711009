import React, { PureComponent, Fragment } from 'react'
import { savePreOnboardingRoute } from '../../auth/actions/onboarding.actions'
import EnsureLogin from '../../auth/components/ensure-login-button/ensure-login-button.component'
import { roomiBlackLogo } from 'roomi/icons'
import PropTypes from 'prop-types'
import track from 'react-tracking'
// import MessagesDropDown from '../components/messages-dropdown/messages-dropdown.component'
// import NotificationsDropDown from '../components/notifications-dropdown/notifications-dropdown.component'
import UserOptionsDropDown from '../components/user-options-dropdown/user-options-dropdown.component'
import { withRouter } from 'react-router-dom'
import lgHeaderStyles from './lg-header.styles'
import { withStyles } from '@material-ui/core'
import messages from '../header.text'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { fetchUserListings } from '../../users/actions/user-listings.actions'
import {
  getUserListingsSplitByActive,
  hasFetchedUserListings
} from '../../users/reducers/user-listings.selector'
// import BoostListingContainer from '../../sponsorships/components/boost-listing-container/boost-listing-container.component'
// import BoostProfileContainer from '../../sponsorships/components/boost-profile-container/boost-profile-container.component'
// import CreateListingButton from '../../manage-listings/components/create-listing-button/create-listing-button.component'
import { hasPaidForProfileBoost } from '../../sponsorships/reducers/sponsorships.selector'
import RoomiNavLink from '../../shared/components/roomi-nav-link/roomi-nav-link.component'

const mapStateToProps = state => {
  const [activeListings, inactiveListings] = getUserListingsSplitByActive(
    state,
    'me'
  )
  return {
    activeListings,
    inactiveListings,
    hasBoost: hasPaidForProfileBoost(state),
    fetched: hasFetchedUserListings(state, 'me'),
    fetchUserListings
  }
}

@track({})
@injectIntl
@withRouter
@withStyles(lgHeaderStyles)
@connect(mapStateToProps)
class LargeHeader extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    isMover: PropTypes.bool
  }

  componentDidMount() {
    this.props.fetchUserListings('me')
  }

  @track({
    action: 'Click_CreateListing',
    fb: true,
    ga: { category: 'Listings' }
  })
  goCreate = () => {}

  @track({ action: 'Click_GetStarted' })
  fireEventAndSaveRoute = () => {
    savePreOnboardingRoute()
  }

  render() {
    const { user, classes, hasBoost, activeListings, isMover } = this.props
    const isLoggedIn = !!user

    const showBoost = isMover && !hasBoost
    return (
      <Fragment>
        <div className={classes.left}>
          <RoomiNavLink to="/" className={classes.link}>
            <img
              className={classes.banner}
              src={roomiBlackLogo}
              alt="Roomi Banner"
            />
          </RoomiNavLink>
        </div>
        <div className={classes.right}>
          {isLoggedIn ? (
            <Fragment>
              <div className={classes.dropDowns}>
                <UserOptionsDropDown user={user} />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <EnsureLogin>
                <a className="clickable aut-button-log-in">
                  <FormattedMessage {...messages.logIn} />
                </a>
              </EnsureLogin>
              <RoomiNavLink
                onClick={this.fireEventAndSaveRoute}
                to="/onboarding"
              >
                <FormattedMessage {...messages.signUp} />
              </RoomiNavLink>
            </Fragment>
          )}
        </div>
      </Fragment>
    )
  }
}

export default LargeHeader
