import { createSelector } from 'reselect';
import { reduce } from 'lodash';

export const isFetchingAnnouncement = state => state.announcements.fetching;
export const getAnnouncements = state => state.announcements.data;
export const getRoomiNotifications = state => {
  const notifications = state.announcements.notifications || [];
  return notifications;
}
export const getTotalUnreadAnnouncements = createSelector(
  // [getAnnouncements],
  [getRoomiNotifications],
  notis => reduce(notis, (sum, noti) => sum + (noti.status !== 'read' ? 1 : 0), 0)
);
