import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { sortBy, filter, includes, remove } from 'lodash'
import { connect } from 'react-redux'
import { Tooltip } from '@material-ui/core'

const mapStateToProps = ({ badges }) => ({ badges: badges.data })

@connect(mapStateToProps)
class Badges extends PureComponent {
  static propTypes = {
    badges: PropTypes.object,
    ids: PropTypes.array.isRequired,
    all: PropTypes.bool,
    tooltip: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf(['user', 'listing']).isRequired
  }

  static sortBadges({ badges, ids, all, type }) {
    if (type === 'listing' && includes(ids, 14)) {
      ids = remove(ids, 14)
    }

    if (!Array.isArray(ids) || !ids.length) return []

    const filtered = filter(
      badges,
      i => i.status === 'active' && i.display[type] && ids.includes(i._id)
    )
    if (!filtered.length) return []
    const sorted = sortBy(filtered, 'order')
    return all ? sorted : [sorted[0]]
  }

  state = {
    ids: [],
    sortedBadges: []
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.ids !== nextProps.ids) {
      return {
        ids: nextProps.ids,
        sortedBadges: Badges.sortBadges(nextProps)
      }
    }
    return null
  }

  render() {
    const { badges, className, tooltip } = this.props
    const { sortedBadges } = this.state
    if (!badges) return null

    return sortedBadges.map((badge, i) => {
      if (badge._id === 15) {
        return null
      }
      return (
        <Tooltip
          title={tooltip ? badge.description : ''}
          placement="top"
          key={i}
        >
          <img
            src={badge.images.april2018.svg}
            className={className}
            alt="Badge"
          />
        </Tooltip>
      )
    })
  }
}

export default Badges
