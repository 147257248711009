import { spawn } from 'redux-saga/effects'
import UsersSaga from './users.saga'
import UserSaga from './user.saga'
import UserListingsSaga from './user-listings.saga'
import UserFeedsSaga from './user-feeds.saga'
import TimelineSaga from '../../timeline/timeline.saga'

export default function*() {
  yield [
    spawn(UsersSaga),
    spawn(UserSaga),
    spawn(UserListingsSaga),
    spawn(UserFeedsSaga),
    spawn(TimelineSaga)
  ]
}
