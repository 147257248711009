import {
  favoriteSuccess,
  favoriteError,
  unFavoriteSuccess,
  unFavoriteError,
  favoritesSet,
  favoritesReset
} from '../actions/favorites.types';
import update from 'immutability-helper';

const initialState = { initialized: false, data: {} };

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case favoriteSuccess:
    case unFavoriteError:
      return update(state, { data: { [payload]: { $set: true } } });
    case unFavoriteSuccess:
    case favoriteError:
      return update(state, { data: { [payload]: { $set: false } } });
    case favoritesSet:
      return update(state, { $set: { data: payload, initialized: true } });
    case favoritesReset:
      return initialState;
    default:
      return state;
  }
};
