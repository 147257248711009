import { get } from '../utils/fetch.util'
import { get as _get } from 'lodash'

const transformFeaturedListing = data => {
  const parsed = JSON.parse(data)
  return parsed.map(item => {
    item.user = item.userId
    item.user.defaultImage = _get(
      item,
      `user.urlOfPictureImages[${item.user.indexOfPrimaryImage || 0}]`
    )
    delete item.userId
    return item
  })
}

export const getFeaturedListings = () => {
  return get('/v1/hotcontent/featuredlistings', {}, {}, [
    transformFeaturedListing
  ])
}
