import {
  errorFetchAllBookingInvites,
  fetchAllBookingInvites,
  fetchedAllBookingInvites,
  cancelBookingInvite,
  errorCancelBookingInvite,
  canceledBookingInvite,
  clearBookingInvites,
  sentBookingInvite,
  fetchBookingInvite,
  fetchedBookingInvite,
  errorFetchBookingInvite,
  declinedBookingInvite,
  acceptedBookingInvite
} from './booking-invites.types';

export const fetchAllBookingInvitesAction = () => ({ type: fetchAllBookingInvites });
export const fetchedAllBookingInvitesAction = (bookingInvites) => ({ type: fetchedAllBookingInvites, payload: bookingInvites });
export const errorFetchAllBookingInvitesAction = (error) => ({ type: errorFetchAllBookingInvites, payload: error });
export const cancelBookingInviteAction = (bookingInvite) => ({ type: cancelBookingInvite, payload: bookingInvite });
export const errorCancelBookingInviteAction = (error) => ({ type: errorCancelBookingInvite, payload: error });
export const bookingInviteCanceledAction = (bookingInvite) => ({ type: canceledBookingInvite, payload: bookingInvite });
export const clearBookingInvitesAction = () => ({ type: clearBookingInvites });
export const bookingInviteSentAction = (bookingInvite) => ({ type: sentBookingInvite, payload: bookingInvite });
export const fetchBookingInviteAction = (id) => ({ type: fetchBookingInvite, payload: id });
export const fetchedBookingInviteAction = (id) => ({ type: fetchedBookingInvite, payload: id });
export const errorFetchBookingInviteAction = (error) => ({ type: errorFetchBookingInvite, payload: error });
export const bookingInviteDeclinedAction = (bookingInvite) => ({ type: declinedBookingInvite, payload: bookingInvite });
export const bookingInviteAcceptedAction = (bookingInvite) => ({ type: acceptedBookingInvite, payload: bookingInvite });

