import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { IntlContext } from '../../../intl.context'
const esRegex = /^\/es(\/|$)/

export default function RoomiNavLink(props) {
  const { language } = useContext(IntlContext)
  const getPath = to => {
    if (!to) {
      return to
    }
    let path = to.pathname ? to.pathname : to
    if (path.match(esRegex) && language !== 'es') {
      path = path.replace(esRegex, '/')
    } else if (!path.match(esRegex) && language === 'es') {
      path = '/es' + path
    }
    return to.pathname ? { ...to, pathname: to.pathname } : path
  }
  return <NavLink {...props} to={getPath(props.to)} />
}
