import roomiBlackLogo from './roomi-black-logo.png'
import { ReactComponent as Share } from './share.svg'
import { ReactComponent as RoomiSmallLogo } from './roomi-small.logo.svg'
import roomiGuestUser from './guest-user.png'
import { ReactComponent as ChatBubbleOutlined } from './chat-bubble-outlined.svg'
import { ReactComponent as NotificationBellOutlined } from './notification-bell-outlined.svg'
import questionCircle from './question-circle.png'
import camera from './camera.png'
import uploadCloud from './upload-cloud.png'
import { ReactComponent as Calendar } from './calendar.svg'
import { ReactComponent as Filter } from './filter.svg'
import { ReactComponent as Location } from './location.svg'
import { ReactComponent as LocationYellow } from './location-yellow.svg'
import { ReactComponent as Money } from './money.svg'
import { ReactComponent as Forbidden } from './forbidden.svg'
import emptyListing from './empty-listing.png'
import { ReactComponent as House } from './house.svg'
import { ReactComponent as HouseYellow } from './house-yellow.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as BackIcon } from './back.svg'
import { ReactComponent as ForwardIcon } from './forward.svg'
import { ReactComponent as LeftArrowShadow } from './chevron-left-shadow.svg'
import { ReactComponent as RightArrowShadow } from './chevron-right-shadow.svg'
import { ReactComponent as Clock } from './clock.svg'
import { ReactComponent as Heart } from './heart.svg'
import { ReactComponent as Roommates } from './roommates.svg'
import { ReactComponent as Truck } from './truck.svg'
import { ReactComponent as Facebook } from './facebook-icon.svg'
import { ReactComponent as FacebookLogo } from './facebook-logo.svg'
import { ReactComponent as LinkedInLogo } from './linkedin-logo.svg'
import { ReactComponent as DoubleQuotes } from './double-quotes.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as Mail } from './mail.svg'
import bus from './bus.png'
import train from './train.png'
import activeBus from './active-bus.png'
import activeTrain from './active-train.png'
import { ReactComponent as Flag } from './flag.svg'
import { ReactComponent as Bullet } from './bullet.svg'
import { ReactComponent as DoubleBullet } from './double-bullet.svg'
import { ReactComponent as YellowHouse } from './yellow-house.svg'
import { ReactComponent as CurrencyUSD } from './usd.svg'
import { ReactComponent as CurrencyGBP } from './gbp.svg'
import { ReactComponent as CurrencyINR } from './inr.svg'
import { ReactComponent as BlueTruck } from './blue-truck.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as BlueCloseCircle } from './blue-close-with-circle.svg'
import { ReactComponent as Trash } from './trash.svg'
import { ReactComponent as Remove } from './redesign-remove.svg' // we can rename this soon from redesign-remove to remove.
import checkMark from './checkmark.png'
import errorMark from './error-mark.png'
import backgroundVerified from './background-verified.png'
import { ReactComponent as Payment } from './payment.svg'
import { ReactComponent as Transport } from './transport.svg'
import notFound from './404.png'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Error } from './error.svg'
import { ReactComponent as HelpCircle } from './help-circle.svg'
import { ReactComponent as MessageArrow } from './message-arrow.svg'
import { ReactComponent as Bus } from './bus.svg'
import { ReactComponent as Train } from './train.svg'
import { ReactComponent as ShortTerm } from './short-term.svg'
import error from './error.png'

export {
  Transport,
  Share,
  Bullet,
  DoubleBullet,
  YellowHouse,
  Trash,
  roomiBlackLogo,
  RoomiSmallLogo,
  roomiGuestUser,
  ChatBubbleOutlined,
  NotificationBellOutlined,
  questionCircle,
  DoubleQuotes,
  camera,
  uploadCloud,
  Calendar,
  Filter,
  LocationYellow,
  Money,
  Forbidden,
  emptyListing,
  House,
  HouseYellow,
  Search,
  BackIcon,
  ForwardIcon,
  LeftArrowShadow,
  RightArrowShadow,
  Clock,
  Heart,
  Roommates,
  Location,
  Truck,
  Facebook,
  FacebookLogo,
  LinkedInLogo,
  Lock,
  Mail,
  bus,
  activeBus,
  train,
  activeTrain,
  Flag,
  CurrencyUSD,
  CurrencyGBP,
  CurrencyINR,
  BlueTruck,
  Edit,
  BlueCloseCircle,
  Remove,
  checkMark,
  errorMark,
  backgroundVerified,
  Payment,
  notFound,
  Close,
  Error,
  HelpCircle,
  Bus,
  Train,
  MessageArrow,
  ShortTerm,
  error
}
