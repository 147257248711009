import React, { PureComponent } from 'react';
import NavLink from '../../../../shared/components/navigation-link/navigation-link.component';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { withStyles, Typography } from '@material-ui/core';
import listingCardInfoStyles from './listing-card-info.styles';

@withStyles(listingCardInfoStyles)
class ListingCardInfo extends PureComponent {
  static propTypes = {
    listing: PropTypes.object.isRequired,
    href: PropTypes.string.isRequired,
    openInTab: PropTypes.bool,
    disableNavigation: PropTypes.bool,
    onRedirect: PropTypes.func
  };

  render() {
    const {
      listing: { rent, currency, moveInDate, location, leaseDuration, layout, totalBedrooms, isShortTerm },
      disableNavigation,
      href,
      openInTab,
      classes,
      onRedirect
    } = this.props;
    return (
      <NavLink
        disabled={disableNavigation}
        className={classes.listingInfo}
        to={href}
        openInTab={openInTab}
        onRedirect={onRedirect}
      >
        <div className={classes.layout}>{layout}</div>
        <Typography color="inherit" className={classes.availability} noWrap>
          <span>{totalBedrooms} BR</span>
          <span className={classes.square}>&bull;</span>
          <span>{moveInDate}</span>
          <span className={classes.square}>&bull;</span>
          <span>{leaseDuration}</span>
        </Typography>
        <Typography color="inherit" className={classes.priceAndLocation} noWrap>
          <FormattedNumber
            className="price"
            value={rent}
            style="currency"
            minimumFractionDigits={0}
            currency={currency}
          />
          &nbsp;/&nbsp;
          { isShortTerm ? 'night' : 'month'}
          &nbsp; in &nbsp;
          {location}
        </Typography>
      </NavLink>
    );
  }
}

export default ListingCardInfo;
