import React, { PureComponent } from 'react';
import { InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import adornmentStyles from './adornment.styles';

@withStyles(adornmentStyles)
class RoomiAdornment extends PureComponent {
  render() {
    return (
      <InputAdornment
        {...this.props}
      />
    );
  }
}

export default RoomiAdornment;
