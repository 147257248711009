export const notificationsFetch = 'FETCH-NOTIFICATIONS';
export const notificationsFetchSuccess = 'FETCH-NOTIFICATIONS-SUCCESS';
export const notificationMarkAllRead = 'MARK-ALL-READ-NOTIFICATIONS';
export const notificationMarkAllReadSuccess = 'MARK-ALL-READ-NOTIFICATIONS-SUCCESS';
export const notificationMarkRead = 'MARK-READ-NOTIFICATION';
export const notificationMarkReadSuccess = 'MARK-READ-NOTIFICATION-SUCCESS';

export const REGISTER_DEVICE = 'REGISTER_DEVICE';
export const REGISTER_DEVICE_SUCCESS = 'REGISTER_DEVICE_SUCCESS';
export const REGISTER_DEVICE_ERROR = 'REGISTER_DEVICE_ERROR';
