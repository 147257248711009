import { select, take, call, put, takeLatest } from 'redux-saga/effects'
import { getSponsorships } from '../data/sponsorships.data'
import { fetch } from '../actions/sponsorships.types'
import { hasFetchedSponsorships } from '../reducers/sponsorships.selector'
import {
  resetSponsorships,
  fetchedSponsorships
} from '../actions/sponsorships.actions'
import { authenticate, unAuthenticate } from '../../auth/actions/auth.types'

function* fetchSponsorships(userId) {
  let fetched = yield select(hasFetchedSponsorships)
  if (fetched) return
  const sponsorships = yield call(getSponsorships, userId)
  yield put(fetchedSponsorships(sponsorships))
}

export default function*() {
  while (1) {
    const { userId } = yield take(authenticate)
    const task = yield takeLatest(fetch, fetchSponsorships, userId)
    yield take(unAuthenticate)
    yield task.cancel()
    yield put(resetSponsorships())
  }
}
