import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import {
  toggleHowItWorksListerDialogAction,
  toggleHowItWorksMoverDialogAction
} from '../how-it-works/actions/how-it-works.actions';
import ListerModal from '../how-it-works/components/lister-modal/lister-modal';
import MoverModal from '../how-it-works/components/mover-modal/mover-modal';

const mapStateToProps = state => ({
  howItWorksListerDialog: state.howItWorks.listerDialog,
  howItWorksMoverDialog: state.howItWorks.moverDialog
});

const mapDispatchToProps = {
  closeHowItWorksListerDialog: toggleHowItWorksListerDialogAction,
  closeHowItWorksMoverDialog: toggleHowItWorksMoverDialogAction
};

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class GlobalDialogs extends PureComponent {
  static propTypes = {
    howItWorksListerDialog: PropTypes.bool,
    howItWorksMoverDialog: PropTypes.bool,
    closeHowItWorksListerDialog: PropTypes.func,
    closeHowItWorksMoverDialog: PropTypes.func
  };

  render() {
    const {
      howItWorksListerDialog,
      howItWorksMoverDialog,
      closeHowItWorksListerDialog,
      closeHowItWorksMoverDialog
    } = this.props;
    return (
      <div>
        {howItWorksListerDialog && (
          <ListerModal onClose={closeHowItWorksListerDialog} />
        )}
        {howItWorksMoverDialog && (
          <MoverModal onClose={closeHowItWorksMoverDialog} />
        )}
      </div>
    );
  }
}

export default GlobalDialogs;
