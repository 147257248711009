import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Typography } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import EnsureLogin from '../../../auth/components/ensure-login-button/ensure-login-button.component'
import SidebarToggle from './sidebar-toggle.component'
import track from 'react-tracking'
import { getTotalUnread } from '../../../messaging/reducers/conversations.selector'
import { getTotalUnreadAnnouncements } from '../../../messaging/reducers/announcements.selector'
import { getPendingReceivedChatRequests } from '../../../chat-requests/chat-requests.selector'
import { unAuthenticateUser } from '../../../auth/actions/auth.actions'
import { savePreOnboardingRoute } from '../../../auth/actions/onboarding.actions'
import { connect } from 'react-redux'
import { roomiBlackLogo, roomiGuestUser } from 'roomi/icons'
import { numberLimiter } from 'roomi/utils'
import { RoomiButton } from 'roomi/material'
import { withRouter } from 'react-router-dom'
import sidebarStyles from './sidebar.styles'
import { withStyles } from '@material-ui/core'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import messages from '../../header.text'
import { LazyImage } from '../../../shared/components/lazy-image/lazy-image.component'
import RoomiNavLink from '../../../shared/components/roomi-nav-link/roomi-nav-link.component'

const mapStateToProps = state => ({
  unreadAnnouncements: getTotalUnreadAnnouncements(state),
  pendingChatRequests: getPendingReceivedChatRequests(state),
  unreadMessages: getTotalUnread(state)
})

@track({})
@injectIntl
@withRouter
@connect(mapStateToProps, { unAuthenticateUser })
@withStyles(sidebarStyles)
class SideBar extends PureComponent {
  static propTypes = {
    user: PropTypes.any,
    pendingChatRequests: PropTypes.array,
    unAuthenticateUser: PropTypes.func,
    unreadAnnouncements: PropTypes.number,
    unreadMessages: PropTypes.number
  }

  state = {
    open: false
  }

  handleToggle = () => this.setState({ open: !this.state.open })
  handleClose = () => this.setState({ open: false })

  @track({ action: 'Log_Out' })
  logOut = () => this.props.unAuthenticateUser()

  @track({
    action: 'Click_CreateListing',
    fb: true,
    ga: { category: 'Listings' }
  })
  goCreate = () => {}

  @track({ action: 'Click_GetStarted' })
  fireEventAndSaveRoute = () => {
    savePreOnboardingRoute()
  }

  logIn = e => e.stopPropagation() // Fixes sidebar close trigger logIn modal destroy

  render() {
    const {
      user,
      classes,
      pendingChatRequests,
      unreadAnnouncements,
      unreadMessages,
      unreadChatRequests = pendingChatRequests.length,
      total = numberLimiter({
        value: unreadAnnouncements + unreadMessages + unreadChatRequests
      }),
      intl: { formatMessage }
    } = this.props
    return (
      <Fragment>
        <SidebarToggle total={total} handleToggle={this.handleToggle} />
        <Drawer
          open={this.state.open}
          variant="temporary"
          classes={{ paper: classes.paper }}
        >
          <div className={classes.logoRow}>
            <Icon
              color="primary"
              className={classes.close}
              onClick={this.handleClose}
            >
              close
            </Icon>
            <RoomiNavLink to="/" onClick={this.handleClose}>
              <img
                src={roomiBlackLogo}
                className={classes.logo}
                alt="Roomi Logo"
              />
            </RoomiNavLink>
          </div>

          <div onClick={this.handleClose} className={classes.list}>
            <RoomiNavLink
              className={classNames(classes.link, classes.border)}
              to="/how-it-works"
              activeClassName="active"
              exact
            >
              <FormattedMessage {...messages.howItWorks} />
            </RoomiNavLink>
            {user ? (
              <Fragment>
                <RoomiNavLink
                  className={classNames(classes.link, classes.border)}
                  to="/rentpayment"
                  activeClassName="active"
                >
                  <FormattedMessage {...messages.rentpayment} />
                </RoomiNavLink>
                <RoomiNavLink
                  className={classes.link}
                  to="/settings"
                  activeClassName="active"
                  exact
                >
                  <FormattedMessage {...messages.settings} />
                </RoomiNavLink>
                <a
                  onClick={this.logOut}
                  className={classNames(classes.logOut, classes.link)}
                >
                  <FormattedMessage {...messages.logOut} />
                </a>
              </Fragment>
            ) : (
              <Fragment>
                <div onClick={this.logIn}>
                  <EnsureLogin afterLogin={this.handleClose}>
                    <a className={classNames(classes.logIn, classes.link)}>
                      <FormattedMessage {...messages.logIn} />
                    </a>
                  </EnsureLogin>
                </div>
                <RoomiNavLink
                  className={classes.link}
                  to="/onboarding"
                  onClick={this.fireEventAndSaveRoute}
                >
                  <FormattedMessage {...messages.signUp} />
                </RoomiNavLink>
              </Fragment>
            )}
            <RoomiButton
              onClick={this.goCreate}
              secondary
              small
              to="/post-listing"
              className={classes.button}
              label={formatMessage(messages.listRoom)}
            />
          </div>
        </Drawer>
      </Fragment>
    )
  }
}

export default SideBar
