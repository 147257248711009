import { get, size, filter, map, identity, partition, find, includes } from 'lodash';
import { createSelector } from 'reselect';
import { getListingData } from '../../listings/reducers/listings.selector';
import { getCurrentUserFromState } from './user.selector';

const getUserListingIds = (state, id) =>
  hasFetchedUserListings(state, id) &&
  get(state, `userListings.${id}.listingIds`);

export const hasFetchedUserListings = (state, id) =>
  get(state, `userListings.${id}.fetched`);

export const hasFetchedCurrentUserListingsSelector = (state) => hasFetchedUserListings(state, 'me');

export const getUserListingsFromState = createSelector(
  [getUserListingIds, getListingData],
  (ids, data) => {
    if (!ids) return null;
    const listings = filter(map(ids, id => data[id]), identity);
    return size(listings) === size(ids) ? listings : null;
  }
);

export const allowedToPostListing = createSelector(
  [getUserListingsFromState, getCurrentUserFromState, (s, t, l) => l],
  (listings, user, listing = {}) => {
    if (!user || !listings) return true;
    return !find(listings, item => item._id !== listing._id && includes(['insufficient', 'pending'], item.status));
  }
);

export const getUserListingsSplitByActive = createSelector(
  [getUserListingsFromState],
  (listings) => partition(listings, { status: 'active' })
);
