import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filter, find } from 'lodash';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';

const mapStateToProps = ({ badges }) => ({ badges: badges.data });

@connect(mapStateToProps)
class BankBadge extends PureComponent {
  static propTypes = {
    badges: PropTypes.object,
    ids: PropTypes.array.isRequired,
    all: PropTypes.bool,
    tooltip: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf(['user', 'listing']).isRequired
  };

  static getBankBadge({ badges, ids, all, type }) {
    if (!ids.length) return [];
    const filtered = filter(badges, i => i.status === 'active' && i.display[type] && ids.includes(i._id));
    if (!filtered.length) return [];
    const bankBadge = find(filtered, { _id: 14 });
    if(!bankBadge) return [];
    return [bankBadge];
  }

  state = {
    ids: [],
    bankBadges: []
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.ids !== nextProps.ids) {
      return {
        ids: nextProps.ids,
        bankBadges: BankBadge.getBankBadge(nextProps)
      };
    }
    return null;
  }

  render() {
    const { badges, className, tooltip } = this.props;
    const { bankBadges } = this.state;
    if (!badges) return null;
    const newLocal = bankBadges.map((badge, i) => (
      <Tooltip title={tooltip ? badge.description : ''} placement="top" key={i}>
        <img src={badge.images.april2018.png} className={className} alt="BankBadge" width="30" height="30" align="middle"/>
      </Tooltip>
    ));
    return newLocal;

  }
}

export default BankBadge;
