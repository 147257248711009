import { size, find, map, get, filter, orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { getMover2MoverEnabledSelector } from 'roomi/reducers/experiments.selector';

const EMPTY = [];
const containsListing = listingId => ({ listing }) => listing && listing._id === listingId;
const getAllChatRequests = state => state.chatRequests.data;
const getSentChatRequestIds = state => state.chatRequests.sent;
const getReceivedChatRequestIds = state => state.chatRequests.received;
export const chatRequestsResolved = state => state.chatRequests.fetched;

const getSentChatRequests = createSelector(
  [getAllChatRequests, getSentChatRequestIds],
  (data, ids) => map(ids, id => data[id])
);

const getSentChatRequestsForListing = createSelector(
  [getSentChatRequests, (_, listingId) => listingId],
  (chatRequests, id) => chatRequests.filter(containsListing(id))
);

export const getReceivedChatRequests = createSelector(
  [getAllChatRequests, getReceivedChatRequestIds],
  (data, ids) => orderBy(map(ids, id => data[id]), ['createdAt'], ['desc'])
);

export const getChatRequestFetching = state => state.chatRequests.fetching;
export const getChatRequestModal = state => state.chatRequests.modal;
export const chatRequestsFetched = state => state.chatRequests.fetched;

export const getReceivedChatRequestsCount = createSelector(
  [getReceivedChatRequestIds],
  (ids) => size(ids)
);

export const isResendable = createSelector(
  [getSentChatRequestsForListing], requests => !!requests.find(r => r.resendable)
);

export const hasAppliedTo = createSelector(
  [getSentChatRequestsForListing], sentChatRequests => !!size(sentChatRequests)
);

export const findExistingChatRequestFrom = createSelector(
  [getReceivedChatRequests, (state, userId) => userId, getMover2MoverEnabledSelector],
  (receivedChatRequests, userId, isSenderEnabled) => find(receivedChatRequests, chatRequest => get(chatRequest, isSenderEnabled ? 'sender._id' : 'mover._id') === userId)
);

export const getPendingReceivedChatRequests = createSelector(
  [getReceivedChatRequests],
  receivedChatRequests => {
    const pending = filter(receivedChatRequests, chatRequest => chatRequest.status === 'pending');
    return size(pending) ? pending : EMPTY;
  }
);

export const getChatRequestPage = createSelector(
  [getPendingReceivedChatRequests, (state, chatRequestId) => chatRequestId, (state, _, itemsPerPage) => itemsPerPage],
  (receivedChatRequests, chatRequestId, itemsPerPage) => {
    if (!chatRequestId) return 0;
    const index = chatRequestId ? receivedChatRequests.findIndex(rq => rq._id === chatRequestId) : -1;
    return index >= 0 ? Math.floor(index / itemsPerPage) : 0;
  }
);
