import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getVerificationMaxCount } from '../../shared/reducers/experiments.selector';

export const getCurrentUserFromState = state => state.user.data;
export const getIdVerificationDataSelector = state => get(state, 'user.data.jumio');
export const getBgVerificationDataSelector = state => get(state, 'user.data.backgroundCheck');

export const idVerificationInProcess = createSelector(
  [getIdVerificationDataSelector], ({ status } = {}) => status && status === 'PENDING'
);
export const bgVerificationInProcess = createSelector(
  [getBgVerificationDataSelector], ({ status } = {}) => status && status === 'pending'
);
export const expiredBgVerification = createSelector(
  [getBgVerificationDataSelector], ({ status } = {}) => status && status === 'expired'
);

export const idVerifiedSelector = createSelector(
  [getIdVerificationDataSelector], ({ verified, firstName, lastName, dob } = {}) => {
    return verified && firstName && lastName && dob;
  }
);

export const bgVerifiedSelector = createSelector(
  [getBgVerificationDataSelector], ({ status } = {}) => status === 'finished'
);

export const userExceededVerificationLimit = createSelector(
  [getIdVerificationDataSelector, getVerificationMaxCount], ({ requestCount } = {}, limit) => requestCount >= limit
);
export const getUsrLikes = state => state.user.data.usrLikes;
