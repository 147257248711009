const socialMediaStyles = theme => ({
  iconContainer: {
    display: 'flex'
  },
  text: {
    color: theme.palette.grey[400]
  }
});

export default socialMediaStyles;
