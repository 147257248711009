const validators = {
  required: (value, validator) => !!value ? void 0 : validator.msg,
  regex: (value, validator) => new RegExp(validator.params.regex).test(value) ? void 0 : validator.msg,
  equalValue: (value, validator, values) => value === values[validator.params.controlField] ? void 0 : validator.msg
};

const validateField = (field, values) => {
  const errors = field.validators
    .map(validator => validators[validator.type](values[field.name], validator, values))
    .filter(error => !!error);
  return errors[0];
};

const dynamicFormValidator = (values, { fields }) => {
  return fields.reduce((errors, field) => ({ ...errors, [field.name]: validateField(field, values) }), {});
};

export default dynamicFormValidator;
