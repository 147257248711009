const errorBannerStyles = theme => ({
  error: {
    padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 3}px`,
    background: theme.palette.error[100],
    transition: 'background .3s ease-in-out',
    '&:hover': {
      background: theme.utils.shadeColor(theme.palette.error[100], -3)
    }
  },
  errorIcon: {
    width: 24,
    height: 24
  },
  closeIcon: {
    width: 16,
    height: 16,
    display: 'flex',
    cursor: 'pointer'
  },
  errorMessage: {
    marginLeft: theme.spacing.unit * 2,
    fontSize: 12
  }
});

export default errorBannerStyles;
