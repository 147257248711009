const buttonStyles = theme => ({
  root: {
    lineHeight: '24px',
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
    fontFamily: theme.typography.button.fontFamily,
    padding: '0 15px'
  },
  fullWidth: {
    width: '100%'
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    '&:active': {
      backgroundColor: theme.palette.primary[800]
    },
    '& $startAdornment svg g': {
      stroke: theme.palette.common.white
    }
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    '&:active': {
      backgroundColor: theme.palette.secondary[800]
    }
  },
  outline: {
    backgroundColor: theme.palette.common.white,
    '&$primary': {
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.dark}`,
        color: theme.palette.primary.dark
      },
      '&:active': {
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary[800]}`,
        color: theme.palette.primary[800]
      }
    },
    '&$secondary': {
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.secondary.dark}`,
        color: theme.palette.secondary.dark
      },
      '&:active': {
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.secondary[800]}`,
        color: theme.palette.secondary[800]
      }
    }
  },
  disabled: {
    opacity: 0.5,
    color: theme.palette.common.white,
    pointerEvents: 'none'
  },
  small: {
    lineHeight: '18px',
    fontSize: theme.typography.button.fontSizeSmall,
    height: 45,
    minWidth: 135
  },
  medium: {
    height: 69,
    minWidth: 209
  },
  large: {
    height: 80,
    minWidth: 316
  },
  textWrapper: {
    textAlign: 'center',
    width: '100%' // needed to align text in ie11
  },
  startAdornment: {
    display: 'flex',
    minWidth: 40 // fixes text centering due different asset styles
  },
  spinner: {
    marginLeft: theme.spacing.unit,
    verticalAlign: 'middle'
  }
});

export default buttonStyles;
