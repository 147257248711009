import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { toggle } from '../../actions/favorites.actions'
import { isFavorited } from '../../reducers/favorites.selector'
import { IconButton } from '@material-ui/core'
import { Heart } from 'roomi/icons'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import EnsureLogin from '../../../../app/auth/components/ensure-login-button/ensure-login-button.component'
import track from 'react-tracking'
import favoriteButtonStyles from './favorite-button.styles'
import { withStyles } from '@material-ui/core'

@track({})
@withStyles(favoriteButtonStyles)
class FavoriteButton extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  @track(({ isFavorite, id }) => {
    if (!isFavorite)
      return { action: 'Favorite_Listing', targetId: id, details: { id } }
    return null
  })
  toggleFavorite = () => this.props.toggle(this.props.id)

  @track(props => ({ preOnboardingAction: toggle(props.id) }))
  beforeSignUp = () => {}

  getButton = isFavorite => {
    const { classes } = this.props
    return (
      <IconButton
        classes={{ root: classes.iconButton }}
        className={classNames({ [classes.favorited]: isFavorite })}
        aria-label="Favorite Listing"
      >
        <Heart
          className={classes.icon}
          color="inherit"
          height={24}
          width={24}
        />
      </IconButton>
    )
  }

  render() {
    const { isFavorite, getButton = this.getButton } = this.props
    return (
      <EnsureLogin
        preSignUp={this.beforeSignUp}
        afterLogin={this.toggleFavorite}
      >
        {getButton(isFavorite)}
      </EnsureLogin>
    )
  }
}

const mapStateToProps = (state, { id }) => ({
  isFavorite: isFavorited(state, id)
})

export default connect(
  mapStateToProps,
  { toggle }
)(FavoriteButton)
