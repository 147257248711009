import React, { PureComponent } from 'react';
import { RightArrowShadow } from 'roomi/icons';
import classNames from 'classnames';

class SlickRightArrow extends PureComponent {
  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick();
  };

  render() {
    const { customClass, className, style, height, width } = this.props;
    return (
      <RightArrowShadow
        onClick={this.handleClick}
        className={classNames(className, customClass)}
        style={{ height, width, ...style }}
      />
    );
  }
}

export default SlickRightArrow;
