const popoverControllerStyles = theme => ({
  paper: {
    overflow: 'visible',
    '&:before, &:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '100%',
      width: 0,
      height: 0
    },
    '&:before': {
      right: 20,
      border: '11px solid transparent',
      borderBottomColor: '#ebebeb'
    },
    '&:after': {
      right: 21,
      border: '10px solid transparent',
      borderBottomColor: theme.palette.common.white
    }
  },
  popover: {
    pointerEvents: 'none'
  },
  body: {
    pointerEvents: 'all'
  },
  open: {
    '& path': {
      fill: theme.palette.primary.main
    }
  }
})

export default popoverControllerStyles
