import React, { PureComponent } from 'react'
import { Tooltip, withStyles } from "@material-ui/core";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";
import shortTermInfoCardStyles from './short-term-info-card.styles'
import shortTermSvg from 'roomi/icons/short-term.svg'

@withStyles(shortTermInfoCardStyles)
class ShortTermInfoCard extends PureComponent {

  render() {
    const { classes } = this.props;
    
    return (
      <Tooltip title={'Featured Room for Rent By night'} placement="top">
        <img
          src={shortTermSvg}
          className={classes.tooltip}
          alt="Featured Room for Rent By night"
        />
      </Tooltip>
      // <Card className={classes.card}>
      //   <CardContent className={classes.cardContent}>
      //     <Typography
      //       className={classes.text}
      //       align="center"
      //       color="error"
      //     >
      //       Featured Room for Rent By night
      //     </Typography>
      //   </CardContent>
      // </Card>
    );
  }
}
export default ShortTermInfoCard
