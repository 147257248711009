import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'
import track from 'react-tracking'
import PopOverController from '../popover-controller/popover-controller.component'
import { connect } from 'react-redux'
import { unAuthenticateUser } from '../../../auth/actions/auth.actions'
import { withRouter } from 'react-router-dom'
import userOptionsStyles from './user-options-dropdown.styles'
import { withStyles } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import messages from '../../header.text'
import { roomiGuestUser } from 'roomi/icons'
import { LazyImage } from '../../../shared/components/lazy-image/lazy-image.component'
import RoomiNavLink from '../../../shared/components/roomi-nav-link/roomi-nav-link.component'

@track({})
@withRouter
@connect(null, { unAuthenticateUser })
@withStyles(userOptionsStyles)
class UserOptionsDropdown extends PureComponent {
  static propTypes = {
    unAuthenticateUser: PropTypes.func
  }

  @track({ action: 'Log_Out' })
  logOut = () => this.props.unAuthenticateUser()

  render() {
    const {
      user: { defaultImage, _id },
      classes
    } = this.props
    const popOverContent = (
      <div className={classes.content}>
        <RoomiNavLink to={'/rentpayment'} activeClassName="active">
          <MenuItem className={classes.item}>
            <FormattedMessage {...messages.rentpayment} />
          </MenuItem>
        </RoomiNavLink>
        <RoomiNavLink to="/settings" activeClassName="active" exact>
          <MenuItem className={classes.item}>
            <FormattedMessage {...messages.settings} />
          </MenuItem>
        </RoomiNavLink>
        <MenuItem onClick={this.logOut} className={classes.item}>
          <FormattedMessage {...messages.logout} />
        </MenuItem>
      </div>
    )
    return (
      <PopOverController
        className={classes.container}
        popoverContent={popOverContent}
      >
        {defaultImage ? (
          <LazyImage
            crop="faces"
            fit="crop"
            mask={'ellipse'}
            square={true}
            src={defaultImage}
            height={36}
            width={36}
          />
        ) : (
          <img src={roomiGuestUser} height={36} width={36} />
        )}
      </PopOverController>
    )
  }
}

export default UserOptionsDropdown
