import { call, put, takeLatest, all } from 'redux-saga/effects'
import { getMembers } from './actions.types'
import { fetchMembers } from '../data/members.data'
import { membersFetched, errorFetching } from './actions'

function* fetchMembersFromServer() {
  try {
    const response = yield call(fetchMembers)
    yield put(membersFetched(response.data))
  } catch (e) {
    yield put(errorFetching(e))
  }
}

function* watchFetchMembersFromServer() {
  yield takeLatest(getMembers, fetchMembersFromServer)
}

function* membersSaga() {
  yield all([watchFetchMembersFromServer()])
}

export default membersSaga
