import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import messages from '../../footer.texts'
import { SOCIALS } from '../../footer.data'
import socialMediaStyles from './social-media.styles'
import { MenuItem, withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { RoomiSelect } from 'roomi/material'
import { IntlContext } from '../../../intl.context'

const values = { year: new Date().getFullYear() }

const SocialMedia = React.memo(function SocialMedia({ classes }) {
  const { language, setLanguage } = useContext(IntlContext)
  return (
    <>
      <div className={classes.iconContainer}>
        {SOCIALS.map(({ icon, name, href }) => (
          <IconButton aria-label={name} href={href} target="_blank" key={name}>
            <img src={icon} height={30} width={30} alt="" />
          </IconButton>
        ))}
      </div>
      <div style={{ maxWidth: 200, width: '100%' }}>
        <RoomiSelect
          value={language}
          onChange={evt => {
            setLanguage(evt.target.value)
          }}
        >
          <MenuItem value={'en'} label={'English'}>
            <FormattedMessage {...messages.english} />
          </MenuItem>
          <MenuItem value={'es'} label={'Spanish'}>
            <FormattedMessage {...messages.spanish} />
          </MenuItem>
        </RoomiSelect>
      </div>
      <FormattedMessage {...messages.copy} values={values}>
        {t => <div className={classes.text}>{t}</div>}
      </FormattedMessage>
    </>
  )
})

export default withStyles(socialMediaStyles)(SocialMedia)
