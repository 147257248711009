import { 
  createRoomiPayAccount,
  updateRoomiPayAddress,
  updateRoomiPayTenants,
  updateRoomiPayRenter,
  updateRoomiPayPayment,
  fetchRoomiPay,
  setRoomiPay,
  resetRoomiPay,
  setInvitation,
  fetchInvitation,
  acceptInvitation,
  cancelRoomiPay
} from './actions.types'

export const createRoomiPayAccountAction = payload => {
  return {
    type: createRoomiPayAccount,
    payload
  }
}

export const updateRoomiPayAddressAction = payload => {
  return {
    type: updateRoomiPayAddress,
    payload
  }
}

export const updateRoomiPayRenterAction = payload => {
  return {
    type: updateRoomiPayRenter,
    payload
  }
}

export const updateRoomiPayTenantsAction = payload => {
  return {
    type: updateRoomiPayTenants,
    payload
  }
}

export const updateRoomiPayPaymentAction = payload => {
  return {
    type: updateRoomiPayPayment,
    payload
  }
}

export const fetchRoomiPayAction = payload => {
  return {
    type: fetchRoomiPay,
    payload
  }
}

export const setRoomiPayAction = payload => {
  return {
    type: setRoomiPay,
    payload
  }
}

export const resetRoomiPayAction = payload => {
  return {
    type: resetRoomiPay,
    payload
  }
}


export const fetchInvitationAction = payload => {
  return {
    type: fetchInvitation,
    payload
  }
}

export const setInvitationAction = payload => {
  return {
    type: setInvitation,
    payload
  }
}

export const acceptInvitationAction = payload => {
  return {
    type: acceptInvitation,
    payload
  }
}

export const cancelRoomiPayAction = payload => {
  return {
    type: cancelRoomiPay,
    payload
  }
}