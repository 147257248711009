import { call, put, select, takeLatest } from 'redux-saga/effects'
import { fetching } from '../actions/neighborhoods.types'
import {
  fetchedNeighborhoods,
  errorFetchingNeighborhoods
} from '../actions/neighborhoods.actions'
import { get } from '../data/neighborhoods.data'
import { getNeighborhoodsForRegion } from '../reducers/neighborhoods.selector'

function* fetch({ id }) {
  try {
    let existingNeighborhoods = yield select(state =>
      getNeighborhoodsForRegion(state, id)
    )
    if (existingNeighborhoods) return
    const neighborhoods = yield call(get, id)
    yield put(fetchedNeighborhoods(id, neighborhoods))
  } catch (e) {
    yield put(errorFetchingNeighborhoods(id, e))
  }
}

function* neighborhoodsSaga() {
  yield takeLatest(fetching, fetch)
}

export default neighborhoodsSaga
