import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, withStyles } from '@material-ui/core'
import { FormattedMessage, injectIntl } from 'react-intl'
import boostProfilePaymentStyles from './boost-profile-payment.component.styles'
import Payment from '../../../payment/components/payment/payment.component'
import { buyProfileBoost } from '../../data/sponsorships.data'
import track from 'react-tracking'
import messages from './boost-profile-payment.text'
import PromoCode from '../../../payment/components/promo-code/promo-code.component'
import RoomiButton from 'roomi/material/button/button.component'

@track({})
@injectIntl
@withStyles(boostProfilePaymentStyles)
class BoostProfilePayment extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    selectedPlan: PropTypes.object.isRequired
  }

  state = {}

  handlePromoCode = code => {
    this.setState({ code })
  }

  @track({ action: 'Sponsorship_Profiles_ClickedBoost' })
  buyProfileBoost = sourceId => {
    const { selectedPlan, tracking, onSuccess } = this.props
    const { code } = this.state
    this.setState({ disabled: true, error: null })

    buyProfileBoost({
      sourceId,
      planId: selectedPlan.id,
      promoCode: code
    }).then(
      data => {
        tracking.trackEvent({
          action: 'Sponsorship_Profiles_Created',
          details: {
            planId: data.planId,
            listingId: data.entityId,
            price: selectedPlan.price
          },
          fb: true,
          ga: { category: 'Boost Profile', value: selectedPlan.price }
        })
        this.setState({ disabled: false })
        onSuccess(data)
      },
      error => {
        tracking.trackEvent({
          action: 'Sponsorship_Profiles_Failed',
          fb: true,
          ga: { category: 'Boost Profile' }
        })
        this.setState({ error, disabled: false })
      }
    )
  }

  render() {
    const {
      user,
      onBack,
      classes,
      selectedPlan,
      intl: { formatMessage }
    } = this.props
    const { disabled, error, code } = this.state
    return (
      <div className={classes.container}>
        <Grid item className={classes.title}>
          <FormattedMessage {...messages.title} />
        </Grid>
        <PromoCode
          scope={'profileBoost'}
          plan={selectedPlan}
          user={user}
          onApply={this.handlePromoCode}
        />
        <Divider className={classes.divider} />
        {!code && (
          <Payment
            userId={user._id}
            onBack={onBack}
            subTitle={formatMessage(messages.subTitle)}
            newCardSubTitle={formatMessage(messages.newCardSubtitle)}
            onSubmit={this.buyProfileBoost}
            isDisabled={disabled}
            error={error}
          />
        )}
        {!!code && (
          <Grid container justify="center" className={classes.buttonsContainer}>
            <RoomiButton
              primary
              small
              label="Submit"
              onClick={this.buyProfileBoost}
              disabled={disabled}
              loading={disabled}
            />
          </Grid>
        )}
      </div>
    )
  }
}

export default BoostProfilePayment
