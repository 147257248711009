import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  FOLLOW_USER,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_ERROR,
  UNFOLLOW_USER,
  UNFOLLOW_USER_SUCCESS,
  UNFOLLOW_USER_ERROR,
  CHECK_FOLLOW_STATUS,
  CHECK_FOLLOW_STATUS_SUCCESS,
  CHECK_FOLLOW_STATUS_ERROR,
  FETCH_NEIGHBORHOOD_TOKEN,
  FETCH_NEIGHBORHOOD_TOKEN_SUCCESS,
  FETCH_NEIGHBORHOOD_TOKEN_ERROR,
  REGISTER_ACTIVITY_REACTION,
  REGISTER_ACTIVITY_REACTION_SUCCESS,
  REGISTER_ACTIVITY_REACTION_ERROR,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
} from './timeline.types';


export const registerUser = (userId) => ({
  type: REGISTER_USER,
  userId,
});

export const registerUserSuccess = (userId, userToken) => ({
  type: REGISTER_USER_SUCCESS,
  userId,
  userToken,
});

export const registerUserError = (error) => ({
  type: REGISTER_USER_ERROR,
  error,
});

export const followUser = (userId) => ({
  type: FOLLOW_USER,
  userId,
});

export const followUserSuccess = (userId) => ({
  type: FOLLOW_USER_SUCCESS,
  userId,
});

export const followUserError = (error) => ({
  type: FOLLOW_USER_ERROR,
  error,
});

export const unfollowUser = (userId) => ({
  type: UNFOLLOW_USER,
  userId,
});

export const unfollowUserSuccess = (userId) => ({
  type: UNFOLLOW_USER_SUCCESS,
  userId,
});

export const unfollowUserError = (error) => ({
  type: UNFOLLOW_USER_ERROR,
  error,
});

export const checkFollowStatus = (userId) => ({
  type: CHECK_FOLLOW_STATUS,
  userId,
});

export const checkFollowStatusSuccess = (userId, followStatus) => ({
  type: CHECK_FOLLOW_STATUS_SUCCESS,
  userId,
  followStatus,
});

export const checkFollowStatusError = (error) => ({
  type: CHECK_FOLLOW_STATUS_ERROR,
  error,
});

export const fetchNeighborhoodToken = (neighborhood) => ({
  type: FETCH_NEIGHBORHOOD_TOKEN,
  neighborhood,
});

export const fetchNeighborhoodTokenSuccess = (neighborhood, neighborhoodToken, neighborhoodId) => ({
  type: FETCH_NEIGHBORHOOD_TOKEN_SUCCESS,
  neighborhood,
  neighborhoodToken,
  neighborhoodId,
});

export const fetchNeighborhoodTokenError = (error) => ({
  type: FETCH_NEIGHBORHOOD_TOKEN_ERROR,
  error,
});

export const registerActivityReaction = (activityId, reactionType, reactionOptions) => ({
  type: REGISTER_ACTIVITY_REACTION,
  activityId,
  reactionType,
  reactionOptions,
});

export const registerActivityReactionSuccess = () => ({
  type: REGISTER_ACTIVITY_REACTION_SUCCESS,
});

export const registerActivityReactionError = (error) => ({
  type: REGISTER_ACTIVITY_REACTION_ERROR,
  error,
});

export const deleteActivity = (activityId) => ({
  type: DELETE_ACTIVITY,
  activityId,
});

export const deleteActivitySuccess = () => ({
  type: DELETE_ACTIVITY_SUCCESS,
});

export const deleteActivityError = (error) => ({
  type: DELETE_ACTIVITY_ERROR,
  error,
});
