import {
  displayIdVerificationPrice,
  resetUserVerification,
  setBgVerificationPlan,
  setIdVerificationPlan,
  confirmIdVerificationPrice,
  displayIdVerificationPayment,
  successIdPayment,
  waitingForEvidentResponse,
  displayBgVerificationPrice,
  confirmBgVerificationPrice,
  displayVerificationTerms,
  verificationTermsAccepted,
  successBgPayment,
  notifyForBgCheckExpired,
  confirmBgRenew,
  displayBgVerificationPayment,
  displayIdFreeVerificationPrice,
  startUserIdVerification,
  startUserBgVerification,
  redirectToIdVerification,
  redirectConfirmed,
  fetchEvidentRedirectUrl,
  evidentNotificationReceived,
  evidentVerificationFinished,
  setIdVerificationUiUrl,
  retryLimitExceed
} from './user-verification.types'

export const startUserIdVerificationAction = config => ({
  type: startUserIdVerification,
  config
})

export const startUserBgVerificationAction = config => ({
  type: startUserBgVerification,
  config
})

export const resetUserVerificationAction = () => ({
  type: resetUserVerification
})

export const displayIdVerificationPriceAction = () => ({
  type: displayIdVerificationPrice
})

export const displayIdFreeVerificationPriceAction = () => ({
  type: displayIdFreeVerificationPrice
})

export const setIdVerificationPlanAction = plan => ({
  type: setIdVerificationPlan,
  payload: plan
})

export const confirmIdVerificationPriceAction = () => ({
  type: confirmIdVerificationPrice
})

export const displayIdVerificationPaymentAction = () => ({
  type: displayIdVerificationPayment
})

export const successIdPaymentAction = evidentTab => ({
  type: successIdPayment,
  evidentTab
})

export const fetchEvidentRedirectUrlAction = data => ({
  type: fetchEvidentRedirectUrl,
  ...data
})

export const setIdVerificationUiUrlAction = webUrl => ({
  type: setIdVerificationUiUrl,
  payload: webUrl
})

export const evidentNotificationReceivedAction = () => ({
  type: evidentNotificationReceived
})

export const evidentVerificationFinishedAction = () => ({
  type: evidentVerificationFinished
})

export const redirectToIdVerificationAction = () => ({
  type: redirectToIdVerification
})

export const redirectConfirmedAction = () => ({
  type: redirectConfirmed
})

export const setBgVerificationPlanAction = plan => ({
  type: setBgVerificationPlan,
  payload: plan
})

export const notifyForBgCheckExpiredAction = () => ({
  type: notifyForBgCheckExpired
})

export const confirmBgRenewAction = () => ({
  type: confirmBgRenew
})

export const displayBgVerificationPriceAction = () => ({
  type: displayBgVerificationPrice
})

export const confirmBgVerificationPriceAction = () => ({
  type: confirmBgVerificationPrice
})

export const displayVerificationTermsAction = () => ({
  type: displayVerificationTerms
})

export const verificationTermsAcceptedAction = () => ({
  type: verificationTermsAccepted
})

export const displayBgVerificationPaymentAction = () => ({
  type: displayBgVerificationPayment
})

export const successBgPaymentAction = () => ({
  type: successBgPayment
})

export const retryLimitExceedAction = () => ({
  type: retryLimitExceed
})

export const waitingForEvidentResponseAction = type => ({
  type: waitingForEvidentResponse,
  payload: type
})
