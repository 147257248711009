import React, { Fragment } from 'react'
import messages from '../../footer.texts'
import { FormattedMessage } from 'react-intl'
import { map } from 'lodash'
import classNames from 'classnames'
import { LINKS } from '../../footer.data'
import { withStyles } from '@material-ui/core'
import sitemapStyles from './sitemap.styles'
import RoomiNavLink from '../../../shared/components/roomi-nav-link/roomi-nav-link.component'

const SiteMap = ({ classes }) => (
  <Fragment>
    {map(LINKS, ({ links, section }) => (
      <div key={section} className={classes.container}>
        <h3 className={classes.title}>
          <FormattedMessage {...messages[section]} />
        </h3>
        <ul className={classes.list}>
          {map(links, ({ name, to, classes: c, ...rest }) => (
            <li key={name} className={classes.listItem}>
              {to ? (
                <RoomiNavLink
                  className={classNames(classes.item, { [c]: c })}
                  to={to}
                >
                  <FormattedMessage {...messages[name]} />
                </RoomiNavLink>
              ) : (
                <a className={classNames(classes.item, { [c]: c })} {...rest}>
                  <FormattedMessage {...messages[name]} />
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
    ))}
  </Fragment>
)

export default withStyles(sitemapStyles)(SiteMap)
