const listingSelectorStyles = theme => ({
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 40,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      fontSize: 24
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    margin: '40px auto 70px',
    maxWidth: 320
  },
  cancelBtn: {
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      marginRight: 35
    }
  }
});

export default listingSelectorStyles;
