import moment from 'moment'
import { map, reduce, get } from 'lodash'

export const leaseDurationFormatter = (
  minDuration,
  maxDuration,
  isShorTerm
) => {
  if (isShorTerm) {
    let min = minDuration || 0
    min = min > 29 ? 30 : min
    let formattedString = min.toString()
    if (maxDuration > minDuration) {
      formattedString += `-${maxDuration}`
    }
    formattedString += formattedString === '1' ? ' night' : ' nights'
    return formattedString
  } else {
    let min = minDuration || 0
    min = min === 13 ? 12 : min
    let formattedString = min.toString()
    if (maxDuration > minDuration) {
      formattedString += maxDuration === 13 ? '+' : `-${maxDuration > 12 ? 12 : maxDuration}`
    } else if (minDuration === 13) {
      formattedString += '+'
    }
    formattedString += formattedString === '1' ? ' month' : ' months'
    return formattedString
  }
}

export const moveInDateFormatter = moveInDate => {
  moveInDate = get(moveInDate, 'iso', moveInDate)
  const date = moment(moveInDate)
  if (moveInDate && date.isBefore(moment())) {
    return 'Available Now'
  }
  return date.utc().format('MMM D')
}

export const genderPrefFormatter = gender =>
  !gender || gender === 'Either' ? 'Male or Female' : gender

export const ageMap = {
  'Early 20s': 1,
  'Late 20s': 3,
  '30s': 5,
  '40s and older': 9
}

export const agePrefFormatter = ages => {
  if (!ages) return ''
  if (ages.length === 1) return 'in their ' + ages[0]
  const mappedAge = map(ages, age => ageMap[age])
  const sum = reduce(mappedAge, (mapSum, el) => mapSum + el)
  switch (sum) {
    case 4:
      return "in their 20's"
    case 6:
      return "in their Early 20's or 30's"
    case 10:
      return "in their Early 20's or 40's and older"
    case 9:
      return "in their 20's to 30's"
    case 13:
      return "in their 20's or 40's and older"
    case 18:
      return 'of any age'
    case 14:
      return 'older than 30'
    case 15:
      return "in their Early 20's or 30's and older"
    case 8:
      return "in their Late 20's or 30's"
    case 12:
      return "in their Late 20's or 40's and older"
    case 17:
      return "in their Late 20's or older"
    default:
      return ''
  }
}
