const paginationStyles = theme => ({
  root: {
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fill: theme.palette.grey[400]
    },
    '& *': {
      outline: 'none',
      userSelect: 'none'
    }
  },
  page: {
    display: 'inline-block',
    height: 28,
    width: 28,
    fontWeight: 500,
    cursor: 'pointer',
    fontSize: 16,
    borderRadius: '50%',
    color: theme.palette.grey[400],
    transition: 'background 0.3s ease-in-out',
    margin: `0 ${theme.spacing.unit / 2}px`,
    border: '2px solid transparent',
    '&:not(.disabled):hover': {
      background: theme.palette.primary[50]
    }
  },
  link: {
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    lineHeight: '26px'
  },
  active: {
    background: theme.palette.primary[50],
    color: theme.palette.primary.main,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`
  },
  break: {
    display: 'inline-block',
    height: 24
  },
  navigation: {
    height: 24,
    display: 'inline-block',
    '&:not(.disabled)': {
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.2)'
      }
    }
  }
});

export default paginationStyles;
