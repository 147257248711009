export const createRoomiPayAccount = 'CREATE_ROOMIPAY_FOR_RENTER'
export const updateRoomiPayAddress = 'UPDATE_ROOMIPAY_ADDRESS'
export const updateRoomiPayTenants = 'UPDATE_ROOMIPAY_TENANTS'
export const updateRoomiPayRenter = 'UPDATE_ROOMIPAY_RENTER'
export const updateRoomiPayPayment = 'UPDATE_ROOMIPAY_PAYMENT'

export const fetchRoomiPay = 'FETCH_ROOMIPAY'
export const setRoomiPay = 'SET_ROOMIPAY'
export const resetRoomiPay = 'RESET_ROOMIPAY'

export const fetchInvitation = 'FETCH_INVITATION'
export const setInvitation = 'SET_INVITATION'
export const acceptInvitation = 'ACCEPT_INVITATION'

export const cancelRoomiPay = 'CANCEL_ROOMIPAY'