import { savePreOnboardingAction } from '../auth/actions/onboarding.actions'
import {
  saveEvent,
  saveRoute,
  getOldRoute,
  getCurrentRoute
} from './events/events.service'
import { sendEvent as gaEvent } from './google-analytics/google-analytics.actions'
import { sendEvent as fbEvent } from './facebook-events/facebook-events.actions'
// import { sendEvent as appboyEvent } from './appboy/appboy.actions'
import { trackPageView } from './sift-science/sift-science.actions'

export default function analytics({
  preOnboardingAction,
  action,
  page,
  targetId = 'self',
  details = {},
  // appboy = true,
  pageView,
  section,
  ga,
  fb,
  source,
  saveInternally = true
}) {
  if (pageView) {
    trackPageView()
    saveRoute(page, section)
  }
  if (action) {
    if (source) details.source = source
    else {
      details.source = pageView ? getOldRoute() : page || getCurrentRoute()
    }
    // if (appboy) {
    //   appboyEvent({ event: action, attributes: { id: targetId, ...details } })
    // }
    if (saveInternally) {
      saveEvent({ eventName: action, data: details, targetId })
    }
  }
  if (ga) {
    // Ga requires a category to be passed in from anywhere it is fired.
    gaEvent({ action, category: ga.category, value: ga.value })
  }
  if (fb) {
    fb === true
      ? fbEvent({ action, details })
      : fbEvent({ action, details: fb })
  }
  if (preOnboardingAction) {
    savePreOnboardingAction(preOnboardingAction)
  }
}
