import {
  FETCH_USER_FEEDS,
  FETCH_USER_FEEDS_SUCCESS,
  FETCH_USER_FEEDS_ERROR,
} from '../actions/user-feeds.types';

import update from 'immutability-helper';

export default (state = {}, { type, userId, error, data } = {}) => {
  switch (type) {
    case FETCH_USER_FEEDS:
      return update(state, {});
    case FETCH_USER_FEEDS_SUCCESS:
      return update(state, {
        $merge: { feeds: data }
      });
    case FETCH_USER_FEEDS_ERROR:
      return update(state, {
        $merge: { error }
      });
    default:
      return state;
  }
};
