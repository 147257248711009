const sliderValuesTrackerStyles = theme => ({
  sliderValuesTracker: {
    position: 'relative',
    color: theme.palette.grey[500],
    fontSize: 12,
    '& > div': {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      position: 'absolute'
    }
  }
});

export default sliderValuesTrackerStyles;
