import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, withStyles } from '@material-ui/core'
import { RoomiInput, RoomiCheckbox } from 'roomi/material'
import { ReactComponent as Down } from './006.arrow-down-material.svg'
import selectStyles from './select.styles'
import classNames from 'classnames'
import { isUndefined } from 'lodash'

const HIDE = { display: 'none' }
const NO_ARROW = () => null

@withStyles(selectStyles)
class RoomiSelect extends PureComponent {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
    value: PropTypes.any,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    height: PropTypes.number,
    description: PropTypes.string,
    titleKey: PropTypes.string,
    options: PropTypes.array,
    valueKey: PropTypes.string,
    inputProps: PropTypes.object,
    inputClass: PropTypes.string,
    itemClass: PropTypes.string,
    startAdornmentClass: PropTypes.string,
    withArrow: PropTypes.bool,
    showError: PropTypes.bool,
    showGenericError: PropTypes.bool
  }

  render() {
    const {
      children,
      value,
      showError,
      showGenericError,
      multiple,
      placeholder,
      options = [],
      valueKey,
      titleKey,
      inputProps = {},
      startAdornmentClass,
      inputClass,
      itemClass,
      classes,
      height,
      description,
      meta,
      input,
      withArrow = true,
      ...rest
    } = this.props

    if (!isUndefined(meta)) inputProps.meta = meta // don't attach meta prop on select component, attach it to inputif (!isUndefined(meta)) inputProps.meta = meta;
    if (!isUndefined(showError)) inputProps.showError = !!showError
    inputProps.showGenericError = showGenericError
    if (height) inputProps.height = height
    if (description) inputProps.description = description

    const { localInputRootClass, arrow, selectMenu, ...restClasses } = classes

    const _options = !!children
      ? children
      : options.map((option, i) => {
          const itemTitle = titleKey ? option[titleKey] : option
          const itemValue = valueKey ? option[valueKey] : option
          const item = !multiple ? (
            <div className="truncate">{itemTitle}</div>
          ) : (
            <RoomiCheckbox
              checked={value.indexOf(itemValue) >= 0}
              label={itemTitle}
              selectCheckbox
            />
          )
          return (
            <MenuItem
              key={i}
              value={itemValue}
              className={classNames({ [itemClass]: itemClass })}
            >
              {item}
            </MenuItem>
          )
        })

    return (
      <Select
        {...rest}
        classes={{
          ...restClasses,
          selectMenu: classNames(selectMenu, { [arrow]: withArrow })
        }}
        value={value || ''}
        multiple={multiple}
        input={
          <RoomiInput
            rootClass={classNames(inputClass, localInputRootClass)}
            startAdornmentClass={startAdornmentClass}
            {...inputProps}
          />
        }
        IconComponent={withArrow ? Down : NO_ARROW}
        displayEmpty
        {...input}
      >
        {
          placeholder ? (
            <MenuItem value={''}>
              <div className="truncate placeholder">{placeholder}</div>
            </MenuItem>
          ) : null
        }
        {_options}
      </Select>
    )
  }
}

export default RoomiSelect
