const loginModalStyles = theme => ({
  paper: {
    [theme.breakpoints.up('sm')]: {
      width: 400
    }
  },
  headerImage: {
    textAlign: 'center'
  },
  loginModal: {
    padding: theme.spacing.unit * 2
  },
  formWrapper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 5}px`
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 900,
    marginTop: theme.spacing.unit
  },
  facebookButton: {
    background: theme.palette.common.facebook,
    fontFamily: theme.typography.fontFamily,
    fontSize: 15,
    '&:hover': {
      background: theme.utils.shadeColor(theme.palette.common.facebook, -15)
    },
    '&:active': {
      background: theme.utils.shadeColor(theme.palette.common.facebook, -30)
    }
  },
  divider: {
    margin: `${theme.spacing.unit}px ${theme.spacing.unit * 4}px ${theme.spacing
      .unit * 2}px`
  },
  signUpContainer: {
    padding: theme.spacing.unit
  },
  forgot: {
    textAlign: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.utils.shadeColor(theme.palette.primary.main, -15)
    }
  },
  forgotDiv: {
    textAlign: 'center',
    marginTop: theme.spacing.unit
  },
  signUp: {
    marginLeft: theme.spacing.unit,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.utils.shadeColor(theme.palette.primary.main, -15)
    }
  },
  image: {
    width: 75,
    [theme.breakpoints.down('sm')]: {
      width: 50
    },
    marginBottom: theme.spacing.unit * 4
  }
});

export default loginModalStyles;
