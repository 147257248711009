const listingCardCarouselStyles = theme => ({
  listingCarousel: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden'
  }
});

export default listingCardCarouselStyles;
