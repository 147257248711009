import { error, fetched, reset, insert } from '../actions/sponsorships.types'
import update from 'immutability-helper'
const defaultState = { fetched: false, data: [] }
export default (
  state = defaultState,
  { type, data, sponsorship, error: errorMsg } = {}
) => {
  switch (type) {
    case fetched:
      return update(state, { $set: { fetched: true, data } })
    case insert:
      return update(state, { data: { $push: [sponsorship] } })
    case error:
      return update(state, { $set: { fetched: false, error: errorMsg } })
    case reset:
      return defaultState
    default:
      return state
  }
}
