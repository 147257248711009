import { get } from 'lodash'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import track from 'react-tracking'
import questionCircle from 'roomi/icons/question-circle.png';
import { RoomiDialog, RoomiButton } from 'roomi/material'

@track()
class WhyNeedInformation extends PureComponent {

  state = {
    modal: false
  }

  @track(({ application }) => ({
    action: 'View_RequestMessage',
    targetId: get(application, 'mover._id')
  }))
  openModal = () => this.setState({ modal: true })
  closeModal = () => this.setState({ modal: false })

  render() {
    return (
      <div className="read-more">
        {this.state.modal && (
          <RoomiDialog open>
            <div className="read-more-modal">
              <div className="read-more-body">
                <FormattedMessage
                  id="intercom.need.infromation.button.message"
                  defaultMessage="Why do we need this information?"
                  tagName="h3"
                />
                <div className="message maintain-white-space">
                <FormattedMessage
                  id="intercom.need.infromation.info"
                  defaultMessage=" "
                  tagName="p"
                />
                </div>
                <div className="message-button-container">
                  <RoomiButton
                    secondary
                    outline
                    small
                    onClick={this.closeModal}
                    label="Close"
                  />
                </div>
              </div>
            </div>
          </RoomiDialog>
        )}
        <FormattedMessage
          id="intercom.need.infromation.button.message"
          defaultMessage="Why do we need this information?"
        >
          {txt => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className="flex-none read-more-button" onClick={this.openModal}>
              <img src={questionCircle} alt="question" height={16} width={16}/>
              <span>{txt}</span>
            </a>
          )}
        </FormattedMessage>
      </div>
    )
  }
}

export default WhyNeedInformation
