import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import chatRequestsReducer from '../../chat-requests/chat-requests.reducer'
import favoritesReducer from '../../favorites/reducers/favorites.reducer'
import flagsReducer from '../../flags/reducers/flags.reducer'
import incompleteListingsReducer from '../../listings/reducers/incomplete-listings.reducer'
import listingsReducer from '../../listings/reducers/listings.reducer'
import manageListingReducer from '../../listings/reducers/manage-listing.reducer'
import importRequestsReducer from '../../manage-listings/reducers/import-requests.reducer'
import {
  announcementsReducer,
  conversationsReducer,
  // layerReducer,
  messagesReducer
} from '../../messaging/reducers'
import onboardingReducer from '../../onboarding/reducers/onboarding.reducer'
import bookingRequestsReducer from '../../requests/reducers/booking-requests.reducer'
import hotContentReducer from '../../shared/reducers/hot-content.reducer'
import howItWorksReducer from '../../how-it-works/reducers/how-it-works.reducer'
import sponsorshipReducer from '../../sponsorships/reducers/sponsorships.reducer'
import membersReducer from '../../team/reducers/members.reducer'
import userListingsReducer from '../../users/reducers/user-listings.reducer'
import userFeedsReducer from '../../users/reducers/user-feeds.reducer'
import userReducer from '../../users/reducers/user.reducer'
import roomiPayReducer from '../../roomipay/reducers/roomipay.reducer'
import profileReducer from '../../users/reducers/users.reducer'
import userVerificationReducer from '../../verification/reducers/user-verification.reducer'
import amenitiesReducer from './amenities.reducer'
import badgesReducer from './badges.reducer'
import configReducer from './config.reducer'
import neighborhoodsReducer from './neighborhoods.reducer'
import reactAppReducer from './react-app.reducer'
import regionsReducer from './regions.reducer'
import rulesReducer from './rules.reducer'
import scriptsReducer from './scripts.reducer'
import bookingInvitesReducer from '../../bookings/reducers/booking-invites.reducer'
import payoutReducer from '../../payout/reducers/payout.reducer'
import timelineReducer from '../../timeline/timeline.reducer';

export const reducers = {
  amenities: amenitiesReducer,
  announcements: announcementsReducer,
  badges: badgesReducer,
  bookingInvites: bookingInvitesReducer,
  bookingRequests: bookingRequestsReducer,
  chatRequests: chatRequestsReducer,
  config: configReducer,
  conversations: conversationsReducer,
  favorites: favoritesReducer,
  flags: flagsReducer,
  form: formReducer,
  hotContent: hotContentReducer,
  howItWorks: howItWorksReducer,
  importRequests: importRequestsReducer,
  incompleteListings: incompleteListingsReducer,
  // layer: layerReducer,
  listings: listingsReducer,
  manageListing: manageListingReducer,
  members: membersReducer,
  messages: messagesReducer,
  neighborhoods: neighborhoodsReducer,
  onboarding: onboardingReducer,
  reactApp: reactAppReducer,
  regions: regionsReducer,
  rules: rulesReducer,
  scripts: scriptsReducer,
  sponsorships: sponsorshipReducer,
  user: userReducer,
  userListings: userListingsReducer,
  userFeeds: userFeedsReducer,
  users: profileReducer,
  userVerification: userVerificationReducer,
  roomipay: roomiPayReducer,
  payout: payoutReducer,
  timeline: timelineReducer,
};

const rootReducer = combineReducers(reducers);

export default rootReducer
