import 'babel-polyfill'
import lazySizes from 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import App from './app/app.component'
import reducers from './app/shared/reducers'
import sagas from './app/sagas'

import {
  askForPermissionToReceiveNotifications,
  initializeFirebase
} from './push-notification'

import { analyticsMiddleware } from './app/analytics/analytics.middleware'
import { appLaunchMiddleWare } from './app/shared/app-launch.middleware'

import './index.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

const sagaMiddleware = createSagaMiddleware()
const middlewares = (process.env.NODE_ENV === 'development'
  ? [require('redux-immutable-state-invariant').default()]
  : []
).concat([
  thunkMiddleware,
  sagaMiddleware,
  analyticsMiddleware,
  appLaunchMiddleWare
])

lazySizes.cfg.loadHidden = false

if (process.env.NODE_ENV === 'development') {
  require('why-did-you-update').whyDidYouUpdate(React, {
    include: [
      'LandingPageComponent',
      'FeaturedListings',
      'AboutSectionComponent'
    ]
  })
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enchanter = composeEnhancers(applyMiddleware(...middlewares))
const store = createStore(reducers, enchanter)

sagaMiddleware.run(sagas)

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById('react-root')
  )
}

Sentry.init({ dsn: process.env.SENTRY_DSN })

// registerServiceWorker();
initializeFirebase()
askForPermissionToReceiveNotifications()

if (!global.Intl) {
  import([
    'intl',
    'intl/locale-data/jsonp/en.js',
    'intl/locale-data/jsonp/es.js'
  ]).then(() => {
    render(App)
  })
} else {
  render(App)
}

if (module.hot) {
  module.hot.accept('./app/app.component', () => {
    const NewApp = require('./app/app.component').default
    render(NewApp)
  })
}
