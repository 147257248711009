import { fetched, error, fetching } from './neighborhoods.types'

export const fetchNeighborhoods = id => ({ type: fetching, id })
export const fetchedNeighborhoods = (id, payload) => ({
  type: fetched,
  payload,
  id
})
export const errorFetchingNeighborhoods = (id, msg) => ({
  type: error,
  error: msg,
  id
})
