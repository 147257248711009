import {
  toggleHowItWorksListerDialog,
  toggleHowItWorksMoverDialog
} from '../actions/how-it-works.types'

const DEFAULT_STATE = { listerDialog: false, moverDialog: false }

export default (state = DEFAULT_STATE, { type } = {}) => {
  switch (type) {
    case toggleHowItWorksListerDialog:
      return { ...state, listerDialog: !state.listerDialog }
    case toggleHowItWorksMoverDialog:
      return { ...state, moverDialog: !state.moverDialog }
    default:
      return state
  }
}
