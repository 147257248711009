const paymentStyles = theme => ({
  title: {
    fontSize: 40,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      fontSize: 24
    }
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 3,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '19px',
    textAlign: 'center'
  },
  paymentError: {
    color: theme.palette.error.main,
    textAlign: 'center',
    marginBottom: theme.spacing.unit * 2
  },
  addCardButton: {
    margin: '20px 0',
    marginLeft: 3
  },
  cancelBtn: {
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      marginRight: 35
    }
  },
  actions: {
    marginTop: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: 65
    }
  }
});

export default paymentStyles;
