import { loadScript } from '../../shared/actions/scripts.action'

initializeSiftObject()

export const startSiftScience = () => dispatch => {
  loadScript('SiftScience', 'https://cdn.siftscience.com/s.js')(dispatch)
}

export function identifyUser(user = {}) {
  window._sift.push(['_setUserId', user._id])
}

export function initializeSiftObject() {
  const _sift = (window._sift = window._sift || [])
  _sift.push(['_setAccount', process.env.SIFT_SCIENCE_KEY])
  _sift.push(['_setUserId', ''])
  _sift.push(['_setSessionId', ''])
  _sift.push(['_trackPageview'])
}

export function trackPageView() {
  window._sift.push(['_trackPageview'])
}

export function trackSession(id) {
  window._sift.push(['_setSessionId', id])
}
