export const   fetchAllBookingInvites= '[BOOKING INVITES] FETCH'
export const   fetchedAllBookingInvites= '[BOOKING INVITES] FETCHED'
export const   errorFetchAllBookingInvites= '[BOOKING INVITES] FETCH ERROR'
export const   cancelBookingInvite= '[BOOKING INVITE] CANCEL'
export const   errorCancelBookingInvite= '[BOOKING INVITE] CANCEL ERROR'
export const   canceledBookingInvite= '[BOOKING INVITE] CANCELED'
export const   clearBookingInvites= '[BOOKING INVITES] CLEAR'
export const   sentBookingInvite= '[BOOKING INVITE] SENT'
export const   fetchBookingInvite= '[BOOKING INVITES] FETCH ONE'
export const   fetchedBookingInvite= '[BOOKING INVITES] FETCHED ONE'
export const   errorFetchBookingInvite= '[BOOKING INVITES] FETCH ONE ERROR'
export const   declinedBookingInvite= '[BOOKING INVITE] DECLINED'
export const   acceptedBookingInvite= '[BOOKING INVITE] ACCEPTED'
