import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import sidebarToggleStyles from './sidebar-toggle.styles';
import { withStyles, Icon } from '@material-ui/core';

@withStyles(sidebarToggleStyles)
class SidebarToggle extends PureComponent {
  static propTypes = {
    handleToggle: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired
  };

  render() {
    const {
      handleToggle,
      total,
      classes: { toggle, badge, icon }
    } = this.props;
    return (
      <div className={toggle} onClick={handleToggle}>
        <Icon color="primary" className={icon}>
          menu
        </Icon>
        {!!total && <div className={badge}>{total}</div>}
      </div>
    );
  }
}

export default SidebarToggle;
