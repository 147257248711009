import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'boost.modal.planSelector.title',
    defaultMessage: 'Select Boost Plan'
  },
  subtitle: {
    id: 'boost.modal.planSelector.subtitle',
    defaultMessage: 'Rise to the top!'
  },
  cancelButton: {
    id: 'boost.modal.planSelector.cancel',
    defaultMessage: 'Cancel'
  },
  continueButton: {
    id: 'boost.modal.planSelector.continue',
    defaultMessage: 'Continue'
  }
})
