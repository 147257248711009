import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import listingPriceStyles from './listing-price.styles';
import { withStyles } from '@material-ui/core';

@withStyles(listingPriceStyles)
class ListingPrice extends PureComponent {
  static propTypes = {
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    isShortTerm: PropTypes.bool
  };

  render() {
    const { price, currency, classes, isShortTerm } = this.props;
    
    if (!price || !currency) return null;
    return (
      <div className={classes.listingPrice}>
        <FormattedNumber
          value={price}
          style="currency"
          minimumFractionDigits={0}
          currency={currency}
        />
        &nbsp;/&nbsp;
        {isShortTerm ? 'night' : 'month'}
      </div>
    );
  }
}

export default ListingPrice;
