import { defineMessages } from 'react-intl';

export default defineMessages({
  successTitle: {
    id: 'payment.new.plaid.account.form.success.title',
    defaultMessage: 'Success'
  },
  successMessage: {
    id: 'payment.new.plaid.account.form.success.message',
    defaultMessage: 'Bank account has been successfully added.'
  },
  errorTitle: {
    id: 'payment.new.plaid.account.form.error.title',
    defaultMessage: 'Error'
  },
  cancelButton: {
    id: 'payment.new.plaid.account.form.cancel.button',
    defaultMessage: 'Cancel'
  }
});
