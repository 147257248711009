import { remove, post, put, get } from '../../shared/utils/fetch.util'
import { omit } from 'lodash'

export const addPayoutMethodApi = (userId, data) => {
  const requestBody = {
    ...data,
    holderType: 'individual',
    usage: 'payout',
    type: 'bank-account'
  }
  return post(`/v2/users/${userId}/accounts`, requestBody)
}

export const updateConnectAccount = (userId, formData) => {
  formData.usage = 'payment'
  formData.currency = 'USD'
  return post(`/v2/users/${userId}/connectAccount`, formData).then(
    resp => resp.data
  )
}

export const getPaymentForm = () => {
  return get('/v2/config/payments').then(response => {
    // Hacky way to rename UK with proper iso code GB. TODO: remove once fixed on backend
    return {
      ...response,
      data: {
        ...response.data,
        bankAccountForm: {
          ...omit(response.data.bankAccountForm, 'UK'),
          GB: response.data.bankAccountForm.UK
        }
      }
    }
  })
}
export const deletePayoutMethodApi = (userId, accountId) =>
  remove(`/v2/users/${userId}/accounts/${accountId}`)

export const setDefaultPayoutMethodApi = (userId, accountId) =>
  put(`/v2/users/${userId}/accounts/${accountId}/default`)
