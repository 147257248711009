const BORDER_COLOR = '#D6D6D6'; // WEB-2302 strict requirement, not existing in grey theme

const sidebarStyles = theme => ({
  paper: {
    width: '100%'
  },
  logoRow: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing.unit * 3,
    borderBottom: `1px solid ${BORDER_COLOR}`,
    flexShrink: 0
  },
  logo: {
    width: 120,
    height: 34,
    marginLeft: theme.spacing.unit * 3,
    cursor: 'pointer'
  },
  close: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary[900]
    }
  },
  userInfoRow: {
    display: 'flex',
    padding: '15px 30px',
    alignItems: 'center',
    borderBottom: `1px solid ${BORDER_COLOR}`,
    flexShrink: 0,
    cursor: 'pointer'
  },
  userImage: {
    flexShrink: 0
  },
  userName: {
    color: theme.palette.common.black,
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit * 4,
    fontSize: 28
  },
  disableProfileRoute: {
    pointerEvents: 'none'
  },
  list: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing.unit * 4}px`,
    flexShrink: 0,
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 70 // adding bottom padding for iPhone (IOS) so user is able to click last item in list
    }
  },
  link: {
    padding: `${theme.spacing.unit * 2}px 0`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&.active span': {
      color: theme.palette.primary.main
    },
    '&:focus': {
      outline: 'none'
    }
  },
  button: {
    marginTop: theme.spacing.unit * 3
  },
  border: {
    borderBottom: `1px solid ${BORDER_COLOR}`
  },
  logIn: {
    cursor: 'pointer'
  },
  logOut: {
    cursor: 'pointer',
    color: theme.palette.secondary.main
  },
  badge: {
    backgroundColor: theme.palette.error.main,
    borderRadius: '50%',
    height: 20,
    width: 20,
    fontSize: 10,
    color: theme.palette.common.white,
    fontWeight: 900,
    textAlign: 'center',
    lineHeight: '20px'
  }
});

export default sidebarStyles;
