const saveDialogStyles = theme => ({
  progressLine: {
    height: 30
  },
  progressWrapper: {
    width: 360,
    position: 'relative',
    margin: '20px 0'
  },
  percentage: {
    position: 'absolute',
    right: 12,
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 900,
    top: 6,
    '&$full': {
      color: theme.palette.common.white
    }
  },
  status: {
    fontSize: 16,
    fontWeight: 900,
    margin: '10px 0'
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `0 ${theme.spacing.unit * 10}px ${theme.spacing.unit * 2}px`,
    textAlign: 'center'
  },
  full: {}
});

export default saveDialogStyles;
