import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'payment.new.credit.card.form.title',
    defaultMessage: 'Payment Details'
  },
  subtitle: {
    id: 'payment.new.credit.card.form.subtitle',
    defaultMessage: 'Enter the following credit card details for payment:'
  },
  firstName: {
    id: 'payment.new.credit.card.form.first.name',
    defaultMessage: 'First Name'
  },
  firstNameRequired: {
    id: 'payment.new.credit.card.form.first.name.required.message',
    defaultMessage: 'Please enter your first name'
  },
  lastName: {
    id: 'payment.new.credit.card.form.last.name',
    defaultMessage: 'Last Name'
  },
  lastNameRequired: {
    id: 'payment.new.credit.card.form.last.name.required.message',
    defaultMessage: 'Please enter your last name'
  },
  cardNumber: {
    id: 'payment.new.credit.card.form.card.number',
    defaultMessage: 'Card Number'
  },
  cardNumberRequired: {
    id: 'payment.new.credit.card.form.card.number.required.message',
    defaultMessage: 'Please enter a credit card'
  },
  invalidCardNumber: {
    id: 'payment.new.credit.card.form.card.number.invalid.message',
    defaultMessage: 'Please enter a valid credit card'
  },
  expirationMonth: {
    id: 'payment.new.credit.card.form.expiration.month',
    defaultMessage: 'Expiration Month'
  },
  expirationMonthRequired: {
    id: 'payment.new.credit.card.form.expiration.month.required.message',
    defaultMessage: 'Please select valid expiration month'
  },
  expirationYear: {
    id: 'payment.new.credit.card.form.expiration.year',
    defaultMessage: 'Expiration Year'
  },
  expirationYearRequired: {
    id: 'payment.new.credit.card.form.expiration.year.required.message',
    defaultMessage: 'Please select valid expiration year'
  },
  zipCode: {
    id: 'payment.new.credit.card.form.zip.code',
    defaultMessage: 'Zip Code'
  },
  zipCodeRequired: {
    id: 'payment.new.credit.card.form.zip.code.required.message',
    defaultMessage: 'Please enter your zip code'
  },
  cvc: {
    id: 'payment.new.credit.card.form.cvc',
    defaultMessage: 'CVC'
  },
  cvcRequired: {
    id: 'payment.new.credit.card.form.cvc.required.message',
    defaultMessage: 'Please enter a CVC'
  },
  invalidCvc: {
    id: 'payment.new.credit.card.form.cvc.invalid.message',
    defaultMessage: 'Please enter a valid CVC'
  },
  cancelButton: {
    id: 'payment.new.credit.card.form.cancel.button',
    defaultMessage: 'Cancel'
  },
  submitButton: {
    id: 'payment.new.credit.card.form.submit.button',
    defaultMessage: 'Submit'
  },
  invalidExpirationDate: {
    id: 'payment.new.credit.card.form.expiration.date.invalid.message',
    defaultMessage: 'Please select valid expiration date'
  }
});
