import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { RoomiConfirmationDialog } from 'roomi/material';
import { getNavigation } from '../shared/reducers/react-app.selector';
import { connect } from 'react-redux';
import {
  finishOnboarding,
  forceOnboarding,
} from '../auth/actions/onboarding.actions';

class Base extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    jumioOpen: false,
  };

  removeQueryParam = param => {
    const {
      history,
      location: { searchParams },
    } = this.props;
    delete searchParams[param];
    const query = qs.stringify(searchParams);
    history.replace(window.location.pathname + query ? `?${query}` : '');
  };

  handleOnboarding = ({ onboarded }) => {
    if (!onboarded) return;
    this.props.finishOnboarding();
    this.removeQueryParam('onboarded');
  };

  handleJumio = ({ idScanStatus }) => {
    if (idScanStatus === 'SUCCESS') {
      this.setState({ jumioOpen: true });
    }
  };

  closeJumio = () => {
    this.setState({ jumioOpen: false });
    this.removeQueryParam('idScanStatus');
  };

  parseQuery = ({ location }) => {
    location.searchParams = qs.parse(location.search.substring(1));
  };

  componentWillReceiveProps(props) {
    const { navigation, history } = this.props;
    this.parseQuery(props);
    if (props.navigation !== navigation) history.push(props.navigation);
    if (history.location.pathname !== '/onboarding')
      forceOnboarding(props.user, history.location.pathname, props.roomipay);
  }

  componentDidMount() {
    this.parseQuery(this.props);
    const { searchParams } = this.props.location;
    this.handleJumio(searchParams);
    this.handleOnboarding(searchParams);
  }

  render() {
    const { jumioOpen } = this.state;
    return (
      <RoomiConfirmationDialog
        open={!!jumioOpen}
        onConfirm={this.closeJumio}
        title="Verification Information Received"
        message="We have received your documentation and are in the process of
          verifying it. Please allow a couple of minutes before we update your
          information."
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.data,
  roomipay: state.roomipay,
  navigation: getNavigation(state),
});

export default connect(
  mapStateToProps,
  { finishOnboarding },
)(Base);
