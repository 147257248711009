const loadingSpinnerWrapperStyles = theme => ({
  root: {
    position: 'relative',
    zIndex: 1,
    height: '100%'
  },
  overlay: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0
  },
  body: {
    '&$disabled': {
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },
  disabled: {}
});

export default loadingSpinnerWrapperStyles;
