import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Icon } from '@material-ui/core';
import addCreditCardButtonStyles from './add-button.component.styles';

@withStyles(addCreditCardButtonStyles)
class AddButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  render() {
    const { label, onClick, className, classes } = this.props;
    return (
      <div className={classNames(classes.button, className)} onClick={onClick}>
        <Icon className={classes.addIcon}>add</Icon>
        {label}
      </div>
    );
  }
}

export default AddButton;
