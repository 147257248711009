const imageElementStyles = theme => ({
  overlay: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    pointerEvents: 'none',
    height: '100%'
  }
});

export default imageElementStyles;
