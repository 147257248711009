import { lazyLoader } from '../shared/utils/lazyLoader'
import ensureLogin from '../auth/components/ensure-login-route/ensure-login-route.component'

const PoliciesRoute = lazyLoader(() => import('../policies/policies.component'))
const HowItWorks = lazyLoader(() =>
  import('../how-it-works/how-it-works.component')
)
// const UserRoute = lazyLoader(() =>
//   import('../users/profile/user-profile.route.component')
// )
const SettingsRoute = lazyLoader(() =>
  import('../users/settings/settings.component')
)
const About = lazyLoader(() => import('../about/about.component'))
const TestimonialRoute = lazyLoader(() =>
  import('../testimonials/testimonials.component')
)
const OnBoarding = lazyLoader(() =>
  import('../onboarding/onboarding.route.component')
);
const RoomiPay = lazyLoader(() =>
  import('../roomipay/roomipay.route.component')
);
const LandingRoute = lazyLoader(() => import('../home/home.component'));
const LocalPage = lazyLoader(() =>
  import('../local/containers/local-page-container/local-page-container')
)
// const components = lazyLoader(() => import('./components'))
const ResetPassword = lazyLoader(() =>
  import('../auth/components/reset-password/reset-password.route.component')
)
const ConfirmEmail = lazyLoader(() =>
  import('../users/confirm-email/confirm-email.component')
)

const routesSettings = {
  policy: {
    component: HowItWorks,
    paths: ['/how-it-works']
  },
  // components: {
  //   component: components,
  //   paths: ['/components'],
  //   hideIntercom: true,
  //   hideFooter: true,
  //   fullScreen: true
  // },
  howItWorks: {
    component: PoliciesRoute,
    paths: ['/policies/:topic', '/policies']
  },
  confirmEmail: {
    component: ConfirmEmail,
    paths: ['/confirm-email']
  },
  landing: {
    component: LandingRoute,
    paths: ['/']
  },
  testimonial: {
    component: TestimonialRoute,
    paths: ['/roomirocks']
  },
  // userProfile: {
  //   component: UserRoute,
  //   remount: false,
  //   paths: ['/roommate/:city/:id', '/roommate/:id']
  // },
  about: {
    component: About,
    paths: ['/about']
  },
  onBoard: {
    component: OnBoarding,
    paths: ['/onboarding'],
    hideSitemap: true
  },
  roomipay: {
    component: RoomiPay,
    paths: ['/rentpayment'],
    hideSitemap: true,
    deep: true
  },
  settings: {
    component: ensureLogin(SettingsRoute),
    paths: ['/settings']
  },
  localPages: {
    component: LocalPage,
    paths: ['/local/:city?', '/local/:city/:neighborhood?']
  },
  resetPassword: {
    component: ResetPassword,
    paths: ['/reset-password']
  },
}

Object.values(routesSettings).forEach(setting => {
  setting.paths = setting.paths.reduce((agg, path) => {
    agg.push(path)
    agg.push('/es' + path)
    return agg
  }, [])
})

export default routesSettings
