import { post, put } from '../../shared/utils/fetch.util'
import { times } from 'lodash'

export const transformUserImage = ({ photos }) => {
  const form = new FormData()
  times(photos.length, i => {
    form.append('file', photos[i], photos[i].name)
  })
  return form
}

export const signUp = params => post('/v2/users/signup', params)
export const fbSignUp = accessToken =>
  post('/v2/users/signup/facebook', accessToken)
export const phoneInfoSubmission = ({ phone, userId }) =>
  post(`/v2/users/${userId}/phone`, { phone })
export const phoneInfoVerification = ({ code, userId }) =>
  post(`/v2/users/${userId}/phone/verify`, { code })
export const userDataSubmission = ({ userId, data }) =>
  put(`/v1/app-users/${userId}/userData`, { ...data })
export const imageSubmission = data =>
  post(`/v1/app-users/${data.userId}/addImage`, data, {}, transformUserImage)
export const identityVerified = ({ userId, accessToken }) =>
  post(`/v2/users/${userId}/identity`, { accessToken })
