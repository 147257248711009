import React, { PureComponent } from 'react';
import { reduxForm, Field, Form } from 'redux-form';
import { Mail } from 'roomi/icons';
import { withStyles, Grid } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RoomiInput, RoomiButton } from 'roomi/material';
import messages from './forgot-form.text';
import validate from './forgot-form.validator';
import forgotFormStyles from './forgot-form.styles';
import imgRequestPassword from '../assets/never_miss_a_message.png';
import PropTypes from 'prop-types';

@injectIntl
@reduxForm({
  form: 'forgotForm',
  propNamespace: 'forgotMeta',
  validate
})
@withStyles(forgotFormStyles)
class ForgotPasswordForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  };
  render() {
    const {
      forgotMeta: { handleSubmit, submitting },
      onSubmit,
      intl: { formatMessage },
      classes
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={16} direction="column">
          <Grid item className={classes.headerImage}>
            <img src={imgRequestPassword} className={classes.image} alt=""/>
          </Grid>
          <Grid item className={classes.title}>
            <FormattedMessage {...messages.title}>
              {value => <span className="aut-text-copy">{value}</span>}
            </FormattedMessage>
          </Grid>
          <Grid item>
            <Field
              placeholder={formatMessage(messages.email)}
              name="username"
              type="email"
              height={50}
              startAdornment={<Mail />}
              component={RoomiInput}
              className={classes.input}
            />
          </Grid>
          <Grid item>
            <RoomiButton
              type="submit"
              primary
              small
              fullWidth
              disabled={submitting}
              label={formatMessage(messages.resetButton)}
              loading={submitting}
              className="aut-button-submit"
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

export default ForgotPasswordForm;
