import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { clamp, debounce, isEqual, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { createRef, PureComponent } from 'react';
import sliderValuesTrackerStyles from './slider-values-tracker.styles';

const detectOverlap = (() => {
  function getPositions(elem) {
    let pos = elem.getBoundingClientRect();
    return [[pos.left, pos.right], [pos.top, pos.bottom]];
  }

  function comparePositions(p1, p2) {
    let r1;
    let r2;
    r1 = p1[0] < p2[0] ? p1 : p2;
    r2 = p1[0] < p2[0] ? p2 : p1;
    return r1[1] > r2[0] || r1[0] === r2[0];
  }

  return function (a, b) {
    let pos1 = getPositions(a);
    let pos2 = getPositions(b);
    return comparePositions(pos1[0], pos2[0]) && comparePositions(pos1[1], pos2[1]);
  };
})();

// TODO: ivan this solves JIRA issue WEB-1899
// deprecate this if 3rd party support this in future: https://github.com/react-component/slider/issues/415
@withStyles(sliderValuesTrackerStyles)
class SliderValuesTracker extends PureComponent {
  static defaultProps = {
    distance: 10,
    separator: '-'
  };

  static propTypes = {
    distance: PropTypes.number,
    labelClass: PropTypes.string,
    max: PropTypes.number,
    maxLabel: PropTypes.string,
    min: PropTypes.number,
    minLabel: PropTypes.string,
    separator: PropTypes.string,
    step: PropTypes.number,
    value: PropTypes.arrayOf(PropTypes.number)
  };

  state = {};

  root = createRef();
  left = createRef();
  right = createRef();
  overlapBoth = createRef();

  getState = () => {
    if (!this.root.current) return null;
    const rootWidth = this.root.current.getBoundingClientRect().width;
    return {
      rootWidth,
      leftWidth: this.left.current.getBoundingClientRect().width,
      rightWidth: this.right.current.getBoundingClientRect().width,
      overlapBothWidth: this.overlapBoth.current.getBoundingClientRect().width,
      unit: rootWidth / ((this.props.max - this.props.min) / this.props.step),
      overlap: detectOverlap(this.left.current, this.right.current)
    };
  };

  checkAndUpdateDimensions = () => {
    const state = this.getState();
    if (state && !isEqual(state, this.state)) {
      this.setState(state);
    }
  };

  debouncedHandler = debounce(this.checkAndUpdateDimensions, 200);

  componentDidMount() {
    window.addEventListener('resize', this.debouncedHandler);
    this.checkAndUpdateDimensions();
  }

  componentDidUpdate({ value }, { rootWidth }) {
    if (value !== this.props.value) {
      this.checkAndUpdateDimensions();
    }
    if (!rootWidth && this.state.rootWidth) {
      this.checkAndUpdateDimensions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandler);
  }

  render() {
    const {
      value,
      min,
      step,
      distance,
      minLabel,
      maxLabel,
      separator,
      bothLabel = `${minLabel} ${separator} ${maxLabel}`,
      labelClass,
      classes
    } = this.props;
    const {
      rootWidth,
      leftWidth,
      rightWidth,
      unit,
      overlap,
      overlapBothWidth
    } = this.state;

    const [ leftX, rightX ] = map(value, i => ((i - min) / step) * unit);

    const leftStyle = {
      paddingRight: distance / 2,
      left: clamp(leftX - (leftWidth / 2), 0, rootWidth - leftWidth) || 0,
      visibility: !overlap ? 'visible' : 'hidden'
    };
    const rightStyle = {
      paddingLeft: distance / 2,
      left: clamp(rightX - (rightWidth / 2), 0, rootWidth - rightWidth) || 0,
      visibility: !overlap ? 'visible' : 'hidden'
    };
    const overlapBothValuesStyle = {
      left: clamp((((leftStyle.left + rightStyle.left) / 2) - overlapBothWidth / 4), 0, rootWidth - overlapBothWidth) || 0,
      visibility: overlap && value[0] !== value[1] ? 'visible' : 'hidden'
    };
    const overlapSingleValueStyle = {
      ...leftStyle,
      visibility: overlap && value[0] === value[1] ? 'visible' : 'hidden'
    };
    return (
      <div ref={this.root} className={classNames(classes.sliderValuesTracker, labelClass)}>
        <div style={leftStyle} ref={this.left}>{minLabel}</div>
        <div style={rightStyle} ref={this.right}>{maxLabel}</div>
        <div style={overlapBothValuesStyle} ref={this.overlapBoth}>{bothLabel}</div>
        <div style={overlapSingleValueStyle}>{minLabel}</div>
      </div>
    );
  }
}

export default SliderValuesTracker;
