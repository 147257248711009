import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import LeftArrow from './slick-left-arrow';
import RightArrow from './slick-right-arrow';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import windowInfo from '../../utils/window-info';
import ImageElement from './image-element/image-element';
import slickStyles from './slick.styles';
import { withStyles } from '@material-ui/core';

const { isTouchDevice } = windowInfo;

export const Slick = ({
  children,
  settings,
  classes,
}) => {
  const {
    images = [],
    initialSlide,
    autoplay,
    width = 30,
    height = 36,
    fake,
    arrowsAlwaysVisible = isTouchDevice,
    arrowsClass,
    ...restSettings
  } = settings;

  if (fake) return <ImageElement src={fake} />;

  const imageElements = children
    ? children
    : images.map(src => <ImageElement key={src} src={src} />);

  const defaultSettings = {
    nextArrow: (
      <RightArrow width={width} height={height} customClass={arrowsClass} />
    ),
    prevArrow: (
      <LeftArrow width={width} height={height} customClass={arrowsClass} />
    ),
    arrows: imageElements.length > 1,
    infinite: imageElements.length > 1,
    autoplay: autoplay && imageElements.length > 1,
    autoplaySpeed: 3000,
    draggable: false,
    pauseOnHover: true,
    initialSlide: initialSlide || 0,
    ...restSettings,
  };

  return (
    <Slider
      className={classNames(classes.defaultSlider, {
        [classes.touchDevice]: arrowsAlwaysVisible,
      })}
      {...defaultSettings}
    >
      {imageElements}
    </Slider>
  );
};

Slick.propTypes = {
  children: PropTypes.array,
  settings: PropTypes.object,
};

export default withStyles(slickStyles)(Slick);
