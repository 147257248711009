import { withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { Field } from 'redux-form';
import DynamicField from './dynamic-field.component';
import dynamicFormFieldsStyles from './dynamic-form-fields.component.styles';

@withStyles(dynamicFormFieldsStyles)
class DynamicFormFields extends PureComponent {
  static propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    groupKey: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object
  };

  static defaultProps = {
    groupKey: 'group'
  };

  render() {
    const { classes, className } = this.props;
    const groupedFields = groupBy(this.props.fields, this.props.groupKey);

    return (
      <div className={className}>
        {
          Object.keys(groupedFields).map(value => (
            <div key={value} className={classes.group}>
              <div className={classes.groupLabel}>{value}</div>
              <div className={classes.groupFields}>
                {
                  groupedFields[value].map((field) => (
                    <div key={field.name} className={classes.field} style={{ flex: `1 1 ${field.width}%` }}>
                      <Field
                        name={field.name}
                        disabled={!field.editable && !!field.prefill}
                        field={field}
                        component={DynamicField}
                      />
                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default DynamicFormFields;
