const slickStyles = theme => ({
  defaultSlider: {
    height: '100%',

    '& .slick-slide': {
      overflow: 'hidden',

      '& > div': {
        height: '100%'
      }
    },

    '& .slick-track, & .slick-list, & .slick-slider, & .carousel-image': {
      height: '100%'
    },

    '& .slick-prev': {
      left: theme.spacing.unit,

      '&:before': {
        display: 'none'
      }
    },

    '& .slick-next': {
      right: theme.spacing.unit,

      '&:before': {
        display: 'none'
      }
    },

    '& .slick-arrow': {
      zIndex: 1
    },

    '&:not($touchDevice) .slick-arrow': {
      opacity: 0,
      transition: 'opacity 0.25s'
    },

    '&:not($touchDevice):hover': {
      '& .slick-arrow': {
        opacity: 0.7,

        '&:hover': {
          opacity: 1
        }
      }
    }
  },
  touchDevice: {}
});

export default slickStyles;
