const listingCardInfoStyles = theme => ({
  listingInfo: {
    position: 'relative',
    display: 'block'
  },
  availability: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  },
  layout: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  priceAndLocation: {
    fontWeight: 900,
    fontSize: 16,
    transition: 'all 0.25s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  square: {
    color: theme.palette.grey[400],
    margin: `0 ${theme.spacing.unit / 2}px`
  }
});

export default listingCardInfoStyles;
