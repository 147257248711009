import {
  flagsSet,
  flagsReset,
  userFLagged,
  listingFlagged,
  openFlag,
  closeFlag
} from './flags.types'
import { postListingFlag, postUserFlag } from '../data/flags.data'
import { fromPairs, map } from 'lodash'

export const flagListing = (id, reason) => dispatch => {
  dispatch(flagListingAction(id))
  return postListingFlag(id, reason)
}

export const flagUser = (id, reason) => dispatch => {
  dispatch(flagUserAction(id))
  return postUserFlag(id, reason)
}

export const setFlags = user => ({
  type: flagsSet,
  payload: {
    listings: fromPairs(
      map(user.usrFlaggedListings, flag => [flag.objectId, true])
    ),
    users: fromPairs(map(user.usrFlaggedUsers, flag => [flag.objectId, true]))
  }
})

export const resetFlags = () => ({ type: flagsReset })

export const flagUserAction = id => ({
  type: userFLagged,
  payload: id
})

export const flagListingAction = id => ({
  type: listingFlagged,
  payload: id
})

export const startFlagModal = (type, id) => ({
  type: openFlag,
  payload: { type, id }
})
export const closeFlagModal = () => ({ type: closeFlag })
