import {
  FETCH_USER_FEEDS,
  FETCH_USER_FEEDS_SUCCESS,
  FETCH_USER_FEEDS_ERROR,
} from './user-feeds.types';

export const fetchUserFeeds = (userId) => ({
  type: FETCH_USER_FEEDS,
  userId,
});

export const fetchUserFeedsSuccess = (data) => ({
  type: FETCH_USER_FEEDS_SUCCESS,
  data,
});

export const fetchUserFeedsError = (error) => ({
  type: FETCH_USER_FEEDS_ERROR,
  error,
});
