import { get, values } from 'lodash'
import { createSelector } from 'reselect'
import { getRegionFromCoordinates } from '../../search/utils/search-utils'

export const getRegionsData = state => get(state, 'regions.data')
export const getRegionInstantRentingStatus = (state, id) =>
  get(state, `regions.data.${id}.instantBookingEnabled`)
export const getRegions = createSelector([getRegionsData], data => values(data))
export const getRegionFromCoordinatesSelector = createSelector(
  [getRegions, (s, c) => c],
  (regions, coordinates) => getRegionFromCoordinates(regions, coordinates)
)
