const loginFormStyles = theme => ({
  forgot: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.utils.shadeColor(theme.palette.primary.main, -15)
    }
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 900,
    marginTop: theme.spacing.unit * 2
  },
  input: {
    fontSize: 16
  }
});

export default loginFormStyles;
