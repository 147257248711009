import { defineMessages } from 'react-intl';

export default defineMessages({
  message: {
    id: 'cookies.consent.message',
    defaultMessage: 'This site uses cookies to provide you a better experience. By using Roomi, you are giving us your consent to use cookies. Learn more about our {link}.'
  },
  link: {
    id: 'cookies.consent.link',
    defaultMessage: 'Ad and Cookie Policy'
  }
});
