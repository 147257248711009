import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { getToken, setToken } from '../../utils/storage/cookies-util'
import consentBannerStyles from './cookies-consent.styles'
import { Icon, withStyles } from '@material-ui/core'
import messages from './cookies-consent.text'
import RoomiNavLink from '../roomi-nav-link/roomi-nav-link.component'

@withStyles(consentBannerStyles)
class CookiesConsent extends PureComponent {
  state = {
    cookiesAccepted: !!getToken('eu_cookie_consent')
  }

  acceptCookies = () => {
    const d = new Date()
    const expires = new Date(d.setMonth(d.getMonth() + 1))
    setToken('eu_cookie_consent', true, { expires })
    this.setState({ cookiesAccepted: true })
  }

  render() {
    const { cookiesAccepted } = this.state
    const { classes } = this.props
    return !cookiesAccepted ? (
      <div className={classes.root}>
        <div className={classes.message}>
          <FormattedMessage
            {...messages.message}
            values={{
              link: (
                <FormattedMessage {...messages.link}>
                  {t => (
                    <RoomiNavLink
                      className={classes.link}
                      to="/policies/ads-cookies"
                    >
                      {t}
                    </RoomiNavLink>
                  )}
                </FormattedMessage>
              )
            }}
          />
        </div>
        <Icon className={classes.icon} onClick={this.acceptCookies}>
          close
        </Icon>
      </div>
    ) : null
  }
}

export default CookiesConsent
