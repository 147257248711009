import {
  resetUserVerification,
  setBgVerificationPlan,
  displayIdVerificationPrice,
  displayIdVerificationPayment,
  successIdPayment,
  setIdVerificationPlan,
  waitingForEvidentResponse,
  displayBgVerificationPrice,
  successBgPayment,
  notifyForBgCheckExpired,
  displayVerificationTerms,
  displayBgVerificationPayment,
  displayIdFreeVerificationPrice,
  startUserBgVerification,
  startUserIdVerification,
  redirectToIdVerification,
  setIdVerificationUiUrl,
  retryLimitExceed
} from '../actions/user-verification.types'
import { omit } from 'lodash'

const DEFAULT_STATE = {}

export default (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case startUserIdVerification:
      return { ...omit(state, ['bgPlan', 'bgVerification']), started: true }
    case startUserBgVerification:
      return { ...omit(state, ['idPlan', 'idVerification']), started: true }
    case resetUserVerification:
      return { started: false }
    case setIdVerificationPlan:
      return { ...state, idPlan: payload }
    case displayIdVerificationPrice:
      return { ...state, idVerification: 'priceConfirmation' }
    case displayIdVerificationPayment:
      return { ...state, idVerification: 'payment' }
    case successIdPayment:
      return { ...state, idVerification: 'waitingResults' }
    case displayIdFreeVerificationPrice:
      return { ...state, idVerification: 'freeConfirmation' }
    case retryLimitExceed:
      return { ...state, idVerification: 'retryError' }
    case setIdVerificationUiUrl:
      return { ...state, webUrl: payload }
    case waitingForEvidentResponse:
      return { ...state, [payload]: 'waitingResults' }
    case displayBgVerificationPrice:
      return { ...state, bgVerification: 'priceConfirmation' }
    case redirectToIdVerification:
      return { ...state, bgVerification: 'redirect' }
    case notifyForBgCheckExpired:
      return { ...state, bgVerification: 'expired' }
    case displayVerificationTerms:
      return { ...state, bgVerification: 'TOS' }
    case displayBgVerificationPayment:
      return { ...state, bgVerification: 'payment' }
    case successBgPayment:
      return { ...state, bgVerification: 'waitingResults' }
    case setBgVerificationPlan:
      return { ...state, bgPlan: payload }
    default:
      return state
  }
}
