import {
  fetchingFeaturedListings,
  fetchedFeaturedListings,
  errorFeaturedListings
} from './hot-content.types'
import Listing from '../../listings/model/listing.model'

export const fetchFeaturedListingsAction = () => ({
  type: fetchingFeaturedListings
})
export const fetchedFeaturedListingsAction = payload => ({
  type: fetchedFeaturedListings,
  payload: Listing.createList(payload)
})
export const errorFetchingFeaturedListingsAction = msg => ({
  type: errorFeaturedListings,
  error: msg
})
