import { defineMessages } from 'react-intl'

export default defineMessages({
  price: {
    id: 'payment.promo.code.price',
    defaultMessage: 'Price:'
  },
  free: {
    id: 'verification.promo.code.free',
    defaultMessage: 'Free'
  },
  enterCode: {
    id: 'verification.promo.code.enterCode',
    defaultMessage: 'Enter Code'
  },
  promoCode: {
    id: 'payment.promo.code.promoCode',
    defaultMessage: 'Promo Code:'
  },
  applyCode: {
    id: 'payment.promo.code.applyCode',
    defaultMessage: 'Apply'
  },
  error: {
    id: 'payment.promo.code.error',
    defaultMessage: 'Please enter your code'
  },
  invalidCode: {
    id: 'payment.promo.code.invalidCode',
    defaultMessage: 'Invalid promo code'
  },
  usedCode: {
    id: 'payment.promo.code.used.code',
    defaultMessage: "Sorry, it looks like you've already used this promo code"
  }
})
