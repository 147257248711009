import { get, remove, post, put } from '../../shared/utils/fetch.util'

const isValidInvite = invite =>
  !!invite.listing && !!invite.mover && !!invite.lister

export const getAllBookingInvitesApi = () =>
  get('/v2/booking-invites').then(response => {
    // We need to filter out invites for which mover/lister/listing is/are deleted. TODO: see if this can be done on backend
    return { ...response, data: response.data.filter(isValidInvite) }
  })

export const getBookingInviteApi = id =>
  get(`/v2/booking-invites/${id}`).then(response => {
    // TODO: see if this can be done on backend
    if (!isValidInvite(response.data)) {
      throw new Error('Not found')
    }
    return response
  })

export const cancelBookingInviteApi = bookingInviteId =>
  remove(`/v2/booking-invites/${bookingInviteId}`)

export const sendBookingInvite = (listingId, data) =>
  post(`/v2/listings/${listingId}/booking-invites`, data)

export const acceptBookingInvite = (bookingInviteId, cardId, priceShown) =>
  put(`/v2/booking-invites/${bookingInviteId}`, {
    type: 'acceptance',
    cardId,
    priceShown
  })

export const declineBookingInvite = bookingInviteId =>
  put(`/v2/booking-invites/${bookingInviteId}`, {
    type: 'rejection',
    reason: 'Rejected'
  })
