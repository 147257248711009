import { map, extend, deburr } from 'lodash'
import {
  getListingImages,
  getListingAvailableDate,
  getListingLease,
  getListingLocation,
  getListingRentingPrice,
  getListingAddress,
  transformListingTransportations,
  checkShortTerm
} from '../utils/listing-mapper'
import {
  leaseDurationFormatter,
  moveInDateFormatter,
  genderPrefFormatter,
  agePrefFormatter
} from '../utils/listing-fields.formatter'
import DefaultImage from '../icons/no-image-available.png'

class Listing {
  static createList = list => map(list, item => new Listing(item))

  constructor(model) {    
    this.defaultImage = DefaultImage
    extend(this, model)
    this.availableDate = getListingAvailableDate({ listing: model })
    this.images = getListingImages({ listing: model })
    this.isShortTerm = checkShortTerm({ listing: model })
    this.leaseOptions = getListingLease({ listing: model })
    this.leaseDuration = leaseDurationFormatter(
      this.leaseOptions.min,
      this.leaseOptions.max,
      this.isShortTerm
    )
    this.genderPref = genderPrefFormatter(this.preferredGender)
    this.agePref = agePrefFormatter(this.preferredAge)
    this.moveInDate = moveInDateFormatter(this.availableDate) || 'Unknown'
    this.location = getListingLocation({ listing: model }) || 'Unknown'
    this.rent = getListingRentingPrice({ listing: model })
    if (this.rules) this.rules = this.rules.map(String)
    if (this.amenities) this.amenities = this.amenities.map(String)
    if (this.transportation)
      this.transportation = transformListingTransportations(this.transportation)
    this.address = getListingAddress({ listing: model })
    this.localHref = `/rooms-for-rent/${encodeURI(deburr(this.address.city))}/${
      this._id
    }`
    this.href = window.location.origin + this.localHref    
  }
}

export default Listing
