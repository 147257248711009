import { defineMessages } from 'react-intl';

export default defineMessages({
  readMore: {
    id: 'expandable.message.read.more',
    defaultMessage: 'Read More'
  },
  readLess: {
    id: 'expandable.message.read.less',
    defaultMessage: 'Read Less'
  }
});
