import {
  favoritesSet,
  favoritesReset,
  favoriteSuccess,
  favoriteError,
  unFavoriteSuccess,
  unFavoriteError,
  toggleFavorite
} from './favorites.types';
import { fromPairs, map } from 'lodash';

export const favoriteSuccessAction = id => ({
  type: favoriteSuccess,
  payload: id
});

export const favoriteErrorAction = id => ({
  type: favoriteError,
  payload: id
});

export const unFavoriteSuccessAction = id => ({
  type: unFavoriteSuccess,
  payload: id
});

export const unFavoriteErrorAction = id => ({
  type: unFavoriteError,
  payload: id
});

export const toggle = id => ({
  type: toggleFavorite,
  payload: id
});

export const setFavorites = (user) => ({
  type: favoritesSet,
  payload: fromPairs(map(user.usrFavorites, (fav) => [fav.objectId, true ]))
});

export const resetFavorites = () => ({
  type: favoritesReset
});
