import React, { PureComponent } from 'react';

import { Helmet } from 'react-helmet';

class Seo extends PureComponent {
  render() {
    const { title: theTitle, description, image, statusCode, keywords, canonicalUrl } = this.props;
    return (
      <Helmet>
        <title>{theTitle}</title>
        <meta property="og:title" content={theTitle}/>
        <meta name="description" content={description}/>
        <meta property="og:description" content={description}/>
        {image && <meta property="og:image" content={image}/>}
        <meta name="prerender-status-code" content={statusCode || 200}/>
        <meta name="keywords" content={keywords}/>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} /> }
      </Helmet>
    );
  }
}

export default Seo;
