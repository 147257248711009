import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, Form } from 'redux-form';
import { Lock, Mail } from 'roomi/icons';
import { withStyles, Grid } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RoomiInput, RoomiButton } from 'roomi/material';
import messages from './login-form.text';
import validate from './login-form.validator';
import loginFormStyles from './login-form.styles';

@injectIntl
@reduxForm({
  form: 'loginForm',
  propNamespace: 'loginMeta',
  validate,
  onSubmitFail: ({ _error } = {}, dispatch, sub, { onError }) =>
    _error && onError && onError(_error)
})
@withStyles(loginFormStyles)
class LoginForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  };

  render() {
    const {
      loginMeta: { handleSubmit, submitting, submitFailed },
      onSubmit,
      intl: { formatMessage },
      classes
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={16} direction="column">
          <Grid item className={classes.title}>
            <FormattedMessage {...messages.roomiLogin}>
              {value => <span className="aut-text-copy">{value}</span>}
            </FormattedMessage>
          </Grid>
          <Grid item>
            <Field
              placeholder={formatMessage(messages.email)}
              name="username"
              type="email"
              height={50}
              showError={submitFailed}
              startAdornment={<Mail />}
              component={RoomiInput}
              className={classes.input}
            />
          </Grid>
          <Grid item>
            <Field
              placeholder={formatMessage(messages.password)}
              name="password"
              type="password"
              height={50}
              showError={submitFailed}
              startAdornment={<Lock />}
              component={RoomiInput}
              className={classes.input}
            />
          </Grid>
          <Grid item>
            <RoomiButton
              type="submit"
              primary
              small
              fullWidth
              disabled={submitting}
              label={formatMessage(messages.login)}
              loading={submitting}
              className="aut-button-submit"
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

export default LoginForm;
