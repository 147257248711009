import { get as fetch, post, remove, put } from '../../shared/utils/fetch.util'

export const get = () => fetch('/v2/booking-requests').then(resp => resp.data)
export const cancelBookingRequest = id =>
  remove(`/v2/booking-requests/${id}`).then(resp => resp.data)
export const updateBookingRequestState = ({
  listingId,
  bookingRequestId,
  data
}) =>
  put(
    `/v2/listings/${listingId}/booking-requests/${bookingRequestId}`,
    data
  ).then(resp => resp.data)
export const submitBookingRequest = (listingId, data) =>
  post(`/v2/listings/${listingId}/booking-requests`, data).then(
    resp => resp.data
  )
