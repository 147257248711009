const sidebarToggleStyles = theme => ({
  toggle: {
    position: 'relative',
    cursor: 'pointer'
  },
  badge: {
    position: 'absolute',
    backgroundColor: theme.palette.error.main,
    borderRadius: '50%',
    height: 20,
    width: 20,
    fontSize: 10,
    color: theme.palette.common.white,
    fontWeight: 900,
    textAlign: 'center',
    lineHeight: '16px',
    border: `2px solid ${theme.palette.common.white}`,
    right: -8,
    bottom: -2,
    fontFamily: theme.typography.fontFamily
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary[900]
    }
  }
});

export default sidebarToggleStyles;
