import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import checkMarkImg from 'roomi/icons/checkmark.png';

class SelectedBadge extends PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    return <img className={this.props.className} src={checkMarkImg} {...this.props} alt=""/>;
  }
}

export default SelectedBadge;

