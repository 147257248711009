import { DialogActions, DialogContent, DialogContentText, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { RoomiButton, RoomiDialog } from 'roomi/material';
import confirmationDialogStyles from './confirmation-dialog.component.styles';
import text from './confirmation-dialog.text';

const dialogClasses = {};

@injectIntl
@withStyles(confirmationDialogStyles)
class RoomiConfirmationDialog extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onBankUpdate: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    userId: PropTypes.string
  };

  render() {
    const {
      intl: { formatMessage },
      message = formatMessage(text.message),
      classes,
      cancelText = formatMessage(text.cancelButton),
      confirmText = formatMessage(text.confirmButton),
      onClose,
      onConfirm,
      onBankUpdate,
      userId,
      onCancel,
      loading,
      ...rest
    } = this.props;

    if (dialogClasses.title !== classes.title) dialogClasses.title = classes.title;

    return (
      <RoomiDialog disableBackdropClick onClose={onClose} dialogClasses={dialogClasses} {...rest}>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          {onCancel && (
            <RoomiButton
              className={classes.cancelBtn}
              secondary
              outline
              small
              onClick={onCancel}
              label={cancelText}
            />
          )}
          {onConfirm && (
            <RoomiButton
              primary
              small
              loading={loading}
              disabled={loading}
              onClick={onConfirm}
              label={confirmText}
            />
          )}
          {onBankUpdate && (
            <RoomiButton
              primary
              small
              to={`/roommate/${userId}`}
              loading={loading}
              disabled={loading}
              onClick={onConfirm}
              label={confirmText}
            />
          )}
        </DialogActions>
      </RoomiDialog>
    );
  }
}

export default RoomiConfirmationDialog;
