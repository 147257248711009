import { defineMessages } from 'react-intl'

export default defineMessages({
  company: {
    id: 'footer.company',
    defaultMessage: 'Company'
  },
  about: {
    id: 'footer.company.about',
    defaultMessage: 'About'
  },
  team: {
    id: 'footer.company.team',
    defaultMessage: 'Team'
  },
  hiring: {
    id: 'footer.company.hiring',
    defaultMessage: 'Hiring'
  },
  media: {
    id: 'footer.media',
    defaultMessage: 'Media'
  },
  press: {
    id: 'footer.media.press',
    defaultMessage: 'Press'
  },
  partnerships: {
    id: 'footer.media.partnerships',
    defaultMessage: 'Partnerships'
  },
  blog: {
    id: 'footer.media.blog',
    defaultMessage: 'Blog'
  },
  testimonials: {
    id: 'footer.media.testimonials',
    defaultMessage: 'Testimonials'
  },
  contact: {
    id: 'footer.contact',
    defaultMessage: 'Contact'
  },
  contactSupport: {
    id: 'footer.contact.support',
    defaultMessage: 'Contact Support'
  },
  helpCenter: {
    id: 'footer.contact.help.center',
    defaultMessage: 'Help Center'
  },
  termsPolicies: {
    id: 'footer.contact.termsPolicies',
    defaultMessage: 'Terms and Policies'
  },
  copy: {
    id: 'footer.copy',
    defaultMessage: '©{year} Roomi inc.'
  },
  howItWorks: {
    id: 'footer.howItWorks',
    defaultMessage: 'How It Works'
  },
  spanish: {
    id: 'footer.spanish',
    defaultMessage: 'Spanish'
  },
  english: {
    id: 'footer.english',
    defaultMessage: 'English'
  }
})
