import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'boost.listing.payment.title',
    defaultMessage: 'Payment Details'
  },
  subTitle: {
    id: 'boost.listing.payment.subTitle',
    defaultMessage: 'Select a payment option to boost your listing:'
  },
  newCardTitle: {
    id: 'boost.listing.payment.new.card.title',
    defaultMessage: 'Payment Details'
  },
  newCardSubtitle: {
    id: 'boost.listing.payment.new.card.subTitle',
    defaultMessage: 'Enter the following credit card details for payment:'
  }
});
