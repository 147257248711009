import { createSelector } from 'reselect';
import { reject, includes, filter, size, isEqual, transform, isObject, keys, get, pick, intersection, every } from 'lodash';
import { formValueSelector, getFormInitialValues, getFormMeta, getFormSyncWarnings, getFormValues, isValid } from 'redux-form';
import { FORM_NAME, INSURANCE_NOT_SUPPORTED_COUNTRIES, USER_INFO_FORM_NAME } from '../post/post-listing.config';

const COMPLETED_STATUSES = ['active', 'pending', 'insufficient', 'deactivated'];
const INCOMPLETE_STATUSES = ['insufficient'];
const NON_DRAFT_STATUSES = [...COMPLETED_STATUSES, ...INCOMPLETE_STATUSES];
const WARNING_FIELDS = ['listingAddress', 'region', 'roomRent', 'roomDeposit'];

const difference = (object, base) => {
  const changes = (_object, _base) => transform(_object, (result, value, key) => {
    if (!isEqual(value, _base[key])) {
      result[key] = (isObject(value) && isObject(_base[key])) ? changes(value, _base[key]) : value;
    }
  });
  return changes(object, base);
};

const selector = formValueSelector(FORM_NAME);
const getManageListingState = state => state.manageListing;
export const shortTermSelector = (state, field) => selector(state, field);
export const getListingFieldsSelector = (state, fields) => isObject(fields) ? selector(state, ...fields) : selector(state, fields);
export const stepValidSelector = (state, i) => !size(get(state, `form[${state.manageListing.FORM_NAME}].warning[${i}]`));

export const getManageListingStateSaveSteps = createSelector(
  [getManageListingState], data => data.SAVE_STEPS
);
export const getListingSyncWarning = state => getFormSyncWarnings(FORM_NAME)(state);
export const isIncompleteSelector = createSelector(
  [getListingSyncWarning], warnings => !!size(warnings)
);
export const wasIncompleteSelector = state => selector(state, 'status') === 'incomplete';
export const wasCompleteSelector = state => includes(['pending', 'active'], selector(state, 'status'));
export const noLimitSelector = (state, limit) => selector(state, 'roomLeaseMaxDuration') === limit;
export const hideImportSelector = state => includes(NON_DRAFT_STATUSES, selector(state, 'status'));
export const showInsuranceSelector = state => every([
  !selector(state, '_id'),
  INSURANCE_NOT_SUPPORTED_COUNTRIES.indexOf(selector(state, 'region.country')) === -1,
  INSURANCE_NOT_SUPPORTED_COUNTRIES.indexOf(get(state, 'config.data.countryCode')) === -1
]);
export const getListingFieldWarning = createSelector(
  [getListingSyncWarning, (s, f) => f], (warnings, field) => get(warnings, `[${field}]`)
);
export const getListingFormMeta = state => getFormMeta(FORM_NAME)(state);
export const getListingFieldMeta = createSelector(
  [getListingFormMeta, (s, f) => f], (meta, field) => get(meta, `[${field}]`)
);
export const fieldMeta = createSelector(
  [getListingFieldMeta, (s, f, m) => m], (meta, m) => get(meta, `[${m}]`)
);
export const imagesToUploadSelector = state => filter(selector(state, 'urlOfListingImages'), 'preview').length;
export const shouldSaveUserDataSelector = state => {
  const initial = getFormInitialValues(USER_INFO_FORM_NAME)(state);
  const actual = getFormValues(USER_INFO_FORM_NAME)(state);
  const isFormValid = isValid(USER_INFO_FORM_NAME)(state);
  return isFormValid && !isEqual(initial, actual);
};
export const getListingChangesSelector = state => {
  const initial = getFormInitialValues(FORM_NAME)(state);
  const actual = getFormValues(FORM_NAME)(state);
  return keys(difference(actual, initial));
};
export const shouldCreateSelector = state => !selector(state, '_id');
export const shouldLinkListing = state => !selector(state, 'userId');
export const isDraftSelector = state => !includes(NON_DRAFT_STATUSES, selector(state, 'status'));

export const getSaveModalStepsSelector = createSelector(
  [getManageListingStateSaveSteps, isDraftSelector, imagesToUploadSelector, shouldSaveUserDataSelector, shouldCreateSelector, shouldLinkListing, getListingChangesSelector],
  (data, isDraft, imagesToUpload, saveUserData, shouldCreate, shouldLink, differences) => {
    if (!isDraft) data = reject(data, { _id: 'creating' });
    if (!imagesToUpload) data = reject(data, { _id: 'uploading' });
    if (!saveUserData) data = reject(data, { _id: 'savingUserData' });
    if (!shouldCreate) data = reject(data, { _id: 'creating' });
    if (!shouldLink) data = reject(data, { _id: 'linking' });
    if (!size(differences) || shouldLink) data = reject(data, { _id: 'savingListingData' });
    return { steps: data, total: !imagesToUpload ? data.length : data.length - 1 };
  }
);

export const displayWarningModalSelector = createSelector(
  [getListingSyncWarning, getListingChangesSelector, (_, s) => s], (warnings, changes, status) => {
    if (status) {
      return !!size(pick(warnings, WARNING_FIELDS));
    } else if (intersection(changes, WARNING_FIELDS).length) { // draft
      return !!size(pick(warnings, WARNING_FIELDS));
    }
    return false;
  }
);

export const getSaveModal = createSelector(
  [getManageListingState], data => data.saveModal
);

export const getSaveListingProgress = createSelector(
  [getSaveModal],
  ({ stepsCompleted, toUpload = 0, totalSteps = 0 }) => {
    const total = totalSteps + toUpload;
    return Math.floor(stepsCompleted / total * 100) || 0;
  }
);
