import {
  each,
  includes,
  forOwn,
  isArray,
  isEmpty,
  isObject,
  size,
  transform,
  filter,
  reduce,
  omitBy,
  isEqual,
  isString,
  omit,
  find,
  pick,
  get,
  castArray
} from 'lodash'
import { multiReplaceInUrlQuery } from 'react-url-query'
import qs from 'querystring'

export const DEFAULT_REGION_NAME = 'New York City'
const durationsMapper = {
  Any: {},
  Short: { minDuration: 1, maxDuration: 3 },
  Medium: { minDuration: 3, maxDuration: 6 },
  Long: { minDuration: 6 }
}

export const analyticsAddress = (address, region) => {
  const query = qs.parse(window.location.search.substring(1))
  return {
    neighborhoodID: get(query, 'neighborhoods[0]'),
    addressCountry: address.country,
    addressRegion: region,
    addressCity: address.city,
    address: address.fullAddress,
    addressState: address.state,
    addressStreet: address.street,
    addressZipCode: address.zipCode,
    addressNeighborhood: address.neighborhood,
    addressNumber: address.houseNumber,
    addressGeopoint: address.geopoint,
    addressCounty: address.county
  }
}

export function formatAddressData(place) {
  if (!place) return null
  const address = {}
  address.fullAddress = place.formatted_address
  each(place.address_components, component => {
    const addressTypes = component.types
    if (includes(addressTypes, 'locality')) {
      address.city = component.long_name
    } else if (includes(addressTypes, 'administrative_area_level_1')) {
      address.state = component.short_name
    } else if (
      includes(addressTypes, 'sublocality_level_1') ||
      includes(addressTypes, 'postal_town')
    ) {
      address.city = component.long_name
      address.neighborhood = component.long_name
    } else if (includes(addressTypes, 'administrative_area_level_2')) {
      address.county = component.short_name
    } else if (includes(addressTypes, 'postal_code')) {
      address.zipCode = component.short_name
    } else if (includes(addressTypes, 'street_number')) {
      address.houseNumber = component.short_name
    } else if (includes(addressTypes, 'route')) {
      address.street = component.long_name
    } else if (includes(addressTypes, 'country')) {
      address.country = component.short_name
    } else if (includes(addressTypes, 'neighborhood')) {
      address.neighborhood = component.long_name
    }
  })

  address.geopoint = { __type: 'GeoPoint' }
  address.geopoint.lat = place.geometry.location.lat()
  address.geopoint.lng = place.geometry.location.lng()
  address.geoCoded = true
  return address
}

const getFilters = _filter => [
  {
    name: 'neighborhoods',
    resettable: true,
    clearable: true,
    value: _filter.neighborhoods,
    newValue: { neighborhoods: [] }
  },
  {
    name: 'cities',
    resettable: true,
    clearable: true,
    value: _filter.cities,
    newValue: { cities: [] }
  },
  {
    name: 'age',
    resettable: true,
    clearable: true,
    value: _filter.age,
    newValue: { age: [] }
  },
  {
    name: 'layouts',
    resettable: true,
    clearable: true,
    value: _filter.layouts,
    newValue: { layouts: [] }
  },
  {
    name: 'totalBedrooms',
    resettable: true,
    clearable: true,
    value: _filter.totalBedrooms,
    newValue: { totalBedrooms: [] }
  },
  {
    name: 'amenities',
    resettable: true,
    clearable: true,
    value: _filter.amenities,
    newValue: { amenities: [] }
  },
  {
    name: 'rules',
    resettable: true,
    clearable: true,
    value: _filter.rules,
    newValue: { rules: [] }
  },
  {
    name: 'allListings',
    resettable: true,
    clearable: true,
    value: _filter.allListings,
    newValue: { allListings: '' }
  },
  {
    name: 'myListings',
    resettable: true,
    clearable: true,
    value: _filter.myListings,
    newValue: { myListings: '' }
  },
  {
    name: 'gender',
    resettable: true,
    clearable: true,
    value: _filter.gender,
    newValue: { gender: 'Either' }
  },
  {
    name: 'moveInDate',
    resettable: false,
    clearable: true,
    value: _filter.moveInDate,
    newValue: { moveInDate: '' }
  },
  {
    name: 'duration',
    resettable: false,
    clearable: true,
    value: _filter.duration,
    newValue: { duration: 'Any' }
  },
  {
    name: 'location',
    resettable: false,
    clearable: true,
    value: _filter.location,
    newValue: { location: '', geo_distance: undefined, geo_box: undefined }
  } // eslint-disable-line
]

export const getResettableFilters = theFilter => {
  const resettableFilters = filter(getFilters(theFilter), 'resettable')
  const [neighborhoods, cities] = resettableFilters
  neighborhoods.value = neighborhoods.value.concat(cities.value)
  neighborhoods.newValue = { ...neighborhoods.newValue, ...cities.newValue }
  resettableFilters.splice(1, 1)
  return resettableFilters
}

export const getChangedFilters = theFilter => {
  const clearableFilters = filter(getFilters(theFilter), 'clearable')
  const values = reduce(
    clearableFilters,
    (agg, el) => {
      agg = { ...agg, ...el.newValue }
      return agg
    },
    {}
  )
  return omitBy(values, (v, k) => {
    if (!theFilter[k]) return true
    return isEqual(theFilter[k], v)
  })
}

export const transformFilterToQuery = ({
  filter: filterObject,
  page,
  pageSize,
  selectedRegion
}) =>
  transform(
    filterObject,
    (obj, value, key) => {
      if (
        (isArray(value) && isEmpty(value)) ||
        (isObject(value) && !size(value)) ||
        key === 'location'
      )
        return
      if (key === 'moveInDate') {
        if (!value) return
        obj.q.availableTo = new Date(value)
      } else if (key === 'region') {
        if (filterObject.geo_distance || filterObject.geo_box) return // eslint-disable-line
        obj.q.region = selectedRegion.shortName
      } else if (key === 'duration')
        forOwn(durationsMapper[value], (v, k) => {
          obj.q[k] = v
        })
      else if (key === 'age') obj.q.agePref = value
      else if (key === 'gender') {
        if (value === 'Either' || !value) return
        obj.q.genderPref = value
      } else obj.q[key] = value
    },
    { page, pageSize, q: {} }
  )

export const addFilterItemsToUrl = state => {
  const page = { page: state.page > 1 ? state.page : '' }
  multiReplaceInUrlQuery({
    ...omit(state.filter, ['geo_distance', 'location', 'geo_box']),
    ...page
  })
}

export function urlToAddress(urlPart) {
  if (!urlPart) return null
  let convertedAddress = ''
  if (isString(urlPart)) {
    convertedAddress = urlPart.replace(/\+/g, ', ')
    convertedAddress = convertedAddress.replace(/-/g, ' ')
    convertedAddress = convertedAddress.replace(/~/g, '-')
  }
  return convertedAddress
}

export function addressToUrl(address) {
  if (!address) return null
  let formattedAddress = ''
  if (isString(address)) {
    formattedAddress = address.replace(/[\[\]\\{}]+/g, '')
    formattedAddress = formattedAddress.replace(/-/g, '~')
    formattedAddress = formattedAddress.replace(/, */g, '+')
    formattedAddress = formattedAddress.replace(/[\/ ]+/g, '-')
  }
  return formattedAddress
}

export const getRegionFromCoordinates = (regions, { lat, lng }) => {
  if (!regions || !lat || !lng) return null
  return find(regions, ({ loc, radius, geometry }) => {
    if (geometry) {
      if (geometry.nw.lat >= lat && geometry.se.lat <= lat) {
        if (
          geometry.nw.lng <= geometry.se.lng &&
          geometry.nw.lng <= lng &&
          geometry.se.lng >= lng
        ) {
          return true
        } else if (
          geometry.nw.lng > geometry.se.lng &&
          (geometry.nw.lng <= lng || lng <= geometry.se.lng)
        ) {
          return true
        }
      }
      return false
    }
    return radius >= distance(lat, lng, loc.lat, loc.lng)
  })
}

function distance(lat1, lon1, lat2, lon2) {
  let R = 6371 // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1)
  let dLon = deg2rad(lon2 - lon1)
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

export function getRegionFromUrl({ region }, regions) {
  return (
    find(regions, region ? { id: region } : { name: DEFAULT_REGION_NAME }) ||
    regions[0]
  )
}

export function getRegionFromUserInterests(
  name = DEFAULT_REGION_NAME,
  regions
) {
  return find(regions, { name }) || regions[0]
}

export const geoCode = (geocoder, query) =>
  new Promise(resolve => geocoder.geocode(query, resolve))

export const geoCodeAddress = (geocoder, query, { searchRadius }, regions) => {
  return geoCode(geocoder, query).then(([result] = []) => {
    let newState = {}
    if (result) {
      const address = formatAddressData(result, query)
      const geo_distance = getGeoQueryFromAddress(address, searchRadius) // eslint-disable-line
      newState = {
        ...newState,
        location: address.fullAddress,
        geo_distance,
        region: getRegionFromCoordinates(regions, geo_distance.loc)
      }
    }
    return newState
  })
}

export const getGeoQueryFromAddress = (address, searchRadius) => {
  if (!address) return null
  let _distance = searchRadius.county
  if (address.city) _distance = searchRadius.city
  if (address.neighborhood) _distance = searchRadius.neighborhood
  if (address.houseNumber) _distance = searchRadius.specificAddress
  return {
    loc: pick(address.geopoint, ['lat', 'lng']),
    distance: _distance
  }
}

export const updatePathname = (oldFilter, newFilter, props) => {
  const newPath = newFilter.location
    ? `/s/${addressToUrl(newFilter.location)}`
    : '/s'
  const oldPath = props.location.pathname
  if (newPath !== oldPath) props.history.replace(newPath)
}

export const initFilter = (urlFilter, geo_distance, location, region, me) => {
  // eslint-disable-line
  const obj = { ...urlFilter }
  if (location) {
    obj.geo_distance = geo_distance // eslint-disable-line
    obj.location = location
  }
  obj.region = region.id
  if (!obj.minPrice) {
    obj.minPrice =
      get(me, 'usrBudget[0]') || get(region, 'limits.rent.min') || 0
  } else obj.minPrice = Number(obj.minPrice)
  if (!obj.maxPrice) {
    obj.maxPrice =
      get(me, 'usrBudget[1]') || get(region, 'limits.rent.max') || 10000
  } else obj.maxPrice = Number(obj.maxPrice)
  if (!obj.duration) {
    obj.duration = 'Any'
  }
  if (!obj.gender) {
    obj.gender = 'Either'
  }
  ;[
    'amenities',
    'rules',
    'age',
    'cities',
    'neighborhoods',
    'layouts',
    'totalBedrooms'
  ].forEach(item => {
    obj[item] = obj[item] ? castArray(obj[item]) : []
  })
  return obj
}
