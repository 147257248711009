import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Hidden } from '@material-ui/core'
import boostListingBannerStyles from './boost-listing-banner.component.styles'
import { ReactComponent as BoostIcon } from '../../assets/boost.svg'
import boostListingBannerText from './boost-listing-banner.text'
import { FormattedMessage } from 'react-intl'
import { RoomiButton } from 'roomi/material'
import BoostListingModal from '../boost-listing-modal/boost-listing-modal.component'
import { injectIntl } from 'react-intl'

@injectIntl
@withStyles(boostListingBannerStyles)
class BoostListingBanner extends PureComponent {
  static propTypes = {
    listings: PropTypes.array,
    userId: PropTypes.string.isRequired,
    plans: PropTypes.array,
    sponsorships: PropTypes.array
  }

  state = { open: this.props.open, hidden: false }

  hideBanner = () => this.setState({ hidden: true })

  openBoostDialog = () => this.setState({ open: true })
  closeBoostDialog = () => this.setState({ open: false })

  render() {
    const {
      listings,
      sponsorships,
      plans,
      userId,
      classes,
      showIcon = true,
      showButtonOnly,
      intl: { formatMessage }
    } = this.props
    const { open, hidden } = this.state

    // TODO: should we have boostable listings selector?
    const idsOfBoostedListings = sponsorships
      .filter(
        sponsorship =>
          sponsorship.entity === 'listing' && sponsorship.status === 'active'
      )
      .map(sponsorship => sponsorship.entityId)
    const boostedListingsMap = idsOfBoostedListings.reduce(
      (map, id) => Object.assign(map, { [id]: true }),
      {}
    )
    const boostableListings = listings.filter(
      listing => !boostedListingsMap[listing._id]
    )

    if (!boostableListings.length || !plans.length || hidden) return null

    return (
      <Fragment>
        {!showButtonOnly}
        <div
          className={!showButtonOnly ? classes.boostBanner : classes.nullClass}
        >
          <div
            className={
              !showButtonOnly
                ? classes.boostBannerContentContainer
                : classes.nullClass
            }
          >
            <div
              className={
                !showButtonOnly || showIcon
                  ? classes.boostBannerContent
                  : classes.nullClass
              }
            >
              <Fragment>
                {showIcon && (
                  <Hidden smDown>
                    <BoostIcon />{' '}
                    {/* TODO: this icon is old one and is bit different than the design */}
                  </Hidden>
                )}
                {!showButtonOnly && (
                  <FormattedMessage {...boostListingBannerText.boostBannerText}>
                    {text => <div className={classes.boostMessage}>{text}</div>}
                  </FormattedMessage>
                )}
              </Fragment>
              <Hidden smDown>
                <RoomiButton
                  className={classes.boostButton}
                  primary
                  small
                  onClick={this.openBoostDialog}
                  label={formatMessage(
                    boostListingBannerText.boostBannerButton
                  )}
                />
              </Hidden>
            </div>
          </div>
          <div className={classes.actions}>
            <Hidden mdUp>
              <RoomiButton
                primary
                small
                onClick={this.openBoostDialog}
                label={formatMessage(
                  boostListingBannerText.boostBannerButtonTabletOrMobile
                )}
              />
            </Hidden>
            {!showButtonOnly && (
              <RoomiButton
                className={classes.closeButton}
                small
                onClick={this.hideBanner}
                label={formatMessage(
                  boostListingBannerText.boostBannerButtonClose
                )}
              />
            )}
          </div>
        </div>
        {open && (
          <BoostListingModal
            boostableListings={boostableListings}
            userId={userId}
            plans={plans}
            open
            onClose={this.closeBoostDialog}
          />
        )}
      </Fragment>
    )
  }
}

export default BoostListingBanner
