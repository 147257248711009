import React, { PureComponent } from 'react'
import { Router } from 'react-router-dom'
import { appStarted } from './shared/actions/react-app.actions'
import { connect } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import v1muitheme from './v1-override'
import ScrollToTop from './shared/components/scroll-to/scroll-to-top.component'
import track from 'react-tracking'
import analytics from './analytics/analytics.handler'
import Layout from './layout.component'
import { RouterToUrlQuery } from 'react-url-query'
import history from './history'
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider'
import MomentUtils from 'material-ui-pickers/utils/moment-utils'
import browserSupported from './browser-support.hoc'
import IntlProvider from './intl.context'

const v1Theme = createMuiTheme(v1muitheme)

@track({}, { dispatch: analytics })
class App extends PureComponent {
  @track({ action: 'APP_LAUNCH' })
  componentDidMount() {
    this.props.appStarted()
  }

  render() {
    return (
      <Router history={history}>
        <RouterToUrlQuery>
          <ScrollToTop>
            <MuiThemeProvider theme={v1Theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider>
                  <Layout />
                </IntlProvider>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </ScrollToTop>
        </RouterToUrlQuery>
      </Router>
    )
  }
}

export default browserSupported(connect(null, { appStarted })(App))
