import facebook from './icons/facebook.png';
import angel from './icons/angel.png';
import instagram from './icons/instagram.png';
import twitter from './icons/twitter.png';
import linkedIn from './icons/linkedin.png';

export const SOCIALS = [
  { icon: angel, name: 'AngelList', href: 'https://bit.ly/roomi-angellist' },
  { icon: instagram, name: 'Instagram', href: 'https://bit.ly/roomi_instagram' },
  { icon: facebook, name: 'Facebook', href: 'https://bit.ly/roomi_facebook' },
  { icon: linkedIn, name: 'LinkedIn', href: 'https://bit.ly/roomi_linkedin' },
  { icon: twitter, name: 'Twitter', href: 'https://bit.ly/roomi_twitter' }
];

export const LINKS = [
  {
    section: 'company',
    links: [
      { name: 'about', to: '/about' },
      { name: 'howItWorks', to: '/how-it-works' }
      // { name: 'team', to: '/team' }
      // { name: 'hiring', to: '/jobs' }
    ]
  }, {
    section: 'media',
    links: [
      { name: 'partnerships', href: 'https://docs.google.com/forms/d/e/1FAIpQLSffxpqiczt1rT_kAXVFznqJyTW6K35Xk8qIUUdf_dyURw0ECA/viewform', rel: 'noopener noreferrer', target: '_blank' },
      // { name: 'press', to: '/press' },
      { name: 'blog', href: 'https://roomiapp.com/blog', rel: 'noopener noreferrer', target: '_blank' },
      { name: 'testimonials', to: '/roomirocks' }
    ]
  }, {
    section: 'contact',
    links: [
      // { name: 'contactSupport', href: '', classes: 'custom-intercom-btn' },
      { name: 'contactSupport', href: 'https://roomiapp.freshdesk.com/support/home', rel: 'noopener noreferrer', target: '_blank' },
      { name: 'helpCenter', href: 'https://roomiapp.freshdesk.com/support/home', rel: 'noopener noreferrer', target: '_blank' },
      { name: 'termsPolicies', to: '/policies' }
    ]
  }
];
