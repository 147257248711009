import { call, takeLatest, all, put, select } from 'redux-saga/effects'
import {
  sendSignUpData,
  sendFbToken,
  verifyPhone,
  sendImages,
  sendUserData,
  verificationComplete,
  afterFbSignUp,
  move,
  sendVerification,
  linkedInConnect
} from './actions.types'
import {
  signUp,
  fbSignUp,
  phoneInfoSubmission,
  phoneInfoVerification,
  imageSubmission,
  userDataSubmission,
  identityVerified
} from '../data/onboarding.data'
import { setAuthToken } from '../../auth/utils/session-manager.util'
import { fetched } from '../../users/actions/user.types'
import { getUser } from '../reducers/user.selector'
import { authenticateUser } from '../../auth/actions/auth.actions'
import { convertBlobsToFiles } from 'roomi/utils/files/file.utils'
import { connectLinkedIn } from '../../socials/data/socials.data'
import { fetchedUser } from '../../users/actions/user.action'
import analytics from '../../analytics/analytics.handler'
import { getUserDetails } from '../../analytics/analytics.utils'

function* signUpUser({ payload }) {
  try {
    const response = yield call(signUp, payload.extendedSignUpValues)
    yield setAuthToken(response.data.token)
    const userId = response.data.user._id
    const updatedUser = yield call(userDataSubmission, {
      userId,
      data: { ...payload.userValues }
    })
    payload.resolve && payload.resolve(updatedUser)
    yield put({ type: fetched, user: updatedUser })
    yield put(authenticateUser(userId))
  } catch (error) {
    payload.reject(error)
  }
}

function* signUpFbUser({ payload }) {
  try {
    const response = yield call(fbSignUp, {
      accessToken: payload.accessToken,
      onboarding: true
    })
    const userId = response.data.user._id
    yield setAuthToken(response.data.token)
    const updatedUser = yield call(userDataSubmission, {
      userId,
      data: { ...payload.userValues }
    })
    yield put({ type: fetched, user: updatedUser })
    analytics({
      action: 'SignUp',
      details: getUserDetails(updatedUser),
      fb: true,
      ga: { category: 'Onboarding' }
    })
    payload.resolve(updatedUser)
    yield put(authenticateUser(userId))
  } catch (error) {
    payload.reject(error)
  }
}

function* handleAfterFbSignUp({ payload }) {
  try {
    const { afterFbSignUpData, user } = payload
    const userId = user._id
    yield put({
      type: sendUserData,
      payload: { userId, data: afterFbSignUpData }
    })
    payload.resolve()
  } catch (error) {
    payload.reject(error)
  }
}

function* sendPhoneVerification({ payload }) {
  try {
    // Initially user has no phone, so phine has to be passed from payload.
    const user = yield select(state => getUser(state))
    const phoneSubmissionData = {
      phone: payload.phone,
      userId: user._id
    }
    yield call(phoneInfoSubmission, phoneSubmissionData)
    if (user.phone !== payload.phone) {
      let updatedUser = { ...user, phoneVerified: false, phone: payload.phone }
      yield put({ type: fetched, user: updatedUser })
    }
    payload.resolve && payload.resolve()
  } catch (error) {
    payload.reject(error)
  }
}

function* phoneVerification({ payload }) {
  try {
    yield call(phoneInfoVerification, payload)
    const user = yield select(state => getUser(state))
    let updatedUser = { ...user, phoneVerified: true }
    yield put({ type: fetched, user: updatedUser })
    payload.resolve()
  } catch (error) {
    payload.reject(error)
  }
}

function* submitImages({ payload }) {
  try {
    const photos = yield call(convertBlobsToFiles, payload.files)
    const user = yield call(imageSubmission, {
      photos,
      userId: payload.userId
    })
    yield put({ type: move, payload: 'forward' })
    yield put({ type: fetched, user })
  } catch (error) {
    console.log(error)
  }
}

function* updateUserData({ payload }) {
  try {
    if (payload.move === null) {
      yield put({ type: move, payload: null })
    }
    const updatedUser = yield call(userDataSubmission, payload)
    yield put({ type: fetched, user: updatedUser })
    payload.resolve && payload.resolve()
  } catch (error) {
    console.log(error, 'userDataError')
  }
}

function* submitVerificationComplete({ payload }) {
  const { userId, accessToken, resolve } = payload
  try {
    yield call(identityVerified, { userId, accessToken })
    resolve()
  } catch (error) {
    // console.log(error);
  }
}

function* handleLinkedInConnect({ payload }) {
  const { code, resolve } = payload
  try {
    const updatedUser = yield call(connectLinkedIn, code, '/onboarding')
    yield put(fetchedUser(updatedUser.data))
    resolve()
  } catch (error) {
    // console.log(error);
  }
}

function* watchOnboardingActions() {
  try {
    yield takeLatest(linkedInConnect, handleLinkedInConnect)
    yield takeLatest(sendSignUpData, signUpUser)
    yield takeLatest(sendFbToken, signUpFbUser)
    yield takeLatest(afterFbSignUp, handleAfterFbSignUp)
    yield takeLatest(sendVerification, sendPhoneVerification)
    yield takeLatest(verifyPhone, phoneVerification)
    yield takeLatest(sendImages, submitImages)
    yield takeLatest(sendUserData, updateUserData)
    yield takeLatest(verificationComplete, submitVerificationComplete)
  } catch (error) {
    // console.log(error);
  }
}

function* onboardingRedesignSaga() {
  try {
    yield all([watchOnboardingActions()])
  } catch (error) {
    // console.log(error);
  }
}

export default onboardingRedesignSaga
