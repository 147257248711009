import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'mover.modal.title',
    defaultMessage: 'Ready to Move In?'
  },
  sub: {
    id: 'mover.modal.subTitle',
    defaultMessage: 'Request to book a room'
  },
  text: {
    id: 'mover.modal.text',
    defaultMessage: 'Submit a booking request to your favorite place! If your request is accepted, you will pay the 1st month’s rent (and a small service fee) upfront.'
  },
  text2: {
    id: 'mover.modal.text2',
    defaultMessage: 'Roomi will handle the money transfer to protect you from any risky situations.'
  },
  text3: {
    id: 'mover.modal.text3',
    defaultMessage: 'If things don\'t work out, you get your money back in full.'
  },
  close: {
    id: 'mover.modal.close',
    defaultMessage: 'Close'
  }
});
