import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteConfirmationTitle: {
    id: 'payment.credit.card.selector.delete.confirmation.title',
    defaultMessage: 'Delete Card Information'
  },
  deleteConfirmationMessage: {
    id: 'payment.credit.card.selector.delete.confirmation.message',
    defaultMessage: 'Are you sure you want to remove your connected card?'
  }
});
