import { call, put, takeLatest } from 'redux-saga/effects'
import { FETCH_USER_FEEDS } from '../actions/user-feeds.types'
import {
  fetchUserFeedsSuccess,
  fetchUserFeedsError
} from '../actions/user-feeds.actions'
import { get } from '../../shared/utils/fetch.util'

export const getUserFeedsService = () => get(`/v2/feeds/followed/regions`)

function* fetchUserFeedsSaga() {
  try {
    const { data } = yield call(getUserFeedsService)
    yield put(fetchUserFeedsSuccess(data))
  } catch (err) {
    yield put(fetchUserFeedsError(err))
  }
}

export default function*() {
  yield takeLatest(FETCH_USER_FEEDS, fetchUserFeedsSaga)
}
