import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import RoomiCheckbox from 'roomi/material/checkbox/checkbox'
import text from './accept-terms-and-cancelation-policies.component.text'
import RoomiNavLink from '../../components/roomi-nav-link/roomi-nav-link.component'

const termsOfService = (
  <FormattedMessage {...text.termsOfService}>
    {value => (
      <RoomiNavLink to="/policies/terms">
        <u>{value}</u>
      </RoomiNavLink>
    )}
  </FormattedMessage>
)

const cancellationPolicy = (
  <FormattedMessage {...text.cancellationPolicy}>
    {value => (
      <RoomiNavLink to="/policies/cancellation-policy">
        <u>{value}</u>
      </RoomiNavLink>
    )}
  </FormattedMessage>
)

const checkboxLabel = (
  <FormattedMessage
    {...text.checkboxLabel}
    values={{ termsOfService, cancellationPolicy }}
  />
)

class AcceptTermsAndCancellationPolicies extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string
  }

  render() {
    return (
      <div className={this.props.className}>
        <RoomiCheckbox
          checked={this.props.checked}
          label={checkboxLabel}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
        />
      </div>
    )
  }
}

export default AcceptTermsAndCancellationPolicies
