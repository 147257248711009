const addPayoutMethodFormStyles = theme => ({
  title: {
    textAlign: 'center',
    fontSize: 40,
    fontWeight: 700,
    [theme.breakpoints.only('xs')]: {
      fontSize: 25
    },
    marginBottom: theme.spacing.unit * 3
  },
  subtitle: {
    textAlign: 'center'
  },
  spinnerContainer: {
    minHeight: 300
  },
  groupLabel: {
    marginBottom: theme.spacing.unit
  },
  form: {
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing.unit * 2
  },
  countryForm: {
    marginTop: theme.spacing.unit * 3
  },
  actions: {
    marginTop: theme.spacing.unit * 4
  },
  flag: {
    fontSize: 17,
    marginLeft: theme.spacing.unit
  },
  selectMenu: {
    '& $flag': {
      marginLeft: 0
    }
  },
  error: {
    textAlign: 'center',
    color: theme.palette.error.main,
    marginBottom: theme.spacing.unit * 3
  },
  helpContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500
  }
});

export default addPayoutMethodFormStyles;
