import { Grid, withStyles, Zoom } from '@material-ui/core'
import { omit } from 'lodash'
import PropTypes from 'prop-types'
import qs from 'querystring'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { checkMark, errorMark } from 'roomi/icons/index'
import { RoomiButton, RoomiDialog } from 'roomi/material/index'
import linkedinModalStyles from 'socials/linkedin-status/modal/linkedin-modal.styles'
import { fetchedUser } from '../../../../users/actions/user.action'
import { connectLinkedIn } from '../../../data/socials.data'
import linkedInPhone from '../linkedin-phone.png'
import messages from './linkedin-modal.text'

@injectIntl
@connect(
  null,
  { fetchedUser }
)
@withStyles(linkedinModalStyles)
class LinkedinStatusModal extends PureComponent {
  static propTypes = {
    redirectPath: PropTypes.string.isRequired,
    qsName: PropTypes.string.isRequired,
    qsValue: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
  }

  state = {
    status: 'connecting'
  }

  cleanQueryStrings = () => {
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: qs.stringify(
        omit(this.props.history.location.searchParams, [
          this.props.qsName,
          'state'
        ])
      )
    })
  }

  connectLinkedIn = () => {
    if (this.state.status !== 'connecting')
      this.setState({ status: 'connecting' })
    return connectLinkedIn(this.props.qsValue, this.props.redirectPath)
      .then(({ data }) => {
        this.setState({ status: 'success', data })
      })
      .catch(() => {
        this.setState({ status: 'error' })
      })
  }

  handleClose = ({ updateUser } = {}) => {
    this.cleanQueryStrings()
    updateUser && this.props.fetchedUser(this.state.data)
    this.setState({ data: null }, () => this.props.close());
  }

  handleConfirm = () => {
    if (this.state.status === 'error') {
      this.connectLinkedIn()
    } else {
      this.handleClose({ updateUser: true })
    }
  }

  componentDidMount() {
    this.connectLinkedIn()
  }

  render() {
    const {
      classes,
      intl: { formatMessage }
    } = this.props
    const { status } = this.state
    return (
      <RoomiDialog title={formatMessage(messages[`title_${status}`])} open>
        <Grid
          container
          direction="column"
          className={classes.linkedInModal}
          alignItems="center"
        >
          <Grid item className={classes.imageContainer}>
            <Zoom in={status === 'error'}>
              <img src={errorMark} alt="Error" className={classes.statusIcon} />
            </Zoom>
            <Zoom in={status === 'success'}>
              <img
                src={checkMark}
                alt="Success"
                className={classes.statusIcon}
              />
            </Zoom>
            <img
              src={linkedInPhone}
              alt="Linkedin profile connect"
              className={classes.image}
            />
          </Grid>
          <Grid item className={classes.message}>
            <FormattedMessage {...messages[`message_${status}`]} tagName="p" />
          </Grid>
          <Grid item container justify="center" spacing={16}>
            {status === 'error' && (
              <Grid item>
                <RoomiButton
                  secondary
                  outline
                  small
                  label={formatMessage(messages.button_cancel)}
                  onClick={this.handleClose}
                />
              </Grid>
            )}
            <Grid item>
              <RoomiButton
                primary
                small
                label={formatMessage(messages[`button_${status}`])}
                disabled={status === 'connecting'}
                loading={status === 'connecting'}
                onClick={this.handleConfirm}
              />
            </Grid>
          </Grid>
        </Grid>
      </RoomiDialog>
    )
  }
}

export default LinkedinStatusModal
