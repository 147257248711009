import { defineMessages } from 'react-intl';

export default defineMessages({
  aboutQuestTitle: {
    id: 'about.quest.title',
    defaultMessage: 'Which of these words describe you?'
  },
  aboutQuestOptions1: {
    id: 'about.quest.options1',
    defaultMessage: 'ENTREPRENEUR'
  },
  aboutQuestOptions2: {
    id: 'about.quest.options2',
    defaultMessage: 'FOODIE'
  },
  aboutQuestOptions3: {
    id: 'about.quest.options3',
    defaultMessage: 'FITNESS JUNKIE'
  },
  aboutQuestOptions4: {
    id: 'about.quest.options4',
    defaultMessage: 'BOOKWORM'
  },
  aboutQuestOptions5: {
    id: 'about.quest.options5',
    defaultMessage: 'EARLY BIRD'
  },
  aboutQuestOptions6: {
    id: 'about.quest.options6',
    defaultMessage: 'NIGHT OWL'
  },
  aboutQuestOptions7: {
    id: 'about.quest.options7',
    defaultMessage: 'PARTY PERSON'
  },
  aboutQuestOptions8: {
    id: 'about.quest.options8',
    defaultMessage: 'HEALTHY'
  },
  aboutQuestOptions9: {
    id: 'about.quest.options9',
    defaultMessage: 'STUDENT'
  }
});
