import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Tooltip, IconButton } from '@material-ui/core'
import { Trash } from 'roomi/icons'
import creditCardSelectorStyles from './credit-card-selector.component.styles'
import {
  RoomiRadioGroup,
  RoomiRadio,
  RoomiConfirmationDialog
} from 'roomi/material'
import CreditCardItem from '../credit-card-item/credit-card-item.component'
import { injectIntl } from 'react-intl'
import text from './credit-card-selector.text'

@injectIntl
@withStyles(creditCardSelectorStyles)
class CreditCardSelector extends PureComponent {
  static propTypes = {
    paymentAccounts: PropTypes.array.isRequired,
    selectedAccount: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onDelete: PropTypes.func
  }

  state = { confirmationModal: { open: false } }

  askConfirmation = account => {
    this.setState({
      confirmationModal: {
        open: true,
        title: this.props.intl.formatMessage(text.deleteConfirmationTitle),
        message: this.props.intl.formatMessage(text.deleteConfirmationMessage),
        onCancel: this.resetState,
        onConfirm: () => {
          this.resetState()
          this.props.onDelete(account)
        }
      }
    })
  }

  onSelect = (event, cardId) =>
    this.props.onSelect(this.props.paymentAccounts.find(i => i.id === cardId))

  resetState = () => this.setState({ confirmationModal: { open: false } })

  render() {
    const { paymentAccounts, selectedAccount, classes, onDelete } = this.props
    const { confirmationModal } = this.state
    return (
      <Fragment>
        <RoomiConfirmationDialog {...confirmationModal} />
        <RoomiRadioGroup onChange={this.onSelect} value={selectedAccount}>
          {paymentAccounts.map(account => (
            <RoomiRadio
              key={account.id}
              className={classes.radio}
              value={account.id}
              labelClass={classes.radioLabel}
              label={
                <div className={classes.creditCardItemContainer}>
                  <CreditCardItem
                    endingWith={account.endingWith}
                    isActive={selectedAccount === account.id}
                    brand={account.brand}
                    logo={account.logo}
                  />
                  {onDelete && (
                    <Tooltip title="Delete" placement="top">
                      <IconButton
                        onClick={() => this.askConfirmation(account)}
                        className={classes.deleteButton}
                      >
                        <Trash />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              }
            />
          ))}
        </RoomiRadioGroup>
      </Fragment>
    )
  }
}

export default CreditCardSelector
