const creditCardSelectorStyles = theme => ({
  radio: {
    marginBottom: 10,
    marginRight: 0
  },
  radioLabel: {
    paddingLeft: theme.spacing.unit * 3,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing.unit * 2 - 1
    }
  },
  deleteButton: {
    marginLeft: 8
  },
  creditCardItemContainer: {
    display: 'flex'
  }
});

export default creditCardSelectorStyles;
