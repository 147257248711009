import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withWidth } from '@material-ui/core/index';
import planSelectorStyles from './plan-selector.component.styles';
import PricePlan from '../price-plan/price-plan.component';

@withWidth()
@withStyles(planSelectorStyles)
class PlanSelector extends PureComponent {
  static propTypes = {
    plans: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedPlan: PropTypes.object
  };

  render() {
    const { classes, plans, onSelect, selectedPlan } = this.props;

    return (
      <div className={classes.planSelector}>
        {
          plans.map((plan, index) =>
            (<PricePlan
              className={index !== 0 ? classes.plan : ''}
              key={index}
              plan={plan}
              onClick={onSelect}
              selected={plan === selectedPlan}
            />)
          )
        }
      </div>
    );
  }
}

export default PlanSelector;
