import { Dialog, DialogTitle, Grid, withMobileDialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { RoomiSpinnerWrapper } from 'roomi/common';
import dialogStyles from './dialog.component.styles';
import messages from './dialog.component.text';

@withMobileDialog({ breakpoint: 'xs' })
@withStyles(dialogStyles)
class RoomiDialog extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    disableBackdropClick: PropTypes.bool,
    dialogClasses: PropTypes.object,
    loading: PropTypes.bool
  };

  render() {
    const {
      title,
      children,
      classes,
      dialogClasses,
      loading,
      onClose,
      ...rest
    } = this.props;

    const allClasses = { ...classes, ...dialogClasses };
    const {
      title: dialogTitle,
      close,
      loadingClass,
      closeContainer,
      ...otherClasses
    } = allClasses;

    return (
      <Dialog classes={otherClasses} {...rest}>
        {onClose && (
          <Grid container justify="flex-end" className={closeContainer}>
            <Grid item className={close} onClick={onClose}>
              <FormattedMessage {...messages.close}>
                {value => <span className="aut-button-close">{value}</span>}
              </FormattedMessage>
            </Grid>
          </Grid>
        )}
        {title && <DialogTitle className={dialogTitle}>{title}</DialogTitle>}
        <RoomiSpinnerWrapper loading={loading} loadingClass={loadingClass}>
          {children}
        </RoomiSpinnerWrapper>
      </Dialog>
    );
  }
}

export default RoomiDialog;
