import React, { PureComponent, Fragment } from 'react'
import { roomiBlackLogo } from 'roomi/icons/index'
import PropTypes from 'prop-types'
import SideBar from '../components/sidebar/sidebar.component'
import EnsureLogin from '../../auth/components/ensure-login-button/ensure-login-button.component'
import { savePreOnboardingRoute } from '../../auth/actions/onboarding.actions'
import smHeaderStyles from './sm-header.styles'
import { withStyles } from '@material-ui/core'
import messages from '../header.text'
import { FormattedMessage } from 'react-intl'
import track from 'react-tracking'
import { fetchUserListings } from '../../users/actions/user-listings.actions'
import { connect } from 'react-redux'
import {
  getUserListingsSplitByActive,
  hasFetchedUserListings
} from '../../users/reducers/user-listings.selector'
import BoostListingContainer from '../../sponsorships/components/boost-listing-container/boost-listing-container.component'
import { LazyImage } from '../../shared/components/lazy-image/lazy-image.component'
import BoostProfileContainer from '../../sponsorships/components/boost-profile-container/boost-profile-container.component'
import RoomiNavLink from '../../shared/components/roomi-nav-link/roomi-nav-link.component'

const mapStateToProps = state => {
  const [activeListings, inactiveListings] = getUserListingsSplitByActive(
    state,
    'me'
  )
  return {
    activeListings,
    inactiveListings,
    fetched: hasFetchedUserListings(state, 'me'),
    fetchUserListings
  }
}

@withStyles(smHeaderStyles)
@track({})
@connect(mapStateToProps)
class SmallHeader extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    isMover: PropTypes.bool
  }

  @track({ action: 'Click_GetStarted' })
  fireEventAndSaveRoute = () => {
    savePreOnboardingRoute()
  }

  componentDidMount() {
    this.props.fetchUserListings('me')
  }

  render() {
    const { user, classes, activeListings, isMover } = this.props
    return (
      <Fragment>
        <div className={classes.left}>
          <SideBar user={user} />
          <RoomiNavLink to="/">
            <img
              className={classes.banner}
              src={roomiBlackLogo}
              alt="Roomi Banner"
            />
          </RoomiNavLink>
        </div>
        <div className={classes.right}>
          {user && (
            <div className="avatar-container">
              <LazyImage
                crop="faces"
                fit="crop"
                mask={'ellipse'}
                src={user.defaultImage}
                height={34}
                width={34}
              />
            </div>
          )}
          {!user && (
            <RoomiNavLink
              onClick={this.fireEventAndSaveRoute}
              to="/onboarding"
              className={classes.link}
            >
              <FormattedMessage {...messages.signUp} />
            </RoomiNavLink>
          )}
          {!user && (
            <EnsureLogin>
              <a className={classes.link}>
                <FormattedMessage {...messages.logIn} />
              </a>
            </EnsureLogin>
          )}
        </div>
      </Fragment>
    )
  }
}

export default SmallHeader
