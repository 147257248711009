import * as firebase from 'firebase/app';
import 'firebase/messaging';

const {
  FIREBASE_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
} = process.env;

/*
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
*/

export const initializeFirebase = () => {
  firebase.initializeApp({
    messagingSenderId: FIREBASE_SENDER_ID,
    projectId: FIREBASE_PROJECT_ID,
    apiKey: FIREBASE_API_KEY,
    appId: FIREBASE_APP_ID,
  });

  if ('serviceWorker' in navigator) {
    const filepath = '/firebase-messaging-sw.js';
    // const filepath = '/service-worker.js';
    navigator.serviceWorker
      .register(filepath)
      .then((registration) => {
        firebase.messaging().useServiceWorker(registration)
      })
      .catch((err) => console.error('Failed to register service worker', err.message));
  }
};

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    window.localStorage.setItem('firebaseToken', token);
    return token;
  } catch (error) {
    console.error(error);
  }
}
