import * as initialState from '../post/post-listing.config';
import { pick } from 'lodash';
import {
  initializingListing,
  startingImageUpload,
  updateProgress,
  saveModalBoot,
  linkingListing,
  savingUserData,
  setModalSteps,
  savingListingData,
  listingSaveFinished,
  resetListingCreation,
  checkingUser,
  importSuccess,
  warningModal
} from '../actions/manage-listing.types';

export default (state = initialState, { type, step, toUpload, meta, totalSteps, action, open, data }) => {
  switch (type) {
    case setModalSteps:
      return { ...state, saveModal: { ...state.saveModal, toUpload, totalSteps } };
    case saveModalBoot:
      return { ...state, saveModal: { ...state.saveModal, message: step.message, open: true } };
    case initializingListing:
    case startingImageUpload:
    case linkingListing:
    case savingUserData:
    case savingListingData:
      return { ...state, saveModal: { ...state.saveModal, message: step.message, enabledLogin: false } };
    case checkingUser:
      return { ...state, saveModal: { ...state.saveModal, message: step.message, enabledLogin: true, data } };
    case updateProgress:
      return { ...state, saveModal: { ...state.saveModal, stepsCompleted: state.saveModal.stepsCompleted + 1 } };
    case listingSaveFinished:
      return {
        ...state,
        saveModal: initialState.saveModal,
        resultModal: { ...state.resultModal, open: true, ...meta }
      };
    case warningModal:
      return { ...state, warningModal: { ...state.warningModal, open } };
    case resetListingCreation:
      return { ...state, ...pick(initialState, ['resultModal', 'saveModal', 'warningModal', 'importModal']) };
    case importSuccess:
      return { ...state, importModal: { ...state.importModal, open } };
    default:
      return state;
  }
};
