import { defineMessages } from 'react-intl';

export default defineMessages({
  boostBannerText: {
    id: 'boost.banner.text',
    defaultMessage: 'Need a roommate fast? Boost your room to the top of the search results!'
  },
  boostBannerButton: {
    id: 'boost.banner.button',
    defaultMessage: 'Boost my listing'
  },
  boostBannerButtonTabletOrMobile: {
    id: 'boost.banner.button.tabletOrMobile',
    defaultMessage: 'Boost'
  },
  boostBannerButtonClose: {
    id: 'boost.banner.button.close',
    defaultMessage: 'Close'
  }
});


