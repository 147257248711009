import { fetchingFeaturedListings, fetchedFeaturedListings, errorFeaturedListings } from '../actions/hot-content.types';

const INITIAL = {
  featuredListings: {}
};

export default (state = INITIAL, { type, payload } = {}) => {
  switch (type) {
  case fetchingFeaturedListings:
    return { ...state, featuredListings: { ...state.featuredListings, fetching: true, fetched: false } };
  case fetchedFeaturedListings:
    return { ...state, featuredListings: { ...state.featuredListings, fetching: false, fetched: true, data: payload } };
  case errorFeaturedListings:
    return { ...INITIAL, featuredListings: { ...INITIAL.featuredListings, fetching: false, fetched: false } };
  default:
    return state;
  }
};

