import { get, find, filter, includes } from 'lodash'
import { createSelector } from 'reselect'

const getOtherPlans = state =>
  get(state, 'config.data.sponsorships.other.plans')
const getListingPlans = state =>
  get(state, 'config.data.sponsorships.listings.plans')
const getRoommatePlans = state =>
  get(state, 'config.data.sponsorships.roommates.plans')
const getProfileBoostPlans = state =>
  get(state, 'config.data.sponsorships.profileBoost.plans')
const getVerificationPlan = state =>
  get(state, 'config.data.sponsorships.identityVerification.plans')
const getCreateListingPlans = state =>
  get(state, 'config.data.sponsorships.createListing.plans')

export const getBackgroundCheckPlan = createSelector(
  [getOtherPlans],
  (plans = []) => find(plans, p => includes(p.options, 'background_check'))
)

export const getActiveBoostPlans = createSelector(
  [getListingPlans],
  (plans = []) => filter(plans, { active: true })
)

export const getActiveRoommatePlans = createSelector(
  [getRoommatePlans],
  (plans = []) => filter(plans, { active: true })
)
export const getActiveProfileBoostPlans = createSelector(
  [getProfileBoostPlans],
  (plans = []) => filter(plans, { active: true })
)

export const getIdentityVerificationPlan = createSelector(
  [getVerificationPlan],
  (plans = []) => find(plans, plan => plan.active === true)
)

export const getActiveCreateListingPlan = createSelector(
  [getCreateListingPlans],
  (plans = []) => filter(plans, { active: true })
)

export const getConfig = state => get(state, 'config.data')
export const getInstantRentingStatus = state =>
  get(state, 'config.data.instantBooking.enabled')
export const paidUserTypesMessaging = state =>
  get(state, 'config.data.roommates.paidForUserTypes')
export const getInstantRentingExpiration = state =>
  parseInt(get(state, 'config.data.instantBooking.timeToExpiry'), 10) / 3600000
export const getReferralOptions = state =>
  get(state, 'config.data.referralSources')
// TODO: see where should we place these selectors
export const getExpiration = state =>
  get(state, 'config.data.applicationSettings.expiration')
export const getChatRequestEnabled = state =>
  get(state, 'config.data.applicationSettings.enabled')
export const getCountryCode = state => get(state, 'config.data.countryCode')
export const getListingTypes = state => get(state, 'config.data.listings')
export const getListingTypesForFiltering = createSelector(
  [getListingTypes],
  types => ({
    layouts: types.layouts,
    totalBedrooms: ['Studio', '1', '2', '3', '4+']
  })
)
