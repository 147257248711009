export const   listingFormSubmitted= 'MANAGE-LISTING-FORM-SUBMIT'
export const   checkIsUserAllowedToPostListing= 'CHECK-USER-ABILITY-TO-POST'
export const   saveModalBoot= 'SAVE-MODAL-BOOTING'
export const   initializingListing= 'INITIALIZING-LISTING'
export const   listingInitialized= 'LISTING-INITIALIZED'
export const   startingImageUpload= 'STARTING-IMAGE-UPLOAD'
export const   checkingUser= 'CHECKING-USER'
export const   imageUploaded= 'LISTING-IMAGE-UPLOADED'
export const   savingUserData= 'SAVING-USER-DATA'
export const   savingListingData= 'SAVING-LISTING-DATA'
export const   linkingListing= 'LINKING-LISTING'
export const   updateProgress= 'UPDATE-PROGRESS'
export const   listingSaveFinished= 'LISTING-SAVE-FINISHED'
export const   postListingCreate= 'POST-LISTING-CREATE'
export const   setModalSteps= 'SET-MODAL-STEPS'
export const   importSuccess= 'IMPORT-SUCCESS'
export const   warningModal= 'DISPLAY-WARNING-MODAL'
export const   userHandledWarning= 'USER-HANDLED-WARNING'
export const   resetListingCreation= 'RESET-LISTING-CREATION'
