const experimentsManagerStyles = theme => ({
  experimentsManager: {
    padding: theme.spacing.unit * 4,
    '& > div': {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.grey[200]}`
      }
    }
  }
});

export default experimentsManagerStyles;
