import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'login.modal.form.email',
    defaultMessage: 'Email'
  },
  password: {
    id: 'login.modal.form.password',
    defaultMessage: 'Password'
  },
  login: {
    id: 'login.modal.form.login',
    defaultMessage: 'Login Now'
  },
  forgot: {
    id: 'login.modal.form.forgot',
    defaultMessage: 'Oops! I forgot my password.'
  },
  roomiLogin: {
    id: 'login.modal.form.roomi.login',
    defaultMessage: 'Enter your Roomi Email and Password'
  },
  missingPassword: {
    id: 'login.modal.form.roomi.missingPassword',
    defaultMessage: 'Please enter your password'
  },
  missingEmail: {
    id: 'login.modal.form.roomi.missingEmail',
    defaultMessage: 'Please enter your email'
  },
  invalidEmail: {
    id: 'login.modal.form.roomi.invalidEmail',
    defaultMessage: 'Please enter a valid email'
  }
});
