import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { RoomiDialog } from 'roomi/material'
import DialogContent from '@material-ui/core/DialogContent'
import SelectBoostPlan from '../select-boost-plan/select-boost-plan.component'
import BoostSuccess from '../boost-success/boost-success.component'
import track from 'react-tracking'
import { insertSponsorship } from '../../actions/sponsorships.actions'
import { connect } from 'react-redux'
import text from './boost-profile-modal.text'
import BoostProfilePayment from '../boost-profile-payment/boost-profile-payment.component'

@track({})
@connect(null, { insertSponsorship })
class BoostProfileModal extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    plans: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = { error: null }
  errorRef = React.createRef()

  componentDidUpdate() {
    if (this.state.error) {
      this.errorRef.current.scrollIntoView()
    }
  }

  initialize = () => {
    const { plans } = this.props
    this.setState({
      step: 0,
      selectedPlan: plans.find(plan => plan.default) || plans[0]
    })
  }

  planSelected = selectedPlan => {
    this.props.tracking.trackEvent({
      action: 'Sponsorship_Profile_PlanSelected',
      details: { planId: selectedPlan.id }
    })
    this.setState({ selectedPlan })
    this.goToNextStep()
  }

  goToNextStep = () => {
    this.setState({ step: this.state.step + 1 })
  }

  goToPreviousStep = () => {
    this.setState({ step: this.state.step - 1 })
  }

  paymentSuccess = sponsorship => {
    this.setState({ sponsorship })
    this.goToNextStep()
  }

  finish = () => {
    this.props.insertSponsorship(this.state.sponsorship)
    this.props.onClose()
  }

  render() {
    const { user, open, plans, onClose } = this.props
    const { step, selectedPlan } = this.state

    return (
      <RoomiDialog open={open} onEnter={this.initialize}>
        <DialogContent>
          {step === 0 && (
            <SelectBoostPlan
              description={text.description}
              plans={plans}
              selectedPlan={selectedPlan}
              onCancel={onClose}
              onSelect={this.planSelected}
            />
          )}
          {step === 1 && (
            <BoostProfilePayment
              user={user}
              selectedPlan={selectedPlan}
              onSuccess={this.paymentSuccess}
              onBack={this.goToPreviousStep}
            />
          )}
          {step === 2 && (
            <BoostSuccess
              successMessage={text.success}
              descriptionMessage={text.successDescription}
              duration={selectedPlan.duration}
              onClose={this.finish}
            />
          )}
        </DialogContent>
      </RoomiDialog>
    )
  }
}

export default BoostProfileModal
