import React, { createContext, useEffect, useRef, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import localeData from '../data'
import { withRouter } from 'react-router-dom'
import { set, get } from './shared/utils/storage/local-storage.util'

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill')
  require('@formatjs/intl-pluralrules/dist/locale-data/en')
  require('@formatjs/intl-pluralrules/dist/locale-data/es')
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en')
  require('@formatjs/intl-relativetimeformat/dist/locale-data/es')
}

const Context = createContext({})
const esRegex = /^\/es(\/|$)/
export const IntlContext = Context

export default withRouter(function IntlProvider(props) {
  const [language, setLanguage] = useState(() => {
    const storage = get('INTL_L')
    if (window.location.pathname.match(esRegex)) {
      return 'es'
    }
    if (storage && storage.val) {
      return storage && storage.val
    }
    return window.navigator.userLanguage || window.navigator.language || 'en'
  })
  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]
  const currentLanguageRef = useRef(languageWithoutRegionCode)

  const getPath = path => {
    if (!path) {
      return path
    }
    if (path.match(esRegex) && currentLanguageRef.current !== 'es') {
      path = path.replace(esRegex, '/')
    } else if (!path.match(esRegex) && currentLanguageRef.current === 'es') {
      path = '/es' + path
    }
    return path
  }
  const getReplacePath = pathObject => {
    return { ...pathObject, pathname: getPath(pathObject.pathname) }
  }

  useEffect(() => {
    currentLanguageRef.current = languageWithoutRegionCode
  }, [languageWithoutRegionCode])

  useEffect(() => {
    const oldPush = props.history.push
    props.history.push = path => {
      oldPush(getPath(path))
    }
    const oldReplace = props.history.replace
    props.history.replace = path => {
      oldReplace(getReplacePath(path))
    }
  }, [])

  useEffect(() => {
    if (
      (languageWithoutRegionCode === 'es' &&
        !props.location.pathname.match(esRegex)) ||
      (languageWithoutRegionCode !== 'es' &&
        props.location.pathname.match(esRegex))
    ) {
      props.history.replace(props.location)
    }
  }, [languageWithoutRegionCode, props.location])

  const messages =
    localeData[language] ||
    localeData[languageWithoutRegionCode] ||
    localeData.en

  const handleSetLanguage = language => {
    set('INTL_L', language)
    setLanguage(language)
  }
  return (
    <ReactIntlProvider locale={language} messages={messages}>
      <Context.Provider
        value={{
          setLanguage: handleSetLanguage,
          language: languageWithoutRegionCode
        }}
      >
        {props.children}
      </Context.Provider>
    </ReactIntlProvider>
  )
})
