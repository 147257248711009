import { get, put, post, patch } from '../../shared/utils/fetch.util'
import { times } from 'lodash'

const transformUserImage = photos => {
  const form = new FormData()
  times(photos.length, i => {
    form.append('file', photos[i], photos[i].name)
  })
  return form
}

export const getMe = params => get(`/v1/app-users/me`, params)
export const updateMe = (userId, data) =>
  put(`/v1/app-users/${userId}/userData`, data)
export const addImages = (userId, data) =>
  post(`/v1/app-users/${userId}/addImage`, data, {}, transformUserImage)
export const updateNotifications = (userId, data) =>
  patch(`/v2/users/${userId}/notifications/`, data).then(resp => resp.data)
export const getMyListings = params => get('/v1/app-users/me/listings', params)
export const deactivateAccount = userId =>
  post('/v2/events', { type: 'user-deactivated', targetId: userId })
export const changePassword = (userId, data) =>
  post(`/v1/app-users/${userId}/password`, data)
export const getMyListingsV3 = query => fetch('/v3/users/me/listings', query).then((res) => res.data)
