import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ListingCard from '../../../listings/components/listing-card/listing-card.component'
import { withStyles } from '@material-ui/core'
import selectableListingCardStyles from './selectable-listing-card.component.styles'
import SelectedBadge from '../../../shared/common/badges/selected-badge/selected-badge.component'
import Listing from '../../../listings/model/listing.model'

@withStyles(selectableListingCardStyles)
class SelectableListingCard extends PureComponent {
  static propTypes = {
    listing: PropTypes.instanceOf(Listing).isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func
  }

  cardClicked = () => {
    this.props.onClick(this.props.listing)
  }

  render() {
    const { listing, classes, selected } = this.props
    return (
      <div className={classes.boostListingCard} onClick={this.cardClicked}>
        {selected && <SelectedBadge className={classes.selectedBadge} />}
        <ListingCard
          listing={listing}
          slider
          actions={false}
          showOwner={false}
          disableNavigation
        />
      </div>
    )
  }
}

export default SelectableListingCard
