import { createSelector } from 'reselect'

export const getSponsorshipsFromState = state => state.sponsorships.data
export const hasFetchedSponsorships = state => state.sponsorships.fetched
export const hasPaidForBgCheck = createSelector(
  [getSponsorshipsFromState],
  sponsorships => {
    return Boolean(
      sponsorships.find(s => s.status === 'active' && s.entity === 'other')
    )
  }
)
export const hasPaidForMessaging = createSelector(
  [getSponsorshipsFromState],
  sponsorships => {
    return Boolean(
      sponsorships.find(
        s => s.status === 'active' && s.entity === 'moversToLister'
      )
    )
  }
)
export const hasPaidForCreateListing = createSelector(
  [getSponsorshipsFromState],
  sponsorships => {
    return Boolean(
      sponsorships.find(
        s => s.status === 'active' && s.entity === 'createListing'
      )
    )
  }
)

export const hasPaidForProfileBoost = createSelector(
  [getSponsorshipsFromState],
  sponsorships => {
    return Boolean(
      sponsorships.find(s => s.status === 'active' && s.entity === 'profile')
    )
  }
)
