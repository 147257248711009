import { map } from 'lodash';

class Part {
  constructor({ id, body, hasContent, url, mimeType }) {
    const that = this;
    that.id = id;
    that.body =  typeof body === 'string' ? body : null;
    that.hasContent = hasContent;
    that.url = url;
    that.mimeType = mimeType;
  }
}

class Message {
  constructor({ id, sentAt, conversationId, isUnread, sender, unreadCount, parts, recipientStatus }) {
    const that = this;
    that.id = id;
    that.uuid =  id.split('/').pop();
    that.convoId = conversationId.split('/').pop();
    that.sentAt = sentAt;
    that.isUnread = isUnread;
    that.isSender = sender.sessionOwner;
    that.sender = sender;
    that.unreadCount = unreadCount;
    that.parts = map(parts, part => new Part(part));
    that.recipientStatus = recipientStatus;
  }
}

Message.createList = (list) => map(list, item => new Message(item));

export default Message;
