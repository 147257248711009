const pricePlanStyles = theme => ({
  pricePlan: {
    borderRadius: 8,
    width: 130,
    height: 165,
    backgroundColor: theme.palette.secondary[100],
    boxShadow: '0 2px 4px 0 rgba(94,94,94,0.1)',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.only('sm')]: {
      width: 110,
      height: 140
    },
    [theme.breakpoints.only('xs')]: {
      width: 75,
      height: 110
    }
  },
  selected: {
    width: 140,
    height: 185,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.only('sm')]: {
      width: 120,
      height: 160
    },
    [theme.breakpoints.only('xs')]: {
      width: 85,
      height: 125
    }
  },
  duration: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 10,
    textTransform: 'uppercase',
    [theme.breakpoints.only('sm')]: {
      fontSize: 15
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 14
    }
  },
  price: {
    fontSize: 30,
    fontWeight: 700,
    [theme.breakpoints.only('sm')]: {
      fontSize: 25
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 20
    }
  }
});

export default pricePlanStyles;
