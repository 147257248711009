import { spawn } from 'redux-saga/effects'
import favoriteSaga from './favorites/actions/favorites.saga'
import membersSaga from './team/actions/members.saga'
import authSaga from './auth/sagas/auth.saga'
import onboardingSaga from './auth/sagas/onboarding.saga'
import onboardingRedesignSaga from './onboarding/actions/onboarding.saga'
import sharedSagas from './shared/sagas'
import listingSaga from './listings/sagas'
import messagingSaga from './messaging/saga'
import requestsSaga from './requests/sagas'
import usersSaga from './users/sagas'
import importRequestsSaga from './manage-listings/sagas/import-requests.saga'
import sponsorshipSaga from './sponsorships/sagas/sponsorships.saga'
import hotContentSaga from './shared/sagas/hot-content.saga'
import chatRequestsSaga from './chat-requests/chat-requests.saga'
import userVerificationSaga from './verification/saga/user-verification.saga'
import bookingInvitesSaga from './bookings/sagas/booking-invites.saga'
import payoutSaga from './payout/saga/payout.saga'
import roomiPaySaga from './roomipay/sagas/roomipay.saga'

export default function*() {
  yield [
    spawn(membersSaga),
    spawn(favoriteSaga),
    spawn(onboardingSaga),
    spawn(sharedSagas),
    spawn(listingSaga),
    spawn(messagingSaga),
    spawn(usersSaga),
    spawn(requestsSaga),
    spawn(importRequestsSaga),
    spawn(sponsorshipSaga),
    spawn(chatRequestsSaga),
    spawn(hotContentSaga),
    spawn(onboardingRedesignSaga),
    spawn(userVerificationSaga),
    spawn(bookingInvitesSaga),
    spawn(payoutSaga),
    spawn(roomiPaySaga),
    spawn(authSaga) // Keep this saga last to allow other sagas which are waiting for authenticate to be dispatched to subscribe before firing
  ]
}
