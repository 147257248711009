import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { injectIntl } from 'react-intl'
import boostListingPaymentStyles from './boost-listing-payment.component.styles'
import Payment from '../../../payment/components/payment/payment.component'
import { buyListingBoost } from '../../data/sponsorships.data'
import track from 'react-tracking'
import Listing from '../../../listings/model/listing.model'
import messages from './boost-listing-payment.text'

@track({})
@injectIntl
@withStyles(boostListingPaymentStyles)
class BoostListingPayment extends PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    selectedPlan: PropTypes.object.isRequired,
    selectedListing: PropTypes.instanceOf(Listing).isRequired
  }

  state = {}

  @track({ action: 'Sponsorship_Listings_ClickedBoost' })
  buyListingBoost = sourceId => {
    const { selectedPlan, selectedListing, tracking, onSuccess } = this.props
    this.setState({ disabled: true, error: null })

    buyListingBoost(selectedListing._id, {
      sourceId,
      planId: selectedPlan.id
    }).then(
      data => {
        tracking.trackEvent({
          action: 'Sponsorship_Listings_Created',
          details: {
            planId: data.planId,
            listingId: data.entityId,
            price: selectedPlan.price
          },
          fb: true,
          ga: { category: 'Boost Listing', value: selectedPlan.price }
        })
        this.setState({ disabled: false })
        onSuccess(data)
      },
      error => {
        tracking.trackEvent({
          action: 'Sponsorship_Listings_Failed',
          fb: true,
          ga: { category: 'Boost Listing' }
        })
        this.setState({ error, disabled: false })
      }
    )
  }

  render() {
    const {
      userId,
      onBack,
      classes,
      intl: { formatMessage }
    } = this.props
    const { disabled, error } = this.state
    return (
      <div className={classes.container}>
        <Payment
          userId={userId}
          onBack={onBack}
          title={formatMessage(messages.title)}
          subTitle={formatMessage(messages.subTitle)}
          newCardTitle={formatMessage(messages.newCardTitle)}
          newCardSubTitle={formatMessage(messages.newCardSubtitle)}
          onSubmit={this.buyListingBoost}
          isDisabled={disabled}
          error={error}
        />
      </div>
    )
  }
}

export default BoostListingPayment
