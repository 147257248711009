import { call, put, takeLatest, take } from 'redux-saga/effects'
import { authenticate, unAuthenticate } from '../../auth/actions/auth.types'
import { unAuthenticateUser } from '../../auth/actions/auth.actions'
import {
  fetchedUser,
  fetchingUser,
  fetchUserError,
  resetUser
} from '../actions/user.action'
import { fetch } from '../actions/user.types'
import { getMe } from '../data/user.data'
import {
  setFavorites,
  resetFavorites
} from '../../favorites/actions/favorites.actions'
import { setFlags, resetFlags } from '../../flags/actions/flags.actions'

function* resetUserData() {
  yield put(resetFavorites())
  yield put(resetFlags())
  yield put(resetUser())
}

function* fetchMe() {
  try {
    const user = yield call(getMe)
    yield put(fetchedUser(user))
    yield put(setFavorites(user))
    yield put(setFlags(user))
  } catch (e) {
    yield put(fetchUserError(e))
    yield resetUserData()
    yield put(unAuthenticateUser())
  }
}

function* userSaga() {
  while (1) {
    yield take(authenticate)
    yield put(fetchingUser())
    yield fetchMe()
    yield takeLatest(fetch, fetchMe)
    yield take(unAuthenticate)
    yield resetUserData()
  }
}

export default userSaga
