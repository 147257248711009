export const defaultSeo = {
  title: 'Safe & Easy Roommate Finder | Rooms for Rent & Sublets | Roomi',
  description:
    'Instantly post and find available rooms and roommates with ' +
    "Roomi's verified roommate finder. Browse rooms for rent and sublets in your city.",
  keywords:
    'Roommate Finder, Rooms for Rent, sublet apartment, ' +
    'roommate, finder, roomi, rent, sublet, rooms, apartment, flatmate, ' +
    'housemate, habitación, encontrar, room share, roomi, roomie, airbnb, pad',
  statusCode: 200,
  canonicalUrl: 'https://roomiapp.com/'
}
