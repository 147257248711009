import {
  appLaunch,
  appNavigation,
  applicationErrorConfirmed,
  applicationError
} from './react-app.types'

export const appStarted = () => ({ type: appLaunch })
export const navigateApp = location => ({ type: appNavigation, location })
export const globalError = error => ({ type: applicationError, error })
export const confirmedError = () => ({ type: applicationErrorConfirmed })
