import {
  errored,
  fetch,
  fetched,
  reset,
  openChatRequest,
  closeChatRequest,
  sent,
  update,
  editRequest
} from './chat-requests.types'
import up from 'immutability-helper'

const defaultModal = {
  open: false,
  data: {}
}

const defaultState = {
  fetched: false,
  fetching: false,
  modal: defaultModal
}

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case fetch:
      return up(state, { $merge: { fetching: true, fetched: false } })
    case fetched:
      return up(state, {
        $merge: { fetching: false, fetched: true, ...payload }
      })
    case errored:
      return up(state, {
        $set: {
          fetching: false,
          fetched: false,
          error: payload,
          modal: state.modal
        }
      })
    case reset:
      return defaultState
    case openChatRequest:
      const modal = {
        open: true,
        data: payload
      }
      return up(state, { modal: { $set: modal } })
    case closeChatRequest:
      return up(state, { modal: { $set: defaultModal } })
    case update:
      return up(state, { data: { [payload._id]: { $merge: payload } } })
    case sent:
      const id = payload._id
      const info = { [id]: payload }
      return up(state, { data: { $merge: info }, sent: { $push: [id] } })
    case editRequest:
      return up(state, { data: { [payload._id]: { $set: payload } } })
    default:
      return state
  }
}
