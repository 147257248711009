export const fetch = 'FETCHING_CHAT_REQUESTS'
export const fetched = 'FETCHED_CHAT_REQUESTS'
export const errored = 'ERROR_CHAT_REQUESTS'
export const reset = 'RESET_CHAT_REQUESTS'
export const update = 'UPDATED_CHAT_REQUEST'
export const sent = 'SENT_CHAT_REQUEST'
export const openChatRequest = 'OPEN_CHAT_REQUEST_MODAL'
export const closeChatRequest = 'CLOSE_CHAT_REQUEST_MODAL'
export const acceptRequest = 'ACCEPT_CHAT_REQUEST'
export const ignoreRequest = 'IGNORE_CHAT_REQUEST'
export const editRequest = 'EDIT_CHAT_REQUEST'
