import React, { PureComponent } from 'react';
import { FieldArray } from 'redux-form';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from './about-questionnaire.text';
import { withStyles, Grid } from '@material-ui/core';
import aboutQuestionnaireStyles from './about-questionnaire.styles';
import PropTypes from 'prop-types';

@injectIntl
@withStyles(aboutQuestionnaireStyles)
class AboutQuestionnaire extends PureComponent {
  static propTypes = {
    input: PropTypes.object.isRequired,
    sm: PropTypes.number,
    xs: PropTypes.number,
  };

  renderAbout = ({ fields }) => {
    const {
      intl: { formatMessage },
      classes,
      sm = 4,
      xs = 6,
    } = this.props;
    const aboutFields = [
      {
        label: formatMessage(messages.aboutQuestOptions1),
        value: 'entrepreneur',
      },
      { label: formatMessage(messages.aboutQuestOptions2), value: 'foodie' },
      { label: formatMessage(messages.aboutQuestOptions3), value: 'fitness' },
      { label: formatMessage(messages.aboutQuestOptions4), value: 'bookworm' },
      { label: formatMessage(messages.aboutQuestOptions5), value: 'day' },
      { label: formatMessage(messages.aboutQuestOptions6), value: 'night' },
      { label: formatMessage(messages.aboutQuestOptions7), value: 'party' },
      { label: formatMessage(messages.aboutQuestOptions8), value: 'healthy' },
      { label: formatMessage(messages.aboutQuestOptions9), value: 'student' },
    ];

    const existingFields = fields.getAll();
    const toggleFields = (hobby, index) => {
      if (existingFields) {
        existingFields.includes(hobby.value)
          ? fields.remove(existingFields.indexOf(hobby.value))
          : fields.push(aboutFields[index].value);
      } else {
        fields.push(aboutFields[index].value);
      }
    };

    return (
      <Grid container justify="space-around">
        {aboutFields.map((hobby, index) => (
          <Grid
            item
            sm={sm}
            xs={xs}
            className={classes.aboutField}
            key={index}
            onClick={toggleFields.bind(this, hobby, index)}
          >
            <div
              className={classNames(classes.aboutFieldText, {
                [classes.selected]:
                  existingFields &&
                  existingFields.includes(aboutFields[index].value),
              })}
            >
              {aboutFields[index].label}
            </div>
          </Grid>
        ))}
      </Grid>
    );
  };

  render() {
    const { classes, input } = this.props;
    return (
      <div>
        <div className={classes.title}>
          <FormattedMessage {...messages.aboutQuestTitle} />
        </div>
        <FieldArray name="about" component={this.renderAbout} {...input} />
      </div>
    );
  }
}

export default AboutQuestionnaire;
