import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import checkboxStyles from './checkbox.styles';
import classNames from 'classnames';

@withStyles(checkboxStyles)
class RoomiCheckbox extends PureComponent {
  static propTypes = {
    itemClass: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    selectCheckbox: PropTypes.bool,
    labelPlacement: PropTypes.oneOf(['start', 'end']),
    labelClass: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    labelPlacement: 'end',
    color: 'primary',
    input: {}
  };

  preventDefault = e => this.props.selectCheckbox && e.preventDefault();

  render() {
    const {
      classes,
      itemClass,
      label,
      selectCheckbox, // eslint-disable-line
      color,
      input,
      input: { value: _value, disabled },
      value = _value,
      checked: ch = !!_value,
      labelPlacement,
      labelClass,
      ...rest
    } = this.props;

    const { colorPrimary, colorSecondary, checked } = classes;

    return (
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        classes={{
          label: classNames(classes.label, labelClass),
          root: itemClass
        }}
        onClick={this.preventDefault}
        control={
          <Checkbox
            {...rest}
            color={color}
            className={classes.size}
            checked={ch}
            classes={{ colorPrimary, checked, colorSecondary }}
            icon={
              <Icon className={classes.sizeIcon}>check_box_outline_blank</Icon>
            }
            checkedIcon={<Icon className={classes.sizeIcon}>check_box</Icon>}
            {...input}
            value={String(value)}
          />
        }
      />
    );
  }
}

export default RoomiCheckbox;
