import { move, moveAutoForward } from '../actions/actions.types'

const initialState = { move: 'forward', moveAutoForward: true }

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case move:
      return { ...state, move: payload }
    case moveAutoForward:
      return { ...state, moveAutoForward: payload }
    default:
      return state
  }
}
