import React, { Component } from 'react';
import PropTypes from 'prop-types';

const getUnicode = iso => iso.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397));

class FlagIcon extends Component {
  static propTypes = {
    isoCountryCode: PropTypes.string.isRequired,
    className: PropTypes.string
  };
  render() {
    return (
      <span className={this.props.className}>{getUnicode(this.props.isoCountryCode)}</span>
    );
  }
}

export default FlagIcon;
