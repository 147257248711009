import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import buttonToggleItemStyles from 'roomi/material/button-toggle/button-toggle-item/button-toggle-item.styles';

@withStyles(buttonToggleItemStyles)
class ButtonToggleItem extends PureComponent {
  static propTypes = {
    itemClass: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    value: PropTypes.any.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.value);
  };

  render() {
    const { selected, itemClass, classes, label } = this.props;
    return (
      <div
        onClick={this.onClick}
        className={classNames(classes.item, itemClass, { [classes.selected]: selected })}
      >
        {label}
      </div>
    );
  }
}

export default ButtonToggleItem;
