import { defineMessages } from 'react-intl';

export default defineMessages({
  checkboxLabel: {
    id: 'invite.to.book.dialog.terms.of.agreement.checkbox.label',
    defaultMessage: 'I agree to Roomi\'s {termsOfService} and {cancellationPolicy}'
  },
  termsOfService: {
    id: 'invite.to.book.dialog.terms.of.service',
    defaultMessage: 'Terms of Service'
  },
  cancellationPolicy: {
    id: 'invite.to.book.dialog.cancellation.policy',
    defaultMessage: 'Cancellation Policy'
  }
});
