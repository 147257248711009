import { withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { error } from 'roomi/icons';
import browserNotSupportedStyles from './browser-not-supported.styles';
import messages from './browser-not-supported.text';

const BrowserNotSupported = ({ classes }) => {
  return (
    <div className={classes.browserNotSupported}>
      <div className={classes.container}>
        <img
          src={error}
          alt="Not Supported Browser"
          className={classes.errorImage}
        />
        <FormattedMessage {...messages.title}>
          {t => <div className={classes.title}>{t}</div>}
        </FormattedMessage>
        <FormattedMessage {...messages.note}>
          {t => <div className={classes.note}>{t}</div>}
        </FormattedMessage>
      </div>
    </div>
  );
};

export default withStyles(browserNotSupportedStyles)(BrowserNotSupported);
