import { error, fetch, set } from './users.types'

export const setUser = (id, user) => ({
  type: set,
  id,
  user
})

export const fetchUser = id => ({
  type: fetch,
  id
})

export const errorUser = (id, errorMsg) => ({
  type: error,
  id,
  error: errorMsg
})
