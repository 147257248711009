export const sendSignUpData = 'SEND_SIGN_UP_DATA'
export const sendFbToken = 'SEND_FB_TOKEN'
export const afterFbSignUp = 'FB_SIGNUP'
export const sendVerification = 'SEND_VERIFICATION'
export const verifyPhone = 'VERIFY_PHONE'
export const sendImages = 'SEND_IMAGES'
export const sendUserData = 'SEND_USER_DATA'
export const verificationComplete = 'VERIFICATION_COMPLETE'
export const move = 'MOVE'
export const moveAutoForward = 'MOVE_AUTO_FORWARD'
export const linkedInConnect = 'LINKEDIN_CONNECT'
