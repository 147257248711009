import React, { PureComponent } from 'react'
import FavoriteButton from '../../../../favorites/components/favorite-button/favorite-button.component'
import PropTypes from 'prop-types'

class ListingActions extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  render() {
    const { id } = this.props
    return <FavoriteButton id={id} />
  }
}

export default ListingActions
