import { defineMessages } from 'react-intl';

export default defineMessages({
  linkedInModalParagraph: {
    id: 'settings.social.linkedIn.modal.paragraph',
    defaultMessage: 'Are you sure you want to disconnect your LinkedIn account from your Roomi account?'
  },
  linkedInModalTitle: {
    id: 'settings.social.linkedIn.modal.title',
    defaultMessage: 'Disconnecting Your LinkedIn'
  },
  facebookModalParagraph: {
    id: 'settings.social.facebook.modal.paragraph',
    defaultMessage: 'Are you sure you want to disconnect your Facebook account from your Roomi account?'
  },
  facebookModalTitle: {
    id: 'settings.social.facebook.modal.title',
    defaultMessage: 'Disconnecting Your Facebook'
  },
  yes: {
    id: 'settings.social.facebook.modal.yes',
    defaultMessage: 'Yes'
  },
  disconnecting: {
    id: 'settings.social.facebook.modal.disconnecting',
    defaultMessage: 'Disconnecting'
  },
  no: {
    id: 'settings.social.facebook.modal.no',
    defaultMessage: 'No'
  },
  trigger: {
    id: 'settings.social.facebook.modal.trigger',
    defaultMessage: 'Disconnect {type} Profile'
  }
});
