import { defineMessages } from 'react-intl';

export default defineMessages({
  hi: {
    id: 'login.modal.header.hi',
    defaultMessage: 'Hey there. Welcome back!'
  },
  close: {
    id: 'login.modal.header.close',
    defaultMessage: 'Close'
  },
  loginFacebook: {
    id: 'login.modal.buttons.loginFacebook',
    defaultMessage: 'Log in with Facebook'
  },
  loginEmail: {
    id: 'login.modal.buttons.loginEmail',
    defaultMessage: 'Login with Email'
  },
  dontHave: {
    id: 'login.modal.bottom.dontHave',
    defaultMessage: "Don't have an account?"
  },
  signUp: {
    id: 'login.modal.bottom.signUp',
    defaultMessage: 'Sign up here'
  },
  forgot: {
    id: 'login.modal.bottom.forgot',
    defaultMessage: 'Oops! I forgot my password.'
  },
  forgotSuccess: {
    id: 'login.modal.bottom.forgot.success',
    defaultMessage:
      'Password reset request successfully sent. You should receive an email with further instructions.'
  }
});
