import * as React from 'react';
import { lazy, Suspense } from 'react';

export function lazyLoader(importProm) {
  const Component = lazy(importProm);
  return function lazyComponent(props) {
    return (
      <Suspense fallback={<div />}>
        <Component {...props} />
      </Suspense>
    );
  };
}
