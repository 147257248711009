const rangeSliderStyles = theme => ({
  rangeSlider: {
    '& .rc-slider-rail': {
      height: 1
    },
    '& .rc-slider-track': {
      backgroundColor: theme.palette.primary.main,
      height: 2
    }
  },
  handle: {
    border: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    width: 10,
    height: 10,
    marginTop: -4
  }
});

export default rangeSliderStyles;
