import { put, take, all } from 'redux-saga/effects';
import { finish } from '../actions/onboarding.actions.types';
import { authenticate } from '../actions/auth.types';
import {
  getPreOnboardingAction,
  removePreOnboardingAction
} from '../actions/onboarding.actions';

function* runOnboarding() {
  const action = getPreOnboardingAction();
  if (action) {
    removePreOnboardingAction();
    yield put(action);
  }
}

export default function*() {
  yield all([take(authenticate), take(finish)]);
  yield runOnboarding();
}
