import { put, take, call, spawn } from 'redux-saga/effects'
import { authenticate, unAuthenticate } from '../actions/auth.types'
import { fetched } from '../../users/actions/user.types'
import { authenticateUser, userLoggedIn } from '../actions/auth.actions'
import { globalError } from '../../shared/actions/react-app.actions'
import { fetchUserListings } from '../../users/actions/user-listings.actions'
import { loginWithFb } from '../data/auth.data'
import { connectFacebook } from '../../socials/data/socials.data'
import {
  getAuthToken,
  removeAuthToken,
  removeSessionId,
  setAuthToken
} from '../utils/session-manager.util'
import qs from 'querystring'
import jwtDecode from 'jwt-decode'
import { getConfig } from 'roomi/actions/config.actions'
import { actionFetchingRoomiMessages } from '../../messaging/actions/messages.actions'
import {
  fetchNotifications,
  registerDevice
} from '../../messaging/actions/announcement.actions'

function* loginSaga() {
  while (1) {
    const { afterLogin } = yield take(authenticate)
    if (afterLogin) {
      yield take(fetched)
      yield put(userLoggedIn())
      yield put(fetchUserListings('me'))
      yield put(fetchNotifications())
      yield put(actionFetchingRoomiMessages())
      yield put(registerDevice())
    }
    yield put(getConfig())
    yield take(unAuthenticate)
    yield call(removeAuthToken)
    yield call(removeSessionId)
  }
}

function* loginWithToken(token) {
  const { _id } = jwtDecode(token)
  setAuthToken(token)
  yield put(authenticateUser(_id))
}

function* initializeLogin() {
  let token = getAuthToken()
  const query = qs.parse(window.location.search.substring(1))
  const { access_token: accessToken } = qs.parse(
    window.location.hash.substring(1)
  )
  const redirectType = query.redirectType
  delete query.redirectType
  const newQuery = qs.stringify(query)
  window.history.replaceState(
    {},
    document.title,
    window.location.origin +
      window.location.pathname +
      (newQuery ? '?' + newQuery : '')
  )
  if (token) {
    const { _id } = jwtDecode(token)
    setAuthToken(token)
    yield put(authenticateUser(_id))
  } else if (accessToken && redirectType === 'login') {
    try {
      const resp = yield call(loginWithFb, { accessToken })
      yield loginWithToken(resp.token)
    } catch (e) {
      yield put(globalError(e))
    }
  }
  if (accessToken && redirectType === 'connect') {
    try {
      yield call(connectFacebook, accessToken)
    } catch (e) {
      yield put(globalError(e))
    }
  }
}

export default function*() {
  yield [spawn(loginSaga), spawn(initializeLogin)]
}
