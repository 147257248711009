import React, { PureComponent, createRef } from 'react';
import { Popover, withStyles, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
import popoverControllerStyles from './popover-controller.styles';
import classNames from 'classnames';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

@withStyles(popoverControllerStyles)
class PopoverController extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    popoverContent: PropTypes.node.isRequired
  };

  state = { open: false };

  element = createRef();

  togglePopover = () => this.setState({ open: !this.state.open });

  closePopover = (event) => {
    if (!this.element.current.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  render() {
    const { children, popoverContent, className, classes } = this.props;
    const { open } = this.state;
    return (
      <div
        ref={this.element}
        className={classNames(className, { [classes.open]: open })}
        onClick={this.togglePopover}
      >
        {children}
        {
          open && (
            <Popover
              open
              className={classes.popover}
              elevation={2}
              anchorEl={this.element.current}
              classes={{ paper: classes.paper }}
              hideBackdrop
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
            >
              <ClickAwayListener onClickAway={this.closePopover}>
                <div className={classes.body}>
                  {popoverContent}
                </div>
              </ClickAwayListener>
            </Popover>
          )
        }
      </div>
    );
  }
}

export default PopoverController;
