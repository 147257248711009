import { appNavigation, applicationError, applicationErrorConfirmed } from '../actions/react-app.types';
import update from 'immutability-helper';

export default (state = {}, { type, location, error } = {}) => {
  switch (type) {
  case appNavigation:
    return update(state, { location: { $set: location } });
  case applicationError:
    return update(state, { error: { $set: error } });
  case applicationErrorConfirmed:
    return update(state, { $unset: ['error'] });
  default:
    return state;
  }
};

