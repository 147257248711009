import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'lister.modal.title',
    defaultMessage: 'Ready for a Move In?'
  },
  sub: {
    id: 'lister.modal.subTitle',
    defaultMessage: 'Send A booking invite'
  },
  text: {
    id: 'lister.modal.text',
    defaultMessage: 'Go into your messages and send an invitation to your future roommate.  If your booking invite is accepted, Roomi will collect your 1st month\'s rent and securely transfer it to you on your new roommate\'s move-in date.'
  },
  close: {
    id: 'lister.modal.close',
    defaultMessage: 'Close'
  }
});
