import { keyBy, map, sumBy } from 'lodash'
import update from 'immutability-helper'
import {
  conversationsFetched,
  conversationsFetching,
  conversationMoved,
  conversationReset,
  conversationUpdated,
  conversationCreated,
  conversationRemoved,
  conversationRecipientUpdated,
  fetchCurrentChatUserInfo,
  fetchCurrentChatUserInfoSuccess,
  fetchCurrentChatUserInfoError,
  fetchCurrentListingInfo,
  fetchCurrentListingInfoSuccess,
  fetchCurrentListingInfoError,
} from '../actions/conversation.types'

export default function(
  state = {
    chatUserInfo: {},
  },
  {
    type,
    payload,
    conversation,
    conversationUuid,
    conversations,
    recipient,
    userInfo,
    userId,
    listingInfo,
  } = {}
) {
  switch (type) {
    case conversationsFetching:
      return update(state, { $set: { fetched: false, fetching: true } })
    case conversationsFetched:
      return update(state, {
        $set: {
          fetched: true,
          fetching: false,
          data: keyBy(conversations, 'uuid'),
          order: map(conversations, 'uuid'),
          totalUnread: sumBy(conversations, 'unreadCount')
        }
      })
    case conversationUpdated:
      const dataQuery = {}
      dataQuery[conversationUuid] = { $set: conversation }
      const oldConversation1 = state.data[conversationUuid]
      const unreadQuery2 = getUnreadQuery(
        state.totalUnread,
        conversation.unreadCount - oldConversation1.unreadCount
      )
      const changedTotal = update(state, unreadQuery2)
      return update(changedTotal, { data: dataQuery })
    case conversationRemoved:
      const oldConversation2 = state.data[conversationUuid]
      const index = state.order.indexOf(conversationUuid)
      // eslint-disable-next-line no-unused-vars
      const unreadQuery3 = getUnreadQuery(
        state.totalUnread,
        -oldConversation2.unreadCount
      )
      return update(state, {
        data: { $unset: [conversationUuid] },
        order: { $splice: [[index, 1]] }
      })
    case conversationCreated:
      const insert = {}
      insert[conversationUuid] = conversation
      const unreadQuery4 = getUnreadQuery(state.totalUnread, conversation.unreadCount)
      const updatedState = update(state, {
        data: { $merge: insert },
        order: { $unshift: [conversationUuid] }
      })
      return update(updatedState, unreadQuery4)
    case conversationMoved:
      const { id, from, to } = payload
      return update(state, { order: { $splice: [[from, 1], [to, 0, id]] } })
    case conversationReset:
      return update(state, { $set: { fetched: false, fetching: false } })
    case conversationRecipientUpdated:
      return update(state, {
        data: { [conversationUuid]: { $merge: { recipient } } }
      })
    case fetchCurrentChatUserInfo:
      return update(state, {
        $set: {
          fetching: true,
          fetched: false,
        },
      });
    case fetchCurrentChatUserInfoSuccess:
      const chatUserInfo = state.chatUserInfo || {};
      chatUserInfo[userId] = userInfo;
      return update(state, {
        $merge: {
          fetching: false,
          fetched: true,
          chatUserInfo: userInfo,
        },
      });

    case fetchCurrentChatUserInfoError:
      return update(state, {
        $set: {
          fetched: false,
          fetching: false,
        },
      });
    case fetchCurrentListingInfo:
      return update(state, {
        $set: {
          fetched: false,
          fetching: true,
        },
      });
    case fetchCurrentListingInfoSuccess:
      return update(state, {
        $set: {
          fetched: true,
          fetching: false,
          listingInfo,
        },
      });
    case fetchCurrentListingInfoError:
      return update(state, {
        $set: {
          fetched: false,
          fetching: false,
        },
      });
    default:
      return state
  }
}

const getUnreadQuery = (total, change) => ({
  $merge: { totalUnread: total + change }
})
