import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Radio, FormControlLabel, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import radioStyles from './radio.styles';

@withStyles(radioStyles)
class RoomiRadio extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node
    ]),
    labelClass: PropTypes.string,
    itemClass: PropTypes.string
  };

  render() {
    const {
      classes,
      label,
      color = 'primary',
      className,
      itemClass,
      labelClass: labelClassName,
      ...rest
    } = this.props;

    const {
      colorPrimary,
      colorSecondary,
      checked,
      size,
      sizeIcon,
      label: labelClass
    } = classes;

    return (
      <FormControlLabel
        label={label}
        classes={{ label: classNames(labelClass, labelClassName) }}
        className={classNames(className, itemClass)}
        control={
          <Radio
            {...rest}
            color={color}
            className={size}
            classes={{ colorPrimary, colorSecondary, checked }}
            icon={<Icon className={sizeIcon}>radio_button_unchecked</Icon>}
            checkedIcon={<Icon className={sizeIcon}>radio_button_checked</Icon>}
          />
        }
      />
    );
  }
}

export default RoomiRadio;
