import { loadScript } from '../../shared/actions/scripts.action'

export const startFacebookEvents = () => dispatch => {
  initializeFbqObject()
  loadScript('FBQ', 'https://connect.facebook.net/en_US/fbevents.js')(dispatch)
  window.fbq('init', '1625525670995210')
  window.fbq('track', 'PageView')
}

function initializeFbqObject() {
  const f = window
  if (f.fbq) return
  const n = (f.fbq = function() {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  })
  if (!f._fbq) f._fbq = n
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []
}

// FB DOCS Link: https://developers.facebook.com/docs/app-events/getting-started-app-events-web#
// FB Pixel Events Tracking Link: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
// FB Events Debugging Link https://www.facebook.com/analytics/911940955526847/?section=most_recent
export const sendEvent = ({ action, details }) =>
  window.fbq && window.fbq('trackCustom', action, details)
