import { call, put, takeLatest, take, select } from 'redux-saga/effects'
import { favoritesSet, toggleFavorite } from './favorites.types'
import {
  favoriteSuccessAction,
  favoriteErrorAction,
  unFavoriteSuccessAction,
  unFavoriteErrorAction
} from './favorites.actions'
import { favorite, unfavorite } from '../data/favorites.data'
import {
  isFavorited,
  hasFavoritesInitialized
} from '../reducers/favorites.selector'

function* favoriteSaga(id) {
  try {
    yield put(favoriteSuccessAction(id))
    yield call(favorite, id)
  } catch (e) {
    yield put(favoriteErrorAction(id))
  }
}

function* unFavoriteSaga(id) {
  try {
    yield put(unFavoriteSuccessAction(id))
    yield call(unfavorite, id)
  } catch (e) {
    yield put(unFavoriteErrorAction(id))
  }
}

function* toggleFavoritedSaga({ payload: id }) {
  let init = yield select(state => hasFavoritesInitialized(state))
  if (!init) yield take(favoritesSet) // wait for it after login
  let wasFavorited = yield select(state => isFavorited(state, id))
  yield call(wasFavorited ? unFavoriteSaga : favoriteSaga, id)
}

function* favoriteListingSaga() {
  yield takeLatest(toggleFavorite, toggleFavoritedSaga)
}

export default favoriteListingSaga
