import { all, call, put, take, takeLatest, select } from 'redux-saga/effects'
import { spawn } from 'redux-saga/es/effects'
import { authenticate, unAuthenticate } from '../auth/actions/auth.types'
import history from '../history'
import {
  editChatRequest,
  errorChatRequests,
  fetchChatRequestsAction,
  fetchedChatRequests,
  resetChatRequests
} from './chat-requests.actions'

import { fetchingUser } from '../users/actions/user.action'

import { accept, decline, get } from './chat-requests.data'
import { acceptRequest, fetch, ignoreRequest } from './chat-requests.types'
import { getMover2MoverEnabledSelector } from 'roomi/reducers/experiments.selector'

function* fetchChatRequestsSaga({ userId }) {
  try {
    const chatRequests = yield call(get)
    const isSenderEnabled = yield select(getMover2MoverEnabledSelector)
    yield put(fetchedChatRequests(chatRequests, userId, isSenderEnabled))
  } catch (e) {
    yield put(errorChatRequests(e))
  }
}

function* loginChatRequestFlow() {
  while (1) {
    const { userId } = yield take(authenticate)
    yield put(fetchChatRequestsAction(userId))
    yield take(unAuthenticate)
    yield put(resetChatRequests())
  }
}

function* ignoreChatRequest() {
  while (1) {
    try {
      const { payload: chatRequestId } = yield take(ignoreRequest)
      const { application: chatRequest } = yield call(decline, chatRequestId)
      yield put(editChatRequest(chatRequest))
    } catch (e) {
      yield put(errorChatRequests(e))
    }
  }
}

function* acceptChatRequest() {
  while (1) {
    try {
      const { payload: chatRequestId } = yield take(acceptRequest)
      const { application: chatRequest, message } = yield call(
        accept,
        chatRequestId
      )
      yield put(editChatRequest(chatRequest))
      const { channelId } = message
      // yield put(fetchingUser())
      yield call([history, history.push], `/messages?channelId=${channelId}`, {
        channelId
      })
    } catch (e) {
      yield put(errorChatRequests(e))
    }
  }
}

export default function*() {
  yield all([
    spawn(loginChatRequestFlow),
    spawn(ignoreChatRequest),
    spawn(acceptChatRequest),
    yield takeLatest(fetch, fetchChatRequestsSaga)
  ])
}
