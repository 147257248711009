import {
  messagesFetching,
  messagesFetched,
  messagesReset,
  messageInserted,
  messageUpdated,
  initiateChat,
  initiateChatSuccess,
  initiateChatError,
  deleteChat,
  deleteChatSuccess,
  deleteChatError,
  resetDeleteStatus,
  fetchingRoomiMessages,
  fetchedRoomiMessages,
} from '../actions/messages.types'
import { keyBy, each, reverse, last } from 'lodash'
import update from 'immutability-helper'
import moment from 'moment'

const timeLimit = 5 * 60 * 1000

const initialState = { deleting: false, deleted: false };

export default function(state = initialState, { type, payload, conversationId, channelId } = {}) {
  switch (type) {
    case messagesFetching:
      return update(state, {
        $set: { fetching: true, fetched: false, conversationId }
      })
    case messagesFetched:
      return getFetchedState(state, { payload, conversationId })
    case messageInserted:
      if (state.conversationId !== conversationId) return state
      return getInsertedState(state, { payload })
    case messageUpdated:
      return update(state, { data: { $merge: { [payload.uuid]: payload } } })
    case messagesReset:
      return update(state, { $set: { fetching: false, fetched: false } })
    case initiateChat:
      return update(state, { $set: { fetching: true, fetched: false } })
    case initiateChatSuccess:
      return update(state, { $set: { fetching: false, fetched: true } })
    case initiateChatError:
      return update(state, { $set: { fetching: false, fetched: true } })
    case deleteChat:
      return update(state, { $merge: { deleting: true } })
    case deleteChatSuccess:
      return update(state, { $merge: { deleting: false, deleted: true } })
    case deleteChatError:
      return update(state, { $merge: { deleting: false, deleted: false } })
    case resetDeleteStatus:
      return update(state, { $merge: { deleting: false, deleted: false } })
    case fetchingRoomiMessages: 
      return update(state, { $merge: { fetching: true, fetched: false }});
    case fetchedRoomiMessages: 
      return update(state, { $merge: { fetching: false, fetched: true, allMessages: payload }});
    default:
      return state
  }
}

function getFetchedState(state, { payload, conversationId }) {
  let lastMessage
  const group = []
  each(reverse(payload), message => {
    const uuid = message.uuid
    const messageDate = getMessageDate(message)
    const lastGroup = last(group) || {}
    if (lastGroup.date !== messageDate) {
      group.push({ date: messageDate, groupedMessagesInDate: [[uuid]] })
    } else {
      isSameSenderAndInTimeLimit(message, lastMessage)
        ? last(lastGroup.groupedMessagesInDate).push(uuid)
        : lastGroup.groupedMessagesInDate.push([uuid])
    }
    lastMessage = message
  })
  let updateQuery = {
    $set: {
      fetching: false,
      fetched: true,
      conversationId,
      data: keyBy(payload, 'uuid'),
      groupedMessagesByDate: group
    }
  }
  return update(state, updateQuery)
}

function getInsertedState(state, { payload: message }) {
  let groupedOrderQuery
  const groupedMessagesByDate = state.groupedMessagesByDate
  const uuid = message.uuid
  const messageDate = getMessageDate(message)
  const { date, groupedMessagesInDate } = last(groupedMessagesByDate) || {}
  if (messageDate !== date) {
    groupedOrderQuery = {
      $push: [{ date: messageDate, groupedMessagesInDate: [[uuid]] }]
    }
  } else {
    const lastMessage = state.data[last(last(groupedMessagesInDate))]
    groupedOrderQuery = isSameSenderAndInTimeLimit(message, lastMessage)
      ? {
          [groupedMessagesByDate.length - 1]: {
            groupedMessagesInDate: {
              [groupedMessagesInDate.length - 1]: { $push: [uuid] }
            }
          }
        }
      : {
          [groupedMessagesByDate.length - 1]: {
            groupedMessagesInDate: { $push: [[uuid]] }
          }
        }
  }
  let data = { $merge: { [uuid]: message } }
  return update(state, { groupedMessagesByDate: groupedOrderQuery, data })
}

const getMessageDate = message =>
  moment(message.sentAt).calendar(null, {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: 'MMM D, YYYY',
    sameElse: 'MMM D, YYYY'
  })

const isSameSenderAndInTimeLimit = (
  { sender: { id }, sentAt },
  lastMessage
) => {
  return (
    id === lastMessage.sender.id &&
    moment(sentAt).diff(lastMessage.sentAt) < timeLimit
  )
}
