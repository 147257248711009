import { errored, fetching, fetched, reset, update, sent } from '../actions/booking-requests.types';
import up from 'immutability-helper';

export default (state = {}, { type, payload } = {}) => {
  switch (type) {
  case fetching:
    return { fetching: true, fetched: false };
  case fetched:
    return  { ...payload, fetching: false, fetched: true };
  case errored:
    return { fetching: false, fetched: false, error: payload };
  case reset:
    return { fetching: false, fetched: false };
  case update:
    const { id, data } = payload;
    return up(state, { data: { [id]: { $merge: data } } });
  case sent:
    const { data: newBR } = payload;
    const info = { [newBR._id]: newBR };
    return up(state, { data: { $merge: info }, sentIds: { $push: [newBR._id] } });
  default:
    return state;
  }
};

