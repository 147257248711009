import { keys, get } from 'lodash';
import { createSelector } from 'reselect';

const getFavoritesData = state => state.favorites.data;
export const hasFavoritesInitialized = state => state.favorites.initialized;
export const isFavorited = (state, id) => get(state, `favorites.data.${id}`);
export const getFavoritesList = createSelector(
  [getFavoritesData, hasFavoritesInitialized],
  (data, initialized) => initialized && keys(data)
);
