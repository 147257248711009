import {
  configError,
  configFetched,
  configFetching,
  updateExperiments
} from './config.types'
import { get } from '../data/config.data'

export const getConfig = () => dispatch => {
  dispatch(fetchingConfig())
  return get().then(
    config => dispatch(fetchedConfig(config)),
    error => dispatch(errorConfig(error))
  )
}

export const fetchingConfig = () => ({ type: configFetching })
export const fetchedConfig = config => ({
  type: configFetched,
  payload: config
})
export const errorConfig = error => ({ type: configError, payload: error })
export const updateExperimentsAction = experiments => ({
  type: updateExperiments,
  payload: experiments
})
