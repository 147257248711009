const dynamicFormFieldsStyles = theme => ({
  group: {
    '&:not(:first-child)': {
      marginTop: theme.spacing.unit * 3
    }
  },
  groupLabel: {
    marginBottom: theme.spacing.unit
  },
  groupFields: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% + 24px)',
    margin: -12
  },
  field: {
    padding: 12,
    minWidth: 0
  }
});

export default dynamicFormFieldsStyles;
