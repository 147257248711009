import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'shared.pageNotFound.header',
    defaultMessage: 'Aw…fishsticks.'
  },
  topHeader: {
    id: 'shared.pageNotFound.topHeader',
    defaultMessage: 'The page you’re looking for does not exist.'
  },
  bottomHeader: {
    id: 'shared.pageNotFound.bottomHeader',
    defaultMessage: 'Just keep swimming, swimming, swimming.... Oops. We mean just keep searching, searching, searching'
  }
});
