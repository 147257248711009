import {
  setToken,
  getToken,
  removeToken
} from '../../shared/utils/storage/cookies-util';
import { trackSession } from '../../analytics/sift-science/sift-science.actions';

export const authorizationTokenBucket = 'jwt';
export const sessionIdBucket = 'session-id';

if (!getSessionId()) setSessionId(Date.now());

export function getAuthToken() {
  return getToken(authorizationTokenBucket);
}

export function removeAuthToken() {
  if (window.FB && window.FB.logout) window.FB.logout();
  return removeToken(authorizationTokenBucket);
}

export function setAuthToken(token) {
  return setToken(authorizationTokenBucket, token);
}

export function getSessionId() {
  return getToken(sessionIdBucket);
}

export function removeSessionId() {
  return removeToken(sessionIdBucket);
}

export function setSessionId(id) {
  if (getSessionId() === id) return;
  trackSession(id);
  setToken(sessionIdBucket, id);
}
