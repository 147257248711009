export const messagesFetched = 'FETCHED-LAYER-MESSAGES'
export const messagesFetching = 'FETCHING-LAYER-MESSAGES'
export const messageSend = 'SEND-LAYER-MESSAGE'
export const messageInserted = 'INSERTED-LAYER-MESSAGE'
export const messageUpdated = 'UPDATED-LAYER-MESSAGE'
export const messagesReset = 'RESET-LAYER-MESSAGES'

export const fetchingRoomiMessages = 'FETCHING-ROOMI-MESSAGES';
export const fetchedRoomiMessages = 'FETCHED-ROOMI-MESSAGES';
export const clearRoomiMessages = 'CLEAR-ROOMI-MESSAGES';

export const initiateChat = 'INITIATE-DIRECT-CHAT';
export const initiateChatSuccess = 'INITIATE-DIRECT-CHAT-SUCCESS';
export const initiateChatError = 'INITIATE-DIRECT-CHAT-ERROR';

export const deleteChat = 'DELETE-DIRECT-CHAT';
export const deleteChatSuccess = 'DELETE-DIRECT-CHAT-SUCCESS';
export const deleteChatError = 'DELETE-DIRECT-CHAT-ERROR';

export const resetDeleteStatus = 'RESET-DELETE-DIRECT-CHAT';
