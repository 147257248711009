/* eslint-disable consistent-return */
import { call, put, select, take, takeLatest } from 'redux-saga/effects'
import {
  getBackgroundCheckPlan,
  getIdentityVerificationPlan
} from 'roomi/reducers/config.selector'
import {
  getBackgroundCheckEnabled,
  getVerificationEnabled
} from 'roomi/reducers/experiments.selector'
import {
  fetchSponsorships,
  insertSponsorship
} from '../../sponsorships/actions/sponsorships.actions'
import { fetched as sponsorshipsFetched } from '../../sponsorships/actions/sponsorships.types'
import {
  hasPaidForBgCheck,
  hasFetchedSponsorships
} from '../../sponsorships/reducers/sponsorships.selector'
import { fetchedUser, fetchUser } from '../../users/actions/user.action'
import { fetched } from '../../users/actions/user.types'
import {
  bgVerifiedSelector,
  expiredBgVerification,
  bgVerificationInProcess,
  idVerificationInProcess,
  getCurrentUserFromState,
  idVerifiedSelector,
  userExceededVerificationLimit
} from '../../users/reducers/user.selector'
import {
  displayBgVerificationPaymentAction,
  displayIdVerificationPaymentAction,
  displayVerificationTermsAction,
  notifyForBgCheckExpiredAction,
  setBgVerificationPlanAction,
  setIdVerificationPlanAction,
  displayBgVerificationPriceAction,
  displayIdFreeVerificationPriceAction,
  displayIdVerificationPriceAction,
  waitingForEvidentResponseAction,
  redirectToIdVerificationAction,
  startUserIdVerificationAction,
  fetchEvidentRedirectUrlAction,
  evidentVerificationFinishedAction,
  startUserBgVerificationAction,
  setIdVerificationUiUrlAction,
  resetUserVerificationAction,
  retryLimitExceedAction,
  successIdPaymentAction
} from '../actions/user-verification.actions'
import {
  confirmBgRenew,
  confirmBgVerificationPrice,
  confirmIdVerificationPrice,
  evidentNotificationReceived,
  evidentVerificationFinished,
  fetchEvidentRedirectUrl,
  redirectConfirmed,
  startUserBgVerification,
  startUserIdVerification,
  successBgPayment,
  successIdPayment,
  verificationTermsAccepted,
  resetUserVerification,
  setIdVerificationUiUrl
} from '../actions/user-verification.types'
import {
  // getEvidentUiUrl,
  getIdCheckrUiUrl,
  runBackgroundCheck
} from '../data/background-check.data'

const VERIFICATION_CONFIG = {
  isIdCheckFree: false,
  isBgCheckFree: false,
  doingIdCheckAfterBgCheckPrompt: false
}

function* postEvidentNotificationReceivedSaga() {
  yield put(fetchUser())
  const { user } = yield take(fetched)
  yield put(fetchedUser(user))
  yield put(evidentVerificationFinishedAction())
}

// opening blank tab then manually updating location is required because of browser's pop up blocker's
function* fetchEvidentRedirectUrlSaga({ evidentTab }) {
  try {
    const user = yield select(getCurrentUserFromState)
    let webUrl = yield select(s => s.userVerification.webUrl)
    if (!webUrl) {
      const { data } = yield call(getIdCheckrUiUrl, user._id)
      webUrl = data.webUrl
    }
    if (evidentTab) evidentTab.location = webUrl
    yield put(setIdVerificationUiUrlAction(webUrl))
    yield put(fetchUser())
    yield take(fetched)
  } catch (e) {
    console.log('Evident new tab error = ', e) // eslint-disable-line
  }
}

function* verificationPaymentSaga(config) {
  const sponsorshipsAlreadyFetched = yield select(hasFetchedSponsorships)
  if (!sponsorshipsAlreadyFetched) {
    yield put(fetchSponsorships())
    yield take(sponsorshipsFetched)
  }

  const userAlreadyPaId = yield select(hasPaidForBgCheck)
  if (userAlreadyPaId) return

  if (!config.isBgCheckFree) {
    const bgPlan = yield select(getBackgroundCheckPlan)
    yield put(setBgVerificationPlanAction(bgPlan))
    yield put(displayBgVerificationPriceAction())
  }
  yield take(confirmBgVerificationPrice)
  yield put(displayVerificationTermsAction())
  yield take(verificationTermsAccepted)
  yield put(displayBgVerificationPaymentAction())
  yield take(successBgPayment)
  yield put(insertSponsorship({ status: 'active', entity: 'other' }))
}

function* userIdVerificationSaga({ config = VERIFICATION_CONFIG }) {
  try {
    const user = yield select(getCurrentUserFromState)
    const idVerificationEnabled = yield select(getVerificationEnabled)
    const userIdVerified = yield select(idVerifiedSelector)
    let evidentTab

    if (config.doingIdCheckAfterBgCheckPrompt) {
      if (!(yield select(idVerificationInProcess))) {
        yield put(successIdPaymentAction())
        evidentTab = window.open('about:blank', 'evidentTab')
      }

      yield put(fetchEvidentRedirectUrlAction({ evidentTab }))
      yield put(waitingForEvidentResponseAction('idVerification'))
      yield take(setIdVerificationUiUrl) // jumio should be initiated and payment made

      const response = yield call(runBackgroundCheck, user._id)
      yield put(fetchedUser(response.data))
    } else {
      if (!idVerificationEnabled || userIdVerified) {
        return yield put(resetUserVerificationAction())
      }

      if (!(yield select(idVerificationInProcess))) {
        if (yield select(userExceededVerificationLimit)) {
          return yield put(retryLimitExceedAction())
        }
        if (config.isIdCheckFree) {
          yield put(displayIdFreeVerificationPriceAction())
        } else {
          const idPlan = yield select(getIdentityVerificationPlan)
          yield put(setIdVerificationPlanAction(idPlan))
          yield put(displayIdVerificationPriceAction())
        }

        yield take(confirmIdVerificationPrice)
        yield put(displayIdVerificationPaymentAction())
        const action = yield take(successIdPayment)
        evidentTab = action.evidentTab
      }
      yield put(fetchEvidentRedirectUrlAction({ evidentTab }))
      yield put(waitingForEvidentResponseAction('idVerification'))
    }
    const { type } = yield take([
      resetUserVerification,
      evidentVerificationFinished
    ])
    if (
      config.doingIdCheckAfterBgCheckPrompt &&
      type !== resetUserVerification
    ) {
      yield put(startUserBgVerificationAction())
    }
  } catch (e) {
    console.log('ID saga error = ', e) // eslint-disable-line
  }
}

function* userBgVerificationSaga({ config = VERIFICATION_CONFIG }) {
  try {
    const user = yield select(getCurrentUserFromState)
    const bgVerificationEnabled = yield select(getBackgroundCheckEnabled)
    const userIdVerified = yield select(idVerifiedSelector)
    const userBgVerified = yield select(bgVerifiedSelector)

    if (!bgVerificationEnabled || userBgVerified) {
      return yield put(resetUserVerificationAction())
    }

    if (yield select(expiredBgVerification)) {
      yield put(notifyForBgCheckExpiredAction())
      yield take(confirmBgRenew)
    }

    if (!(yield select(bgVerificationInProcess))) {
      yield call(verificationPaymentSaga, config)
    }

    if (!userIdVerified) {
      if (!(yield select(idVerificationInProcess))) {
        yield put(redirectToIdVerificationAction())
        yield take(redirectConfirmed)
      }
      return yield put(
        startUserIdVerificationAction({
          ...config,
          doingIdCheckAfterBgCheckPrompt: true
        })
      )
    }

    if (!(yield select(bgVerificationInProcess))) {
      const response = yield call(runBackgroundCheck, user._id)
      yield put(fetchedUser(response.data))
    }

    yield put(waitingForEvidentResponseAction('bgVerification'))
    yield take(evidentVerificationFinished)
  } catch (e) {
    console.log('BG saga error = ', e) // eslint-disable-line
  }
}

export default function*() {
  yield takeLatest(startUserIdVerification, userIdVerificationSaga)
  yield takeLatest(startUserBgVerification, userBgVerificationSaga)
  yield takeLatest(fetchEvidentRedirectUrl, fetchEvidentRedirectUrlSaga)
  yield takeLatest(
    evidentNotificationReceived,
    postEvidentNotificationReceivedSaga
  )
}
