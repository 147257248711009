import { appLaunch } from './actions/react-app.types'
import { getRegions } from './actions/regions.actions'
import { fetchRules } from './actions/rules.actions'
import { fetchAmenities } from './actions/amenities.actions'
import { startFacebook } from '../socials/actions/facebook.actions'
import { getBadges } from './actions/badges.actions'
import { getConfig } from './actions/config.actions'
import { startFacebookEvents } from '../analytics/facebook-events/facebook-events.actions'
import { startGoogleAnalytics } from '../analytics/google-analytics/google-analytics.actions'
import { startSatismeter } from '../analytics/satismeter/satismeter.actions'
// import { startIntercom } from '../analytics/intercom/intercom.actions';
import { startFullStory } from '../analytics/fullstory/fullstory.actions'
// import { startAppboy } from '../analytics/appboy/appboy.actions'
import { startSiftScience } from '../analytics/sift-science/sift-science.actions'
import { startBranch } from '../analytics/branch/branch.actions'
import { loadPlaid } from '../plaid/plaid.actions'

export const appLaunchMiddleWare = store => next => action => {
  switch (action.type) {
    case appLaunch:
      store.dispatch(startFacebook())
      // store.dispatch(startIntercom());
      store.dispatch(startFacebookEvents())
      store.dispatch(startGoogleAnalytics())
      store.dispatch(startSatismeter())
      store.dispatch(startFullStory())
      // store.dispatch(startAppboy())
      store.dispatch(startSiftScience())
      store.dispatch(startBranch())
      store.dispatch(getRegions())
      store.dispatch(getConfig())
      store.dispatch(getBadges())
      store.dispatch(fetchRules())
      store.dispatch(fetchAmenities())
      store.dispatch(loadPlaid())
      break
    default:
  }
  next(action)
}
