const boostSuccessStyles = theme => ({
  root: {
    textAlign: 'center'
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 40,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      fontSize: 24
    }
  },
  description: {
    marginBottom: 50,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 300,
    textAlign: 'center'
  },
  timeLeftContainer: {
    position: 'relative',
    marginTop: 25,
    marginBottom: 35
  },
  timeLeft: {
    position: 'absolute',
    color: '#FFFFFF',
    fontSize: 25,
    fontWeight: 700,
    lineHeight: '34px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textTransform: 'uppercase',
    [theme.breakpoints.only('xs')]: {
      fontSize: 20
    }
  }
});

export default boostSuccessStyles;
