import { Grid, Icon, withStyles, Zoom } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import promoCodeStyles from './promo-code.component.styles'
import messages from './promo-code.text'
import classNames from 'classnames'
import RoomiInput from 'roomi/material/input/input'
import RoomiSpinner from 'roomi/common/loading-spinner/loading-spinner.component'
import { getPromoCodeAPI } from '../../../promo-code/data/promo-code.data'
import track from 'react-tracking'

@track()
@injectIntl
@withStyles(promoCodeStyles)
class PromoCode extends PureComponent {
  static propTypes = {
    onApply: PropTypes.func,
    scope: PropTypes.string,
    plan: PropTypes.object,
    user: PropTypes.object,
    classes: PropTypes.object
  }

  state = {
    code: ''
  }

  handleCodeUpdate = e => this.setState({ code: e.target.value })

  handleKeyPress = ({ charCode }) => charCode === 13 && this.checkPromoCode()

  checkPromoCode = () => {
    const { intl, plan, user, tracking, scope } = this.props
    if (!this.state.code) {
      return this.setState({
        fetching: false,
        error: intl.formatMessage(messages.error)
      })
    }
    this.setState({ fetching: true })
    const details = {
      userId: user._id,
      usedCode: this.state.code,
      planId: plan.id
    }
    return getPromoCodeAPI(this.state.code)
      .then(data => {
        if (!data.scope[scope] || data.status !== 'active') {
          tracking.trackEvent({
            details,
            action: 'Click_SubmitInvalidPromoCode'
          })
          return this.setState({
            fetching: false,
            error: intl.formatMessage(messages.invalidCode)
          })
        }

        if (
          user.usedPromoCodes &&
          user.usedPromoCodes.find(
            i => i.toLowerCase() === this.state.code.toLowerCase()
          )
        ) {
          return this.setState({
            fetching: false,
            error: intl.formatMessage(messages.usedCode)
          })
        }

        tracking.trackEvent({ details, action: 'Click_ApplyPromo' })
        const reducedPrice =
          plan.price - (plan.price * data.discount.percentage) / 100
        return this.setState(
          {
            reducedPrice,
            fetching: false,
            usedCode: this.state.code,
            error: null
          },
          () => this.props.onApply(this.state.code)
        )
      })
      .catch(error => {
        tracking.trackEvent({
          details,
          action: 'Click_SubmitInvalidPromoCode'
        })
        this.setState({
          error: intl.formatMessage(messages.invalidCode),
          reducedPrice: undefined,
          fetching: false,
          usedCode: this.state.code
        })
      })
  }

  getAdornment = () => {
    if (this.state.fetching) return <RoomiSpinner size={24} />
    if (this.state.reducedPrice) {
      return (
        <Zoom in timeout={{ enter: 500 }}>
          <Icon className={this.props.classes.checkMark}>check_circle</Icon>
        </Zoom>
      )
    }
    if (this.state.error) {
      return (
        <Zoom in timeout={{ enter: 500 }}>
          <Icon className={this.props.classes.errorIcon}>error</Icon>
        </Zoom>
      )
    }
    return <div style={{ height: 24, width: 24 }} />
  }

  render() {
    const {
      classes,
      intl: { formatMessage },
      plan
    } = this.props

    const { code, error, reducedPrice, fetching } = this.state

    const disabled = !code || fetching || reducedPrice !== undefined

    return (
      <Grid item className={classes.promoCodeContainer}>
        <Grid
          container
          direction="column"
          className={classes.promoCode}
          spacing={16}
        >
          <Grid item container justify="space-between">
            <Grid item xs>
              <FormattedMessage {...messages.price} />
            </Grid>
            <Grid item xs="auto">
              <Grid container>
                {reducedPrice > 0 ? (
                  <Zoom in timeout={{ enter: 1000, exit: 0 }}>
                    <Grid item className={classes.appliedCode}>
                      <FormattedNumber
                        value={reducedPrice}
                        style="currency"
                        currency={plan.currency}
                        maximumFractionDigits={2}
                      />
                    </Grid>
                  </Zoom>
                ) : (
                  <Zoom
                    in={reducedPrice === 0}
                    timeout={{ enter: 1000, exit: 0 }}
                  >
                    <Grid item className={classes.appliedCode}>
                      <FormattedMessage {...messages.free} />
                    </Grid>
                  </Zoom>
                )}
                <Grid
                  item
                  className={classNames(classes.price, {
                    [classes.reducedPrice]: reducedPrice !== undefined
                  })}
                >
                  <FormattedNumber
                    value={plan.price}
                    style="currency"
                    currency={plan.currency}
                    maximumFractionDigits={2}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
            spacing={16}
            className={classes.promoWrapper}
          >
            <Grid item xs>
              <FormattedMessage {...messages.promoCode} />
            </Grid>
            <Grid item xs="auto">
              <Grid container wrap="nowrap" spacing={16}>
                <Grid item xs="auto">
                  <FormattedMessage {...messages.applyCode}>
                    {txt => (
                      <div
                        className={classNames(classes.applyCode, {
                          [classes.disabled]: disabled
                        })}
                        onClick={this.checkPromoCode}
                      >
                        {txt}
                      </div>
                    )}
                  </FormattedMessage>
                </Grid>
                <Grid item xs className={classes.inputWrapper}>
                  <RoomiInput
                    input={{
                      value: code,
                      onChange: this.handleCodeUpdate,
                      onKeyPress: this.handleKeyPress
                    }}
                    meta={{ error, touched: !!error, visited: !!error }}
                    placeholder={formatMessage(messages.enterCode)}
                    endAdornment={this.getAdornment()}
                    className={classes.input}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default PromoCode
