import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { RoomiDialog, RoomiButton } from 'roomi/material';
import { DialogContent, withStyles } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './lister-modal.text';
import listerModalStyles from './lister-modal.styles';
import image from '../../assets/topMover.png';

@injectIntl
@withStyles(listerModalStyles)
class ListerModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };

  closeModal = () => this.props.onClose('lister');

  render() {
    const { intl: { formatMessage }, classes } = this.props;
    return (
      <RoomiDialog
        open
        title={formatMessage(messages.title)}
      >
        <DialogContent className={classes.root}>
          <FormattedMessage {...messages.sub}>
            {t => <div className={classes.sub}>{t}</div>}
          </FormattedMessage>
          <div className={classes.image}>
            <img src={image} alt="Lister"/>
          </div>
          <FormattedMessage {...messages.text}>
            {t => <div className={classes.text}>{t}</div>}
          </FormattedMessage>
          <RoomiButton
            className={classes.button}
            small
            primary
            onClick={this.closeModal}
            label={formatMessage(messages.close)}
          />
        </DialogContent>
      </RoomiDialog>
    );
  }
}

export default ListerModal;
