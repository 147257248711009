import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import RoomiDialog from 'roomi/material/dialog/dialog.component'
import AddBankAccountForm from '../../components/add-payout-method-form/add-plaid-bank-account-form.component'
import NewPlaidDialog from '../../../payment/components/new-plaid-account/new-plaid-account.component'

import newBankAccountDialogStyles from './new-bank-account-dialog.styles'
import { withStyles, DialogContent, Grid } from '@material-ui/core'

@injectIntl
@withStyles(newBankAccountDialogStyles)
class NewBankAccountDialog extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    onCardAdded: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  }

  state = { 
    error: null,
    newPlaidDialog: false
  }

  togglePlaidDialog = () =>
    this.setState({ newPlaidDialog: !this.state.newPlaidDialog })

  addPayoutMethod = payoutMethod => {
    this.setState({ error: null })
    this.setState({ formData: payoutMethod });
    this.togglePlaidDialog();
  }

  render() {
    const {
      listingId,
      listingCountry,
      open,
      userId,
      onCancel,
      onCardAdded,
      classes,
    } = this.props
    const { error, newPlaidDialog, formData } = this.state

    return (
      <RoomiDialog open={open} >
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
          >
          <AddBankAccountForm
            listingId={listingId}
            listingCountry={listingCountry}
            onCancel={onCancel}
            onSubmit={this.addPayoutMethod}
            className={classes.root}
            error={error}
          />
          <NewPlaidDialog
            open={newPlaidDialog}
            userId={userId}
            onCardAdded={onCardAdded}
            onCancel={this.togglePlaidDialog}
            formData={formData}
          />
        </Grid>
        </DialogContent>
      </RoomiDialog>
    )
  }
}

export default NewBankAccountDialog
