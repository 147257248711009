import { loadScript } from '../../shared/actions/scripts.action'
import { getUserIdentity } from '../analytics.utils'

export const startFullStory = () => dispatch => {
  initializeFSOject()
  loadScript('FS', 'https://' + window._fs_host + '/s/fs.js')(dispatch)
}

export function identifyUser(user) {
  const displayName = getUserIdentity(user)
  const fsUser = {
    displayName,
    email: user.email
  }
  window.FS.identify(user._id, fsUser)
}

export function initializeFSOject() {
  window._fs_debug = false
  window._fs_host = 'www.fullstory.com'
  window._fs_org = 'Q8DQ'
  const fs = (a, b) => {
    fs.q ? fs.q.push([a, b]) : fs._api(a, b)
  }
  fs.q = []
  fs.identify = (i, v) => {
    fs('user', { uid: i })
    if (v) fs('user', v)
  }
  fs.setUserVars = v => {
    fs('user', v)
  }
  fs.identifyAccount = (i, v) => {
    v = v || {}
    v.acctId = i
    fs('account', v)
  }
  fs.clearUserCookie = (d, i) => {
    d = document.donain
    while (1) {
      document.cookie = 'fs_uid=;domain=' + d + ';path=/;expires=' + new Date(0)
      i = d.indexOf('.')
      if (i < 0) break
      d = d.slice(i + 1)
    }
  }
  window.FS = fs
}
