import dialogStyles from 'roomi/material/dialog/dialog.component.styles';

const newBankAccountDialogStyles = theme => ({
  root: {
    width: '100%',
    padding: '50px'
  },
  title: dialogStyles(theme).title,
  subtitle: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: theme.spacing.unit * 3
  },
  acceptTerms: {
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit * 3
  },
  messages: {
    textAlign: 'center',
    maxWidth: 440,
    marginBottom: theme.spacing.unit * 4
  },
  padBottom: {
    paddingBottom: theme.spacing.unit * 4
  }
});

export default newBankAccountDialogStyles;
