import { regionsError, regionsFetched, regionsFetching } from './regions.types'
import { get } from '../data/regions.data'

export const getRegions = () => dispatch => {
  dispatch(fetchingRegions())
  return get().then(
    regions => dispatch(fetchedRegions(regions)),
    error => dispatch(errorRegions(error))
  )
}

export const fetchingRegions = () => ({ type: regionsFetching })
export const fetchedRegions = regions => ({
  type: regionsFetched,
  payload: regions
})
export const errorRegions = error => ({ type: regionsError, payload: error })
