import React, { PureComponent } from 'react'
import SmallHeader from './small/sm-header.component'
import LargeHeader from './large/lg-header.component'
import OnBoardingHeader from './onboarding/onboarding-header.component'
import { getCurrentUserFromState } from '../users/reducers/user.selector'
import headerStyles from './header.styles'
import { withStyles, Hidden } from '@material-ui/core'
import { connect } from 'react-redux'
import experimentManagerHoc from 'roomi/components/configuration/experiments/experiments-manager/components/experiment-manager-hoc/experiment-manager-hoc' // eslint-disable-line
import { fetchNotifications } from '../messaging/actions/announcement.actions'

const mapActionsToProps = dispatch => ({
  dispatchFetchNotifications: () => dispatch(fetchNotifications())
})

const mapStateToProps = state => ({
  loggedUser: getCurrentUserFromState(state)
})

@experimentManagerHoc
@connect(mapStateToProps, mapActionsToProps)
@withStyles(headerStyles)
class Header extends PureComponent {
  componentDidMount() {
    const { dispatchFetchNotifications } = this.props
    dispatchFetchNotifications()
  }

  render() {
    const { loggedUser, classes } = this.props
    const isMover = loggedUser && loggedUser.usrType === 'mover'

    if (
      loggedUser &&
      loggedUser.onboarding.required &&
      loggedUser.onboarding.state !== 'finished'
    ) {
      return (
        <div className={classes.header}>
          <OnBoardingHeader user={loggedUser} />
        </div>
      )
    }

    return (
      <div className={classes.header}>
        <Hidden mdUp>
          <SmallHeader user={loggedUser} isMover={isMover} />
        </Hidden>
        <Hidden smDown>
          <LargeHeader user={loggedUser} isMover={isMover} />
        </Hidden>
      </div>
    )
  }
}

export default Header
