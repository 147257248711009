const sitemapStyles = theme => ({
  container: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5%',
      '&:not(:last-child)': {
        marginBottom: 40
      }
    }
  },
  title: {
    color: theme.palette.grey[800],
    marginTop: 0,
    marginBottom: theme.spacing.unit,
    fontSize: 18
  },
  list: {
    paddingLeft: 0,
    margin: 0,
    listStyle: 'none'
  },
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
    color: theme.palette.grey[600],
    '&:last-child': {
      paddingBottom: 0
    }
  },
  item: {
    cursor: 'pointer',
    fontWeight: 400,
    '&:hover': {
      color: theme.palette.primary.main
    },
    '&.active span': {
      color: theme.palette.primary.main
    }
  }
});

export default sitemapStyles;
