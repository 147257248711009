import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import selectBoostPlanStyles from './select-boost-plan.component.styles'
import PlanSelector from '../plan-selector/plan-selector.component'
import { RoomiButton } from 'roomi/material'
import { FormattedMessage } from 'react-intl'
import text from './select-boost-plan.text'
import { injectIntl } from 'react-intl'

@injectIntl
@withStyles(selectBoostPlanStyles)
class SelectBoostPlan extends PureComponent {
  static propTypes = {
    plans: PropTypes.array.isRequired,
    selectedPlan: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    description: PropTypes.object
  }

  state = { selectedPlan: this.props.selectedPlan }

  selectPlan = plan => this.setState({ selectedPlan: plan })

  selectBoostPlan = () => this.props.onSelect(this.state.selectedPlan)

  render() {
    const {
      description,
      classes,
      plans,
      onCancel,
      intl: { formatMessage }
    } = this.props
    const { selectedPlan } = this.state
    return (
      <div>
        <div className={classes.title}>
          <FormattedMessage {...text.title} />
        </div>
        <div className={classes.subtitle}>
          <FormattedMessage {...text.subtitle} />
        </div>
        <div className={classes.description}>
          <FormattedMessage {...description} />
        </div>
        <div className={classes.planSelectorContainer}>
          <PlanSelector
            plans={plans}
            onSelect={this.selectPlan}
            selectedPlan={selectedPlan}
          />
        </div>
        <div className={classes.actions}>
          <RoomiButton
            className={classes.cancelBtn}
            outline
            secondary
            small
            onClick={onCancel}
            label={formatMessage(text.cancelButton)}
          />
          <RoomiButton
            primary
            small
            onClick={this.selectBoostPlan}
            label={formatMessage(text.continueButton)}
          />
        </div>
      </div>
    )
  }
}

export default SelectBoostPlan
