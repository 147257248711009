import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { RoomiDialog } from 'roomi/material';
import { LinearProgress, withStyles } from '@material-ui/core';
import saveModalStyles from './save-dialog.styles';
import classNames from 'classnames';

@withStyles(saveModalStyles)
class SaveModal extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string,
    percentage: PropTypes.number,
    displayChildren: PropTypes.bool.isRequired,
    hideStatus: PropTypes.bool.isRequired
  };

  render() {
    const {
      open,
      title,
      children,
      percentage = 0,
      status,
      displayChildren,
      hideStatus,
      classes
    } = this.props;
    return (
      <RoomiDialog
        open={open}
        title={title}
      >
        <div className={classes.body}>
          <div className={classes.progressWrapper}>
            <LinearProgress
              classes={{ root: classes.progressLine }}
              variant="determinate"
              value={percentage}
            />
            <div className={classNames(classes.percentage, { [classes.full]: percentage === 100 })}>{percentage}%</div>
          </div>
          {!hideStatus && <div className={classes.status}>{status}</div>}
          {displayChildren && children && (
            <Fragment>
              {children}
            </Fragment>
          )}
        </div>
      </RoomiDialog>
    );
  }
}

export default SaveModal;
