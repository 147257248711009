import {
  listingFormSubmitted,
  checkIsUserAllowedToPostListing,
  startingImageUpload,
  imageUploaded,
  initializingListing,
  listingInitialized,
  updateProgress,
  saveModalBoot,
  linkingListing,
  listingSaveFinished,
  postListingCreate,
  savingUserData,
  savingListingData,
  setModalSteps,
  checkingUser,
  importSuccess,
  warningModal,
  resetListingCreation,
  userHandledWarning
} from './manage-listing.types'

export const listingFormSubmittedAction = (data, blockRedirect) => ({
  type: listingFormSubmitted,
  blockRedirect,
  data
})

export const checkIsUserAllowedToPostListingAction = () => ({
  type: checkIsUserAllowedToPostListing
})
export const saveModalBootAction = step => ({ type: saveModalBoot, step })
export const startImagesUploadAction = step => ({
  type: startingImageUpload,
  step
})
export const imageUploadedAction = () => ({ type: imageUploaded })

export const checkingUserAction = (step, data) => ({
  type: checkingUser,
  step,
  data
})
export const initializingListingAction = step => ({
  type: initializingListing,
  step
})
export const listingInitializedAction = listing => ({
  type: listingInitialized,
  listing
})
export const linkingListingAction = step => ({ type: linkingListing, step })
export const updateProgressAction = percentage => ({
  type: updateProgress,
  percentage
})
export const listingSaveFinishedAction = meta => ({
  type: listingSaveFinished,
  meta
})
export const postListingCreateAction = action => ({
  type: postListingCreate,
  action
})
export const savingUserDataAction = step => ({ type: savingUserData, step })
export const savingListingDataAction = step => ({
  type: savingListingData,
  step
})
export const setModalStepsAction = (toUpload, totalSteps) => ({
  type: setModalSteps,
  toUpload,
  totalSteps
})
export const toggleImportSuccessModal = open => ({ type: importSuccess, open })
export const toggleWarningModal = open => ({ type: warningModal, open })
export const userHandledWarningAction = fix => ({
  type: userHandledWarning,
  fix
})
export const resetListingCreationAction = () => ({ type: resetListingCreation })
