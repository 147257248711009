import update from 'immutability-helper';
import { resetRoomiPay, setRoomiPay, setInvitation } from '../actions/actions.types';

const initialState = { fetched: false, fetchedInvitation: false };

export default (state = initialState, { type, id, error, payload } = {}) => {
  switch (type) {
    case resetRoomiPay: {
      return initialState 
    }
    case setRoomiPay: {
      return {
        ...state,
        fetched: true,
        data: payload.data
      }
    }
    case setInvitation: {
      return {
        ...state,
        fetchedInvitation: true,
        invitation: payload.invitation
      }
    }
    // case set:
    //   return update(state, {
    //     [id]: {
    //       $set: { fetching: false, fetched: true }
    //     },
    //     data: { $merge: { [id]: user } }
    //   });
    // case errorType:
    //   return update(state, {
    //     [id]: { $set: { fetching: false, fetched: false, error } }
    //   });
    default:
      return state;
  }
};
