const linkedinModalStyles = theme => ({
  linkedInModal: {
    padding: theme.spacing.unit * 4
  },
  image: {
    height: 230,
    marginBottom: theme.spacing.unit * 3
  },
  message: {
    marginBottom: theme.spacing.unit * 5
  },
  imageContainer: {
    position: 'relative'
  },
  statusIcon: {
    position: 'absolute',
    top: -10,
    right: -10,
    borderRadius: '50%',
    height: 30,
    border: `2px solid ${theme.palette.common.white}`
  }
});

export default linkedinModalStyles;
