import {
  fetched,
  remove,
  reset,
  addIncomplete,
  updateIncomplete
} from './incomplete-listings.types'
import Listing from '../model/listing.model'

export const fetchedIncompleteListings = incompleteListings => ({
  type: fetched,
  incompleteListings: Listing.createList(incompleteListings)
})

export const addIncompleteAction = incompleteListing => ({
  type: addIncomplete,
  incompleteListing: new Listing(incompleteListing)
})

export const updateIncompleteAction = incompleteListing => ({
  type: updateIncomplete,
  incompleteListing: new Listing(incompleteListing)
})

export const removeIncompleteListing = id => ({ type: remove, id })
export const resetIncompleteListings = () => ({ type: reset })
