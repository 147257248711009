const headerStyles = theme => ({
  header: {
    minHeight: 52,
    height: 52,
    marginBottom: 2,
    padding: `0 ${theme.spacing.unit * 5}px`,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: theme.typography.fontFamily,
    boxShadow: `0 4px 2px -2px ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing.unit * 3}px`
    }
  }
});

export default headerStyles;

