import { defineMessages } from 'react-intl'

export default defineMessages({
  boostBannerText: {
    id: 'boost.profile-banner.text',
    defaultMessage:
      'Need a room fast? Boost your profile to the top of the search results!'
  },
  boostBannerButton: {
    id: 'boost.profile-banner.button',
    defaultMessage: 'Boost my profile'
  },
  boostBannerButtonTabletOrMobile: {
    id: 'boost.profile-banner.button.tabletOrMobile',
    defaultMessage: 'Boost'
  },
  boostBannerButtonClose: {
    id: 'boost.profile-banner.button.close',
    defaultMessage: 'Close'
  }
})
