const boostListingPaymentStyles = theme => ({
  title: {
    fontSize: 40,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      fontSize: 24
    }
  },
  divider: {
    alignSelf: 'stretch',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 400,
    margin: '0 auto'
  }
})

export default boostListingPaymentStyles
