import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import track from 'react-tracking';
import LinkedinStatusModal from 'socials/linkedin-status/modal/linkedin-modal';
import linkedInStyles from './linkedIn-connect.styles';
import messages from './linkedIn-connect.text';

const linkedInAuthUrl = 'https://www.linkedin.com/oauth/v2/authorization';
const responseType = 'code';
const redirectUri = window.location.origin;

@withRouter
@track({ page: 'Linked-InConnect' })
@withStyles(linkedInStyles)
class LinkedInConnect extends PureComponent {
  static propTypes = {
    isDisabled: PropTypes.bool,
    trigger: PropTypes.func,
    className: PropTypes.string,
    event: PropTypes.object,
    fullWidth: PropTypes.bool,
    redirectPath: PropTypes.string.isRequired,
    showResultInModal: PropTypes.bool.isRequired
  };

  state = {
    open: true
  };

  @track(({ event }) => !!event && event)
  authenticateLinkedIn = () => {
    if (this.props.isDisabled) return;
    const url = `${linkedInAuthUrl}?response_type=${responseType}&client_id=` +
      `${process.env.LINKEDIN_KEY}&redirect_uri=${redirectUri}${this.props.redirectPath}&scope=r_liteprofile r_emailaddress`;
    window.open(url, '_self');
  };

  trigger = () => <FormattedMessage {...messages.label}/>;

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      trigger = this.trigger,
      classes,
      isDisabled,
      className,
      fullWidth,
      redirectPath,
      showResultInModal,
      history,
      location
    } = this.props;
    return (
      <Fragment>
        <div
          onClick={this.authenticateLinkedIn}
          className={classNames(classes.linkedInConnect, className, {
            [classes.disabled]: isDisabled,
            [classes.fullWidth]: fullWidth
          })}
        >
          {trigger()}
        </div>
        {showResultInModal && this.state.open && location.searchParams[responseType] && (
          <LinkedinStatusModal
            close={this.handleClose}
            redirectPath={redirectPath}
            qsName={responseType}
            qsValue={location.searchParams[responseType]}
            history={history}
          />
        )}
      </Fragment>
    );
  }
}

export default LinkedInConnect;
