import { Grid, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Form, reduxForm } from 'redux-form'
import { RoomiSpinner } from 'roomi/common'
import { RoomiButton } from 'roomi/material'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { addPaymentCard } from '../../actions/payment.actions'
import { addPaymentAccount } from '../../data/payment.data'
import { STRIPE_CARD_TYPE } from '../../types/payment.types'
import withStripe from '../../stripe/with-stripe.component'
import newCreditCardFormStyles from './new-credit-card-form.component.styles'
import text from './new-credit-card-form.text'

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  }
}

@connect(null, { addPaymentCard })
@injectIntl
@reduxForm({
  form: 'newCreditCardForm'
})
@withStripe
@withStyles(newCreditCardFormStyles)
class StripeCreditCardForm extends PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    onCardAdded: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    submitText: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    className: PropTypes.string
  }

  state = {
    processing: false,
    error: null,
    errorMessage: ''
  }

  handleChange = ({ error }) => {
    this.setState({ errorMessage: '' })
    if (error) {
      this.setState({ errorMessage: error.message })
    }
  }

  handleSubmit = () => {
    if (this.props.stripe) {
      this.props.stripe.createToken().then(payload => this.submit(payload))
    } else {
      this.setState({ errorMessage: `Stripe hasn't loaded yet.` })
    }
  }

  submit = data => {
    if (data.error) {
      return this.setState({ error: true, errorMessage: data.error.message })
    }
    this.setState({ processing: true, error: null })
    const paymentAccount = {
      stripeToken: data.token.id,
      type: STRIPE_CARD_TYPE
    }
    addPaymentAccount(this.props.userId, paymentAccount).then(
      card => {
        this.props.addPaymentCard(card)
        this.setState({ processing: false })
        this.props.onCardAdded(card)
      },
      error => {
        this.setState({ processing: false, error })
      }
    )
  }

  // triggering form manually since we have use case when type="submit" triggers parent form
  triggerSubmitManually = () => this.props.submit()

  render() {
    const { isLoading } = this.props
    if (isLoading) return <RoomiSpinner middle />

    const {
      submitting,
      classes,
      onCancel,
      intl: { formatMessage },
      className,
      title,
      subTitle,
      submitText = formatMessage(text.submitButton)
    } = this.props
    const { processing, error } = this.state
    const disabled = submitting || processing

    return (
      <div
        className={classNames(classes.newCreditCardFromContainer, className)}
      >
        {title && <div className={classes.title}>{title}</div>}
        {subTitle && <div className={classes.subtitle}>{subTitle}</div>}
        <Form noValidate onSubmit={this.handleSubmit}>
          <Grid container spacing={24}>
            {error && (
              <Grid item xs={12}>
                <div className={classes.formError}>{error}</div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={classNames(classes.cardForm)}>
                <CardElement
                  onChange={this.handleChange}
                  {...createOptions()}
                />
              </div>
            </Grid>
            {this.state.errorMessage && (
              <Grid item xs={12}>
                <div className={classes.invalidError} role="alert">
                  {this.state.errorMessage}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={classNames(classes.actions)}>
                <RoomiButton
                  className={classes.cancelBtn}
                  disabled={disabled}
                  outline
                  secondary
                  small
                  onClick={onCancel}
                  label={formatMessage(text.cancelButton)}
                />
                <RoomiButton
                  primary
                  small
                  disabled={disabled}
                  onClick={this.triggerSubmitManually}
                  loading={disabled}
                  label={submitText}
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </div>
    )
  }
}

export default injectStripe(StripeCreditCardForm)
