import { identifyUser as fsId } from './fullstory/fullstory.actions'
// import {
//   identifyUser as inId,
//   logOutUser as inLogout
// } from './intercom/intercom.actions'
// import { identifyUser as abId } from './appboy/appboy.actions'
import { identifyUser as smId } from './satismeter/satismeter.actions'
import { identifyUser as smSS } from './sift-science/sift-science.actions'
import { unAuthenticate, loggedIn } from '../auth/actions/auth.types'
import { fetched } from '../users/actions/user.types'
import { saveEvent } from './events/events.service'
import { get } from 'lodash'
import { getUserDetails } from './analytics.utils'
import { newImport } from '../manage-listings/actions/import-requests.types'
import analytics from './analytics.handler'

let user
let data

export const analyticsMiddleware = store => next => action => {
  switch (action.type) {
    case fetched:
      user = action.user
      fsId(user)
      // inId(user)
      // abId(user)
      smId(user)
      smSS(user)
      break
    case loggedIn:
      user = get(store.getState(), 'user.data')
      data = getUserDetails(user)
      analytics({ action: 'SignIn', targetId: user._id, details: data })
      break
    case newImport:
      user = get(store.getState(), 'user.data')
      data = { listingUrl: action.imported.externalUrl }
      saveEvent({ eventName: 'Import_Listing', targetId: user._id, data })
      break
    case unAuthenticate:
      // inLogout()
      smId()
      break
    default:
  }
  next(action)
}
