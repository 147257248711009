import update from 'immutability-helper'
// import { findIndex } from 'lodash'
import {
  notificationsFetch,
  notificationsFetchSuccess,
  notificationMarkRead,
  notificationMarkReadSuccess,
  REGISTER_DEVICE,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_ERROR,
} from '../actions/announcement.types'

const initialState = {
  fetched: false,
  fetching: false,
  data: [],
  notifications: [],
};

export default function(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case notificationsFetch:
      return update(state, {
        $merge: {
          fetched: false,
          fetching: false,
        },
      });

    case notificationsFetchSuccess:
      return update(state, {
        $merge: {
          fetched: true,
          fetching: false,
          data: payload,
          notifications: payload,
        },
      });

    case notificationMarkRead:
      return update(state, {
        $set: {
          fetched: false,
          fetching: false,
        },
      });

    case notificationMarkReadSuccess:
      return update(state, {
        $set: {
          fetched: true,
          fetching: false,
        },
      });

    case REGISTER_DEVICE:
      return update(state, {
        $merge: {
          token: action.firebaseToken,
          deviceTokenSet: false,
          fetched: false,
          fetching: true,
        }
      });

    case REGISTER_DEVICE_SUCCESS:
      return update(state, {
        $merge: {
          deviceTokenSet: true,
          fetched: true,
          fetching: false,
        }
      });

    case REGISTER_DEVICE_ERROR:
      return update(state, {
        $merge: {
          fetched: false,
          fetching: false,
        }
      });

    default:
      return state
  }
}
