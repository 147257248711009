const onBoardingHeaderStyles = theme => ({
  onboardingHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerBanner: {
    height: 30,
    width: 110
  },
  headerRight: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  onboardingLogin: {
    height: 40,
    minWidth: 110
  }
});

export default onBoardingHeaderStyles;
