const boostListingBannerStyles = theme => ({
  boostBanner: {
    display: 'flex',
    padding: '11px 40px',
    background: theme.palette.primary[50],
    [theme.breakpoints.down('sm')]: {
      padding: '10px 15px',
      flexDirection: 'column'
    }
  },
  boostBannerContentContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  boostBannerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  boostMessage: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '22px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  actions: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10
    }
  },
  closeButton: {
    alignSelf: 'center',
    color: theme.palette.primary.main
  },
  boostButton: {
    flexShrink: 0
  },
  nullClass: {
    marginTop: 0
  }
})

export default boostListingBannerStyles
