import { call, put, take, takeLatest, select, all } from 'redux-saga/effects'
import { authenticate, unAuthenticate } from '../../auth/actions/auth.types'
import { update, fetching } from '../actions/booking-requests.types'
import { getCurrentUserFromState } from '../../users/reducers/user.selector'

import {
  resetInstantRenting,
  fetchedInstantRenting,
  fetchingInstantRenting,
  errorInstantRenting
} from '../actions/booking-requests.actions'
import { get } from '../data/booking-requests.data'

function* fetchBookingRequests({ userId }) {
  try {
    const bookingRequests = yield call(get)
    yield put(fetchedInstantRenting(bookingRequests, userId))
  } catch (e) {
    yield put(errorInstantRenting(e))
  }
}

function* reFetchBookingRequests({
  payload: {
    data: { state }
  }
}) {
  if (state !== 'accepted') return
  let { _id } = yield select(st => getCurrentUserFromState(st))
  yield put(fetchingInstantRenting(_id))
}

function* loginBookingRequestsFlow() {
  while (1) {
    const { userId } = yield take(authenticate)
    yield put(fetchingInstantRenting(userId))
    yield take(unAuthenticate)
    yield put(resetInstantRenting())
  }
}

export default function*() {
  yield all([
    loginBookingRequestsFlow(),
    takeLatest(update, reFetchBookingRequests),
    takeLatest(fetching, fetchBookingRequests)
  ])
}
