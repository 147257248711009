export const REGISTER_USER = 'TIMELINE/REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'TIMELINE/REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'TIMELINE/REGISTER_USER_ERROR';

export const FETCH_NEIGHBORHOOD_TOKEN = 'TIMELINE/FETCH_NEIGHBORHOOD_TOKEN';
export const FETCH_NEIGHBORHOOD_TOKEN_SUCCESS = 'TIMELINE/FETCH_NEIGHBORHOOD_TOKEN_SUCCESS';
export const FETCH_NEIGHBORHOOD_TOKEN_ERROR = 'TIMELINE/FETCH_NEIGHBORHOOD_TOKEN_ERROR';

export const FOLLOW_USER = 'TIMELINE/FOLLOW_USER';
export const FOLLOW_USER_SUCCESS = 'TIMELINE/FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_ERROR = 'TIMELINE/FOLLOW_USER_ERROR';

export const UNFOLLOW_USER = 'TIMELINE/UNFOLLOW_USER';
export const UNFOLLOW_USER_SUCCESS = 'TIMELINE/UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_ERROR = 'TIMELINE/UNFOLLOW_USER_ERROR';

export const CHECK_FOLLOW_STATUS = 'TIMELINE/CHECK_FOLLOW_STATUS';
export const CHECK_FOLLOW_STATUS_SUCCESS = 'TIMELINE/CHECK_FOLLOW_STATUS_SUCCESS';
export const CHECK_FOLLOW_STATUS_ERROR = 'TIMELINE/CHECK_FOLLOW_STATUS_ERROR';

export const REGISTER_ACTIVITY_REACTION = 'TIMELINE/REGISTER_ACTIVITY_REACTION';
export const REGISTER_ACTIVITY_REACTION_SUCCESS = 'TIMELINE/REGISTER_ACTIVITY_REACTION_SUCCESS';
export const REGISTER_ACTIVITY_REACTION_ERROR = 'TIMELINE/REGISTER_ACTIVITY_REACTION_ERROR';

export const DELETE_ACTIVITY = 'TIMELINE/DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESS = 'TIMELINE/DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_ERROR = 'TIMELINE/DELETE_ACTIVITY_ERROR';
