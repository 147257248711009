import {
  scripts,
  scriptLoadFail,
  scriptLoadInit,
  scriptLoadSuccess
} from '../actions/scripts.action.types'
import { each } from 'lodash'
import update from 'immutability-helper'

export default function scriptsReducer(state = {}, { type, payload } = {}) {
  let newState = state
  each(scripts, (src, key) => {
    if (
      type === `${key}_${scriptLoadFail}` ||
      type === `${key}_${scriptLoadInit}` ||
      type === `${key}_${scriptLoadSuccess}`
    ) {
      newState = update(state, { $merge: { [key]: payload } })
      return false
    }
    return true
  })
  return newState
}
