import { put, select, takeLatest, call } from 'redux-saga/effects'
import { getPayoutAccounts } from 'roomi/reducers/auth.user.selector'
import {
  setPayoutFlowStepAction,
  paymentConfigsFetchedAction
} from '../actions/payout.actions'
import { resetPayoutFlow, fetchPayemntConfigs } from '../actions/payout.types'
import { payoutFlowSteps } from '../reducers/payout.reducer'
import { payoutFlowStepSelector } from '../selectors/payout.selectors'
import { getPaymentForm } from '../data/payout.data'

function* startPayoutFlowSaga() {
  const accounts = yield select(getPayoutAccounts)
  const currentStep = yield select(payoutFlowStepSelector)
  const nextStep = !!accounts.length
    ? payoutFlowSteps.Select
    : payoutFlowSteps.Empty
  if (nextStep !== currentStep) {
    yield put(setPayoutFlowStepAction(nextStep))
  }
}

function* fetchPaymentConfigsSaga() {
  const { data: configs } = yield call(getPaymentForm)
  yield put(paymentConfigsFetchedAction(configs))
}

export default function*() {
  yield takeLatest(resetPayoutFlow, startPayoutFlowSaga)
  yield takeLatest(fetchPayemntConfigs, fetchPaymentConfigsSaga)
}
