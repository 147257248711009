import { post, remove } from '../../shared/utils/fetch.util'

export const addPaymentAccount = (userId, paymentInfo) => {
  paymentInfo.usage = 'payment'
  paymentInfo.currency = 'USD'
  return post(`/v2/users/${userId}/accounts`, paymentInfo).then(
    resp => resp.data
  )
}

export const removePaymentAccount = (userId, accountId) =>
  remove(`/v2/users/${userId}/accounts/${accountId}`)
export const setAccountDefault = cardId => {
  const event = {
    type: 'user-default-payment-method-changed',
    targetId: cardId
  }
  return post('/v2/events', event)
}
