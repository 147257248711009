import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';

const providerFieldMapper = {
  email: {
    base: 'mailto:',
    body: 'body',
    subject: 'subject'
  },
  facebook: {
    base: 'https://www.facebook.com/dialog/share',
    url: 'href',
    appId: 'app_id',
    hashTag: 'hashTag'
  },
  twitter: {
    base: 'https://www.twitter.com/intent/tweet',
    body: 'text',
    url: 'url',
    hashTag: 'hashTags'
  }
};

@track({})
class SocialShare extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    appId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    body: PropTypes.string,
    url: PropTypes.string,
    subject: PropTypes.string,
    provider: PropTypes.oneOf(['twitter', 'facebook', 'email']),
    hashTag: PropTypes.string
  };

  @track(({ provider, id }) => ({ action: 'Share_Listing', details: { shareType: provider }, targetId: id }))
  share = () => {
    const { body, url, subject, provider, appId } = this.props;
    const fieldMap = providerFieldMapper[provider];
    let query = [];
    if (url) query.push(`${fieldMap.url}=${url}`);
    if (subject) query.push(`${fieldMap.subject}=${subject}`);
    if (body) query.push(`${fieldMap.body}=${body}`);
    if (appId) query.push(`${fieldMap.appId}=${appId}`);
    const urlString = fieldMap.base + '?' + query.join('&');
    window.open(urlString, provider, 'width=640,height=445');
  };

  render() {
    return (
      <div onClick={this.share}>
        {this.props.children}
      </div>
    );
  }
}
export default SocialShare;
