import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  fetchedListing,
  fetchingError,
  suggestedFetched,
  suggestedError,
  listingConfigsFetchedAction
} from '../actions/listings.actions'
import {
  listingFetching,
  suggestedListingsFetching,
  fetchListingConfigs
} from '../actions/listings.types'
import { setUser } from '../../users/actions/users.actions'
import { get, getSuggested, getConfigsForListing } from '../data/listings.data'
import { getListing, hasFetchedSuggested } from '../reducers/listings.selector'

function* fetchListing({ id }) {
  try {
    let listing = yield select(state => getListing(state, id))
    if (listing) return
    listing = yield call(get, id)
    yield put(fetchedListing(id, listing))
    const user = listing.user
    yield put(setUser(user._id, user))
  } catch (e) {
    yield put(fetchingError(id, e))
  }
}

function* fetchSuggestedListings({ id }) {
  try {
    const fetched = yield select(state => hasFetchedSuggested(state, id))
    if (fetched) return
    const listings = yield call(getSuggested, id)
    yield put(suggestedFetched(id, listings))
  } catch (e) {
    yield put(suggestedError(id, e))
  }
}

function* fetchListingConfigsSaga({ payload: listingId }) {
  const { data: configs } = yield call(getConfigsForListing, listingId)
  yield put(listingConfigsFetchedAction(listingId, configs))
}

export default function*() {
  yield [
    takeLatest(listingFetching, fetchListing),
    takeLatest(suggestedListingsFetching, fetchSuggestedListings),
    takeLatest(fetchListingConfigs, fetchListingConfigsSaga)
  ]
}
