import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, RadioGroup, Collapse, FormHelperText } from '@material-ui/core';
import { RoomiRadio } from 'roomi/material';
import classNames from 'classnames';
import radioGroupStyles from './radio-group.styles';

@withStyles(radioGroupStyles)
class RoomiRadioGroup extends PureComponent {
  static propTypes = {
    value: PropTypes.any,
    children: PropTypes.node,
    color: PropTypes.string,
    options: PropTypes.array,
    titleKey: PropTypes.string,
    valueKey: PropTypes.string,
    itemClass: PropTypes.string
  };

  renderBoxes = () => {
    const {
      options = [],
      valueKey,
      titleKey,
      children,
      itemClass,
      color,
      classes
    } = this.props;
    return children ? children : options.map((opt, i) => {
      const val = String(!valueKey ? opt : opt[valueKey]);
      const label = !titleKey ? opt : opt[titleKey];
      return (
        <RoomiRadio key={i} value={val} label={label} color={color} itemClass={classNames(itemClass, classes.radioItem)}/>
      );
    });
  };

  render() {
    const {
      input,
      value,
      row,
      itemClass, // eslint-disable-line
      meta: { error, warning, touched } = {},
      showError = Boolean(touched && (error || warning)),
      classes, // eslint-disable-line
      ...rest
    } = this.props;
    return (
      <Fragment>
        <RadioGroup
          {...rest}
          value={value}
          row={row}
          {...input}
        >
          {this.renderBoxes()}
        </RadioGroup>
        <Collapse in={showError}>
          <FormHelperText error={showError}>{error || warning}</FormHelperText>
        </Collapse>
      </Fragment>
    );
  }
}

export default RoomiRadioGroup;
