import dude from './icons/dude.png';

const DUDE_WIDTH = 60;

const footerStyles = theme => ({
  footer: {
    display: 'flex',
    flexShrink: 0,
    padding: '30px 0',
    fontFamily: theme.typography.fontFamily,
    maxWidth: 1280,
    margin: '0 auto',
    [theme.breakpoints.down(1280 + DUDE_WIDTH * 2)]: {
      paddingRight: DUDE_WIDTH,
      paddingLeft: DUDE_WIDTH
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > div': {
        flexBasis: 'auto'
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0',
      alignItems: 'center'
    },
    '&$onboarding $socialContainer': {
      flexBasis: '100%',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between'
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }
    }
  },
  footerWrapper: {
    backgroundColor: theme.palette.background.footer,
    position: 'relative',
    flex: '0 1 auto'
  },
  onboarding: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 60px'
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      padding: '0 25px'
    }
  },
  sitemapContainer: {
    flexBasis: '65%',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: 240,
      display: 'block'
    }
  },
  socialContainer: {
    flexBasis: '35%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit,
      alignItems: 'center',
      minHeight: 100,
      justifyContent: 'space-around'
    }
  },
  footerGuyImage: {
    position: 'absolute',
    backgroundImage: `url(${dude})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 91,
    width: DUDE_WIDTH,
    right: 0,
    bottom: 0
  }
});

export default footerStyles;
