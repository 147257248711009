import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withWidth } from '@material-ui/core/index';
import pricePlanStyles from './price-plan.component.styles';
import classNames from 'classnames';
import text from './price-plan.text';
import { FormattedMessage, FormattedNumber } from 'react-intl';

@withWidth()
@withStyles(pricePlanStyles)
class PricePlan extends PureComponent {
  static propTypes = {
    plan: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string
  };

  planClicked = () => this.props.onClick && this.props.onClick(this.props.plan);

  render() {
    const { plan: { price, duration, count, currency }, selected, classes, className } = this.props;

    const planClasses = classNames(
      classes.pricePlan,
      { [classes.selected]: selected },
      className
    );

    return (
      <div className={planClasses} onClick={this.planClicked}>
        {duration && (
          <div className={classes.duration}>
            {duration}&nbsp;
            <FormattedMessage
              {...text.planUnit}
              values={{ unit: duration }}
            />
          </div>
        )
        }
        {count && (
            <div className={classes.duration}>
              {count}&nbsp;
              <FormattedMessage
                {...text.planUnitCount}
                values={{ unit: count }}
              />
            </div>
          )
        }
        <div className={classes.price}>
          <FormattedNumber
            value={price}
            style="currency"
            minimumFractionDigits={2}
            currency={currency}
          />
        </div>
      </div>
    );
  }
}

export default PricePlan;
