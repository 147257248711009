const buttonToggleItemStyles = theme => ({
  item: {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.grey[400],
    transition: 'all 0.2s ease-in-out',
    userSelect: 'none',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2.5}px`,
    '&$selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    }
  },
  selected: {}
});

export default buttonToggleItemStyles;
