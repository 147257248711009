import axios from 'axios'
import { get as lodashGet } from 'lodash'
import {
  getAuthToken,
  getSessionId,
  setSessionId,
  removeAuthToken
} from '../../auth/utils/session-manager.util'

const stagingInstance = axios.create({
  // baseURL: `${process.env.SERVER_URL}/api`,
  baseURL: `https://api.staging.roomiapp.com/api`,
  // baseURL: `http://localhost:9001/api`,
  headers: {
    'Content-Type': 'application/json',
    platform: 'web',
    version: process.env.APP_VERSION
  }
})

function handleResponseError(resp) {
  if (resp.response.status === 401) removeAuthToken()
  return Promise.reject(
    lodashGet(resp, 'response.data.message') || resp.message
  )
}

function handleResponse({ data, headers }) {
  headers['session-id'] && setSessionId(headers['session-id'])
  return data
}

stagingInstance.interceptors.request.use(config => {
  const token = getAuthToken()
  if (token) config.headers.Authorization = `Bearer ${token}`
  const sessionId = getSessionId()
  if (sessionId) config.headers['session-id'] = sessionId
  return config
})
stagingInstance.interceptors.response.use(handleResponse, handleResponseError)

function sendRequest({
  url,
  params = {},
  headers = {},
  data = {},
  method,
  transformRequest,
  transformResponse
}) {
  const config = { method, url, params, headers, data }
  if (transformRequest) config.transformRequest = transformRequest
  if (transformResponse) config.transformResponse = transformResponse
  return stagingInstance(config)
}

export const get = (url, params = {}, headers = {}, transformResponse) =>
  sendRequest({
    method: 'GET',
    url,
    params,
    headers,
    transformResponse
  })

export const post = (url, data = {}, headers = {}, transformRequest) =>
  sendRequest({
    method: 'POST',
    url,
    data,
    headers,
    transformRequest
  })

export const put = (url, data = {}, headers = {}) =>
  sendRequest({
    method: 'PUT',
    url,
    data,
    headers
  })

export const patch = (url, data = {}, headers = {}) =>
  sendRequest({
    method: 'PATCH',
    url,
    data,
    headers
  })

export const remove = (url, data = {}, headers = {}) =>
  sendRequest({
    method: 'DELETE',
    url,
    data,
    headers
  })
