const inputStyles = theme => ({
  inputContainer: {
    width: '100%'
  },
  root: {
    background: theme.palette.background.primary,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'transparent',
    transition: theme.transitions.create(['border-color']),
    padding: '4px 8px',
    fontSize: 14,
    flex: 1,
    width: '100%',
    height: '100%',
    '& > input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        margin: 0,
        '-webkit-appearance': 'none'
      }
    }
  },
  errorText: {
    lineHeight: '11px'
  },
  input: {
    padding: `${theme.spacing.unit}px 0 ${theme.spacing.unit - 3}px`
  },
  inputType: {
    height: 'auto' // fixes input with custom type eg. email password
  },
  inputMultiline: {
    padding: 0
  },
  focused: {
    borderColor: theme.palette.primary.main,
    background: 'white'
  },
  error: {
    borderColor: theme.palette.background.errorBorder,
    background: theme.palette.background.error,
    color: theme.palette.error.main,
    '&$focused': {
      background: 'white'
    },
    '& svg': {
      fill: theme.palette.background.errorBorder
    },
    '& svg g': {
      stroke: theme.palette.error.main
    },
    '& div.placeholder': {
      color: theme.palette.error.main
    }
  },
  multiline: {
    padding: 8
  },
  counterWrapper: {
    position: 'relative',
    top: 3
  },
  counter: {
    position: 'absolute',
    right: 0
  }
});

export default inputStyles;
