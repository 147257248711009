import { defineMessages } from 'react-intl';

export default defineMessages({
  planUnit: {
    id: 'price.plan.unit',
    defaultMessage: '{unit, plural, one {Day} other {Days}}'
  },
  planUnitCount: {
    id: 'priceCount.plan.unit',
    defaultMessage: '{unit, plural, one {Listing} other {Listing}}'
  }
});
