import update from 'immutability-helper';

import {
  flagsReset,
  flagsSet,
  listingFlagged,
  userFLagged,
  openFlag,
  closeFlag
} from '../actions/flags.types';

const defaultModal = {
  open: false,
  data: {}
};
const defaultState = { modal: defaultModal };

export default (state = defaultState, { type, payload } = {}) => {
  switch (type) {
    case flagsReset:
      return defaultState;
    case flagsSet:
      return update(state, { $merge: payload });
    case listingFlagged:
      return update(state, { listings: { $merge: { [payload]: true } } });
    case userFLagged:
      return update(state, { users: { $merge: { [payload]: true } } });
    case openFlag:
      const modal = {
        open: true,
        data: payload
      };
      return update(state, { modal: { $set: modal } });
    case closeFlag:
      return update(state, { modal: { $set: defaultModal } });
    default:
      return state;
  }
};
