const consentBannerStyles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.secondary,
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    position: 'fixed',
    zIndex: 10,
    bottom: 0,
    width: '100%'
  },
  message: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.primary.main,
    flexGrow: 1
  },
  link: {
    fontSize: 15,
    marginLeft: theme.spacing.unit / 2,
    textDecoration: 'underline'
  },
  icon: {
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing.unit * 2
  }
});

export default consentBannerStyles;
