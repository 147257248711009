const expandableMessageStyles = theme => ({
  messageContainer: {
    padding: theme.spacing.unit * 2,
    background: theme.palette.background.primary,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '25px',
    color: theme.palette.common.black,
    wordBreak: 'break-all',
    position: 'relative',
    '&:before': {
      right: '100%',
      top: 28,
      border: 'solid transparent',
      content: '" "',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvents: 'none',
      borderColor: 'rgba(136, 183, 213, 0)',
      borderRightColor: theme.palette.background.primary,
      borderWidth: 13,
      marginTop: -13,
      [theme.breakpoints.down(400)]: {
        borderWidth: 7
      }
    }
  },
  collapseBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 500
  }
});

export default expandableMessageStyles;
