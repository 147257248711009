import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'add.payout.method.form.title',
    defaultMessage: 'Add Payout Method'
  },
  subtitle: {
    id: 'add.payout.method.form.subtitle',
    defaultMessage: 'Please complete the following payout information below:'
  },
  confirmButtonLabel: {
    id: 'add.payout.method.form.confirm.button.label',
    defaultMessage: 'Add Account'
  },
  backButtonLabel: {
    id: 'add.payout.method.form.back.button.label',
    defaultMessage: 'Back'
  },
  cancelButtonLabel: {
    id: 'add.payout.method.form.cancel.button.label',
    defaultMessage: 'Cancel'
  },
  nextButtonLabel: {
    id: 'add.payout.method.form.next.button.label',
    defaultMessage: 'Next'
  },
  bankTitle: {
    id: 'add.bank.method.form.title',
    defaultMessage: 'Bank Details'
  },
  bankSubtitle: {
    id: 'add.bank.method.form.subtitle',
    defaultMessage: 'Enter the following bank details'
  },
});
