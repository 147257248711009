export const   listingFetching= 'FETCHING-LISTING'
export const   listingFetched= 'SET-LISTING'
export const   listingsFetched= 'SET-LISTINGS'
export const   listingError= 'ERROR-LISTING'
export const   listingUpdated= 'UPDATE-STORE-LISTING'
export const   suggestedListingsFetching= 'SUGGESTED-LISTINGS-FETCHING'
export const   suggestedListingsFetched= 'SUGGESTED-LISTINGS-FETCHED'
export const   suggestedListingsError= 'SUGGESTED-LISTINGS-ERROR'
export const   reactivate= 'REACTIVATE-LISTING'
export const   deactivate= 'DEACTIVATE-LISTING'
export const   remove= 'DELETE-LISTING'
export const   listingConfigsFetched= '[LISTING CONFIGS] FETCHED'
export const   fetchListingConfigs= '[LISTING CONFIGS] GET'
