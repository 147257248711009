import { Grid, withStyles } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { notFound } from 'roomi/icons';
import notFoundStyles from './page-not-fond.styles';
import text from './page-not-found.text';

@withStyles(notFoundStyles)
class PageNotFound extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="column" alignItems="center" className={classes.pageNotFound}>
        <Grid item className={classes.header}>
          <FormattedMessage {...text.header} />
        </Grid>
        <Grid item className={classes.topHeader}>
          <FormattedMessage {...text.topHeader} />
        </Grid>
        <Grid item>
          <img src={notFound} alt="404" className={classes.notFoundImage}/>
        </Grid>
        <Grid item className={classes.bottomHeader}>
          <FormattedMessage {...text.bottomHeader} />
        </Grid>
      </Grid>
    );
  }
}

export default PageNotFound;
