import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import expandableMessageStyles from './expandable-message.component.styles';
import lineClamp from 'line-clamp';
import classNames from 'classnames';
import text from './expandable.message.text';
import { FormattedMessage } from 'react-intl';

@withStyles(expandableMessageStyles)
class ExpandableMessage extends PureComponent {
  static propTypes = {
    message: PropTypes.string.isRequired,
    className: PropTypes.string
  };

  state = {};
  element = createRef();

  componentDidMount() {
    this.clamp();
    // we need to call set state here and cause another render because this is the earliest we can know if text has been
    // clamped or not.
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      expandable: this.element.current.innerText !== this.props.message,
      expanded: false
    });
  }

  clamp = () => lineClamp(this.element.current, 2);

  showAll = () => {
    this.element.current.innerText = this.props.message;
    this.setState({ expanded: true });
  };

  showLess = () => {
    this.clamp();
    this.setState({ expanded: false });
  };

  render() {
    const { classes, message, className } = this.props;
    const { expandable, expanded } = this.state;

    return (
      <div className={classNames(classes.messageContainer, className)}>
        <div ref={this.element}>{message}</div>
        {
          expandable && (
            expanded
              ? (
                <FormattedMessage {...text.readLess}>
                  { value => <div className={classes.collapseBtn} onClick={this.showLess}>{value}</div> }
                </FormattedMessage>
              )
              : (
                <FormattedMessage {...text.readMore}>
                  { value => <div className={classes.collapseBtn} onClick={this.showAll}>{value}</div> }
                </FormattedMessage>
              )
          )
        }
      </div>
    );
  }
}

export default ExpandableMessage;
