import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import BoostListingBanner from '../../components/boost-listing-banner/boost-listing-banner.component'
import { getCurrentUserFromState } from '../../../users/reducers/user.selector'
import { getActiveBoostPlans } from 'roomi/reducers/config.selector'
import { fetchSponsorships } from '../../actions/sponsorships.actions'
import { getSponsorshipsFromState } from '../../reducers/sponsorships.selector'
import { fetchUserListings } from '../../../users/actions/user-listings.actions'
import {
  getUserListingsSplitByActive,
  hasFetchedUserListings
} from '../../../users/reducers/user-listings.selector'

const mapStateToProps = state => {
  const [activeListings, inactiveListings] = getUserListingsSplitByActive(
    state,
    'me'
  )
  return {
    activeListings,
    inactiveListings,
    me: getCurrentUserFromState(state),
    fetched: hasFetchedUserListings(state, 'me'),
    sponsorships: getSponsorshipsFromState(state),
    plans: getActiveBoostPlans(state)
  }
}

@connect(
  mapStateToProps,
  { fetchSponsorships, fetchUserListings }
)
class BoostListingContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchUserListings('me')
    this.props.fetchSponsorships()
  }

  render() {
    const {
      me,
      sponsorships,
      plans,
      activeListings,
      fetched,
      showButtonOnly = false,
      showIcon = true,
      listings
    } = this.props

    console.log('listings || activeListings', listings);

    if (!me) return null
    return (
      <div style={{ maxWidth: '100%' }}>
        <BoostListingBanner
          userId={me._id}
          sponsorships={sponsorships}
          listings={listings || activeListings}
          fetched={fetched}
          plans={plans}
          showButtonOnly={showButtonOnly}
          showIcon={showIcon}
        />
      </div>
    )
  }
}

export default BoostListingContainer
