import { post, remove } from '../../shared/utils/fetch.util'

export const login = body =>
  post('/v2/users/login', body).then(resp => resp.data)
export const loginWithFb = body =>
  post('/v2/users/login/facebook', body).then(resp => resp.data)
export const requestPassword = body =>
  remove(`/v2/users/${body.username}/password`).then(resp => resp.data)
export const resetPassword = body =>
  post(`/v1/app-users/resetPassword`, body).then(resp => resp.data)
