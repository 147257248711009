import { call, put, takeLatest } from 'redux-saga/effects'
import { fetching } from '../actions/amenities.types'
import {
  fetchedAmenities,
  errorFetchingAmenities
} from '../actions/amenities.actions'
import { get } from '../data/amenities.data'

function* fetch() {
  try {
    const amenities = yield call(get)
    yield put(fetchedAmenities(amenities))
  } catch (e) {
    yield put(errorFetchingAmenities(e))
  }
}

function* amenitiesSaga() {
  yield takeLatest(fetching, fetch)
}

export default amenitiesSaga
