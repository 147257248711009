import React, { PureComponent } from 'react';
import ListingPrice from './listing-price/listing-price.component';
import Slick from '../../../shared/components/slick/slick.component';
import PropTypes from 'prop-types';
import NavLink from '../../../shared/components/navigation-link/navigation-link.component';
import { withStyles } from '@material-ui/core';
import listingCardCarouselStyles from './listing-card-carousel.styles';

@withStyles(listingCardCarouselStyles)
class ListingCarousel extends PureComponent {
  static propTypes = {
    listing: PropTypes.object.isRequired,
    href: PropTypes.string,
    openInTab: PropTypes.bool,
    slider: PropTypes.bool,
    showPrice: PropTypes.bool,
    onRedirect: PropTypes.func
  };

  render() {
    const {
      listing: {
        indexOfPrimaryImage: primary = 0,
        defaultImage,
        images = [],
        currency = 'USD',
        rent,
        isShortTerm
      },
      onRedirect,
      href,
      openInTab,
      slider,
      showPrice,
      classes
    } = this.props;
    
    const settings = {
      autoPlay: false,
      infinite: true,
      lazyLoad: 'ondemand',
      initialSlide: primary,
      images,
      fake: !images.length || !slider ? (images[primary] || defaultImage) : undefined // eslint-disable-line
    };
    return (
      <div className={classes.listingCarousel}>
        {href ? (
          <NavLink openInTab={openInTab} to={href} onRedirect={onRedirect}>
            <Slick settings={settings}/>
          </NavLink>
        ) : (
          <Slick settings={settings}/>
        )}
        {rent && showPrice && <ListingPrice price={rent} currency={currency} isShortTerm={isShortTerm ? true : false}/>}
      </div>
    );
  }
}

export default ListingCarousel;
