import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import SiteMap from './components/sitemap/sitemap.component';
import SocialMedia from './components/social-media/social-media.component';
import { withStyles } from '@material-ui/core';
import footerStyles from './footer.styles';
import classNames from 'classnames';

// With router is needed to re-render component every time route changes. This is needed because SiteMap child component
// uses NavLink to highlight link to the currently active page
@withRouter
@withStyles(footerStyles)
class Footer extends PureComponent {
  render() {
    const { classes, hideSitemap, onboarding } = this.props;

    return (
      <div className={classes.footerWrapper}>
        <footer className={classNames(classes.footer, { [classes.onboarding]: onboarding })}>
          {
            !hideSitemap && (
              <div className={classes.sitemapContainer}>
                <SiteMap/>
              </div>
            )
          }
          <div className={classes.socialContainer}>
            <SocialMedia/>
          </div>
          <div className={classes.footerGuyImage}/>
        </footer>
      </div>
    );
  }
}

export default Footer;
