import { map, castArray } from 'lodash';
import axios from 'axios';

const MAX_IMAGE_DIMENSIONS = 1024;

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const convertFilesToBase64 = images => {
  return Promise.all(map(castArray(images), getBase64));
};

export const convertBase64toFile = (base64, filename) => {
  let arr = base64.split(',');
  let type = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type });
};

export const convertFilesToBlobs = images => {
  return map(castArray(images), window.URL.createObjectURL);
};

const call = (image) => {
  if (!image.preview) return Promise.resolve(image);
  return axios({ url: image.preview, responseType: 'blob' }).then(({ data }) => {
    return new File([data], image.name, { type: data.type });
  });
};

export const convertBlobsToFiles = (images) => {
  return Promise.all(map(castArray(images), call));
};

const dataUrlToBlob = (dataURI, name) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });
  return {
    id: Date.now(),
    name,
    size: blob.size,
    type: blob.type,
    preview: URL.createObjectURL(blob),
    _file: blob
  };
};

const finishLoading = originalUrls => {
  return Promise.all(castArray(originalUrls).map(url => new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      resolve(false);
    };
    img.src = url;
  })));
};

const getOrientation = file => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = e => {
      const view = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xFFD8) return resolve(-2);
      const length = view.byteLength;
      let offset = 2;
      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xFFE1) {
          offset += 2;
          if (view.getUint32(offset, false) !== 0x45786966) return resolve(-1);
          const little = view.getUint16(offset += 6, false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) === 0x0112) {
              return resolve(view.getUint16(offset + (i * 12) + 8, little));
            }
          }
        } else if ((marker & 0xFF00) !== 0xFF00) break;
        else offset += view.getUint16(offset, false);
      }
      return resolve(-1);
    };
    reader.readAsArrayBuffer(file);
  });
};

const changeOrientationAndForceMaxSize = (results, name) => {
  const img = results[0][0];
  const orientation = results[1];
  let width = img.width;
  let height = img.height;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const ratio = width / height;
  if (width > MAX_IMAGE_DIMENSIONS && width > height) {
    width = MAX_IMAGE_DIMENSIONS;
    height = width / ratio;
  } else if (height > MAX_IMAGE_DIMENSIONS && height > width) {
    height = MAX_IMAGE_DIMENSIONS;
    width = height * ratio;
  }
  if ([5, 6, 7, 8].indexOf(orientation) > -1) {
    canvas.width = height;
    canvas.height = width;
  } else {
    canvas.width = width;
    canvas.height = height;
  }

  switch (orientation) {
  case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
  case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
  case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
  case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
  case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
  case 7: ctx.transform(0, -1, -1, 0, height, width); break;
  case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
  default: ctx.transform(1, 0, 0, 1, 0, 0);
  }
  ctx.drawImage(img, 0, 0, width, height);
  return dataUrlToBlob(canvas.toDataURL(), name);
};

export const processImage = image => Promise.all([finishLoading(image.preview), getOrientation(image)])
  .then(results => changeOrientationAndForceMaxSize(results, image.name));

export const revokeObjectURL = image => window.URL.revokeObjectURL(image);
