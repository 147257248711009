const experimentItemStyles = theme => ({
  badge: {
    width: '100%'
  },
  experimentItem: {
    cursor: 'pointer',
    fontWeight: 600,
    transition: 'background 0.2s ease-in',
    '&:hover, &$active': {
      background: theme.palette.primary[100]
    }
  },
  active: {}
});

export default experimentItemStyles;
