import { get } from 'lodash';
import { createSelector } from 'reselect';

export const getVerificationEnabled = state => get(state, 'config.data.identityVerification.enabled');
export const getVerificationMaxCount = state => get(state, 'config.data.identityVerification.maxRequests');
export const getBackgroundCheckEnabled = state => get(state, 'config.data.backgroundCheck.enabled');
export const getExperiments = state => get(state, 'config.data.experiments');

export const getExperimentVariation = createSelector(
  [getExperiments, (s, experimentName) => experimentName],
  (experiments, experimentName) => {
    if (!experiments) return {};
    const experiment = experiments.find(e => e.name === experimentName);
    return experiment ? experiment.variation : {};
  }
);
export const getVerificationExperimentVariation = state => getExperimentVariation(state, 'Hide verification flow');
export const getMover2MoverExperimentVariation = state => state.config.fetched && getExperimentVariation(state, 'Show M2M Chats');

export const getBackgroundCheckEnabledSelector = createSelector(
  [getBackgroundCheckEnabled, getVerificationExperimentVariation],
  (enabled, variation) => enabled && (variation.name === 'Show' && !variation.value)
);
export const getIdCheckEnabledSelector = createSelector(
  [getVerificationEnabled, getVerificationExperimentVariation],
  (enabled, variation) => enabled && (variation.name === 'Show' && !variation.value)
);

export const getMover2MoverEnabledSelector = createSelector(
  [getMover2MoverExperimentVariation],
  variation => variation ? variation.value : true
);
