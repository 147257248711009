const styles = theme => ({
  paper: {
    '&:not($paperFullScreen)': {
      width: 584
    }
  },
  title: {
    textAlign: 'center',
    '& > h2': {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: '55px',
      [theme.breakpoints.only('xs')]: {
        fontSize: 25,
        lineHeight: 'unset'
      }
    }
  },
  closeContainer: {
    flexShrink: 0 // IOS iphone fix
  },
  close: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary[800]
    }
  },
  paperFullScreen: {},
  loadingClass: {
    height: 520,
    width: 536
  }
});

export const smallerTitle = {
  padding: '24px 0 32px 0',
  '& h2': {
    color: 'black',
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '27px',
    textAlign: 'center'
  }
};

export default styles;
