export const   conversationsFetched= 'FETCHED-LAYER-CONVERSATIONS'
export const   conversationsFetching= 'FETCHING-LAYER-CONVERSATIONS'
export const   conversationUpdated= 'UPDATED-LAYER-CONVERSATION'
export const   conversationMoved= 'MOVED-LAYER-CONVERSATION'
export const   conversationCreated= 'INSERTED-LAYER-CONVERSATION'
export const   conversationCreate= 'CREATE-LAYER-CONVERSATION'
export const   conversationRemoved= 'REMOVED-LAYER-CONVERSATION'
export const   conversationReset= 'RESET-LAYER-CONVERSATION'
export const   conversationRecipientUpdated= 'UPDATED-CONVERSATION-IDENTITY'

export const fetchCurrentChatUserInfo = 'FETCH-CURRENT-CHAT-USER-INFO';
export const fetchCurrentChatUserInfoSuccess = 'FETCH-CURRENT-CHAT-USER-INFO-SUCCESS';
export const fetchCurrentChatUserInfoError = 'FETCH-CURRENT-CHAT-USER-INFO-ERROR';

export const fetchCurrentListingInfo = 'FETCH-CURRENT-LISTING-INFO';
export const fetchCurrentListingInfoSuccess = 'FETCH-CURRENT-LISTING-INFO-SUCCESS';
export const fetchCurrentListingInfoError = 'FETCH-CURRENT-LISTING-INFO-ERROR';
