import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { FacebookAuthorize } from 'socials'
import { fetchedUser } from '../../../users/actions/user.action'
import { connectFacebook } from '../../data/socials.data'
import facebookConnectStyles from './facebook-connect.styles'
import messages from './facebook-connect.text'

@connect(
  null,
  { fetchedUser }
)
@withStyles(facebookConnectStyles)
class FacebookConnect extends PureComponent {
  static propTypes = {
    onError: PropTypes.func,
    trigger: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSuccess: PropTypes.func,
    fullWidth: PropTypes.bool,
    className: PropTypes.string
  }

  state = {
    processing: false
  }

  onAuthorization = accessToken => {
    this.setState({ processing: true })
    connectFacebook(accessToken).then(
      ({ data: user }) => {
        this.setState({ processing: false })
        this.props.fetchedUser(user)
        this.props.onSuccess && this.props.onSuccess()
      },
      error => {
        this.setState({ processing: false })
        this.props.onError && this.props.onError(error)
      }
    )
  }

  trigger = () => <FormattedMessage {...messages.label} />

  render() {
    const { processing } = this.state
    const {
      onError,
      trigger = this.trigger,
      classes,
      isDisabled,
      className,
      fullWidth
    } = this.props

    return (
      <FacebookAuthorize
        className={classNames(classes.facebookConnect, className, {
          [classes.disabled]: isDisabled,
          [classes.fullWidth]: fullWidth
        })}
        trigger={trigger}
        redirectType="connect"
        isDisabled={isDisabled || processing}
        onAuthorization={this.onAuthorization}
        onCancel={onError}
      />
    )
  }
}

export default FacebookConnect
