import { fetchedMembers, fetchingError } from '../actions/actions.types';
import update from 'immutability-helper';

const initialState = { members: [], loaded: null };

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case fetchedMembers:
      return update(state, { $set: { members: payload, loaded: true } });
    case fetchingError:
      return update(state, { $set: { loaded: false } });
    default:
      return state;
  }
};
