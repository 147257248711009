const planSelectorStyles = theme => ({
  planSelector: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  plan: {
    marginLeft: 20,
    [theme.breakpoints.only('xs')]: {
      marginLeft: 15
    }
  }
});

export default planSelectorStyles;
