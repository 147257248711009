import { Grid, MenuItem, withStyles } from '@material-ui/core'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import FlagIcon from 'roomi/common/flag-icon/flag-icon.component'
import DynamicForm from 'roomi/common/dynamic-form/dynamic-form.component'
import RoomiButton from 'roomi/material/button/button.component'
import RoomiSelect from 'roomi/material/select/select'
import { fetchPaymentConfigsAction } from '../../actions/payout.actions'
import {
  getPayoutSupportedCountriesSelector,
  getPaymentFormConfigSelector
} from '../../selectors/payout.selectors'
import addPayoutMethodFormStyles from './add-payout-method-form.component.styles'
import text from './add-payout-method-form.component.text'
import RoomiSpinner from 'roomi/common/loading-spinner/loading-spinner.component'
// import NeedInformationButton from '../../../analytics/intercom/need-information-button/need-information-button.component'
import WhyNeedInformation from '../../../requests/my-requests/shared-components/why-need-information'
import { submit } from 'redux-form'

const formName = 'addPayoutMethodForm'

const selectClasses = {}

const mapStateToProps = (state, ownProps) => ({
  formConfig: getPaymentFormConfigSelector(state),
  supportedCountries: getPayoutSupportedCountriesSelector(state),
  submitting: state.form[formName] && state.form[formName].submitting
})

@injectIntl
@connect(mapStateToProps, {
  fetchPayemntConfigs: fetchPaymentConfigsAction,
  submitForm: () => submit(formName)
})
@withStyles(addPayoutMethodFormStyles)
class AddBankAccountForm extends PureComponent {
  static propTypes = {
    formConfig: PropTypes.object,
    supportedCountries: PropTypes.arrayOf(PropTypes.object),
    submitting: PropTypes.bool,
    fetchPayemntConfigs: PropTypes.func,
    submitForm: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.string,
    classes: PropTypes.object
  }

  state = { selectedCountry: 'US', selectedType: 'individual' }

  componentDidMount() {
    if (!this.props.formConfig) {
      this.props.fetchPayemntConfigs()
    }
  }
  handleTypeChange = ({ target: { value } }) =>
    this.setState({ selectedType: value })

  handleCountryChange = ({ target: { value } }) =>
    this.setState({ selectedCountry: value })

  render() {
    const {
      formConfig,
      supportedCountries,
      submitting,
      onSubmit,
      onCancel,
      error,
      submitForm,
      classes,
      intl: { formatMessage }
    } = this.props
    const { selectedCountry, selectedType } = this.state
    const loading = !formConfig || !supportedCountries
    const supportedTypes = [
      {
        code: 'individual',
        name: 'Individual'
      },
      {
        code: 'business',
        name: 'Business'
      }
    ]
    selectClasses.selectMenu = classes.selectMenu

    return (
      <div>
        <FormattedMessage {...text.bankTitle}>
          {value => <div className={classes.title}>{value}</div>}
        </FormattedMessage>
        <FormattedMessage {...text.bankSubtitle}>
          {value => <div className={classes.subtitle}>{value}</div>}
        </FormattedMessage>
        {loading && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.spinnerContainer}
          >
            <RoomiSpinner />
          </Grid>
        )}
        {!loading && (
          <div className={classes.form}>
            {error && <div className={classes.error}>{error}</div>}
            <Grid container item xs={12} spacing={16} justify="center">
              <Grid item sm={12} xs={12}>
                <RoomiSelect
                  value={selectedCountry}
                  onChange={this.handleCountryChange}
                  classes={selectClasses}
                >
                  {supportedCountries.map(country => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name} ({country.code}){' '}
                      <FlagIcon
                        className={classes.flag}
                        isoCountryCode={country.code}
                      />
                    </MenuItem>
                  ))}
                </RoomiSelect>
              </Grid>

              <Grid item sm={12} xs={12}>
                <div className={classes.groupLabel}>Account Type</div>
                <RoomiSelect
                  value={selectedType}
                  onChange={this.handleTypeChange}
                  classes={selectClasses}
                >
                  {supportedTypes.map(type => (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  ))}
                </RoomiSelect>
              </Grid>
            </Grid>
            <DynamicForm
              key={selectedCountry}
              className={classes.countryForm}
              form={formName}
              country={selectedCountry}
              accountType={selectedType}
              fields={formConfig[selectedCountry][selectedType]}
              onSubmit={onSubmit}
              error={error}
            />
            <div className={classes.helpContainer}>
              <WhyNeedInformation />
            </div>
          </div>
        )}
        <Grid
          container
          spacing={16}
          className={classes.actions}
          justify="center"
        >
          <Grid item>
            <RoomiButton
              secondary
              outline
              small
              fullWidth
              label={formatMessage(text.cancelButtonLabel)}
              onClick={onCancel}
            />
          </Grid>
          <Grid item>
            <RoomiButton
              primary
              small
              fullWidth
              label={formatMessage(text.nextButtonLabel)}
              disabled={!formConfig}
              loading={submitting}
              onClick={submitForm}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AddBankAccountForm
