import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Icon } from '@material-ui/core';
import addCreditCardButtonStyles from './add-credit-card-button.component.styles';
import { FormattedMessage } from 'react-intl';
import messages from './add-credit-card-button.text';

@withStyles(addCreditCardButtonStyles)
class AddCreditCardButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    text: PropTypes.string
  };

  render() {
    const { onClick, className, text, classes } = this.props;
    return (
      <div className={classNames(classes.button, className)} onClick={onClick}>
        <Icon className={classes.addIcon}>add</Icon>
        {text ? text : <FormattedMessage {...messages.addPaymentMethod} />}
      </div>
    );
  }
}

export default AddCreditCardButton;
