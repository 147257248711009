import Imgix from 'react-imgix';
import React from 'react';
import classNames from 'classnames';

/**
 * Wrapper around Imgix tags so we can migrate easier when updating Imgix.
 * Also detect if src is a base64 bit image and do not use Imgix in that case.
 * @param alt
 * @param src
 * @param width
 * @param height
 * @param mask
 * @param fit
 * @param draggable
 * @param className
 * @param key
 * @param crop
 * @param square
 * @param border
 * @returns {*}
 * @constructor
 */
// fit="clip" draggable={false} className={classes.image} src={src}
export const LazyImage = ({
  alt,
  src,
  width,
  height,
  mask,
  fit,
  draggable,
  className,
  key,
  crop,
  square,
  border,
}) => {
  if (
    src &&
    (src.indexOf('data://image/png;base64') === 0 ||
      src.indexOf('data:image/png;base64') === 0)
  ) {
    if (alt) {
      return <img alt={alt} src={src} width={width} height={height} />;
    }
    return <img alt="unset" src={src} width={width} height={height} />;
  }

  const htmlAttributes = { alt: 'unset' };
  const customParams = {};
  if (alt) {
    htmlAttributes.alt = alt;
  }
  if (mask) {
    customParams.mask = mask;
  }
  if (fit) {
    customParams.fit = fit;
  }
  if (crop) {
    customParams.crop = crop;
  }
  if (square) {
    customParams.ar = '1:1';
  }
  if (border) {
    customParams.border = border;
  }

  const dimensionParams = {};
  if (width && width !== 'unset') {
    dimensionParams.width = width;
  }
  if (height) {
    dimensionParams.height = height;
  }

  return key ? (
    <Imgix
      key={key}
      draggable={draggable}
      className={classNames('lazyload', className)}
      imgixParams={customParams}
      htmlAttributes={htmlAttributes}
      src={src}
      {...dimensionParams}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
    />
  ) : (
    <Imgix
      draggable={draggable}
      className={classNames('lazyload', className)}
      imgixParams={customParams}
      htmlAttributes={htmlAttributes}
      src={src}
      {...dimensionParams}
      attributeConfig={{
        src: 'data-src',
        srcSet: 'data-srcset',
        sizes: 'data-sizes',
      }}
    />
  );
};
