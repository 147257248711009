import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { RoomiSmallLogo } from 'roomi/icons';
import spinnerStyles from './spinner.styles';
import classNames from 'classnames';
import { withStyles, CircularProgress } from '@material-ui/core';

@withStyles(spinnerStyles)
class LoadingSpinner extends PureComponent {
  static propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
    className: PropTypes.string,
    middle: PropTypes.bool,
    size: PropTypes.number
  };

  render() {
    const { size = 50, middle, classes, color = 'primary', className } = this.props;
    return (
      <div className={classNames(classes.root, className, { [classes.middleRoot]: middle })}>
        <div className={classNames(classes.container)}>
          <CircularProgress
            size={size}
            color={color}
            thickness={2}
          />
          <RoomiSmallLogo className={classNames(classes.logo, { [classes.inherit]: color === 'inherit' })} style={{
            width: size * 0.6,
            height: size * 0.6,
            left: `calc(50% - ${size * 0.6 / 2 }px)`,
            top: `calc(50% - ${size * 0.6 / 2 }px)`
          }}/>
        </div>
      </div>
    );
  }
}

export default LoadingSpinner;
