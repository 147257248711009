const variationItemStyles = theme => ({
  variationItem: {
    cursor: 'pointer',
    paddingLeft: theme.spacing.unit * 3
  },
  active: {
    color: theme.palette.primary.main
  }
});

export default variationItemStyles;
