import {
  messagesFetching,
  messagesFetched,
  messagesReset,
  messageInserted,
  messageUpdated,
  messageSend,
  initiateChat,
  initiateChatSuccess,
  initiateChatError,
  deleteChat,
  deleteChatSuccess,
  deleteChatError,
  resetDeleteStatus,
  fetchingRoomiMessages,
  fetchedRoomiMessages,
  clearRoomiMessages,
} from './messages.types'

import Message from '../models/message.model'

export const actionFetchingRoomiMessages = () => ({
  type: fetchingRoomiMessages,
});

export const actionFetchedRoomiMessages = (data) => ({
  type: fetchedRoomiMessages,
  payload: data,
});

export const actionClearRoomiMessages = () => ({
  type: clearRoomiMessages,
});

export const fetchedMessages = (data, convoId) => ({
  type: messagesFetched,
  payload: Message.createList(data),
  conversationId: convoId
})

export const fetchMessages = conversationId => ({
  type: messagesFetching,
  conversationId
})

export const resettingMessages = () => ({ type: messagesReset })

export const insertedMessage = (data, conversationId) => ({
  type: messageInserted,
  payload: new Message(data),
  conversationId
})

export const updatedMessage = data => ({
  type: messageUpdated,
  payload: new Message(data)
})

export const sendMessage = (conversationId, { text, image }) => ({
  type: messageSend,
  payload: { data: { text, image }, conversationId }
})

export const actionInitiateChat = ({ userId, listingId }) => ({
  type: initiateChat,
  recipientId: userId,
  listingId,
});

export const actionInitiateChatSuccess = () => ({
  type: initiateChatSuccess
});

export const actionInitiateChatError = (err) => ({
  type: initiateChatError
});

export const actionDeleteChat = (channelId) => ({
  type: deleteChat,
  channelId,
});

export const actionDeleteChatSuccess = () => ({
  type: deleteChatSuccess
});

export const actionDeleteChatError = (error) => ({
  type: deleteChatError,
  error,
});

export const actionResetDeleteChat = () => ({
  type: resetDeleteStatus,
});
