import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { startStripe } from '../actions/stripe.actions';

export default function(ComposedComponent) {
  class WithStripe extends PureComponent {
    componentDidMount() {
      const stripeStatus = get(this.props, 'scripts.Stripe');
      if (!stripeStatus || stripeStatus === 'ERROR') {
        this.props.startStripe();
      }
    }

    render() {
      const {
        scripts: { Stripe },
        isDisabled,
        label,
        ...rest
      } = this.props;
      return (
        <ComposedComponent
          {...rest}
          isLoading={!Stripe || Stripe === 'INIT'}
          isDisabled={isDisabled}
          label={label}
        />
      );
    }
  }

  const mapStateToProps = ({ scripts }) => ({ scripts });

  return connect(
    mapStateToProps,
    { startStripe },
  )(WithStripe);
}
