import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import boostSuccessStyles from './boost-success.component.styles'
import { FormattedMessage } from 'react-intl'
import text from './boost-success.text'
import boostSuccessImg from '../../assets/boost-success.png'
import ovalImg from '../../assets/oval.png'
import { RoomiButton } from 'roomi/material'
import { injectIntl } from 'react-intl'

@injectIntl
@withStyles(boostSuccessStyles)
class BoostSuccess extends PureComponent {
  static propTypes = {
    duration: PropTypes.any,
    successMessage: PropTypes.object,
    descriptionMessage: PropTypes.object,
    onClose: PropTypes.func.isRequired
  }

  render() {
    const {
      classes,
      successMessage,
      descriptionMessage,
      duration,
      onClose,
      intl: { formatMessage }
    } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <FormattedMessage {...successMessage} />
        </div>
        <div>
          <img src={boostSuccessImg} alt="" />
        </div>
        <div className={classes.timeLeftContainer}>
          <img src={ovalImg} alt="" />
          <div className={classes.timeLeft}>
            <FormattedMessage
              values={{ unit: duration }}
              {...text.boostSuccessUnit}
            />
          </div>
        </div>
        <div className={classes.description}>
          <FormattedMessage {...descriptionMessage} />
        </div>
        <div>
          <RoomiButton
            primary
            small
            onClick={onClose}
            label={formatMessage(text.boostSuccessButton)}
          />
        </div>
      </div>
    )
  }
}

export default BoostSuccess
