const newCreditCardFromStyles = theme => ({
  newCreditCardFromContainer: {
    [theme.breakpoints.only('xs')]: {
      width: 'auto'
    }
  },
  title: {
    fontSize: 40,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      fontSize: 24
    }
  },
  subtitle: {
    marginBottom: 38,
    marginTop: 30,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '19px',
    textAlign: 'center'
  },
  cancelBtn: {
    [theme.breakpoints.up('sm')]: {
      marginRight: 35
    }
  },
  actions: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      [theme.breakpoints.only('xs')]: {
        flex: '1 1 100%'
      }
    }
  },
  formError: {
    textAlign: 'center',
    color: theme.palette.error.main
  },
  cardForm: {
    maxWidth: '500px',
    margin: '0 auto',
    justifyContent: 'center',
    display: 'block',
    padding: '10px 14px',
    fontSize: '1em',
    fontFamily: 'Source Code Pro, monospace',
    boxShadow: `rgba(50, 50, 93, 0.24902) 0px 1px 3px, rgba(0, 0, 0, 0.14968) 0px 1px 0px`,
    border: 0,
    outline: 0,
    borderRadius: '4px',
    background: 'white'
  },
  invalidError: {
    color: theme.palette.error.main,
    margin: '0 auto',
    marginLeft: 35,
    justifyContent: 'center'
  },
});

export default newCreditCardFromStyles;
