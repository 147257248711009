import { call, put, takeLatest } from 'redux-saga/effects'
import { fetching } from '../actions/rules.types'
import { fetchedRules, errorFetchingRules } from '../actions/rules.actions'
import { get } from '../data/rules.data'

function* fetch() {
  try {
    const rules = yield call(get)
    yield put(fetchedRules(rules))
  } catch (e) {
    yield put(errorFetchingRules(e))
  }
}

function* rulesSaga() {
  yield takeLatest(fetching, fetch)
}

export default rulesSaga
