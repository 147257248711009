import {
  toggleHowItWorksListerDialog,
  toggleHowItWorksMoverDialog
} from './how-it-works.types'

export const toggleHowItWorksListerDialogAction = () => ({
  type: toggleHowItWorksListerDialog
})
export const toggleHowItWorksMoverDialogAction = () => ({
  type: toggleHowItWorksMoverDialog
})
