import { finish } from './onboarding.actions.types';
import {
  set,
  setWithExpiration,
  get,
  remove
} from '../../shared/utils/storage/local-storage.util';
import history from '../../history';
import { includes } from 'lodash';

export const finishOnboarding = () => ({
  type: finish
});

export const forceOnboarding = ({ onboarding = {} } = {}, path,roomipay = {}) => {
  if (
    onboarding.required &&
    onboarding.state !== 'finished' &&
    path !== '/verification' &&
    !includes(path, '/policies') &&
    !includes(path, '/rentpayment')
  ) {
    if(roomipay.fetched) {
      if(roomipay.data) {
        history.push('/rentpayment');
      } else {
        savePreOnboardingRoute();
        history.push('/onboarding');
      }
    } else {
      history.push('/onboarding');
    }
  }
};

export function savePreOnboardingRoute() {
  set('pre_onboarding_route', {
    url: window.location.pathname + window.location.search,
    react: true
  });
}

export function getPreOnboardingRoute() {
  return get('pre_onboarding_route').val;
}

export function removePreOnboardingRoute() {
  remove('pre_onboarding_route');
}

export function removePreOnboardingAction() {
  remove('pre_onboarding_action');
}

export function savePreOnboardingAction(action) {
  setWithExpiration('pre_onboarding_action', action, 60);
}

export function getPreOnboardingAction() {
  return get('pre_onboarding_action').val;
}
