import { call, put, select, takeLatest } from 'redux-saga/effects'
import { unAuthenticate } from '../../auth/actions/auth.types'
import history from '../../history'
import {
  fetchedListing,
  fetchedListings
} from '../../listings/actions/listings.actions'
import {
  errorFetchAllBookingInvitesAction,
  fetchedAllBookingInvitesAction,
  bookingInviteCanceledAction,
  errorCancelBookingInviteAction,
  clearBookingInvitesAction,
  fetchedBookingInviteAction,
  errorFetchBookingInviteAction
} from '../actions/booking-invites.actions'
import {
  fetchAllBookingInvites,
  cancelBookingInvite,
  fetchBookingInvite
} from '../actions/booking-invites.types'
import {
  getAllBookingInvitesApi,
  cancelBookingInviteApi,
  getBookingInviteApi
} from '../data/booking-invites.data'
import { bookingInvitesFetchedSelector } from '../selectors/booking-invites.selector'
import sendAnalytics from '../../analytics/analytics.handler'

function* fetchAllBookingInvitesSaga() {
  try {
    const fetched = yield select(bookingInvitesFetchedSelector)
    if (!fetched) {
      const { data: bookingInvites } = yield call(getAllBookingInvitesApi)
      const listings = bookingInvites.map(invite => invite.listing)
      yield put(fetchedListings(listings))
      yield put(fetchedAllBookingInvitesAction(bookingInvites))
    }
  } catch (e) {
    yield put(errorFetchAllBookingInvitesAction(e))
  }
}

function* fetchBookingInviteSaga({ payload: id }) {
  try {
    const { data: bookingInvite } = yield call(getBookingInviteApi, id)
    yield put(fetchedListing(bookingInvite.listing._id, bookingInvite.listing))
    yield put(fetchedBookingInviteAction(bookingInvite))
  } catch (e) {
    if (/not found/i.test(e)) {
      // Hacky way to check if response was 404. TODO: see how to better handle this
      yield call([history, history.push], '/not-found')
    } else {
      yield put(errorFetchBookingInviteAction(e))
    }
  }
}

function* cancelBookingInviteSaga({ payload: bookingInvite }) {
  try {
    yield call(cancelBookingInviteApi, bookingInvite.id)
    yield put(bookingInviteCanceledAction(bookingInvite))
    yield call(sendAnalytics, {
      action: 'Click_CancelBookingInvite',
      details: {
        listerId: bookingInvite.lister._id,
        moverId: bookingInvite.mover._id,
        listingId: bookingInvite.listing._id
      },
      fbq: true,
      ga: { category: 'BookingInvite' }
    })
  } catch (e) {
    yield put(errorCancelBookingInviteAction(e))
  }
}

function* clearBookingInvitesSaga() {
  yield put(clearBookingInvitesAction())
}

export default function*() {
  yield takeLatest(fetchAllBookingInvites, fetchAllBookingInvitesSaga)
  yield takeLatest(cancelBookingInvite, cancelBookingInviteSaga)
  yield takeLatest(unAuthenticate, clearBookingInvitesSaga)
  yield takeLatest(fetchBookingInvite, fetchBookingInviteSaga)
}
