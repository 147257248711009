import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { withStyles, withMobileDialog } from '@material-ui/core'

import RoomiConfirmationDialog from 'roomi/material/confirmation-dialog/confirmation-dialog.component'
import { RoomiSpinner } from 'roomi/common'
import { RoomiDialog } from 'roomi/material'

import PlaidForm from '../../../plaid/plaid.component'

import { addPaymentCard } from '../../actions/payment.actions'
import { addPaymentAccount } from '../../data/payment.data'
import { BANK_ACCOUNT_TYPE } from '../../types/payment.types'

import * as dialogStyles from './new-plaid-account.styles'
import text from './new-plaid-account.text'

@withStyles(dialogStyles)
@withMobileDialog({ breakpoint: 'xs' })
@connect(
  null,
  { addPaymentCard }
)
@injectIntl
class NewPlaidDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    onCardAdded: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    formData:PropTypes.object
  }

  state = {
    showPlaidForm: true,
    confirmationModal: { open: false },
    processing: false,
    error: null
  }

  resetState = () => {
    this.setState({
      showPlaidForm: true,
      confirmationModal: { open: false },
      processing: false,
      error: null
    })
  }

  showConfirmationPopup = card => {
    this.setState({
      processing: false,
      confirmationModal: {
        open: true,
        title: this.props.intl.formatMessage(text.successTitle),
        message: this.props.intl.formatMessage(text.successMessage),
        onConfirm: () => {
          this.resetState()
          this.props.onCardAdded(card)
        }
      }
    })
  }

  showErrorPopup = error => {
    this.setState({
      processing: false,
      confirmationModal: {
        open: true,
        title: this.props.intl.formatMessage(text.errorTitle),
        message: error,
        onConfirm: () => {
          this.resetState()
          this.props.onCancel()
        }
      }
    })
  }

  submit = (meta, data) => {
    this.setState({ processing: true, showPlaidForm: false })
    const paymentAccount = {
      account_id: data.account_id,
      public_token: data.public_token,
      type: BANK_ACCOUNT_TYPE
    }
    const formData = this.props.formData    
    const accountData = {...paymentAccount, ...formData}
    
    addPaymentAccount(this.props.userId, accountData).then(
      card => {
        this.props.addPaymentCard(card)
        this.resetState()
        this.props.onCardAdded(card)
      },
      error => {
        this.showErrorPopup(error)
      }
    )
  }

  onCancel = () => {
    this.resetState()
    this.props.onCancel()
  }

  render() {
    const { open, userId } = this.props
    const { showPlaidForm, confirmationModal, processing } = this.state

    return (
      <Fragment>
        {open && (
          <RoomiDialog open={open}>
            {processing ? (
              <RoomiSpinner middle />
            ) : (
              <PlaidForm
                userId={userId}
                clientName={process.env.PLAID_CLIENT_NAME}
                product={['auth']}
                apiKey={process.env.PLAID_PUBLIC_KEY}
                selectAccount
                env={process.env.PLAID_ENV}
                open={showPlaidForm}
                onSuccess={this.submit}
                onExit={this.onCancel}
              />
            )}
          </RoomiDialog>
        )}
        <RoomiConfirmationDialog {...confirmationModal} />
      </Fragment>
    )
  }
}

export default NewPlaidDialog
