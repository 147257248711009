import { loadScript } from '../../shared/actions/scripts.action'
import { getUserIdentity } from '../analytics.utils'

export const startSatismeter = () => dispatch => {
  window.satismeter =
    window.satismeter ||
    function() {
      ;(window.satismeter.q = window.satismeter.q || []).push(arguments)
    }
  window.satismeter.l = 1 * new Date()
  loadScript('Satismeter', 'https://app.satismeter.com/satismeter.js')(dispatch)
}

export const identifyUser = (user = {}) =>
  window.satismeter({
    writeKey: 'uYi7yy0bVczxRk2Q',
    userId: user._id,
    traits: {
      name: getUserIdentity(user),
      email: user.email,
      createdAt: user.createdAt
    }
  })
