const moverModalStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  sub: {
    fontFamily: theme.typography.button.fontFamily,
    fontSize: 20,
    color: theme.palette.primary.main
  },
  image: {
    width: 200,
    margin: `${theme.spacing.unit * 4}px 0`,
    '& img': {
      width: '100%'
    }
  },
  text: {
    marginBottom: theme.spacing.unit * 3,
    textAlign: 'center'
  },
  text2: {
    marginBottom: theme.spacing.unit * 5,
    textAlign: 'center',
    width: '90%'
  },
  text3: {
    marginBottom: theme.spacing.unit * 5,
    textAlign: 'center',
    width: '90%',
    fontWeight: 'bold'
  },
  button: {
    alignSelf: 'center',
    flexShrink: 0
  },
  [theme.breakpoints.only('xs')]: {
    text2: {
      marginBottom: theme.spacing.unit * 3
    },
    image: {
      width: 150,
      margin: `${theme.spacing.unit * 2}px 0`
    }
  }
});

export default moverModalStyles;
