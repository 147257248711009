import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setToken = (name, value, options) => cookies.set(name, value, { ...options, path: '/' });
export const getToken = (name) => cookies.get(name);
export const removeToken = (name) => {
  cookies.remove(name, { path: '/' });
  cookies.remove(name);
};
