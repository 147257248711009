import { has, get, reduce, pick, map, groupBy } from 'lodash';

function getListingLocation({ listing }) {
  if (!listing) return '';
  if (has(listing, 'address')) {
    return listing.address.neighborhood || listing.address.city || listing.address.state;
  }
  return listing.listingAddressNeighborhood || listing.listingAddressCity || listing.listingAddressState;
}

function getListingAddress({ listing }) {
  if (!listing) return '';
  if (has(listing, 'address')) {
    return listing.address;
  }
  return {
    state: listing.listingAddressState,
    city: listing.listingAddressCity,
    region: listing.listingAddressRegion,
    neighborhood: listing.listingAddressNeighborhood,
    country: listing.listingAddressCountry,
    geoPoint: {
      lng: get(listing, 'listingAddressGeopoint.longitude'),
      lat: get(listing, 'listingAddressGeopoint.latitude')
    }
  };
}

function getListingImages({ listing }) {
  if (!listing) return [];
  if (has(listing, 'urlOfListingImages')) {
    return listing.urlOfListingImages;
  }
  return listing.images;
}

function getListingRentingPrice({ listing }) {
  if (!listing) return null;
  if (has(listing, 'roomRent')) {
    return listing.roomRent;
  }
  return listing.rent;
}

function getListingOwnerProfileImage({ listing }) {
  if (!listing) return '';
  if (has(listing, 'user.defaultImage')) {
    return listing.user.defaultImage;
  }
  return get(listing, 'user.profileImage');
}

function getListingAvailableDate({ listing }) {
  if (!listing) return '';
  if (has(listing, 'roomAvailableDate.iso')) {
    return listing.roomAvailableDate.iso;
  }
  return listing.availableDate;
}

function checkShortTerm({ listing }) {
  if (!listing) return '';
  if (has(listing, 'isShortTerm')) {
    return listing.isShortTerm;
  }
  return false;
}

function getShortTermListingAvailabledate({ listing }) {
  if (!listing) return [];
  if (has(listing, 'roomAvailableDateFrom.iso') && has(listing, 'roomAvailableDateTo.iso')) {
    return [listing.roomAvailableDateFrom.iso, listing.roomAvailableDateTo.iso];
  }
}
function getListingLease({ listing }) {
  if (!listing) return {};
  if (has(listing, 'roomLeaseDuration')) {
    return { min: listing.roomLeaseDuration, max: listing.roomLeaseMaxDuration };
  }
  return { min: listing.leaseDuration, max: listing.leaseMaxDuration };
}

function transformListingTransportations(transportations) {
  return reduce(transportations, (agg, trans) => {
    if (!trans.lat || !trans.long) return agg;
    agg[trans.id] = {
      ...pick(trans, ['id', 'name', 'lat', 'distance']),
      lng: trans.long,
      categories: map(groupBy(trans.transit, 'category'), (routes, category) => ({ category, routes }))
    };
    return agg;
  }, {});
}

export {
  getListingLocation,
  getListingImages,
  getListingRentingPrice,
  getListingAddress,
  getListingOwnerProfileImage,
  getListingAvailableDate,
  getListingLease,
  transformListingTransportations,
  getShortTermListingAvailabledate,
  checkShortTerm
};
