import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'boost.modal.listingSelector.title',
    defaultMessage: 'Select a listing to Boost'
  },
  cancelButton: {
    id: 'boost.modal.listingSelector.cancel',
    defaultMessage: 'Cancel'
  },
  continueButton: {
    id: 'boost.modal.listingSelector.continue',
    defaultMessage: 'Continue'
  }
});
