import {
  call,
  takeLatest,
  select,
  take,
  put,
  all,
  takeEvery,
  cancel,
  fork
} from 'redux-saga/effects'
import * as listingAPI from '../data/listings.data'
import { USER_INFO_FORM_NAME, FORM_NAME } from '../post/post-listing.config'
import { fetched } from '../../users/actions/user.types'
import { fetched as fetchedUserListings } from '../../users/actions/user-listings.types'
import { size, map, isString, last, pick, find } from 'lodash'
import {
  convertBlobsToFiles,
  revokeObjectURL
} from '../../shared/utils/files/file.utils'
import {
  addUserListingAction,
  fetchUserListings
} from '../../users/actions/user-listings.actions'
import {
  allowedToPostListing,
  hasFetchedUserListings
} from '../../users/reducers/user-listings.selector'
import { getCurrentUserFromState } from '../../users/reducers/user.selector'
import { change, getFormValues } from 'redux-form'
import Listing from '../model/listing.model'

import { updateMe } from '../../users/data/user.data'
import history from '../../history'
import {
  checkingUserAction,
  imageUploadedAction,
  initializingListingAction,
  linkingListingAction,
  listingInitializedAction,
  listingSaveFinishedAction,
  saveModalBootAction,
  savingListingDataAction,
  savingUserDataAction,
  setModalStepsAction,
  startImagesUploadAction,
  toggleWarningModal,
  updateProgressAction
} from '../actions/manage-listing.actions'
import {
  checkIsUserAllowedToPostListing,
  listingFormSubmitted,
  resetListingCreation,
  initializingListing,
  listingInitialized,
  imageUploaded,
  linkingListing,
  postListingCreate,
  saveModalBoot,
  savingUserData,
  savingListingData,
  checkingUser,
  userHandledWarning
} from '../actions/manage-listing.types'
import {
  getSaveModalStepsSelector,
  imagesToUploadSelector,
  isIncompleteSelector,
  shouldCreateSelector,
  shouldLinkListing,
  shouldSaveUserDataSelector,
  getListingChangesSelector,
  isDraftSelector,
  wasIncompleteSelector
} from '../reducers/manage-listing.selector'
import { updateListing } from '../actions/listings.actions'
import {
  addIncompleteAction,
  removeIncompleteListing,
  updateIncompleteAction
} from '../actions/incomplete-listings.actions'
import { fetchedUser } from '../../users/actions/user.action'
import { getAnalyticsListing } from '../reducers/listings.selector'
import analytics from '../../analytics/analytics.handler'

function* manageListingSaveSaga({ data, blockRedirect }) {
  // eslint-disable-line
  try {
    let incomplete
    if (data.onboarding) {
      delete data.onboarding
      incomplete = data.incomplete
      yield all(
        map(data, (value, field) => put(change(FORM_NAME, field, value)))
      )
    } else {
      incomplete = yield select(isIncompleteSelector)
    }

    const imagesToUpload = yield select(imagesToUploadSelector)
    const shouldCreate = yield select(shouldCreateSelector)
    const shouldSaveUserData = yield select(shouldSaveUserDataSelector)
    let { steps: saveSteps, total: totalSteps } = yield select(
      getSaveModalStepsSelector
    )
    let listing = data

    // WEB-2271 TODO: Ivan - temporary fix, remove flow + modal if we don't need this in near future
    const displayWarningModal = false // yield select(s => displayWarningModalSelector(s, listing.status));
    if (displayWarningModal) {
      yield put(toggleWarningModal(true)) // modal opens with ignore + fix
      const { fix } = yield take(userHandledWarning)
      yield put(toggleWarningModal(false))
      if (!fix) {
        return yield call([history, history.push], {
          pathname: '/myListings/manage',
          state: { preventBlock: true }
        })
      }
      return false // break saga to fix
    }

    yield put(setModalStepsAction(imagesToUpload, totalSteps))
    // booting up save modal
    yield put(saveModalBootAction(find(saveSteps, { _id: 'booting' })))

    // initializing listing
    if (shouldCreate) {
      yield put(initializingListingAction(find(saveSteps, { _id: 'creating' })))
      const {
        listing: { _id, userId }
      } = yield take(listingInitialized)
      yield put(change(FORM_NAME, '_id', _id))
      if (userId) {
        yield put(change(FORM_NAME, 'userId', userId))
        // recalculate steps - linking not needed
        ;({ steps: saveSteps } = yield select(getSaveModalStepsSelector))
      }
      listing = { ...listing, _id, userId }
    }
    const activeListing = !(yield select(isDraftSelector))

    // images upload
    if (imagesToUpload) {
      yield put(startImagesUploadAction(find(saveSteps, { _id: 'uploading' })))
      const photos = yield call(convertBlobsToFiles, data.urlOfListingImages)
      const uploadData = { _id: listing._id, incomplete: !activeListing }
      const newUrls = yield all(
        map(photos, image => call(uploadImageSaga, { image, ...uploadData }))
      )
      listing = { ...listing, urlOfListingImages: newUrls }

      // re-save listing after images upload is done
      if (!activeListing) {
        yield call(listingAPI.updateIncompleteListing, {
          ...listing,
          incomplete: true
        })
      } else {
        yield call(
          listingAPI.updateListing,
          pick(listing, ['urlOfListingImages', '_id'])
        )
      }
      yield put(
        change(FORM_NAME, 'urlOfListingImages', listing.urlOfListingImages)
      )
    }

    // * check user permission
    const formValues = yield select(s => getFormValues(FORM_NAME)(s))
    yield put(
      checkingUserAction(find(saveSteps, { _id: 'checking' }), {
        ...formValues,
        incomplete: yield select(isIncompleteSelector)
      })
    )
    const userId = yield call(allowedToPostListingSaga)
    const allowed = yield select(s => allowedToPostListing(s, 'me', listing))
    if (!allowed && !activeListing) return false // break saga execution, modal displayed

    // saving user data if there is changes
    if (shouldSaveUserData) {
      yield put(
        savingUserDataAction(find(saveSteps, { _id: 'savingUserData' }))
      )
      const userData = yield select(s => getFormValues(USER_INFO_FORM_NAME)(s))
      const updatedMe = yield call(updateMe, userId, userData)
      yield put(fetchedUser(updatedMe))
    }

    // linking listing with user - after auth
    let shouldLink = yield select(shouldLinkListing)
    const differences = yield select(getListingChangesSelector)
    let activeListingStatusChanged
    if (shouldLink) {
      yield put(linkingListingAction(find(saveSteps, { _id: 'linking' })))
      listing = { ...listing, incomplete }
      const createdListing = yield call(
        listingAPI.updateIncompleteListing,
        listing
      )
      if (createdListing.status === 'pending') {
        const aListing = yield select(s =>
          getAnalyticsListing(s, createdListing)
        )
        analytics({
          action: 'Create_Listing',
          details: aListing,
          targetId: createdListing._id,
          ga: { category: 'Listings', value: createdListing.rent },
          fb: {
            listingId: createdListing._id,
            rent: createdListing.roomRent,
            region: createdListing.listingRegion
          }
        })
      }
    } else if (size(differences)) {
      yield put(
        savingListingDataAction(find(saveSteps, { _id: 'savingListingData' }))
      )
      if (activeListing) {
        const updatedListing = yield call(
          listingAPI.updateListing,
          pick(listing, [...differences, '_id'])
        )
        if (listing.status === 'active')
          activeListingStatusChanged = updatedListing.status !== listing.status
        listing = { ...listing, status: updatedListing.status }
        const aListing = yield select(s =>
          getAnalyticsListing(s, updatedListing)
        )
        analytics({
          action: 'Edit_Listing',
          details: { ...aListing, fieldsChanged: differences },
          targetId: updatedListing._id
        })
      } else {
        listing = { ...listing, incomplete, listingSource: 'Website' }
        const createdListing = yield call(
          listingAPI.updateIncompleteListing,
          listing
        )
        if (createdListing.status === 'pending') {
          const aListing = yield select(s =>
            getAnalyticsListing(s, createdListing)
          )
          analytics({
            action: 'Create_Listing',
            details: aListing,
            targetId: createdListing._id,
            ga: { category: 'Listings', value: createdListing.roomRent },
            fb: {
              listingId: createdListing._id,
              rent: createdListing.roomRent,
              region: createdListing.listingRegion
            }
          })
        }
      }
    } else {
      listing = { ...listing, incomplete }
      if (listing.status === 'active') activeListingStatusChanged = false
    }

    const wasIncomplete = yield select(wasIncompleteSelector)
    yield put(
      listingSaveFinishedAction({
        newlyCreated: shouldCreate || wasIncomplete,
        activeListingStatusChanged
      })
    )

    const { action } = yield take(postListingCreate)
    if (action === 'redirect') {
      const redirectUrl = listing.incomplete
        ? '/myListings/manage'
        : `/rooms-for-rent/${listing._id}`
      yield call([history, history.replace], {
        pathname: blockRedirect || redirectUrl,
        state: { preventBlock: true }
      })
    } else {
      // bg check
    }

    // sync listings in redux store
    if (listing.incomplete) {
      if (shouldCreate || shouldLink) {
        yield put(addIncompleteAction({ ...listing, status: 'incomplete' }))
      } else {
        yield put(updateIncompleteAction({ ...listing, status: 'incomplete' }))
      }
    } else {
      yield put(removeIncompleteListing([listing._id]))
      if (shouldCreate || wasIncomplete || shouldLink) {
        yield put(addUserListingAction('me', [listing._id]))
      } else {
        yield put(updateListing(listing._id, new Listing(listing)))
      }
    }
    // if (data.isShortTerm) {
    //   // manage-short-term-listings
    //   // also use different API
    // } else {

    // }
  } catch (e) {} // eslint-disable-line
}

function* allowedToPostListingSaga() {
  try {
    const loggedUser = yield select(getCurrentUserFromState)
    let { _id } = loggedUser || {}
    if (!_id) {
      ;({
        user: { _id }
      } = yield take(fetched))
    }
    const fetchedUsersListings = yield select(s =>
      hasFetchedUserListings(s, 'me')
    )
    if (!fetchedUsersListings) {
      yield put(fetchUserListings('me'))
      yield take(fetchedUserListings)
    }
    return _id
  } catch (err) {
    return err
  } // eslint-disable-line
}

function* initializeIncompleteListingSaga({ data }) {
  try {
    const initialized = yield call(listingAPI.updateIncompleteListing, {
      ...data,
      urlOfListingImages: [],
      incomplete: true
    })
    yield put(listingInitializedAction(initialized))
  } catch (err) {} // eslint-disable-line
}

function* uploadImageSaga({ image, _id, incomplete }) {
  try {
    if (isString(image)) return yield image
    const resp = yield call(
      listingAPI[incomplete ? 'addIncompleteListingImage' : 'addListingImage'],
      { _id, incomplete, photos: [image] }
    )
    yield put(imageUploadedAction())
    yield call(revokeObjectURL, image) // memory clean up
    return yield last(resp.urlOfListingImages)
  } catch (err) {
    return err
  } // eslint-disable-line
}

function* updateProgress() {
  yield put(updateProgressAction())
}

function* runSaga(data) {
  const task = yield fork(manageListingSaveSaga, data)
  yield take(resetListingCreation)
  yield cancel(task)
}

export default function*() {
  yield [
    takeLatest(listingFormSubmitted, runSaga),
    takeLatest(initializingListing, initializeIncompleteListingSaga),
    takeEvery(
      [
        saveModalBoot,
        listingInitialized,
        imageUploaded,
        linkingListing,
        savingUserData,
        savingListingData,
        checkingUser
      ],
      updateProgress
    ),
    takeLatest(checkIsUserAllowedToPostListing, allowedToPostListingSaga)
  ]
}
