import React, { PureComponent } from 'react';
import { ButtonBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import buttonStyles from './button.component.styles';
import { Link } from 'react-router-dom';
import Spinner from 'roomi/common/loading-spinner/loading-spinner.component';

@withStyles(buttonStyles)
class RoomiButton extends PureComponent {
  static propTypes = {
    startAdornment: PropTypes.node,
    loading: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool,
    outline: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        state: PropTypes.object,
      }),
    ]),
  };

  render() {
    const {
      startAdornment,
      loading,
      children,
      classes,
      className: classNameProp,
      primary,
      secondary,
      outline,
      disabled,
      fullWidth,
      small,
      medium,
      large,
      label,
      to,
      ...rest
    } = this.props;

    const className = classNames(
      classes.root,
      {
        [classes.fullWidth]: fullWidth,
        [classes.primary]: primary,
        [classes.secondary]: secondary,
        [classes.outline]: outline,
        [classes.disabled]: disabled || loading,
        [classes.small]: small,
        [classes.medium]: medium || (!small && !large),
        [classes.large]: large,
      },
      classNameProp,
    );

    return (
      <ButtonBase
        className={className}
        {...rest}
        {...(to && { to, component: Link })}
      >
        {startAdornment && (
          <div className={classes.startAdornment}>{startAdornment}</div>
        )}
        <div className={classes.textWrapper}>
          {/* we need this wrapper because of the font alignment issue */}
          {label ? label : children}
          {loading && (
            <Spinner size={20} color="inherit" className={classes.spinner} />
          )}
        </div>
      </ButtonBase>
    );
  }
}

export default RoomiButton;
