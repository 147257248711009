import { createSelector } from 'reselect';

const getUserData = state => {
  return state.user.data;
};
const userLoaded = state => state.user.data;
export const getUser = createSelector(
  [userLoaded, getUserData],
  (loaded, data) => loaded && data
);
