import { filter, map, find } from 'lodash';
import moment from 'moment';
import { roomiGuestUser } from 'roomi/icons';

const recipientLeft = {
  avatarUrl: roomiGuestUser,
  displayName: 'Inactive User'
};

function Conversation({ id = '', createdAt, lastMessage, unreadCount, metadata, participants }) {
  const that = this;
  that.id = id;
  that.uuid =  id.split('/').pop();
  that.createdAt = createdAt;
  that.unreadCount = unreadCount;
  that.metadata = metadata;
  that.recipient = filter(participants, { sessionOwner: false })[0];
  if (!that.recipient) {
    that.displayText = 'User has left the conversation';
    that.recipient = recipientLeft;
    that.recipientLeft = true;
  } else {
    that.displayText = that.getDisplayText(lastMessage);
  }
  that.displayDate = that.getDisplayDate(createdAt, lastMessage);
}

Conversation.prototype.getDisplayText = (lastMessage) => {
  if (!lastMessage) return 'Send a Message!';
  const textPart = find(lastMessage.parts, { mimeType: 'text/plain' });
  const message = textPart && (textPart.body || 'Sent a message...');
  return message || 'Sent an Attachment...';
};

Conversation.prototype.getDisplayDate = (createdAt, lastMessage) => {
  const date = lastMessage ? lastMessage.sentAt : createdAt;
  const theDate = moment(date);
  return theDate.calendar(null, {
    sameDay: 'h:mm A',
    lastDay: '[Yesterday]',
    lastWeek: 'MMM D',
    sameElse: 'MMM D'
  });
};

Conversation.createList = (list) => map(list, (item) => new Conversation(item));

export default Conversation;
