import { keyBy, omit } from 'lodash';
import { reset, fetched, remove, addIncomplete, updateIncomplete } from '../actions/incomplete-listings.types';
import update from 'immutability-helper';

const initialState = { data: {} };

export default (
  state = initialState,
  { type, incompleteListings, incompleteListing, id } = {}
) => {
  switch (type) {
  case fetched:
    return update(state, {
      data: { $set: keyBy(incompleteListings, '_id') }
    });
  case addIncomplete:
    return update(state, {
      data: { $merge: { [incompleteListing._id]: incompleteListing } }
    });
  case updateIncomplete:
    return update(state, {
      data: { [incompleteListing._id]: { $merge: incompleteListing } }
    });
  case remove:
    return update(state, { $set: { data: omit(state.data, id) } });
  case reset:
    return initialState;
  default:
    return state;
  }
};
