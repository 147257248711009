import React, { PureComponent } from 'react';
import { Input, FormHelperText, Collapse } from '@material-ui/core';
import Adornment from '../adornment/adornment';
import { withStyles } from '@material-ui/core/styles';
import inputStyles from './input.styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MinimumLength from '../../../shared/components/required-characters/minimum-length';
import messages from './input.text';
import { injectIntl } from 'react-intl';

@injectIntl
@withStyles(inputStyles)
class RoomiInput extends PureComponent {
  static propTypes = {
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    height: PropTypes.number,
    description: PropTypes.string,
    input: PropTypes.any, // redux-form field prop
    meta: PropTypes.any, // redux-form field prop
    rootClass: PropTypes.string,
    startAdornmentClass: PropTypes.string,
    showError: PropTypes.bool,
    min: PropTypes.number,
    showGenericError: PropTypes.bool
  };

  render() {
    const {
      intl: { formatMessage },
      startAdornment,
      endAdornment,
      input,
      errorMessage,
      meta: { error = errorMessage, warning, touched, visited, submitFailed } = {},
      showGenericError,
      genericError = showGenericError ? formatMessage(messages.genericError) : '',
      multiline,
      height = !multiline && 40,
      description,
      style,
      rootClass,
      startAdornmentClass,
      classes,
      min,
      genericErrorMessage = touched && !visited && Boolean(error || warning) && genericError, // triggering touch manually from redux-form api
      fieldErrorMessage = (submitFailed || (touched && visited)) && (error || warning),
      showError = Boolean(fieldErrorMessage || genericErrorMessage),
      ...props
    } = this.props;
    const { counter, counterWrapper, errorText, root, inputContainer, ...inputClasses } = classes;
    return (
      <div className={inputContainer}>
        <Input
          {...props}
          {...input} // this will override input value + handlers if its form input
          {...(startAdornment && {
            startAdornment: (
              <Adornment position="start" className={startAdornmentClass}>
                {startAdornment}
              </Adornment>
            )
          })}
          {...(endAdornment && { endAdornment: <Adornment position="end">{endAdornment}</Adornment> })}
          multiline={multiline}
          classes={{ root: classNames(root, rootClass), ...inputClasses }}
          style={{ ...style, height }}
          error={showError}
        />
        {!!min && (
          <div className={counterWrapper}>
            <div className={counter}>
              <MinimumLength min={min} value={props.value || input.value}/>
            </div>
          </div>
        )}
        <Collapse in={showError}>
          <FormHelperText error={showError} classes={{ root: errorText }}>
            {genericErrorMessage || fieldErrorMessage}
          </FormHelperText>
        </Collapse>
        {!!description && (
          <FormHelperText disabled>
            {description}
          </FormHelperText>
        )}
      </div>
    );
  }
}

export default RoomiInput;
