import { call, put, take } from 'redux-saga/effects'
import { authenticate, unAuthenticate } from '../../auth/actions/auth.types'

import {
  resetInstantRenting,
  fetchedInstantRenting,
  fetchingInstantRenting,
  errorInstantRenting
} from '../actions/import-requests.actions'
import { get } from '../data/import-requests.data'

function* fetchBookingRequests() {
  try {
    yield put(fetchingInstantRenting())
    const importRequests = yield call(get)
    yield put(fetchedInstantRenting(importRequests))
  } catch (e) {
    yield put(errorInstantRenting(e))
  }
}

export default function*() {
  while (1) {
    yield take(authenticate)
    yield fetchBookingRequests()
    yield take(unAuthenticate)
    yield put(resetInstantRenting())
  }
}
