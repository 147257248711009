const listingPriceStyles = theme => ({
  listingPrice: {
    position: 'absolute',
    bottom: 20,
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: 16,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    margin: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    cursor: 'text'
  }
});

export default listingPriceStyles;
