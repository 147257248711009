import { loadScript } from '../../shared/actions/scripts.action'

export const startFacebook = () => dispatch => {
  loadScript('FB', 'https://connect.facebook.net/en_US/sdk.js')(dispatch).then(
    initialize
  )
}

function initialize() {
  window.FB &&
    window.FB.init({
      appId: process.env.FACEBOOK_APP_ID,
      version: 'v2.8'
    })
}
