import { get, post } from '../../shared/utils/fetch.util'

export const getSponsorships = userId =>
  get(`/v2/users/${userId}/sponsorships`).then(resp => resp.data)
export const buyListingBoost = (listingId, data) =>
  post(`/v2/sponsorships/listings/${listingId}`, data).then(resp => resp.data)

export const buyProfileBoost = data =>
  post(`/v2/sponsorships/profiles`, data).then(resp => resp.data)

export const buyRoommatePlan = data =>
  post('/v2/sponsorships/roommates/moversToLister', data).then(
    resp => resp.data
  )
export const buyCreateListingPlan = data =>
  post('/v2/sponsorships/create-listing', data).then(resp => resp.data)
