import { defineMessages } from 'react-intl';

export default defineMessages({
  title_connecting: {
    id: 'linkedin.title.connecting',
    defaultMessage: 'Connecting Your Profile'
  },
  title_success: {
    id: 'linkedin.title.success',
    defaultMessage: 'LinkedIn connected'
  },
  title_error: {
    id: 'linkedin.title.error',
    defaultMessage: 'Oops! Try Again'
  },
  button_success: {
    id: 'linkedin.button.success',
    defaultMessage: 'View Profile'
  },
  button_error: {
    id: 'linkedin.button.error',
    defaultMessage: 'Try Again'
  },
  button_cancel: {
    id: 'linkedin.button.cancel',
    defaultMessage: 'Cancel'
  },
  button_connecting: {
    id: 'linkedin.button.connecting',
    defaultMessage: 'Connecting...'
  },
  message_connecting: {
    id: 'linkedin.message.connecting',
    defaultMessage: 'Hold tight while we connecting your account.'
  },
  message_success: {
    id: 'linkedin.message.success',
    defaultMessage: 'You\'ve successfully connected your LinkedIn. Now go check it out!'
  },
  message_error: {
    id: 'linkedin.message.error',
    defaultMessage: 'Looks like something went wrong. Let\'s try that again.'
  }
});
