export const startUserIdVerification = '[EVIDENT] [ID] START'
export const startUserBgVerification = '[EVIDENT] [BG] START'
export const resetUserVerification = '[EVIDENT] RESET_USER_VERIFICATION'
export const setIdVerificationPlan = '[EVIDENT] [ID] SET_PLAN'
export const displayIdVerificationPrice = '[EVIDENT] [ID] DISPLAY_PRICE'
export const displayIdFreeVerificationPrice =
  '[EVIDENT] [ID] DISPLAY_FREE_PRICE'
export const displayIdVerificationPayment =
  '[EVIDENT] [ID] VERIFICATION_PAYMENT'
export const successIdPayment = '[EVIDENT] [ID] SUCCESS_PAYMENT'
export const confirmIdVerificationPrice = '[EVIDENT] [ID] CONFIRM_PRICE'
export const retryLimitExceed = '[EVIDENT] [ID] LIMIT_EXCEED'
export const fetchEvidentRedirectUrl = '[EVIDENT] [ID] FETCH_EVIDENT_URL'
export const setIdVerificationUiUrl = '[EVIDENT] [ID] SET_EVIDENT_URL'
export const redirectToIdVerification =
  '[EVIDENT] [BG] REDIRECT_TO_ID_VERIFICATION'
export const redirectConfirmed = '[EVIDENT] [BG] REDIRECTED_TO_ID'
export const setBgVerificationPlan = '[EVIDENT] [BG] SET_PLAN'
export const notifyForBgCheckExpired = '[EVIDENT] [BG] VERIFICATION_EXPIRED'
export const confirmBgRenew = '[EVIDENT] [BG] RENEW_CONFIRMED'
export const displayBgVerificationPrice = '[EVIDENT] [BG] DISPLAY_PRICE'
export const confirmBgVerificationPrice = '[EVIDENT] [BG] CONFIRM_PRICE'
export const displayVerificationTerms = '[EVIDENT] [BG] TERMS_OF_SERVICE'
export const verificationTermsAccepted = '[EVIDENT] TERMS_OF_SERVICE_ACCEPTED'
export const displayBgVerificationPayment =
  '[EVIDENT] [BG] VERIFICATION_PAYMENT'
export const successBgPayment = '[EVIDENT] [BG] SUCCESS_PAYMENT'
export const waitingForEvidentResponse = '[EVIDENT] WAITING_RESPONSE'
export const evidentNotificationReceived = '[EVIDENT] NOTIFICATION_RECEIVED'
export const evidentVerificationFinished = '[EVIDENT] VERIFICATION_FINISHED'
