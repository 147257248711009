import update from 'immutability-helper';
import {
  fetchedAllBookingInvites,
  canceledBookingInvite,
  errorCancelBookingInvite,
  clearBookingInvites,
  errorFetchAllBookingInvites,
  sentBookingInvite,
  fetchedBookingInvite,
  declinedBookingInvite,
  acceptedBookingInvite
} from '../actions/booking-invites.types';

const DEFAULT_STATE = { fetched: false, error: null, data: {} };

export default (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case fetchedAllBookingInvites:
      const bookingInvitesMap = payload.reduce((map, bookingInvite) => {
        map[bookingInvite.id] = formatBookingInvite(bookingInvite);
        return map;
      }, {});
      return { ...state, fetched: true, data: bookingInvitesMap };
    case canceledBookingInvite:
      return update(state, { data: { [payload.id]: { state: { $set: 'canceled' } } } });
    case errorFetchAllBookingInvites:
    case errorCancelBookingInvite:
      return { ...state, error: payload };
    case sentBookingInvite:
    case fetchedBookingInvite:
      return update(state, { data: { [payload.id]: { $set: formatBookingInvite(payload) } } });
    case clearBookingInvites:
      return DEFAULT_STATE;
    case declinedBookingInvite:
      return !!state.data[payload.id]
        ? update(state, { data: { [payload.id]: { state: { $set: 'rejected' } } } })
        : state;
    case acceptedBookingInvite:
      return !!state.data[payload.id]
        ? update(state, { data: { [payload.id]: { state: { $set: 'accepted' } } } })
        : state;
    default:
      return state;
  }
};

const formatBookingInvite = (bookingInvite) => {
  // Avoid storing listing by setting it to null so we don't get stale data if/when listing is updated
  return { ...bookingInvite, listingId: bookingInvite.listing._id, listing: null };
};
