import {
  Badge,
  Collapse,
  Grid,
  ListItem,
  withStyles,
  Icon
} from '@material-ui/core';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import VariationItem from '../variation-item/variation-item';
import experimentItemStyles from './experiment-item.styles';

@withStyles(experimentItemStyles)
class ExperimentItem extends PureComponent {
  static propTypes = {
    experiment: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onVariationChange: PropTypes.func.isRequired,
    open: PropTypes.string.isRequired
  };

  onClick = () => {
    this.props.onClick && this.props.onClick(this.props.experiment);
  };

  onVariationClick = variation => {
    if (isEqual(variation, this.props.experiment.variation)) return;
    this.props.onVariationChange &&
      this.props.onVariationChange(this.props.experiment, variation);
  };

  render() {
    const { experiment, open, classes } = this.props;
    const {
      name,
      allVariations,
      finished,
      variation: experimentVariation
    } = experiment;
    const active = open === name;
    return (
      <div>
        <Badge
          color={!finished ? 'primary' : 'error'}
          badgeContent={allVariations.length}
          className={classes.badge}
        >
          <ListItem
            onClick={this.onClick}
            className={classNames(classes.experimentItem, {
              [classes.active]: active
            })}
          >
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                {`${name} (${finished ? 'Finished' : 'Active'})`}
              </Grid>
              <Grid item>
                {active ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
              </Grid>
            </Grid>
          </ListItem>
        </Badge>
        <Collapse in={active}>
          {allVariations.map(variation => (
            <VariationItem
              key={variation.name}
              variation={variation}
              active={isEqual(experimentVariation, variation)}
              onClick={this.onVariationClick}
            />
          ))}
        </Collapse>
      </div>
    );
  }
}

export default ExperimentItem;
