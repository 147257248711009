const aboutQuestionnaireStyles = (theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit + 4
  },
  aboutField: {
    marginTop: theme.spacing.unit + 2,
    marginRight: 'auto',
    cursor: 'pointer'
  },
  aboutFieldText: {
    width: '90%',
    textAlign: 'center',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 0.25}px`,
    color: theme.palette.secondary[600],
    border: `${theme.spacing.unit * 0.25}px solid ${theme.palette.secondary[600]}`,
    borderRadius: 5,
    fontSize: 11,
    verticalAlign: 'middle',
    fontWeight: 800
  },
  selected: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 0.25}px`,
    backgroundColor: theme.palette.secondary[600],
    color: theme.palette.common.white
  }
});

export default aboutQuestionnaireStyles;
