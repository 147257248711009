import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import loadingSpinnerWrapperStyles from './loading-spinner-wrapper.styles';
import Spinner from './loading-spinner.component';

const LoadingSpinnerWrapper = ({
  loading,
  children,
  classes,
  loadingClass,
}) => {
  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.overlay}>
          <Spinner middle />
        </div>
      )}
      <div
        className={classNames(classes.body, {
          [classes.disabled]: loading,
          [loadingClass]: loading,
        })}
      >
        {children}
      </div>
    </div>
  );
};

LoadingSpinnerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  loadingClass: PropTypes.string,
};

export default withStyles(loadingSpinnerWrapperStyles)(LoadingSpinnerWrapper);
