import React, { PureComponent, Fragment } from 'react';
import { RoomiDatePicker, RoomiSelect, RoomiCheckbox, RoomiInput } from 'roomi/material';

class DynamicField extends PureComponent {
  render() {
    const {
      field: {
        placeholder,
        label,
        type,
        items,
        min
      },
      ...props
    } = this.props;
    return (
      <Fragment>
        {type === 'checkbox' && <RoomiCheckbox {...props} label={label}/>}
        {type === 'textbox' && <RoomiInput {...props} placeholder={placeholder}/>}
        {type === 'date-picker' && <RoomiDatePicker iconPosition="end" {...props} minDate={min} placeholder={placeholder} openToYearSelection/>}
        {type === 'textarea' && <RoomiInput {...props} rows={4} multiline placeholder={placeholder}/>}
        {type === 'dropdown' &&
        <RoomiSelect {...props} options={items} titleKey="title" valueKey="value" placeholder={placeholder}/>}
      </Fragment>
    );
  }
}

export default DynamicField;
