import { call, takeLatest, all, put, select } from 'redux-saga/effects'

import {
  createRoomiPayAccount,
  updateRoomiPayAddress,
  updateRoomiPayTenants,
  updateRoomiPayRenter,
  fetchRoomiPay,
  setRoomiPay,
  resetRoomiPay,
  fetchInvitation,
  setInvitation,
  acceptInvitation,
  updateRoomiPayPayment,
  cancelRoomiPay
} from '../actions/actions.types'

import {
    fetchRoomiPayAction,
    setRoomiPayAction,
    resetRoomiPayAction,
    setInvitationAction
} from '../actions/actions'

import {
    createRoomiPayAccountCall,
    updateRoomiPayAddressCall,
    updateRoomiPayTenantsCall,
    updateRoomiPayRenterCall,
    fetchRoomiPayCall,
    fetchInvitationCall,
    acceptInvitationCall,
    updateRoomiPayPaymentCall,
    cancelRoomiPayCall
} from './roomipay.data'
import { unAuthenticate, authenticate } from '../../auth/actions/auth.types'

function* handleFetch({ payload }) {
    try {
      const response = yield call(fetchRoomiPayCall)
      if(response.data.roomipay.length == 0) {
        throw new Error("No roomipay")
      }
      yield put(setRoomiPayAction({
        data: response.data.roomipay
      }))
    } catch (error) {
      yield put(setRoomiPayAction({
        data: false
      }))
    }
}

function* handleCreate({ payload }) {
    try {
        const response = yield call(createRoomiPayAccountCall, {
            type: payload.type,
            creatorType: payload.creatorType
        })
        yield put(setRoomiPayAction({
          data: response.data.roomipay
        }))
        payload.resolve && payload.resolve()
    } catch (error) {
        payload.reject(error)
    }
}
function* handleAddressUpdate({ payload }) {
  try {
      const response = yield call(updateRoomiPayAddressCall, payload)
      yield put(setRoomiPayAction({
        data: response.data.roomipay
      }))  
      payload.resolve && payload.resolve()
    } catch (error) {
      payload.reject(error)
  }
}
function* handleRenterUpdate({ payload }) {
  try {
    const response = yield call(updateRoomiPayRenterCall, payload)
    yield put(setRoomiPayAction({
      data: response.data.roomipay
    }))  
    payload.resolve && payload.resolve()
  } catch (error) {
    payload.reject(error)
}
}
function* handleTenantsUpdate({ payload }) {
  try {
    const response = yield call(updateRoomiPayTenantsCall, payload)
    yield put(setRoomiPayAction({
      data: response.data.roomipay
    })) 
    payload.resolve && payload.resolve()

  } catch (error) {
    payload.reject(error)
  }
}

function* handleAuth({payload}) {
  yield put(fetchRoomiPayAction(payload))
}

function* handleUnAuth({payload}) {
  yield put(resetRoomiPayAction())
}

function* handleInvitation({payload}) {
  try {
    const response = yield call(
      fetchInvitationCall,
      payload.id,
      payload.type
    )
    yield put(setInvitationAction({
      invitation: response.data.invitation
    }))
  } catch (error) {
    yield put(setInvitationAction({
      invitation: false
    }))
  }
}

function* handleAcceptInvitation({payload}) {
  try {
    const response = yield call(
      acceptInvitationCall,
      payload.id,
      payload.type
    )
    yield put(setRoomiPayAction({
      data: response.data.roomipay
    })) 
    payload.resolve && payload.resolve()
  } catch (error) {
    payload.reject(error)
  }

}


function* handleCancel() {
  yield call(
    cancelRoomiPayCall
  )
  yield put(setRoomiPayAction({
    data: null
  })) 
}

function* handleRoomiPayPayment({payload}) {
  try {
    const response = yield call(
      updateRoomiPayPaymentCall,
      payload
    )
    yield put(setRoomiPayAction({
      data: response.data.roomipay
    })) 
    payload.resolve && payload.resolve()
  } catch (error) {
    payload.reject(error)
  }
}


function* watchRoomiPayActions() {
    try {

      yield takeLatest(createRoomiPayAccount, handleCreate)
      yield takeLatest(updateRoomiPayAddress, handleAddressUpdate)
      yield takeLatest(updateRoomiPayTenants, handleTenantsUpdate)
      yield takeLatest(updateRoomiPayRenter, handleRenterUpdate)
      yield takeLatest(updateRoomiPayPayment, handleRoomiPayPayment)
      
      yield takeLatest(fetchRoomiPay, handleFetch)

      yield takeLatest(authenticate, handleAuth)
      yield takeLatest(unAuthenticate, handleUnAuth)

      yield takeLatest(fetchInvitation, handleInvitation)
      yield takeLatest(acceptInvitation, handleAcceptInvitation)

      yield takeLatest(cancelRoomiPay, handleCancel)
      

    } catch (error) {
      // console.log(error);
    }
  }
  
  function* roomiPaySaga() {
    try {
      yield all([watchRoomiPayActions()])
    } catch (error) {
      // console.log(error);
    }
  }
  
  export default roomiPaySaga
  