import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RoomiConfirmationDialog } from 'roomi/material'
import { fetchedUser } from '../../../users/actions/user.action'
import { disconnectAccount } from '../../data/socials.data'
import socialDisconnectStyles from './social-disconnect.styles'
import messages from './social-disconnect.text'

@injectIntl
@connect(
  null,
  { fetchedUser }
)
@withStyles(socialDisconnectStyles)
class SocialDisconnect extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['facebook', 'linkedIn']).isRequired,
    trigger: PropTypes.func,
    onError: PropTypes.func
  }

  state = {
    processing: false,
    open: false
  }

  toggle = () => this.setState({ open: !this.state.open })

  handleDisconnect = () => {
    this.setState({ processing: true })
    disconnectAccount(this.props.type).then(
      ({ data: user }) => {
        this.setState({ processing: false })
        this.props.fetchedUser(user)
      },
      error => {
        this.props.onError && this.props.onError(error)
        this.setState({ processing: false })
      }
    )
  }

  trigger = () => (
    <FormattedMessage
      {...messages.trigger}
      values={{ type: this.props.type }}
    />
  )

  render() {
    const {
      intl: { formatMessage },
      trigger = this.trigger,
      type,
      classes
    } = this.props
    const { open, processing } = this.state

    const modalSettings = {
      onCancel: this.toggle,
      onConfirm: this.handleDisconnect,
      cancelText: formatMessage(messages.no),
      confirmText: formatMessage(
        messages[!processing ? 'yes' : 'disconnecting']
      ),
      title: formatMessage(messages[`${type}ModalTitle`]),
      message: formatMessage(messages[`${type}ModalParagraph`])
    }
    return (
      <Fragment>
        {open && (
          <RoomiConfirmationDialog
            {...modalSettings}
            open
            loading={processing}
          />
        )}
        <div className={classes.socialDisconnect} onClick={this.toggle}>
          {trigger()}
        </div>
      </Fragment>
    )
  }
}

export default SocialDisconnect
