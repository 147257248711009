import React, { PureComponent } from 'react';
import { RoomiConfirmationDialog } from 'roomi/material';
import { getError } from '../shared/reducers/react-app.selector';
import { confirmedError } from '../shared/actions/react-app.actions';
import { connect } from 'react-redux';

class GlobalError extends PureComponent {
  render() {
    const { error, confirmedError: closeError } = this.props;
    return (
      <RoomiConfirmationDialog
        open={!!error}
        title="Error"
        onConfirm={closeError}
        confirmText="Okay"
        message={error}
      />
    );
  }
}

const mapStateToProps = state => ({ error: getError(state) });

export default connect(
  mapStateToProps,
  { confirmedError }
)(GlobalError);
