import { size, get, reduce, each, set, unset } from 'lodash';
import { defineMessages } from 'react-intl';

const DEFAULT_REGION_LIMITS = { min: 0, max: 10000 };
export const FORM_NAME = 'postListingForm';
export const USER_INFO_FORM_NAME = 'userInfoForm';
export const MIN_PHOTOS = 2;
export const MAX_IMAGE_SIZE = 40000000;
export const MIN_CHARS = 50;
export const AVAILABILITY_LIMITS = { min: 1, max: 13 };
export const SHORT_TERM_AVAILABILITY_LIMITS = { min: 1, max: 31 };
export const USER_FIELDS = ['biography'];
export const INSURANCE_NOT_SUPPORTED_COUNTRIES = ['IN', 'GB', 'CA'];
export const DEFAULT_LISTING = {
  backgroundCheckRequired: true,
  isInsuranceInterested: false,
  roomLeaseDuration: 1,
  roomLeaseMaxDuration: 31,
  preferredGender: 'Either',
  amenities: [],
  rules: [],
  layout: 'Private Room',
  urlOfListingImages: [],
  roomAvailableDate: {
    __type: 'Date'
  },
  roomAvailableDateFrom: {
    __type: 'Date'
  },
  roomAvailableDateTo: {
    __type: 'Date'
  },
  application: true,
  preferredAge: [],
  listingSource: 'Website',
  isShortTerm: true
};

const messages = defineMessages({
  address: {
    id: 'form.validation.error.address',
    defaultMessage: 'Please enter an address'
  },
  region: {
    id: 'form.validation.error.region',
    defaultMessage: 'Sorry, we do not support your region yet'
  },
  roomRent: {
    id: 'form.validation.error.roomRent',
    defaultMessage: 'Please leave a valid monthly rent amount'
  },
  roomRentMax: {
    id: 'form.validation.error.roomRentMax',
    defaultMessage: 'Rent amount cannot exceed {max}.'
  },
  roomRentMin: {
    id: 'form.validation.error.roomRentMin',
    defaultMessage: 'Rent rent amount must be higher then {min}.'
  },
  dailyRoomRent: {
    id: 'form.validation.error.dailyRoomRent',
    defaultMessage: 'Please leave a valid daily rent amount'
  },
  dailyRoomRentMax: {
    id: 'form.validation.error.dailyRoomRentMax',
    defaultMessage: 'Rent amount cannot exceed {max}.'
  },
  dailyRoomRentMin: {
    id: 'form.validation.error.dailyRoomRentMin',
    defaultMessage: 'Rent rent amount must be higher then {min}.'
  },
  roomDeposit: {
    id: 'form.validation.error.roomDeposit',
    defaultMessage: 'Please leave a valid deposit amount'
  },
  roomDepositMax: {
    id: 'form.validation.error.roomDepositMax',
    defaultMessage: 'Rent deposit cannot exceed {max}.'
  },
  roomDepositMin: {
    id: 'form.validation.error.roomDepositMin',
    defaultMessage: 'Rent deposit amount must be higher then {min}.'
  },
  'roomAvailableDate.iso': {
    id: 'form.validation.error.roomAvailableDate.iso',
    defaultMessage: 'Please enter the date the room will be available'
  },
  'roomAvailableDateFrom.iso': {
    id: 'form.validation.error.roomAvailableDateFrom.iso',
    defaultMessage: 'Please enter the date the room will be available'
  },
  'roomAvailableDateTo.iso': {
    id: 'form.validation.error.roomAvailableDateTo.iso',
    defaultMessage: 'Please enter the date the room will be available'
  },
  listingDescription: {
    id: 'form.validation.error.listingDescription',
    defaultMessage: 'Please leave at least {MIN_CHARS} characters'
  },
  photos: {
    id: 'form.validation.error.photos',
    defaultMessage: 'Please upload at least {MIN_PHOTOS} photos'
  },
  onePhoto: {
    id: 'form.validation.error.onePhoto',
    defaultMessage: 'You\'ve only uploaded one photo. Please upload one more so your future roommate can learn more!'
  },
  preferredAge: {
    id: 'form.validation.error.preferredAge',
    defaultMessage: 'Please select a preferred age'
  },
  referral: {
    id: 'form.validation.error.referral',
    defaultMessage: 'Please provide a response'
  },
  layout: {
    id: 'form.validation.error.layout',
    defaultMessage: 'Please select listing type'
  },
  totalBedrooms: {
    id: 'form.validation.error.totalBedrooms',
    defaultMessage: 'Please select number of bedrooms'
  },
  totalBathrooms: {
    id: 'form.validation.error.totalBathrooms',
    defaultMessage: 'Please select number of bathrooms'
  },
  totalOccupants: {
    id: 'form.validation.error.totalOccupants',
    defaultMessage: 'Please select number of occupants'
  },
  booting: {
    id: 'form.validation.step1.booting',
    defaultMessage: 'Booting up to save your listing'
  },
  creatingListing: {
    id: 'form.validation.step2.creatingListing',
    defaultMessage: 'Creating your listing'
  },
  uploadingImages: {
    id: 'form.validation.step3.uploadingImages',
    defaultMessage: 'Uploading your listing images'
  },
  checking: {
    id: 'form.validation.step3.checking',
    defaultMessage: 'Checking your login status'
  },
  savingUserData: {
    id: 'form.validation.step5.savingUser',
    defaultMessage: 'Saving your profile changes'
  },
  savingListingData: {
    id: 'form.validation.step5.savingListingData',
    defaultMessage: 'Saving listing changes'
  },
  linkingListing: {
    id: 'form.validation.step4.linkingListing',
    defaultMessage: 'Adding your listing to your account'
  },
  saveModalTitle: {
    id: 'form.validation.modal.title',
    defaultMessage: 'Saving Your Listing...'
  }
});

export const SAVE_STEPS = [{
  _id: 'booting',
  message: messages.booting
}, {
  _id: 'creating',
  message: messages.creatingListing
}, {
  _id: 'uploading',
  message: messages.uploadingImages
}, {
  _id: 'checking',
  message: messages.checking
}, {
  _id: 'savingUserData',
  message: messages.savingUserData
}, {
  _id: 'savingListingData',
  message: messages.savingListingData
}, {
  _id: 'linking',
  message: messages.linkingListing
}];

export const saveModal = {
  open: false,
  stepsCompleted: 0,
  title: messages.saveModalTitle,
  message: SAVE_STEPS[0].message,
  enabledLogin: false
};
export const warningModal = {
  open: false
};
export const resultModal = {
  open: false
};
export const importModal = {
  open: false
};

export const warningFn = (values, props) => {   
  const STEPS = values.isShortTerm ? props.SHORT_TERM_STEPS : props.STEPS;
  const rentLimit = get(values, 'region.limits.rent', DEFAULT_REGION_LIMITS);
  const _warning = {};
  const warnings = reduce(STEPS, (warn, formField, i) => {
    each(formField.fields, field => {
      if (!field.validator) return;
      const args = [get(values, field.name)];
      if (field.name === 'roomRent' || field.name === 'roomDeposit' || field.name === 'dailyRoomRent') args.push(rentLimit);
      const { message } = field.validator(...args);
      if (message) {
        const translated = props.intl.formatMessage(message, { ...rentLimit, MIN_PHOTOS, MIN_CHARS });
        set(warn, field.name, translated);
        set(_warning, `[${i}][${field.name}]`, translated);
      } else {
        unset(warn, field.name);
        unset(_warning, `[${i}][${field.name}]`);
      }
    });
    return warn;
  }, {});
  warnings._warning = _warning;
  return warnings;
};

export const SHORT_TERM_STEPS = [{
  name: 'The Basics',
  fields: [{
    name: 'listingAddress',
    validator: value => {
      if (!value) return { message: messages.address };
      return {};
    }
  }, {
    name: 'region',
    validator: value => {
      if (!value) return { message: messages.region };
      return {};
    }
  }, {
    name: 'roomRent',
    validator: (value, { min, max }) => {
      if (!value) return { message: messages.roomRent };
      if (value >= max) return { message: messages.roomRentMax };
      if (value <= min) return { message: messages.roomRentMin };
      return {};
    }
  }, {
    name: 'roomAvailableDateFrom.iso',
    validator: value => {
      if (!value) return { message: messages['roomAvailableDateFrom.iso'] };
      return {};
    }
  }, {
    name: 'roomAvailableDateTo.iso',
    validator: value => {
      if (!value) return { message: messages['roomAvailableDateTo.iso'] };
      return {};
    }
  }]
}, {
  name: 'The Room',
  fields: [{
    name: 'amenities'
  }, {
    name: 'layout',
    validator: value => {
      if (!value) return { message: messages.layout };
      return {};
    }
  }, {
    name: 'totalBedrooms',
    validator: value => {
      if (!value) return { message: messages.totalBedrooms };
      return {};
    }
  }, {
    name: 'totalBathrooms',
    validator: value => {
      if (!value) return { message: messages.totalBathrooms };
      return {};
    }
  }, {
    name: 'totalOccupants',
    validator: value => {
      if (!value) return { message: messages.totalOccupants };
      return {};
    }
  }, {
    name: 'urlOfListingImages',
    validator: value => {
      if (size(value) < MIN_PHOTOS && size(value) !== 1) return { message: messages.photos };
      if (size(value) === 1) return { message: messages.onePhoto };
      return {};
    }
  }]
}, {
  name: 'Listing Description',
  fields: [{
    name: 'listingDescription',
    validator: (value = '') => {
      if (size(value.replace(/  +/g, ' ').replace(/(\r?\n)+|(\r)+/g, ' ')) < MIN_CHARS) return { message: messages.listingDescription };
      return {};
    }
  }, {
    name: 'neighborhoodDescription'
  }]
}, {
  name: 'Roommate Preferences',
  fields: [{
    name: 'rules'
  }, {
    name: 'preferredAge',
    validator: value => {
      if (!size(value)) return { message: messages.preferredAge };
      return {};
    }
  }, {
    name: 'preferredGender'
  }, {
    name: 'backgroundCheckRequired'
  }, {
    name: 'roommateDescription'
  }]
}, {
  name: 'Your Information',
  fields: [{
    name: 'realEstatePro'
  }, {
    name: 'referralSource',
    validator: value => {
      if (!size(value)) return { message: messages.referral };
      return {};
    }
  }, {
    name: 'isInsuranceInterested'
  }]
}];


export const STEPS = [{
  name: 'The Basics',
  fields: [{
    name: 'listingAddress',
    validator: value => {
      if (!value) return { message: messages.address };
      return {};
    }
  }, {
    name: 'region',
    validator: value => {
      if (!value) return { message: messages.region };
      return {};
    }
  }, {
    name: 'roomRent',
    validator: (value, { min, max }) => {
      if (!value) return { message: messages.roomRent };
      if (value >= max) return { message: messages.roomRentMax };
      if (value <= min) return { message: messages.roomRentMin };
      return {};
    }
  }, {
    name: 'roomDeposit',
    validator: (value, { min, max }) => {
      if (value >= max) return { message: messages.roomDepositMax };
      if (value < min) return { message: messages.roomDepositMin };
      return {};
    }
  }, {
    name: 'roomAvailableDate.iso',
    validator: value => {
      if (!value) return { message: messages['roomAvailableDate.iso'] };
      return {};
    }
  }]
}, {
  name: 'The Room',
  fields: [{
    name: 'amenities'
  }, {
    name: 'layout',
    validator: value => {
      if (!value) return { message: messages.layout };
      return {};
    }
  }, {
    name: 'totalBedrooms',
    validator: value => {
      if (!value) return { message: messages.totalBedrooms };
      return {};
    }
  }, {
    name: 'totalBathrooms',
    validator: value => {
      if (!value) return { message: messages.totalBathrooms };
      return {};
    }
  }, {
    name: 'totalOccupants',
    validator: value => {
      if (!value) return { message: messages.totalOccupants };
      return {};
    }
  }, {
    name: 'urlOfListingImages',
    validator: value => {
      if (size(value) < MIN_PHOTOS && size(value) !== 1) return { message: messages.photos };
      if (size(value) === 1) return { message: messages.onePhoto };
      return {};
    }
  }]
}, {
  name: 'Listing Description',
  fields: [{
    name: 'listingDescription',
    validator: (value = '') => {
      if (size(value.replace(/  +/g, ' ').replace(/(\r?\n)+|(\r)+/g, ' ')) < MIN_CHARS) return { message: messages.listingDescription };
      return {};
    }
  }, {
    name: 'neighborhoodDescription'
  }]
}, {
  name: 'Roommate Preferences',
  fields: [{
    name: 'rules'
  }, {
    name: 'preferredAge',
    validator: value => {
      if (!size(value)) return { message: messages.preferredAge };
      return {};
    }
  }, {
    name: 'preferredGender'
  }, {
    name: 'backgroundCheckRequired'
  }, {
    name: 'roommateDescription'
  }]
}, {
  name: 'Your Information',
  fields: [{
    name: 'realEstatePro'
  }, {
    name: 'referralSource',
    validator: value => {
      if (!size(value)) return { message: messages.referral };
      return {};
    }
  }, {
    name: 'isInsuranceInterested'
  }]
}];
