import { fetched, errored, fetching, reset } from '../actions/user.types';
import { addCard, removeCard } from '../../payment/actions/payment.types';
import {
  addPayoutAccount,
  deletePayoutAccount,
  setDefaultPayoutAccount
} from '../../payout/actions/payout.types';
import updateHelper from 'immutability-helper';

const initialState = { fetching: false, fetched: false };

export default function(
  state = initialState,
  { type, user, error, payload } = {}
) {
  switch (type) {
    case fetching:
      return updateHelper(state, { $set: { fetching: true, fetched: false } });
    case fetched:
      return updateHelper(state, {
        $set: { data: user, fetching: false, fetched: true }
      });
    case errored:
      return updateHelper(state, {
        $set: { error, fetching: false, fetched: true }
      });
    case reset:
      return initialState;
    case addCard:
      return updateHelper(state, {
        data: { accounts: { payment: { $push: [payload] } } }
      });
    case removeCard:
      const index = state.data.accounts.payment.indexOf(payload);
      return updateHelper(state, {
        data: { accounts: { payment: { $splice: [[index, 1]] } } }
      });
    case addPayoutAccount:
      return updateHelper(state, {
        data: { accounts: { payout: { $push: [payload] } } }
      });
    case deletePayoutAccount:
      const accountIndex = state.data.accounts.payout.findIndex(
        account => account.id === payload
      );
      return updateHelper(state, {
        data: { accounts: { payout: { $splice: [[accountIndex, 1]] } } }
      });
    case setDefaultPayoutAccount:
      const currentDefaultAccountIndex = state.data.accounts.payout.findIndex(
        account => account.isDefault
      );
      const newDefaultAccountIndex = state.data.accounts.payout.findIndex(
        account => account.id === payload
      );
      const accounts = [...state.data.accounts.payout];
      accounts[currentDefaultAccountIndex] = updateHelper(
        accounts[currentDefaultAccountIndex],
        { isDefault: { $set: false } }
      );
      accounts[newDefaultAccountIndex] = updateHelper(
        accounts[newDefaultAccountIndex],
        { isDefault: { $set: true } }
      );
      return updateHelper(state, {
        data: { accounts: { payout: { $set: accounts } } }
      });
    default:
      return state;
  }
}
