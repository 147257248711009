import { removeAuthToken } from './auth/utils/session-manager.util'
import BrowserNotSupported from './browser-not-supported/browser-not-supported'
import BrowserInfo from './shared/utils/browser-info/browser.info'

const { name } = new BrowserInfo().getBrowserInfo()
const NON_SUPPORTED_BROWSERS = ['Microsoft Internet Explorer']

const checkBrowserSupport = WrappedComponent => {
  if (NON_SUPPORTED_BROWSERS.indexOf(name) >= 0) {
    removeAuthToken()
    return BrowserNotSupported
  }
  return WrappedComponent
}

export default checkBrowserSupport
