const favoriteButtonStyles = theme => ({
  iconButton: {
    cursor: 'pointer',
    height: 28,
    width: 28,
    '&:hover': {
      background: 'transparent'
    },
    '& svg path': {
      fill: theme.palette.common.white
    }
  },
  favorited: {
    '& svg path': {
      fill: theme.palette.error.main
    }
  }
});

export default favoriteButtonStyles;
