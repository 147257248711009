import { post, remove } from '../../shared/utils/fetch.util'

export function connectLinkedIn(code, path) {
  const redirectUrl = `${window.location.origin}${path || '/linkedIn'}`
  return post(`/v2/users/me/social/accounts/linkedIn`, { code, redirectUrl })
}

export const connectFacebook = accessToken =>
  post(`/v2/users/me/social/accounts/facebook`, { accessToken })
export const disconnectAccount = type =>
  remove(`/v2/users/me/social/accounts/${type}`)
