import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { RoomiDialog } from 'roomi/material';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import StripeCreditCardForm from '../new-credit-card-form/credit-card-form-stripeUI.component';
// import NewCreditCardForm from '../new-credit-card-form/new-credit-card-form.component'
import newCreditCardDialogStyles from './new-credit-card-dialog.styles';
import { withStyles } from '@material-ui/core';
import text from './new-credit-card-dialog.component.text';
import AsyncStripeProvider from '../../stripe/async-stripe-provider';
// import { config } from '../../../../test/data';

@injectIntl
@withStyles(newCreditCardDialogStyles)
class NewCreditCardDialog extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    userId: PropTypes.string.isRequired,
    onCardAdded: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const {
      open,
      userId,
      onCancel,
      onCardAdded,
      classes,
      intl: { formatMessage },
    } = this.props;
    return (
      <RoomiDialog open={open}>
        {/* <NewCreditCardForm
              userId={userId}
              onCardAdded={onCardAdded}
              onCancel={onCancel}
              className={classes.root}
              title={formatMessage(text.title)}
              subTitle={formatMessage(text.subtitle)}
            /> */}
        <AsyncStripeProvider apiKey={process.env.STRIPE_KEY}>
          <Elements>
            <StripeCreditCardForm
              userId={userId}
              onCardAdded={onCardAdded}
              onCancel={onCancel}
              className={classes.root}
              title={formatMessage(text.title)}
              subTitle={formatMessage(text.subtitle)}
            />
          </Elements>
        </AsyncStripeProvider>
      </RoomiDialog>
    );
  }
}

export default NewCreditCardDialog;
