import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import listingSelectorStyles from './listing-selector.component.styles'
import Listing from '../../../listings/model/listing.model'
import SelectableListingCard from '../selectable-listing-card/selectable-listing-card.component'
import { RoomiButton } from 'roomi/material'
import text from './listing-selector.text'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'

@injectIntl
@withStyles(listingSelectorStyles)
class ListingSelector extends PureComponent {
  static propTypes = {
    listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)).isRequired,
    selectedListing: PropTypes.instanceOf(Listing),
    onCancel: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired
  }

  state = { selectedListing: this.props.selectedListing }

  selectListing = () => this.props.onSelect(this.state.selectedListing)

  handleListingClick = listing => this.setState({ selectedListing: listing })

  render() {
    const {
      classes,
      intl: { formatMessage }
    } = this.props
    return (
      <div>
        <div className={classes.title}>
          <FormattedMessage {...text.title} />
        </div>
        {this.props.listings.map(listing => (
          <SelectableListingCard
            key={listing._id}
            onClick={this.handleListingClick}
            listing={listing}
            selected={listing === this.state.selectedListing}
          />
        ))}
        <div className={classes.actions}>
          <RoomiButton
            className={classes.cancelBtn}
            outline
            secondary
            small
            onClick={this.props.onCancel}
            label={formatMessage(text.cancelButton)}
          />
          <RoomiButton
            primary
            small
            onClick={this.selectListing}
            label={formatMessage(text.continueButton)}
          />
        </div>
      </div>
    )
  }
}

export default ListingSelector
