import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'new.credit.card.dialog.form.title',
    defaultMessage: 'Payment Details'
  },
  subtitle: {
    id: 'new.credit.card.dialog.form.subtitle',
    defaultMessage: 'Enter the following credit card details for payment:'
  }
});
