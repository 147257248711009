import { defineMessages } from 'react-intl';

export default defineMessages({
  message: {
    id: 'confirmation.dialog.default.message',
    defaultMessage: 'Are you sure?'
  },
  confirmButton: {
    id: 'confirmation.dialog.default.confirm.button.text',
    defaultMessage: 'Confirm'
  },
  cancelButton: {
    id: 'confirmation.dialog.default.cancel.button.text',
    defaultMessage: 'Cancel'
  }
});
