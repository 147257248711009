import React from 'react';
import LoadingSpinnerWrapper from './loading-spinner-wrapper';

const loadingSpinnerHOC = getPropsFn => Component => props => {
  // eslint-disable-line
  const { loading, loadingClass } = getPropsFn(props);
  return (
    <LoadingSpinnerWrapper loading={loading} loadingClass={loadingClass}>
      <Component {...props} />
    </LoadingSpinnerWrapper>
  );
};

export default loadingSpinnerHOC;
