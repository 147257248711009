import {
  conversationsFetched,
  conversationsFetching,
  conversationMoved,
  conversationCreate,
  conversationReset,
  conversationCreated,
  conversationRemoved,
  conversationUpdated,
  conversationRecipientUpdated,
  fetchCurrentChatUserInfo,
  fetchCurrentChatUserInfoSuccess,
  fetchCurrentChatUserInfoError,
  fetchCurrentListingInfo,
  fetchCurrentListingInfoSuccess,
  fetchCurrentListingInfoError,
} from './conversation.types'

import Conversation from '../models/conversation.model'

export const fetchedConversations = data => ({
  type: conversationsFetched,
  conversations: Conversation.createList(data)
})

export const createConversation = ({ userId, listingId }) => ({
  type: conversationCreate,
  userId,
  listingId
})
export const createdConversation = data => {
  const conversation = new Conversation(data)
  return {
    type: conversationCreated,
    conversation,
    conversationUuid: conversation.uuid
  }
}

export const updatedConversation = data => {
  const conversation = new Conversation(data)
  return {
    type: conversationUpdated,
    conversation,
    conversationUuid: conversation.uuid
  }
}

export const removedConversation = id => ({
  type: conversationRemoved,
  conversationUuid: id.split('/').pop()
})
export const fetchingConversations = () => ({ type: conversationsFetching })
export const movedConversation = (id, from, to) => ({
  type: conversationMoved,
  payload: { id, from, to }
})
export const resetConversation = () => ({ type: conversationReset })
export const updatedIdentity = (conversationUuid, recipient) => ({
  type: conversationRecipientUpdated,
  recipient,
  conversationUuid
})

export const actionFetchCurrentChatUserInfo = (userId) => ({
  type: fetchCurrentChatUserInfo,
  userId,
});

export const actionFetchCurrentChatUserInfoSuccess = (userId, userInfo) => ({
  type: fetchCurrentChatUserInfoSuccess,
  userId,
  userInfo,
});

export const actionFetchCurrentChatUserInfoError = (userId) => ({
  type: fetchCurrentChatUserInfoError,
  userId,
});

export const actionFetchCurrentListingInfo = (listingId) => ({
  type: fetchCurrentListingInfo,
  listingId,
});

export const actionFetchCurrentListingInfoSuccess = (listingId, listingInfo) => ({
  type: fetchCurrentListingInfoSuccess,
  listingId,
  listingInfo,
});

export const actionFetchCurrentListingInfoError = (listingId) => ({
  type: fetchCurrentListingInfoError,
  listingId,
});
