import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Icon } from '@material-ui/core'
import classNames from 'classnames'
import { ReactComponent as AmericanExpress } from '../../assets/american-express.svg'
import { ReactComponent as Discover } from '../../assets/discover.svg'
import { ReactComponent as MasterCard } from '../../assets/mastercard.svg'
import { ReactComponent as Visa } from '../../assets/visa.svg'
import { ReactComponent as DinersClub } from '../../assets/visa.svg'
import { ReactComponent as DefaultIcon } from '../../assets/default-credit-card.svg'
import creditCardItemStyles from './credit-card-item.component.styles'

const creditCardImageMap = {
  Visa,
  Discover,
  MasterCard,
  'American Express': AmericanExpress,
  'Diners Club': DinersClub
}

@withStyles(creditCardItemStyles)
class CreditCardItem extends PureComponent {
  static propTypes = {
    brand: PropTypes.string,
    logo: PropTypes.string,
    endingWith: PropTypes.string.isRequired,
    isActive: PropTypes.bool
  }

  render() {
    const { brand, logo, endingWith, isActive, classes } = this.props
    const CreditIcon = creditCardImageMap[brand] || DefaultIcon
    const InstitutionLogo = (
      <div className={classes.institutionLogoContainer}>
        <img
          className={classes.institutionLogoImage}
          src={`data:image/jpeg;base64,${logo}`}
          alt=""
        />
      </div>
    )

    return (
      <div
        className={classNames(classes.creditCardItem, 'layout-row', {
          [classes.active]: isActive
        })}
      >
        <div
          className={classNames(classes.creditCardIconContainer, 'flex-center')}
        >
          {logo ? InstitutionLogo : <CreditIcon height={38} width={56} />}
        </div>
        <div
          className={classNames(
            classes.creditCardNumberContainer,
            'flex flex-center layout-row'
          )}
        >
          <span className="flex">
            xxxx-xxxx-xxxx-
            {endingWith}
          </span>
          {isActive && <Icon className={classes.checkIcon}>check</Icon>}
        </div>
      </div>
    )
  }
}

export default CreditCardItem
