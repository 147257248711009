import { get } from 'lodash';

export const getUserIdentity = ({ usrFirstName, usrLastName, username }) => {
  return usrFirstName && usrLastName ? `${usrFirstName} ${usrLastName}` : username;
};

export const getUserDetails = (user) => ({
  type: user.provider,
  dob: user.dob,
  usrEmail: user.email,
  usrFirstName: user.usrFirstName,
  usrGender: user.usrGender,
  usrLastName: user.usrLastName,
  usrRegion: get(user, 'usrRegionOfInterest.regionName'),
  isMoverOrLister: user.usrType
});
