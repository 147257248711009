import { clearPayoutFlow, setPayoutFlowStep, toggleAddPayoutDialog, paymentConfigsFetched } from '../actions/payout.types';
import update from 'immutability-helper';

export const payoutFlowSteps = {
  Empty: 'EMPTY',
  Select: 'SELECT',
  Add: 'ADD'
};


const DEFAULT_STATE = {
  step: null, 
  openAddDialog: false, 
  configs: { data: {}, error: null }
};

export default (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case toggleAddPayoutDialog:
      return { ...state, openAddDialog: !state.openAddDialog };
    case setPayoutFlowStep:
      return { ...state, step: payload };
    case clearPayoutFlow:
      return DEFAULT_STATE;
    case paymentConfigsFetched:
      return update(state, {
        configs: { data: { $set: payload.data } } 
      });
    default:
      return state;
  }
};
