import { FormControlLabel, Switch } from '@material-ui/core';
import React, { PureComponent } from 'react';

class RoomiSwitch extends PureComponent {
  render() {
    const {
      label,
      labelPlacement,
      input: { value: _value, ...restInput } = {},
      value = _value || '',
      checked = _value,
      color = 'primary',
      ...rest
    } = this.props;
    return (
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        control={<Switch
          {...rest}
          color={color}
          checked={checked}
          {...restInput}
          value={String(value)}
        />}
      />
    );
  }
}

export default RoomiSwitch;
