import { Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Badges } from 'roomi/common'
import UserAvatar from '../../../users/components/user-avatar/user-avatar.component'
import { getUserName } from '../../../users/utils/user-fields.formatter'
import ListingCarousel from '../listing-card-carousel/listing-card-carousel.component'
import ListingActions from './listing-card-actions/listing-card-actions.component'
import ListingCardInfo from './listing-card-info/listing-card-info.component'
import ShortTermInfoCard from '../short-term-info-card/short-term-info-card.component'
import listingCardStyles from './listing-card.styles'

@withStyles(listingCardStyles)
class ListingCard extends PureComponent {
  static propTypes = {
    listing: PropTypes.object.isRequired,
    slider: PropTypes.bool.isRequired,
    actions: PropTypes.bool,
    tooltip: PropTypes.bool,
    openInTab: PropTypes.bool,
    showOwner: PropTypes.bool.isRequired,
    disableNavigation: PropTypes.bool,
    badges: PropTypes.bool,
    className: PropTypes.string,
    classes: PropTypes.object,
    onRedirect: PropTypes.func
  }

  handleRedirect = () => {
    this.props.onRedirect && this.props.onRedirect(this.props.listing._id)
  }

  render() {
    const {
      listing,
      actions,
      showOwner,
      slider,
      tooltip,
      openInTab,
      disableNavigation,
      badges,
      className,
      classes
    } = this.props

    const href = `/rooms-for-rent/${listing._id}`
    return (
      <div className={classNames(classes.listingCard, className)}>
        {badges && (
          <div className={classes.badgesContainer}>
            <Badges type="listing" tooltip={tooltip} ids={listing.badges} />
            {listing.isShortTerm && <ShortTermInfoCard />}
          </div>
        )}
        {actions && (
          <div className={classes.actionsContainer}>
            <ListingActions id={listing._id} />
          </div>
        )}
        <div className={classes.carouselContainer}>
          <ListingCarousel
            href={disableNavigation ? null : href}
            onRedirect={this.handleRedirect}
            openInTab={openInTab}
            listing={listing}
            slider={slider}
          />
        </div>
        <div className={classes.bottomContainer}>
          <div
            className={classNames(classes.infoContainer, {
              [classes.withOwner]: showOwner
            })}
          >
            <ListingCardInfo
              openInTab={openInTab}
              showOwner={showOwner}
              href={href}
              onRedirect={this.handleRedirect}
              disableNavigation={disableNavigation}
              listing={listing}
            />
          </div>
          {showOwner && (
            <div className={classes.avatarContainer}>
              <UserAvatar
                user={listing.user}
                openInTab={openInTab}
                badges={badges}
                tooltip={tooltip}
              >
                <Typography
                  color="inherit"
                  className={classes.ownerName}
                  noWrap
                >
                  {getUserName(listing.user)}
                </Typography>
              </UserAvatar>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ListingCard
