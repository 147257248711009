import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import variationItemStyles from './variation-item.styles';

@withStyles(variationItemStyles)
class VariationItem extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    variation: PropTypes.object.isRequired
  };

  onClick = () => {
    this.props.onClick && this.props.onClick(this.props.variation);
  };

  render() {
    const { variation, active, classes } = this.props;
    return (
      <pre
        className={classNames(classes.variationItem, { [classes.active]: active })}
        onClick={this.onClick}
      >
        {JSON.stringify(variation)}
      </pre>
    );
  }
}

export default VariationItem;
