import { fetchingBadges, fetchedBadges, errorBadges } from './badges.types'
import { get } from '../data/badges.data'

export const getBadges = () => dispatch => {
  dispatch(badgesFetching())
  get().then(
    resp => {
      dispatch(badgesFetched(resp.data))
    },
    resp => {
      dispatch(badgesError(resp))
    }
  )
}

export const badgesFetching = () => ({
  type: fetchingBadges
})

export const badgesFetched = data => ({
  type: fetchedBadges,
  payload: data
})

export const badgesError = data => ({
  type: errorBadges,
  payload: data
})
