import { map, each } from 'lodash';
import {
  listingFetched,
  listingError,
  listingUpdated,
  listingsFetched,
  remove,
  suggestedListingsFetched,
  suggestedListingsError,
  listingConfigsFetched
} from '../actions/listings.types';
import update from 'immutability-helper';

const initialState = {
  data: {},
  suggested: {},
  configs: { data: {}, error: null }
};

export default (
  state = initialState,
  { type, payload, id, listing, listings, error, update: updatedData } = {}
) => {
  let container;
  let dataQuery;
  let containerUpdate;
  switch (type) {
    case listingFetched:
      container = { fetched: true };
      return update(state, {
        [id]: { $set: container },
        data: { [id]: { $set: listing } }
      });
    case listingsFetched:
      dataQuery = getDataQueryForListings(listings);
      containerUpdate = getContainerUpdatesForListings(listings);
      return update(state, { data: dataQuery, ...containerUpdate });
    case listingUpdated:
      if (!state.data[id]) return state;
      return update(state, { data: { [id]: { $merge: updatedData } } });
    case listingError:
      container = { error, fetched: false };
      return update(state, { [id]: { $set: container } });
    case remove:
      return update(state, { $unset: [id], data: { $unset: [id] } });
    case suggestedListingsFetched:
      dataQuery = getDataQueryForListings(listings);
      containerUpdate = getContainerUpdatesForListings(listings);
      container = {
        suggestedListings: map(listings, '_id'),
        fetched: true,
        fetching: false
      };
      return update(state, {
        data: dataQuery,
        ...containerUpdate,
        suggested: { [id]: { $set: container } }
      });
    case suggestedListingsError:
      container = { error, fetched: false, fetching: false };
      return update(state, { suggested: { [id]: { $set: container } } });
    case listingConfigsFetched:
      return update(state, {
        configs: { data: { [payload.id]: { $set: payload.data } } }
      });
    default:
      return state;
  }
};

function getDataQueryForListings(listings) {
  const query = {};
  each(listings, listing => {
    query[listing._id] = { $set: listing };
  });
  return query;
}

function getContainerUpdatesForListings(listings) {
  const query = {};
  each(listings, listing => {
    const container = { fetched: true };
    query[listing._id] = { $set: container };
  });
  return query;
}
