import { logEvents } from '../data/events.data'
import { set, remove, get } from '../../shared/utils/storage/local-storage.util'

export const eventRecordInterval = 1000 * 10
export const storageKey = 'pending-events'
export const navigationEvents = 'navigation'

setInterval(sendEvents, eventRecordInterval)
sendEvents()

export function saveEvent({ eventName, data = {}, targetId }) {
  let eventQueue = get(storageKey).val || []
  eventQueue.push({
    name: eventName,
    targetId: targetId,
    details: data,
    timestamp: new Date().toISOString()
  })
  saveEventsToStorage(eventQueue)
}

export function sendEvents() {
  let eventQueue = get(storageKey).val || []
  if (!eventQueue.length) return
  removeEventsFromStorage()
  logEvents(eventQueue).catch(() => {
    let events = get(storageKey).val || []
    saveEventsToStorage(events.concat(eventQueue))
  })
}

export function removeEventsFromStorage() {
  remove(storageKey)
}

export function saveEventsToStorage(events) {
  set(storageKey, events)
}

export function saveRoute(page, section) {
  const { val: navigation = {} } = get(navigationEvents)
  navigation.from = navigation.to || {}
  navigation.to = {
    url: window.location.pathname + window.location.search,
    page,
    section
  }
  set(navigationEvents, navigation)
}

export function getOldRoute() {
  const { val: { from } = {} } = get(navigationEvents)
  return from && from.page
}

export function getCurrentRoute() {
  const { val: { to } = {} } = get(navigationEvents)
  return to && to.page
}
