import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RoomiButton } from 'roomi/material';
import { Elements } from 'react-stripe-elements';
import propValidator from 'roomi/utils/prop-validator';
import { getPaymentAccounts } from '../../../shared/reducers/auth.user.selector';
import AddCreditCardButton from '../add-credit-card-button/add-credit-card-button.component';
import CreditCardSelector from '../credit-card-selector/credit-card-selector.component';
import NewCardDialog from '../new-credit-card-dialog/new-credit-card-dialog.component';
// import NewCreditCardForm from '../new-credit-card-form/new-credit-card-form.component'
import StripeCreditCardForm from '../new-credit-card-form/credit-card-form-stripeUI.component';
// import NewPlaidDialog from '../../../payment/components/new-plaid-account/new-plaid-account.component'
import NewBankAccountDialog from '../../../payout/components/new-bank-account-dialog/new-bank-account-dialog.component';
import paymentStyles from './payment.component.styles';
import text from './payment.text';
import AsyncStripeProvider from '../../stripe/async-stripe-provider';

const mapStateToProps = state => ({
  paymentAccounts: getPaymentAccounts(state),
});

@connect(mapStateToProps)
@injectIntl
@withStyles(paymentStyles)
class Payment extends PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    onSelect: propValidator({ required: ['onSubmit'], type: 'function' }),
    onSubmit: propValidator({ required: ['onSelect'], type: 'function' }),
    addNewCardInDialog: PropTypes.bool,
    onBack: PropTypes.func,
    isDisabled: PropTypes.bool,
    newCardSubmitText: PropTypes.string,
    newCardTitle: PropTypes.string,
    newCardSubTitle: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    error: PropTypes.string,
    classes: PropTypes.object,
  };

  static defaultProps = {
    addNewCardInDialog: false,
  };

  constructor(props) {
    super(props);
    const { paymentAccounts, addNewCardInDialog } = props;
    const step = addNewCardInDialog || !!paymentAccounts.length ? 0 : 1;
    const defaultPaymentAccount = paymentAccounts.find(
      account => account.isDefault,
    );
    const selected = defaultPaymentAccount && defaultPaymentAccount.id;
    this.props.onSelect && this.props.onSelect(defaultPaymentAccount);
    this.state = { step, selected };
  }

  handleSelect = account => {
    this.setState({ selected: account.id });
    this.props.onSelect && this.props.onSelect(account);
  };

  handleSubmit = () => this.props.onSubmit(this.state.selected);

  addCreditCard = () => this.setState({ step: 1 });

  cancelAddCreditCard = () => this.setState({ step: 0 });

  addBankAccount = () => this.setState({ step: 2 });

  cancelAddBankAccount = () => this.setState({ step: 0 });

  cardAdded = card => {
    this.setState({ step: 0 });
    this.handleSelect(card);
  };

  render() {
    const {
      paymentAccounts,
      userId,
      addNewCardInDialog,
      onSubmit,
      onBack,
      isDisabled,
      error,
      newCardSubmitText,
      title,
      subTitle,
      newCardTitle,
      newCardSubTitle,
      classes,
      intl: { formatMessage },
    } = this.props;

    const { selected, step } = this.state;
    const showActions = !!onSubmit || !!onBack;
    const showCardSelector = step === 0 || addNewCardInDialog;
    const showCreditCardForm = step === 1;
    const showBankAccountFrom = step === 2;

    const cardAccounts = paymentAccounts.filter(
      acc => acc.type && acc.type.toLowerCase() === 'card',
    );
    const bankAccounts = paymentAccounts.filter(
      acc => acc.type && acc.type.toLowerCase() === 'bank_account',
    );

    return (
      <div>
        {showCardSelector && (
          <Fragment>
            {title && <div className={classes.title}>{title}</div>}
            {error && <div className={classes.paymentError}>{error}</div>}
            {subTitle && <div className={classes.subtitle}>{subTitle}</div>}
            <CreditCardSelector
              paymentAccounts={cardAccounts}
              onSelect={this.handleSelect}
              selectedAccount={selected}
            />
            <div className={classes.addCardButton}>
              <AddCreditCardButton onClick={this.addCreditCard} />
            </div>
            <CreditCardSelector
              paymentAccounts={bankAccounts}
              onSelect={this.handleSelect}
              selectedAccount={selected}
            />
            <div className={classes.addCardButton}>
              <AddCreditCardButton
                text="Add bank account"
                onClick={this.addBankAccount}
              />
            </div>
            {showActions && (
              <div className={classes.actions}>
                {onBack && (
                  <RoomiButton
                    className={classes.cancelBtn}
                    outline
                    secondary
                    small
                    onClick={onBack}
                    disabled={isDisabled}
                    label={formatMessage(text.backButton)}
                  />
                )}
                {onSubmit && (
                  <RoomiButton
                    primary
                    small
                    onClick={this.handleSubmit}
                    disabled={isDisabled || !selected}
                    loading={isDisabled}
                    label={formatMessage(text.submitButton)}
                  />
                )}
              </div>
            )}
          </Fragment>
        )}
        {showCreditCardForm &&
          (addNewCardInDialog ? (
            <NewCardDialog
              open
              userId={userId}
              onCardAdded={this.cardAdded}
              onCancel={this.cancelAddCreditCard}
            />
          ) : (
            // <NewCreditCardForm
            //   userId={userId}
            //   onCardAdded={this.cardAdded}
            //   onCancel={this.cancelAddCreditCard}
            //   submitText={newCardSubmitText}
            //   title={newCardTitle}
            //   subTitle={newCardSubTitle}
            // />
            <AsyncStripeProvider apiKey={process.env.STRIPE_KEY}>
              <Elements>
                <StripeCreditCardForm
                  userId={userId}
                  onCardAdded={this.cardAdded}
                  onCancel={this.cancelAddCreditCard}
                  submitText={newCardSubmitText}
                  // className={classes.root}
                  title={newCardTitle}
                  subTitle={newCardSubTitle}
                />
              </Elements>
            </AsyncStripeProvider>
          ))}
        {showBankAccountFrom && (
          // <NewPlaidDialog
          //   open
          //   userId={userId}
          //   onCardAdded={this.cardAdded}
          //   onCancel={this.cancelAddBankAccount}
          // />
          <NewBankAccountDialog
            open
            userId={userId}
            onCardAdded={this.cardAdded}
            onCancel={this.cancelAddBankAccount}
          />
        )}
      </div>
    );
  }
}

export default Payment;
