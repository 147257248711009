import { filter, keys, forEach } from 'lodash'

export const appIdentifier = 'Roomiapp-'
let storage
export let storageEnabled = false

initializeStorage()
removeExpired()

export function initializeStorage() {
  try {
    window.localStorage.setItem('test', 'test')
    window.localStorage.removeItem('test')
    storage = window.localStorage
    storageEnabled = true
  } catch (exception) {
    storage = {
      setItem: (k, v) => {
        storage[k] = v
      },
      removeItem: k => delete storage[k],
      getItem: k => storage[k]
    }
  }
}

export function removeExpired() {
  const roomiKeys = filter(keys(storage), key => key.match(/^Roomiapp-/))
  forEach(roomiKeys, k => {
    const { isExpired } = getItem(k)
    if (isExpired) storage.removeItem(k)
  })
}

export function set(key, val) {
  storage.setItem(appIdentifier + key, window.JSON.stringify({ val }))
}

export function setWithExpiration(key, val, mins) {
  const expireTime = isNaN(mins) ? 8 : mins
  const expiresAt = new Date(Date.now() + expireTime * 1000 * 60)
  const wrapper = {
    val,
    expiresAt
  }
  storage.setItem(appIdentifier + key, window.JSON.stringify(wrapper))
}

export function get(key) {
  return getItem(appIdentifier + key)
}

export function remove(key) {
  return storage.removeItem(appIdentifier + key)
}

export function getItem(key) {
  const item = storage.getItem(key)
  const result = item ? JSON.parse(item) : item
  if (!result) return {}
  const { val, expiresAt } = result
  const now = Date.now()
  return {
    val: val,
    isExpired: expiresAt ? new Date(expiresAt) < now : false
  }
}

export function reset() {
  const roomiKeys = filter(keys(storage), key => key.match(/^Roomiapp-/))
  forEach(roomiKeys, storage.removeItem)
}
