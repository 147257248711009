import { all, spawn } from 'redux-saga/effects'
import AnnouncementSaga, {
  notificationSagaService as NotificationSaga,
  notificationReadSagaService as NotificationReadSaga
} from './announcement.saga'
import StreamSaga from './stream.saga'

export default function*() {
  yield all([
    spawn(StreamSaga),
    spawn(NotificationReadSaga),
    spawn(AnnouncementSaga),
    spawn(NotificationSaga)
  ])
}
