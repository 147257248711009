import { loadScript } from '../../shared/actions/scripts.action'

initializeGA()

export const startGoogleAnalytics = () => dispatch => {
  loadScript('GA', 'https://www.google-analytics.com/analytics.js')(dispatch)
}

function initializeGA() {
  window.GoogleAnalyticsObject = 'ga'
  window.ga =
    window.ga ||
    function() {
      ;(window.ga.q = window.ga.q || []).push(arguments)
    }
  window.ga.l = 1 * new Date()
  window.ga('create', process.env.GOOGLE_ANALYTICS_KEY, 'auto')
  window.ga('send', 'pageview')
}

export function sendEvent({ category, action, value }) {
  window.ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventValue: value
  })
}
