import { Collapse, FormGroup, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { RoomiCheckbox } from 'roomi/material';
import propValidator from 'roomi/utils/prop-validator';

class RoomiCheckboxGroup extends PureComponent {
  static propTypes = {
    onChange: propValidator({
      required: ['input.onChange'],
      type: 'function',
    }),
    value: propValidator({ required: ['input.value'] }),
    options: PropTypes.array.isRequired,
    itemClass: PropTypes.string,
    renderLabel: PropTypes.func,
    row: PropTypes.bool,
    titleKey: PropTypes.string,
    valueKey: PropTypes.string,
    showError: PropTypes.bool,
    input: propValidator({ required: ['onChange', 'value'], type: 'object' }),
    meta: propValidator({ required: ['onChange', 'value'], type: 'object' }),
  };

  onChange = ({ target: { value: _value } }) => {
    const {
      input = {},
      value = input.value,
      onChange = input.onChange,
    } = this.props;
    const index = value.indexOf(_value);
    const _values = [...value];
    if (index >= 0) {
      _values.splice(index, 1);
    } else {
      _values.push(_value);
    }
    onChange(_values);
    if (this.props.meta && !this.props.meta.touched) this.props.input.onBlur();
    if (this.props.meta && !this.props.meta.visited) this.props.input.onFocus();
  };

  render() {
    const {
      meta: { error, warning, touched } = {},
      showError = Boolean(touched && (error || warning)),
      value,
      onChange,
      input = { value, onChange },
      row,
      itemClass,
      options,
      valueKey,
      titleKey,
      renderLabel,
    } = this.props;
    return (
      <Fragment>
        <FormGroup row={row} className="checkbox-group">
          {options.map((option, i) => {
            const _value = String(!valueKey ? option : option[valueKey]);
            const label = !titleKey ? option : option[titleKey];
            const checked = input.value.indexOf(_value) >= 0;
            return (
              <RoomiCheckbox
                key={i}
                checked={checked}
                label={!renderLabel ? label : renderLabel(label)}
                itemClass={itemClass}
                onChange={this.onChange}
                value={_value}
              />
            );
          })}
        </FormGroup>
        <Collapse in={showError}>
          <FormHelperText error={showError}>{error || warning}</FormHelperText>
        </Collapse>
      </Fragment>
    );
  }
}

export default RoomiCheckboxGroup;
