const creditCardItemStyles = theme => ({
  creditCardItem: {
    border: `1px solid ${theme.palette.grey[300]}`,
    alignItems: 'stretch',
    '&$active': {
      borderColor: theme.palette.primary.main,
      '& $creditCardIconContainer': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  creditCardIconContainer: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  },
  creditCardNumberContainer: {
    backgroundColor: theme.palette.background.primary,
    minWidth: 180,
    padding: '0 10px'
  },
  active: {},
  checkIcon: {
    color: theme.palette.primary.main
  },
  institutionLogoContainer: {
    height: 38,
    width: 56
  },
  institutionLogoImage: {
    height: 38,
    width: 38,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  }
});

export default creditCardItemStyles;
