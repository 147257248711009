const maxScriptLoadTime = 4000;

export const load = (src) => {
  return new Promise((resolve, reject) => {
    const stopScriptLoading = setTimeout(reject, maxScriptLoadTime);
    const t = document.getElementById('react-root');
    const s = document.createElement('script');
    s.src = src;
    s.async = true;
    s.onload = s.onreadystatechange = function () {
      if (!this.readyState || this.readyState === 'complete') {
        clearTimeout(stopScriptLoading);
        resolve(this);
      }
    };
    s.onerror = s.onabort = reject;
    t.parentNode.insertBefore(s, t);
  });
};
