import { configFetched, configFetching, configError, updateExperiments } from '../actions/config.types';

export default (state = {}, { type, payload } = {}) => {
  switch (type) {
    case configFetching:
      return { fetching: true, fetched: false };
    case configFetched:
      return { fetching: false, fetched: true, data: payload };
    case configError:
      return { fetching: false, fetched: false, error: payload };
    case updateExperiments:
      return { ...state, data: { ...state.data, experiments: payload } };
    default:
      return state;
  }
};

