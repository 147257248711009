import {
  fetch,
  error,
  fetched,
  remove,
  addUserListing
} from './user-listings.types'

export const fetchUserListings = id => ({
  type: fetch,
  id
})

export const addUserListingAction = (id, listingIds) => ({
  type: addUserListing,
  id,
  listingIds
})

export const fetchedUserListings = (id, listingIds) => ({
  type: fetched,
  id,
  listingIds
})

export const errorUserListings = (id, errorMsg) => ({
  type: error,
  id,
  error: errorMsg
})

export const deleteUserListing = id => ({
  type: remove,
  id
})
