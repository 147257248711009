import { createSelector } from 'reselect'
import { map, filter, includes, get, size } from 'lodash'
import { getUserAge } from '../utils/user-fields.formatter'

export const getUserFromState = (state, id) => get(state, `users.data.${id}`)
export const getUserErrorFromState = (state, id) =>
  get(state, `users.${id}.error`)

export const getAnalyticsUser = createSelector(
  [getUserFromState, s => s.badges && s.badges.data],
  getAnalyticsUserObject
)

export const getUserFeeds = (state) => {
  return get(state, 'userFeeds.feeds') || [];
};

function getAnalyticsUserObject(user, badges) {
  if (!user) return null
  return {
    profileId: user._id,
    profileAge: getUserAge(user),
    profileFirstName: user.usrFirstName,
    profileLastName: user.usrLastName,
    corporate: user.corporate,
    numImages: size(user.urlOfPictureImages),
    userVerifications: map(
      filter(badges, badge => includes(user.badges, badge._id)),
      'name'
    ),
    profileDescription: user.biography,
    profileGender: user.usrGender,
    workHistory: map(user.workHistory, 'position')
  }
}
