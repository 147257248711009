import { call, put, takeLatest, select } from 'redux-saga/effects'
import { fetchingFeaturedListings } from '../actions/hot-content.types'
import {
  errorFetchingFeaturedListingsAction,
  fetchedFeaturedListingsAction
} from '../actions/hot-content.actions'
import { getFeaturedListings } from '../data/hot-content.data'
import { getHotContentSelector } from '../reducers/hot-content.selector'

function* fetchFeaturedListings() {
  try {
    const alreadyFetched = yield select(s =>
      getHotContentSelector(s, 'featuredListings')
    )
    if (alreadyFetched) return
    const featuredListings = yield call(getFeaturedListings)
    yield put(fetchedFeaturedListingsAction(featuredListings))
  } catch (e) {
    yield put(errorFetchingFeaturedListingsAction(e))
  }
}

function* hotContentSaga() {
  yield takeLatest(fetchingFeaturedListings, fetchFeaturedListings)
}

export default hotContentSaga
