import { emailRegexpTest } from 'roomi/utils';
import messages from './login-form.text';

const validate = ({ username, password }, { intl: { formatMessage } }) => {
  const errors = {};
  if (!password) errors.password = formatMessage(messages.missingPassword);
  if (!username) errors.username = formatMessage(messages.missingEmail);
  if (username && !emailRegexpTest(username))
    errors.username = formatMessage(messages.invalidEmail);
  return errors;
};

export default validate;
