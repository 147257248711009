import { put, takeLatest, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import history from '../../history'

import {
  initiateChat,
  deleteChat,
  fetchingRoomiMessages
} from '../actions/messages.types'
import {
  fetchCurrentChatUserInfo,
  fetchCurrentListingInfo
} from '../actions/conversation.types'
import {
  actionFetchingRoomiMessages,
  actionFetchedRoomiMessages,
  actionClearRoomiMessages,
  actionInitiateChatSuccess,
  actionInitiateChatError,
  actionDeleteChatSuccess,
  actionDeleteChatError
} from '../actions/messages.actions'

import {
  actionFetchCurrentChatUserInfoSuccess,
  actionFetchCurrentChatUserInfoError,
  actionFetchCurrentListingInfoSuccess,
  actionFetchCurrentListingInfoError
} from '../actions/conversation.actions'

import { get, post, remove } from '../../shared/utils/fetch.util'

const createChannel = recipientId => {
  const URL = '/v2/messages/stream/channels'
  const body = { recipient_id: recipientId }
  return post(URL, body)
}

export function* intiateChatSaga({ recipientId }) {
  if (!recipientId) return
  try {
    yield call(createChannel, recipientId)
    yield put(actionInitiateChatSuccess)
    yield call(
      [history, history.push],
      `/messages?recipientId=${recipientId}`,
      { recipientId }
    )
  } catch (err) {
    yield put(actionInitiateChatError)
  }
}

const deleteChannel = channelId => {
  const URL = `/v2/messages/stream/channels/${channelId}`
  return remove(URL)
}

export function* deleteChatSaga({ channelId }) {
  if (!channelId) return
  try {
    yield call(deleteChannel, channelId)
    yield put(actionDeleteChatSuccess)
  } catch (err) {
    yield put(actionDeleteChatError)
  }
}

const fetchUserMessages = () => get('/v2/messages/stream?status=delivered')

export function* fetchUserMessagesSaga() {
  try {
    const { data } = yield call(fetchUserMessages)
    yield put(actionFetchedRoomiMessages(data))
    yield delay(10000)
    yield put(actionFetchingRoomiMessages())
  } catch (err) {
    yield put(actionClearRoomiMessages())
  }
}

const fetchChatUserInfo = userId => get(`/v2/users/${userId}`)

export function* fetchChatUserInfoSaga({ userId }) {
  if (!userId) {
    yield put(actionFetchCurrentChatUserInfoSuccess(userId))
    return
  }
  try {
    const { data } = yield call(fetchChatUserInfo, userId)
    yield put(actionFetchCurrentChatUserInfoSuccess(userId, data.user))
  } catch (err) {
    yield put(actionFetchCurrentChatUserInfoError(userId))
  }
}

const fetchListingInfo = listingId => get(`/v2/listings/${listingId}`)

export function* fetchListingInfoSaga({ listingId }) {
  if (!listingId) return
  try {
    const { data: listingData } = yield call(fetchListingInfo, listingId)
    yield put(actionFetchCurrentListingInfoSuccess(listingId, listingData))
  } catch (err) {
    yield put(actionFetchCurrentListingInfoError(listingId))
  }
}

export default function* streamChatSaga() {
  yield takeLatest([initiateChat], intiateChatSaga)
  yield takeLatest([deleteChat], deleteChatSaga)
  yield takeLatest([fetchingRoomiMessages], fetchUserMessagesSaga)
  yield takeLatest([fetchCurrentChatUserInfo], fetchChatUserInfoSaga)
  yield takeLatest([fetchCurrentListingInfo], fetchListingInfoSaga)
}
