import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'browser.not.supported.title',
    defaultMessage: 'Oops! It looks like we are no longer supporting Internet Explorer.'
  },
  note: {
    id: 'browser.not.supported.note',
    defaultMessage: 'Please use a Chrome, Safari or Firefox browser to continue using the Roomi app.'
  }
});
