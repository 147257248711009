import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { each } from 'lodash';
import PropTypes from 'prop-types';
import { Handle, Range } from 'rc-slider';
import React, { PureComponent } from 'react';
import rangeSliderStyles from './range-slider.styles';
import SliderValuesTracker from './slider-values-tracker';

@withStyles(rangeSliderStyles)
class RangeSlider extends PureComponent {
  static propTypes = {
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    step: PropTypes.number,
    value: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    onAfterChange: PropTypes.func,
    minLabelFn: PropTypes.func,
    maxLabelFn: PropTypes.func,
    pushable: PropTypes.number,
    labelClass: PropTypes.string,
    handleClass: PropTypes.string,
    transformValues: PropTypes.func
  };

  state = {
    value: this.props.names ? this.props.names.map(name => this.props[name].input.value) : this.props.value
  };

  updateLocalValue = (value) => {
    this.props.transformValues && this.props.transformValues(value);
    this.setState({ value }, () => this.props.onChange && this.props.onChange(value));
  };

  updateExternalValue = (value) => {
    this.props.transformValues && this.props.transformValues(value);
    this.props.onAfterChange && this.props.onAfterChange(value);
    if (this.props.names) { // redux-form case (update values in redux)
      each(this.props.names, (item, i) => this.props[item].input.onChange(value[i]));
    }
  };

  handle = (props) => (
    <Handle
      value={props.value}
      offset={props.offset}
      key={props.index}
      className={classNames(props.className, this.props.classes.handle, this.props.handleClass)}
    />
  );

  render() {
    const {
      min,
      max,
      step = 1,
      classes,
      minLabelFn,
      maxLabelFn,
      pushable,
      labelClass
    } = this.props;
    const { value } = this.state;

    return (
      <div>
        <Range
          className={classes.rangeSlider}
          min={min}
          max={max}
          value={value}
          step={step}
          handle={this.handle}
          pushable={pushable}
          onChange={this.updateLocalValue}
          onAfterChange={this.updateExternalValue}
        />
        <SliderValuesTracker
          min={min}
          max={max}
          value={value}
          step={step}
          minLabel={minLabelFn(value)}
          maxLabel={maxLabelFn(value)}
          labelClass={labelClass}
        />
      </div>
    );
  }
}

export default RangeSlider;
