import createCachedSelector from 're-reselect';

export const payoutsEnabledSelector = state => state.config.data && state.config.data.instantBooking.payouts.enabled;

export const payoutFlowStepSelector = state => state.payout.step;

export const openAddPayoutDialogSelector = state => state.payout.openAddDialog;

export const getPaymentFormConfigSelector = state =>  
  state.payout.configs.data && state.payout.configs.data.bankAccountForm;

export const getPayoutSupportedCountriesSelector = state =>
  payoutsEnabledSelector(state)
    ? state.config.data.instantBooking.payouts.supportedCountries
      .map(country => country.code === 'UK' ? { ...country, code: 'GB' } : country) // Hacky way to set proper ISO code for United Kingdom. TODO: Remove once fixed on backend
    : [];

export const payoutsSupportedForCountrySelector = createCachedSelector(
  getPayoutSupportedCountriesSelector,
  (state, countryCode) => countryCode,
  (supportedCountries, countryCode) => supportedCountries.some(country => country.code === countryCode)
)(
  (state, countryCode) => countryCode
);
