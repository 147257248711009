import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Form, reduxForm } from 'redux-form';
import DynamicFormFields from './dynamic-form-fields.component';
import dynamicFormValidator from './dynamic-form.validator';
import { pick, reduce } from 'lodash';
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => {
  const fields = props.fields;
  const business = {};
  const billingAddress = {};
  const initialValues = reduce(fields, (agg, { name, prefill, type, group, placeholder, items }) => {    
    switch (type) {
      case 'dropdown':
        if (placeholder === 'State') {
          billingAddress.state = prefill;
        }
        break;
      case 'checkbox':
        agg[name] = Boolean(prefill);
        break;
      default:
        if (group === 'Billing Address') {          
          if (placeholder === 'City') billingAddress.city = prefill;
          if (placeholder === 'Zip') billingAddress.zipcode = prefill;
          if (placeholder === 'Address Line') billingAddress.address1 = prefill;
        }
        if (group === 'Business Information') {                    
          if (placeholder === 'Business Name') business.name = prefill;
          if (placeholder === 'Business Tax Id') business.taxId = prefill;
        }
        if (group !== 'Billing Address' && group !== 'Business Information') agg[name] = prefill || '';
    }
    return agg;
  },{});
  initialValues.business = business;
  initialValues.billingAddress = billingAddress;
  return { initialValues };
};

@connect(mapStateToProps)
@reduxForm({
  validate: dynamicFormValidator,
  form: 'bankAccountForm',
  enableReinitialize: true
})
class DynamicForm extends PureComponent {
  static propTypes = {
    form: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    country: PropTypes.string,
    accountType: PropTypes.string
  };

  onSubmit = (data) => {
    const fieldsToPick = this.props.fields.filter(field => field.includeInRequest).map(field => field.name);
    const formData = pick(data, fieldsToPick);
    formData.country = this.props.country;
    formData.account_holder_type = this.props.accountType;
    return this.props.onSubmit(formData);
  };

  render() {
    const { handleSubmit, fields, className } = this.props;
    return (
      <Form
        noValidate
        onSubmit={handleSubmit(this.onSubmit)}
        className={className}
      >
        <DynamicFormFields fields={fields}/>
      </Form>
    );
  }
}

export default DynamicForm;
