import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withBreakPoints from '../with-breakpoints/with-breakpoints.component'
import RoomiNavLink from '../roomi-nav-link/roomi-nav-link.component'

class NavigationLink extends PureComponent {
  static propTypes = {
    to: PropTypes.any.isRequired,
    openInTab: PropTypes.bool,
    disabled: PropTypes.bool,
    onRedirect: PropTypes.func
  }

  render() {
    const {
      children,
      openInTab,
      size,
      disabled,
      to,
      className = '',
      onRedirect,
      ...props
    } = this.props
    const isSmall = size === 'xs'
    if (openInTab && !isSmall) props.target = '_blank'
    return disabled ? (
      <div className={'fill-parent ' + className} {...props}>
        {children}
      </div>
    ) : (
      <RoomiNavLink
        to={to}
        className={className}
        onClick={onRedirect}
        {...props}
      >
        {children}
      </RoomiNavLink>
    )
  }
}

export default withBreakPoints(NavigationLink)
