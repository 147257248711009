import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { getCurrentUserFromState } from '../../../users/reducers/user.selector'
import { getActiveProfileBoostPlans } from 'roomi/reducers/config.selector'
import { fetchSponsorships } from '../../actions/sponsorships.actions'
import {
  hasPaidForProfileBoost,
  hasFetchedSponsorships
} from '../../reducers/sponsorships.selector'
import BoostProfileBanner from '../boost-profle-banner/boost-profile-banner.component'

const mapStateToProps = state => {
  return {
    me: getCurrentUserFromState(state),
    fetched: hasFetchedSponsorships(state),
    hasBoost: hasPaidForProfileBoost(state),
    plans: getActiveProfileBoostPlans(state)
  }
}

@connect(mapStateToProps, { fetchSponsorships })
class BoostProfileContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchSponsorships()
  }

  render() {
    const {
      me,
      hasBoost,
      plans,
      showButtonOnly = false,
      showIcon = true,
      fetched
    } = this.props

    if (!me || hasBoost || !fetched) return null

    return (
      <div style={{ maxWidth: '100%' }}>
        <BoostProfileBanner
          user={me}
          plans={plans}
          showButtonOnly={showButtonOnly}
          showIcon={showIcon}
        />
      </div>
    )
  }
}

export default BoostProfileContainer
