import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NavigationLink from 'roomi/components/navigation-link/navigation-link.component';
import { Badges } from 'roomi/common';
import { roomiGuestUser } from 'roomi/icons';
import userAvatarStyles from './user-avatar.styles';
import { withStyles } from '@material-ui/core';
import { LazyImage } from '../../../shared/components/lazy-image/lazy-image.component';

const IMAGE_PARAMS = { mask: 'ellipse' };

@withStyles(userAvatarStyles)
class UserAvatar extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    height: PropTypes.number,
    openInTab: PropTypes.bool,
    className: PropTypes.string,
    badges: PropTypes.bool,
    tooltip: PropTypes.bool,
  };

  render() {
    const {
      user,
      openInTab,
      height = 60,
      w = height,
      classes,
      children,
      badges,
      tooltip,
    } = this.props;
    const imgSrc = user.defaultImage || user.profileImage || roomiGuestUser;
    return (
      <NavigationLink
        className={classes.userAvatar}
        to={`/roommate/${user._id}`}
        openInTab={openInTab}
      >
        <div
          className={classes.userImageContainer}
          style={{ height: height + 6, width: w + 6 }}
        >
          <LazyImage
            alt={'user avatar'}
            height={height}
            width={height}
            mask={'ellipse'}
            key={user._id}
            customParams={IMAGE_PARAMS}
            crop="faces"
            fit="crop"
            src={imgSrc}
            className={classes.userImage}
          />
          {badges && (
            <div className={classes.badgesContainer}>
              <Badges
                type="user"
                tooltip={tooltip}
                ids={user.badges}
                className={classes.badge}
              />
            </div>
          )}
        </div>
        {children}
      </NavigationLink>
    );
  }
}

export default UserAvatar;
