import {
  scripts,
  scriptLoadFail,
  scriptLoadInit,
  scriptLoadSuccess
} from './scripts.action.types'
import { load } from '../../shared/utils/script.loader'

export const loadScript = (key, src) => dispatch => {
  scripts[key] = src
  dispatch(loadScriptInit(key))
  return load(src).then(
    () => dispatch(loadScriptSuccess(key)),
    () => dispatch(loadScriptFail(key))
  )
}

function loadScriptInit(key) {
  return {
    type: `${key}_${scriptLoadInit}`,
    payload: 'INIT'
  }
}

function loadScriptSuccess(key) {
  return {
    type: `${key}_${scriptLoadSuccess}`,
    payload: 'SUCCESS'
  }
}

function loadScriptFail(key) {
  return {
    type: `${key}_${scriptLoadFail}`,
    payload: 'ERROR'
  }
}
