import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginModal from '../login-modal/login-modal.component';

class EnsureLoginButton extends PureComponent {
  static propTypes = {
    preSignUp: PropTypes.func,
    afterLogin: PropTypes.func,
    isDisabled: PropTypes.bool,
    onCancel: PropTypes.func
  };

  state = {
    open: false
  };

  openModal = () => {
    !this.props.isDisabled && this.setState({ open: true });
  };

  handleAfterLogin = () => {
    if (!this.props.isDisabled) {
      this.setState({ open: false });
      this.props.afterLogin && this.props.afterLogin();
    }
  };

  onCancel = () => {
    this.props.onCancel && this.props.onCancel();
    this.setState({ open: false });
  };

  render() {
    const { user, preSignUp, children, className = '' } = this.props;
    const { open } = this.state;
    return user ? (
      <div className={className} onClick={this.handleAfterLogin}>
        {children}
      </div>
    ) : (
      <div className={className}>
        <div onClick={this.openModal}>{children}</div>
        {open && (
          <LoginModal
            onCancel={this.onCancel}
            onAuthentication={this.handleAfterLogin}
            preSignUp={preSignUp}
            isOpen
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ user: { data } }) => ({ user: data });

export default connect(mapStateToProps)(EnsureLoginButton);
