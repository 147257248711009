const AVATAR_WIDTH = 100;

const listingCardStyles = theme => ({
  listingCard: {
    height: '100%',
    position: 'relative',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.grey[200]}`,
    fontFamily: theme.typography.fontFamily
  },
  badgesContainer: {
    position: 'absolute',
    top: theme.spacing.unit,
    cursor: 'default',
    left: theme.spacing.unit,
    zIndex: 1
  },
  actionsContainer: {
    position: 'absolute',
    cursor: 'default',
    zIndex: 1,
    top: 12,
    right: 12
  },
  carouselContainer: {
    background: theme.palette.grey[200],
    width: '100%',
    paddingBottom: '67%',
    position: 'relative',
    '&:hover + $bottomContainer > $infoContainer': {
      color: theme.palette.primary.main
    }
  },
  bottomContainer: {
    display: 'flex',
    background: theme.palette.common.white,
    '& > *': {
      maxHeight: 80
    }
  },
  infoContainer: {
    padding: 12,
    flex: 1,
    '&:hover': {
      color: theme.palette.primary.main
    },
    '&$withOwner': {
      maxWidth: `calc(100% - ${AVATAR_WIDTH}px)`,
      paddingRight: 0
    }
  },
  ownerName: {
    fontSize: 12,
    fontWeight: 300,
    lineHeight: '26px',
    textAlign: 'center'
  },
  avatarContainer: {
    width: AVATAR_WIDTH,
    position: 'relative',
    bottom: 36
  },
  withOwner: {}
});

export default listingCardStyles;
