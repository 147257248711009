const userOptionStyles = theme => ({
  content: {
    fontFamily: theme.typography.fontFamily,
    '& > .active span': {
      color: theme.palette.primary.main
    }
  },
  item: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    color: theme.palette.common.black,
    fontSize: 14
  }
})

export default userOptionStyles
