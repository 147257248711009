const linkedInStyles = theme => ({
  linkedInConnect: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 15,
    display: 'inline-block',
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary[900]
    },
    '&$disabled': {
      opacity: 0.6,
      pointerEvents: 'none'
    }
  },
  disabled: {},
  fullWidth: {
    width: '100%'
  }
});

export default linkedInStyles;
