import { fetching, fetched, error } from '../actions/neighborhoods.types';
import update from 'immutability-helper';

const initialState = { data: {} };

export default (state = initialState, { type, payload, id, error: errorMsg } = {}) => {
  let container;
  switch (type) {
  case fetching:
    container = { fetched: false };
    return update(state, { [id]: { $set: container } });
  case fetched:
    container = { fetched: true };
    return update(state, { [id]: { $set: container }, data: { [id]: { $set: payload } } });
  case error:
    container = { error: errorMsg, fetched: false };
    return update(state, { [id]: { $set: container } });
  default:
    return state;
  }
};

