import {
  errored,
  fetching,
  fetched,
  reset,
  newImport
} from '../actions/import-requests.types'

const initialState = { fetching: false, fetched: false, data: {} }

const dataMerger = (data, newRequest) => {
  const newData = {
    [newRequest._id]: newRequest
  }
  return { ...data, ...newData }
}

export default (
  state = initialState,
  { type, requests, error, imported } = {}
) => {
  switch (type) {
    case fetching:
      return { fetching: true, fetched: false, data: state.data }
    case fetched:
      return { fetching: false, fetched: true, data: requests }
    case newImport:
      return { data: dataMerger(state.data, imported) }
    case errored:
      return { fetching: false, fetched: false, error: error }
    case reset:
      return initialState
    default:
      return state
  }
}
